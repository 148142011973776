/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Connector Management filter definitions
 * @module Epic.AppOrchard.ConnectorManagement.ColumnDefinitions
 */

import { IColumnDefinition } from "ao/types";
import { IConnectorAppListItem, IConnectorManagementLookups } from "ao/types/connectorManagement";
import { AppListColumnKeys, OrgListColumnKeys, SharedColumnKeys } from "./constants";

export const AppListColumnDefs: IColumnDefinition[] = [
	{
		key: SharedColumnKeys.id,
		name: "Connector App ID",
		tooltip: "Connector App ID",
		defaultShow: true,
		isSortable: true,
		minWidth: 160,
		maxWidth: 160,
		width: 160,
	},
	{
		key: SharedColumnKeys.trackId,
		name: "Track ID",
		tooltip: "Track App ID",
		defaultShow: false,
		isSortable: true,
		minWidth: 100,
		maxWidth: 100,
		width: 100,
	},
	{
		key: AppListColumnKeys.clientAppId,
		name: "Client App ID",
		tooltip: "Client App ID",
		defaultShow: true,
		isSortable: true,
		minWidth: 140,
		maxWidth: 140,
		width: 140,
	},
	{
		key: SharedColumnKeys.name,
		name: "Name",
		tooltip: "App Name",
		defaultShow: true,
		isSortable: true,
		width: 200,
		minWidth: 200,
		maxWidth: 300,
	},
	{
		key: AppListColumnKeys.appCategories,
		name: "App Categories",
		tooltip: "App Categories",
		defaultShow: true,
		isSortable: false,
		width: 300,
		minWidth: 300,
		maxWidth: 350,
	},
	{
		key: SharedColumnKeys.reviewStatus,
		name: "Review Status",
		tooltip: "Review Status of App",
		defaultShow: false,
		isSortable: true,
		minWidth: 140,
		maxWidth: 140,
		width: 140,
	},
	{
		key: SharedColumnKeys.reviewer,
		name: "Reviewer",
		tooltip: "Reviewer of App",
		defaultShow: false,
		isSortable: true,
		minWidth: 150,
		maxWidth: 250,
		width: 150,
	},
	{
		key: SharedColumnKeys.reviewedOn,
		name: "Reviewed On",
		tooltip: "Date App was reviewed", // Last reviewed?
		defaultShow: false,
		isSortable: true,
		minWidth: 125,
		maxWidth: 125,
		width: 125,
	},
	{
		key: SharedColumnKeys.comment,
		name: "Comment",
		tooltip: "Comment",
		defaultShow: false,
		isSortable: true,
		minWidth: 250,
		maxWidth: 450,
		width: 250,
	},
];

export const OrgListColumnDefs: IColumnDefinition[] = [
	{
		key: SharedColumnKeys.id,
		name: "Connector Org ID",
		tooltip: "Connector Org ID",
		defaultShow: true,
		isSortable: true,
		minWidth: 150,
		maxWidth: 150,
		width: 150,
	},
	{
		key: SharedColumnKeys.trackId,
		name: "Track ID",
		tooltip: "Organization Track ID",
		defaultShow: false,
		isSortable: true,
		minWidth: 100,
		maxWidth: 100,
		width: 100,
	},
	{
		key: OrgListColumnKeys.organizationId,
		name: "Organization ID",
		tooltip: "Client Organization ID",
		defaultShow: true,
		isSortable: true,
		minWidth: 150,
		maxWidth: 150,
		width: 150,
	},
	{
		key: SharedColumnKeys.name,
		name: "Name",
		tooltip: "Organization Name",
		defaultShow: true,
		isSortable: true,
		width: 250,
		minWidth: 250,
		maxWidth: 300,
	},
	{
		key: SharedColumnKeys.reviewStatus,
		name: "Review Status",
		tooltip: "Review Status of Organization",
		defaultShow: false,
		isSortable: true,
		minWidth: 140,
		maxWidth: 140,
		width: 140,
	},
	{
		key: SharedColumnKeys.reviewer,
		name: "Reviewer",
		tooltip: "Name of Organization Reviewer",
		defaultShow: false,
		isSortable: true,
		minWidth: 150,
		maxWidth: 250,
		width: 150,
	},
	{
		key: SharedColumnKeys.reviewedOn,
		name: "Reviewed On",
		tooltip: "Date Organization was Reviewed",
		defaultShow: false,
		isSortable: true,
		minWidth: 125,
		maxWidth: 125,
		width: 125,
	},
	{
		key: SharedColumnKeys.comment,
		name: "Comment",
		tooltip: "Comment",
		defaultShow: false,
		isSortable: true,
		minWidth: 250,
		maxWidth: 450,
		width: 250,
	},
	{
		key: OrgListColumnKeys.type,
		name: "Type",
		tooltip: "Type",
		defaultShow: true,
		isSortable: true,
		minWidth: 100,
		maxWidth: 300,
		width: 200,
	},
	{
		key: OrgListColumnKeys.website,
		name: "Website",
		tooltip: "Website",
		defaultShow: true,
		isSortable: false,
		minWidth: 150,
		maxWidth: 150,
		width: 150,
	},
];

export const AdminOnlyColumnDefs: IColumnDefinition[] = [
	{
		key: SharedColumnKeys.actionLinks,
		name: "Actions",
		tooltip: "Actions",
		defaultShow: true,
		isSortable: false,
		width: 250,
		minWidth: 250,
		maxWidth: 400,
	},
];

export const AppListAdminColumnDefs: IColumnDefinition[] = AppListColumnDefs.concat(AdminOnlyColumnDefs);

export const OrgListAdminColumnDefs: IColumnDefinition[] = OrgListColumnDefs.concat(AdminOnlyColumnDefs);

export function getSharedColumnTitle(record: IConnectorAppListItem, column: IColumnDefinition): string {
	switch (column.key) {
		case SharedColumnKeys.id:
			return record.id.toString();
		case SharedColumnKeys.trackId:
			return record.trackId ? record.trackId.toString() : "";
		case SharedColumnKeys.name:
			return record.name ? record.name : "";
		case SharedColumnKeys.reviewStatus:
			return record.reviewStatus.name ?? "";
		case SharedColumnKeys.reviewer:
			return record.reviewer?.name ?? "";
		case SharedColumnKeys.reviewedOn:
			return record.reviewedOn ? new Date(record.reviewedOn).toLocaleDateString() : "";
		case SharedColumnKeys.comment:
			return record.comment ?? "";
		default:
			return "";
	}
}

export function getSharedColumnText(
	record: IConnectorAppListItem,
	column: IColumnDefinition,
	lookups: IConnectorManagementLookups,
): string {
	switch (column.key) {
		case SharedColumnKeys.id:
			return record.id.toString();
		case SharedColumnKeys.trackId:
			return record.trackId ?? "";
		case SharedColumnKeys.name:
			return record.name ?? "";
		case SharedColumnKeys.reviewStatus:
			return record.reviewStatus.name ?? "";
		case SharedColumnKeys.reviewer:
			return record.reviewer?.name ?? "";
		case SharedColumnKeys.reviewedOn:
			return record.reviewedOn ? new Date(record.reviewedOn).toLocaleDateString() : "";
		case SharedColumnKeys.comment:
			return record.comment ?? "";
		default:
			return "";
	}
}

export function getConnectorAppColumnTitle(
	connectorApp: IConnectorAppListItem,
	column: IColumnDefinition,
): string {
	switch (column.key) {
		case AppListColumnKeys.clientAppId:
			return connectorApp.clientAppId?.toString() ?? "";
		case AppListColumnKeys.appCategories:
			return connectorApp.appCategories
				? connectorApp.appCategories.map((category) => category.categoryName).join(", ")
				: "";
	}
	return "";
}

export function getConnectorAppColumnText(
	connectorApp: IConnectorAppListItem,
	column: IColumnDefinition,
	lookups: IConnectorManagementLookups,
): string {
	switch (column.key) {
		case AppListColumnKeys.clientAppId:
			return connectorApp.clientAppId?.toString() ?? "";
	}
	return "";
}
