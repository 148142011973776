/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Component for editing and adding Redirect URIs.
 * Can be reused in AO
 */

import { CloseIcon } from "@chakra-ui/icons";
import { Box, Button, Flex, VStack } from "@chakra-ui/react";
import { hasCustomUriScheme } from "ao/utils/helpers";
import React, { memo, useCallback, useMemo } from "react";
import { FieldError } from "react-hook-form";
import { ErrorMessage, UrlInput } from "..";

interface IEndpointURI {
	onChangeCallback: (url: string, remove: boolean, index: number) => void;
	readonly?: boolean;
	value: string;
	index: number;
	error?: FieldError;
	ariaLabel?: string;
}

const RedirectUriRow: React.FunctionComponent<IEndpointURI> = (props: IEndpointURI): JSX.Element => {
	const { onChangeCallback, readonly, value, index, error, ariaLabel } = props;

	const handleOnChangeRemove = (_e: React.MouseEvent<HTMLSpanElement>): void => {
		onChangeCallback(value, true, index);
	};

	const handleRowChange = useCallback(
		(e: string) => {
			onChangeCallback(e, false, index);
		},
		[index, onChangeCallback],
	);

	const hasCustomPrefix = hasCustomUriScheme(value);

	return (
		<>
			<Flex bg="rgb(239,239,239)" borderRadius="4px">
				<Box flex="1">
					<UrlInput
						url={value}
						readonly={readonly}
						placeholder={hasCustomPrefix ? "myApp://callback" : "www.myApp.com/callback"}
						onChangeCallback={handleRowChange}
						backgroundColor="white"
						borderRadius="0"
						isInvalid={!!error?.message}
						aria-label={ariaLabel}
						allowCustomScheme
					/>
				</Box>
				<Box alignItems="center" display="flex">
					{!readonly ? (
						<Button
							bg="rgb(239,239,239)"
							display="inline"
							borderLeftRadius="0px"
							onClick={handleOnChangeRemove}
							tabIndex={0}
							aria-label={"Remove" + ariaLabel}
						>
							<CloseIcon fontSize="0.75rem" />
						</Button>
					) : (
						<Box as="span" display="inline" borderLeftRadius="0px" width="38.5px"></Box>
					)}
				</Box>
			</Flex>
			<ErrorMessage message={error?.message} isNotForm />
		</>
	);
};

interface IOnChangeCallback {
	(value: string[]): void;
}

interface IProps {
	rows: string[];
	readonly?: boolean;
	onChangeCallback: IOnChangeCallback;
	errors: FieldError[];
	ariaLabel: string;
	isLiveEditMode?: boolean;
	lastSavedUriIndex?: number;
}

export const EndpointURIs: React.FunctionComponent<IProps> = memo(
	(props: IProps): JSX.Element => {
		const {
			rows,
			readonly,
			onChangeCallback,
			errors,
			ariaLabel,
			isLiveEditMode,
			lastSavedUriIndex,
		} = props;
		const handleNewRow = (): void => {
			onChangeCallback([...rows, "https://"]);
		};

		const lastSavedIndex = lastSavedUriIndex !== undefined ? lastSavedUriIndex : rows.length - 1;

		const updateOrRemoveUrl = useCallback(
			(url: string, remove: boolean, index: number) => {
				let updatedRows: string[];

				if (remove) {
					updatedRows = rows.filter((item, itemIndex) => itemIndex !== index);
				} else {
					// update URL
					updatedRows = rows.map((item, itemIndex) => (itemIndex === index ? url : item));
				}
				onChangeCallback(updatedRows);
			},

			[onChangeCallback, rows],
		);

		const rowlist = useMemo(
			() =>
				rows.map((redirect: string, index: number) => {
					return (
						<RedirectUriRow
							index={index}
							key={index}
							// we only allow vendors to add new URIs to a live app
							readonly={isLiveEditMode && index > lastSavedIndex ? false : readonly}
							value={redirect}
							error={errors?.[index]}
							ariaLabel={ariaLabel}
							onChangeCallback={updateOrRemoveUrl}
						/>
					);
				}),
			[ariaLabel, errors, isLiveEditMode, lastSavedIndex, readonly, rows, updateOrRemoveUrl],
		);

		return (
			<>
				<VStack spacing={1} align="stretch">
					{rowlist.length > 0 && <>{rowlist}</>}
					{(!readonly || isLiveEditMode) && (
						<Button
							bg="none"
							color="royalblue"
							border="dotted"
							borderColor="gray"
							borderWidth={"1px"}
							fontWeight="400px"
							onClick={handleNewRow}
							aria-label={ariaLabel + "Add a URI"}
							title={"Add a Endpoint URI"}
						>
							Add a URI
						</Button>
					)}
				</VStack>
			</>
		);
	},
);
