/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Button to mark something as a favorite/default which is disabled when the current item already is the favorite
 * @module Epic.AppOrchard.Core.FavoriteButton
 */

import { ButtonProps, IconButton } from "@chakra-ui/react";
import React, { FC, memo } from "react";
import { AiFillHome, AiOutlineHome } from "react-icons/ai";

interface IProps {
	onClick: () => void;
	/** Whether the current selected item is the favorite already */
	favoriteSelected: boolean;
	isSaving: boolean;
	/** button size */
	size: string | undefined;
	/** title/helptext for when selected item is the favorite already */
	favoriteSelectedTitle: string;
	/** title/helptext for when selected item is the not the favorite */
	favoriteNotSelectedTitle: string;
}

type Props = IProps & ButtonProps;

/** Button to mark something as a favorite/default which is disabled when the current item already is the favorite */
export const FavoriteButton: FC<Props> = memo((props: Props) => {
	const {
		onClick,
		favoriteNotSelectedTitle,
		favoriteSelected,
		favoriteSelectedTitle,
		size,
		isSaving,
		...rest
	} = props;

	return (
		<IconButton
			{...rest}
			color="#333"
			background={favoriteSelected ? "gray.300" : "white"}
			_hover={{
				background: "gray.300",
			}}
			border="1px solid #A0AEC0"
			style={{ marginInlineStart: "0" }}
			borderLeft={{ base: "1px", lg: "0" }}
			size={size}
			title={favoriteSelected ? favoriteSelectedTitle : favoriteNotSelectedTitle}
			aria-label={favoriteSelected ? favoriteSelectedTitle : favoriteNotSelectedTitle}
			onClick={onClick}
			isLoading={isSaving}
			disabled={favoriteSelected || isSaving}
			icon={favoriteSelected ? <AiFillHome size={18} /> : <AiOutlineHome size={18} />}
		></IconButton>
	);
});
