/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file ShowroomFooter component
 * @module Epic.AppOrchard.Frame.ShowroomFooter
 */

import { Box, Center, Grid, GridItem, LinkProps, Wrap } from "@chakra-ui/react";
import { showroomText } from "ao/chakraTheme";
import { useSiteInfoState } from "ao/state/siteInfo";
import { ContactSubject, IUserSecurity } from "ao/types";
import React, { FC, memo, useCallback, useState } from "react";
import { AOLink, MessageForm } from "../Core";
import { ContentWidth } from "../Showroom/Helper";

interface IProps {
	userSecurity: IUserSecurity;
}

interface IFooterLinkProps {
	url: string;
	name: string;
	title?: string;
}

type FooterLinkProps = IFooterLinkProps & LinkProps;
const FooterLink: FC<FooterLinkProps> = memo((props) => {
	const { url, name, title, ...rest } = props;
	return (
		<Box fontSize="1.0em" fontFamily="Jost" fontWeight="400">
			<AOLink
				textDecoration="none"
				url={url}
				title={title}
				fontWeight="600"
				color={`${showroomText} !important`}
				_hover={{ textDecoration: "underline" }}
				{...rest}
			>
				{name}
			</AOLink>
		</Box>
	);
});

export const ShowroomFooter: FC<IProps> = (props) => {
	const { userSecurity } = props;

	const [showMessageForm, setShowMessageForm] = useState(false);
	const hideMessageForm = useCallback(() => setShowMessageForm(false), []);

	const onShowMessageFormClicked = useCallback((ev: React.MouseEvent<HTMLAnchorElement>) => {
		setShowMessageForm(true);
		ev.preventDefault();
		ev.stopPropagation();
		return false;
	}, []);

	const { siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);

	return (
		<>
			<Box
				as="footer"
				backgroundColor={"showroomAccent.500"}
				h="auto"
				boxShadow="0px 3px 6px #00000029;"
			>
				<Box maxW={ContentWidth} m="0 auto" height="100%">
					<Grid templateColumns="repeat(2, 1fr)" gap={15} w="100%" minH="85px">
						<GridItem colSpan={{ base: 2, lg: 1 }} display="grid">
							<Center py="5px">
								<Wrap spacing="20px" justify="center">
									<FooterLink url="#" onClick={onShowMessageFormClicked} name="Contact" />
									<FooterLink
										url={`${siteInfo.vendorServicesUrl}Resources/Privacy`}
										name="Privacy Policy"
									/>
									<FooterLink
										url={`${siteInfo.vendorServicesUrl}Home`}
										name="Vendor Services"
									/>
									<FooterLink
										url={`${siteInfo.vendorServicesUrl}FAQ/Index`}
										name={"Frequently Asked Questions (FAQs)"}
									/>
								</Wrap>
							</Center>
						</GridItem>

						<GridItem
							colSpan={{ base: 2, lg: 1 }}
							alignItems="center"
							display="grid"
							fontFamily="Jost"
							fontWeight="300"
						>
							<Box
								color={`${showroomText} !important`}
								float="right"
								textAlign={{ base: "center", lg: "right" }}
								pr={{ base: "0", lg: "20px" }}
								flexGrow={1}
								lineHeight={{ base: "1em", sm: "unset" }}
							>
								<Box fontSize="x-small" fontWeight="500">
									Copyright &#169; {new Date().getFullYear().toString()} Epic Systems
									Corporation
								</Box>
							</Box>
						</GridItem>
					</Grid>
				</Box>
			</Box>
			<MessageForm
				isOpen={showMessageForm}
				onClose={hideMessageForm}
				userSecurity={userSecurity}
				defaultSubject={ContactSubject.ShowroomInquiry}
			/>
		</>
	);
};
