/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Warning user is about to be logged out
 * @module Epic.AppOrchard.Frame.LogOutWarning
 */

import { Box, Modal, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { pluralIfNeeded } from "ao/utils/helpers";
import React, { FC, memo, useEffect, useState } from "react";
import { Shell } from "./shell";

export const LogOutWarning: FC = memo(() => {
	const timerStartSeconds = 60;

	//#region state/hooks
	const [logoutTimer, setLogoutTimer] = useState(timerStartSeconds);
	const [isOpen, setIsOpen] = useState(false);
	const [refreshingSession, setRefreshingSession] = useState(false);

	useEffect(() => {
		const showWarningInterval = window.setInterval(() => {
			setIsOpen(Shell.ShowingLogoutWarning());
		}, 1000);

		return () => window.clearInterval(showWarningInterval);
	}, []);

	useEffect(() => {
		let logoutInterval: number | undefined;

		if (isOpen) {
			logoutInterval = window.setInterval(
				() => setLogoutTimer((logoutTimer) => Math.max(logoutTimer - 1, 0)),
				1000,
			);
		} else {
			setLogoutTimer(timerStartSeconds); // reset countdown timer on close
			clearInterval(logoutInterval);
			setRefreshingSession(false);
		}

		return () => clearInterval(logoutInterval);
	}, [isOpen]);

	//#endregion

	return (
		<Modal onClose={() => setRefreshingSession(true)} isOpen={isOpen} isCentered size="full">
			<ModalOverlay />
			<ModalContent
				onClick={() => setRefreshingSession(true)}
				w={{ base: "80%", lg: "35%" }}
				p="1em"
				minH="4em"
			>
				<Box margin="auto">
					{refreshingSession
						? "Your session is being refreshed."
						: `Due to inactivity you will be logged out in ${logoutTimer} ${pluralIfNeeded(
								"second",
								logoutTimer,
						  )}.`}
				</Box>
			</ModalContent>
		</Modal>
	);
});
