/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Users give consent/decline to overall site privacy policy
 * @module Epic.AppOrchard.Frame.OverallPrivacyPolicy
 */

import {
	Box,
	Button,
	Checkbox,
	Divider,
	FormControl,
	Heading,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { useDispatch } from "@epic/react-redux-booster";
import { config } from "ao/appConfig";
import { agreeToPrivacyPolicy } from "ao/data";
import { updateUserSecurity } from "ao/store-methods/userSecurity";
import { IUserSecurity } from "ao/types";
import { getCurrentLocation } from "ao/utils/helpers";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { ChangeEvent, FC, useCallback, useState } from "react";
import { AOLink, LinkButton, StatusModal } from "../Core";

interface IProps {
	userSecurity: IUserSecurity;
}

/**
 * Users give consent/decline to overall site privacy policy.
 *
 * This cannot be fully tested until the /Home/Index page is converted to React
 * 		since we redirect to that page server-side when the privacy policy needs to be filled out so this component cannot be hit.
 *
 *  NOTE: do not wrap this component in memo since it needs to rerender when the current page changes and that is not a prop
 */
export const OverallPrivacyPolicy: FC<IProps> = (props: IProps) => {
	const { userSecurity } = props;
	//#region state/hooks
	const currentPage = getCurrentLocation().toLocaleLowerCase();
	const isOpen =
		userSecurity.userId !== null && // user is logged in
		!userSecurity.isEpicUser &&
		!userSecurity.isEpicCustomer &&
		userSecurity.agreedToPrivacyPolicy === null &&
		currentPage !== "resources/privacy" &&
		currentPage !== "account/create";

	const [agreeChecked, setAgreeChecked] = useState(false);
	const [hasError, setHasError] = useState(false);
	const dispatch = useDispatch();

	const handleAgreeChecked = useCallback(
		(ev: ChangeEvent<HTMLInputElement>) => setAgreeChecked(ev.target.checked),
		[],
	);

	const [savingState, agreeToPrivacyPolicyFn] = useAsync(agreeToPrivacyPolicy, {
		executeImmediately: false,
		displayName: "agreeToPrivacyPolicy",
		onSuccess: (response) => {
			if (response.Data.Success) {
				updateUserSecurity(dispatch, { ...userSecurity, agreedToPrivacyPolicy: new Date() });
				return;
			}
			setHasError(true);
		},
		onFailure: () => setHasError(true),
	});
	const isSaving = isInFlight(savingState);

	const handleAgreeClicked = useCallback(() => agreeToPrivacyPolicyFn(), [agreeToPrivacyPolicyFn]);

	//#endregion

	return (
		<>
			<Modal isOpen={isOpen} onClose={() => {}} size="2xl" closeOnEsc={false}>
				<ModalOverlay />
				<ModalContent>
					<ModalHeader pb="0">
						<Heading fontSize="1.5em" fontWeight="normal" paddingBottom={"5px"}>
							Privacy Policy
						</Heading>
					</ModalHeader>
					<ModalBody>
						<Divider />
						<Box mt="1em">
							By checking the box below, you agree that Epic Systems Corporation ("Epic"), a
							company based in the United States of America, may control and process your data
							for the purposes described in the {config.SiteName}{" "}
							<AOLink url="Resources/Privacy" target="_blank" color="#2c6da5">
								Privacy Policy
							</AOLink>
							. The types of data collected as you access {config.SiteName} are listed in the{" "}
							<AOLink url="Resources/Privacy" target="_blank" color="#2c6da5">
								Privacy Policy
							</AOLink>
							.
						</Box>
						<Box mt="1em">
							You are not legally or contractually obligated to provide your data to Epic. Even
							if you give your consent by checking this box, you may later withdraw your consent
							by contacting Epic via the {config.SiteName} contact form. If you do not give your
							consent to use of your data, or you withdraw your consent, you may not be able to
							use {config.SiteName}.
						</Box>
						<FormControl mt="1em">
							<Checkbox
								isChecked={agreeChecked}
								onChange={handleAgreeChecked}
								disabled={isSaving}
								fontWeight="bold"
							>
								I agree
							</Checkbox>
						</FormControl>
					</ModalBody>
					<Divider />
					<ModalFooter>
						<Button
							colorScheme="green"
							onClick={handleAgreeClicked}
							disabled={!agreeChecked || isSaving}
							title={!agreeChecked ? "You need to agree to the terms before continuing" : ""}
							mr={2}
						>
							Agree
						</Button>
						<LinkButton
							colorScheme="blue"
							as={AOLink}
							url="Account/LogOff"
							disabled={isSaving}
							color="white"
						>
							Log Out
						</LinkButton>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<StatusModal
				title="Error"
				message={
					"An unexpected error occurred. Please try again later. If you repeatedly get this error contact your Epic representative."
				}
				isOpen={hasError}
				onClose={useCallback(() => setHasError(false), [])}
				status="error"
			></StatusModal>
		</>
	);
};
