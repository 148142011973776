/**
 * @copyright Copyright 2024-2024 Epic Systems Corporation
 * @file Info message pill
 * @module Epic.AppOrchard.Core.InfoMessage
 */

import { Flex, FlexProps } from "@chakra-ui/react";
import { FC, memo } from "react";

interface IErrorProps {
	message: string | undefined;
}

type IProps = FlexProps & IErrorProps;

export const InfoMessage: FC<IProps> = memo((props: IProps) => {
	const { message, ...rest } = props;

	return message && message.length > 0 ? (
		<Flex
			{...rest}
			backgroundColor={rest.backgroundColor ? rest.backgroundColor : "blue.100"}
			display="inline-block"
			marginTop={rest.mt || rest.marginTop || "7px"}
			padding="2px 8px"
			borderRadius="15px"
			color={rest.color ? rest.color : "rgb(80,50,0)"}
		>
			{message}
		</Flex>
	) : null;
});
