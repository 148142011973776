/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file showroom store updater methods
 * @module Epic.AppOrchard.StoreMethods.Showroom
 */

import { IDispatch } from "@epic/react-redux-booster";
import { IShowroomHomepage } from "ao/components/Showroom/data/data";
import { showroomActions } from "ao/state/showroom";
import { IThumbnailDictionary, ThumbnailActions } from "ao/state/showroomThumbnails";

export function updateShowroom(dispatch: IDispatch, homepageData?: IShowroomHomepage): void {
	if (homepageData) {
		dispatch(showroomActions.setState(homepageData));
	}
}

export function updateShowroomThumbnails(dispatch: IDispatch, thumbnails?: IThumbnailDictionary): void {
	dispatch(ThumbnailActions.setState(thumbnails ?? {}));
}
