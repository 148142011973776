/**
 * @copyright Copyright 2019-2023 Epic Systems Corporation
 * @file hook to update document title
 * @module Epic.AppOrchard.Hooks.UseDocumentTitle
 */

import { useAsync } from "@epic/react-async-hook";
import { IDispatch } from "@epic/react-redux-booster";
import { useEffect, useMemo } from "react";
import { getFallbackSiteInfo, getSiteInfo } from "../data";
import { useSiteInfoState } from "../state/siteInfo";
import { updateSiteInfo } from "../store-methods/siteInfo";

/**
 * set the document's title + Vendor Services site prefix when it changes
 * Loads Vendor Services site name from server or store if already cached there
 *
 * @param pageName name for this specific page
 */
export function useDocumentTitle(dispatch: IDispatch, pageName: string): void {
	// fetch site info from store
	const { siteInfo, hasLoaded: siteInfoLoaded } = useSiteInfoState((selectors) => selectors.getState(), []);
	const fallbackSiteInfo = useMemo(() => getFallbackSiteInfo(), []);

	useAsync(getSiteInfo, {
		executeImmediately: !siteInfoLoaded,
		displayName: "getSiteInfo",
		onSuccess: (siteInfo) => updateSiteInfo(dispatch, siteInfo),
		onFailure: () => updateSiteInfo(dispatch, { ...fallbackSiteInfo }),
	});

	// construct title - use site name loaded from server (e.g. Vendor Services [Dev] Admin) or default
	const title = pageName + " - " + (siteInfoLoaded ? siteInfo.siteName : fallbackSiteInfo.siteName);

	useEffect(() => {
		document.title = title;
	}, [dispatch, siteInfoLoaded, title]);
}
