/**
 * @copyright Copyright 2021-2022 Epic Systems Corporation
 * @file Dropdown menu item (similar to Chakra MenuItem component) used by the DropdownMenu component
 * @module Epic.AppOrchard.Core.DropdownMenuItem
 */
import { Button, ButtonProps } from "@chakra-ui/react";
import React, { FC, memo, ReactElement, useCallback, useContext } from "react";
import { DropdownMenuContext, useDescendant } from "./DropdownMenu";

interface IProps {
	/** use fragment instead of button for the menu item. The child is assumed to be a button  */
	useChildButton?: boolean;
}
type DropdownMenuItemProps = IProps & ButtonProps & { children: React.ReactNode };

/** a selectable item in the dropdown menu */
export const DropdownMenuItem: FC<DropdownMenuItemProps> = memo((props: DropdownMenuItemProps) => {
	const { children, useChildButton, ...rest } = props;
	const { setSelected } = useContext(DropdownMenuContext);
	const { index, register } = useDescendant();

	const handleMouseMove = useCallback(() => setSelected && setSelected(index), [index, setSelected]);
	const childWidth = (children as ReactElement)?.props?.width || "100%";

	return useChildButton ? (
		<>
			{React.cloneElement(children as ReactElement, {
				width: childWidth,
				display: "block",
				variant: "ghost",
				role: "menuitem",
				ref: register,
				onMouseMove: handleMouseMove,
				...rest,
			})}
		</>
	) : (
		<Button
			{...rest}
			width={"100%"}
			display="block"
			variant="ghost"
			role="menuitem"
			ref={register}
			onMouseMove={handleMouseMove}
		>
			{children}
		</Button>
	);
});
