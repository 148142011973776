/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file Login menu shown to users who are not logged in
 * @module Epic.AppOrchard.Frame.LoginMenu
 */

import { Box, Button, Image, Text } from "@chakra-ui/react";
import { config } from "ao/appConfig";
import { AOLink, DropdownList, DropdownMenu, DropdownMenuItem, DropdownTrigger } from "ao/components/Core";
import { HeaderMenuButton } from "ao/components/Frame";
import { useSiteInfoState } from "ao/state/siteInfo";
import { IUserSecurity, ParentMenu } from "ao/types";
import { createCookie, getBaseUrl, getFullUrl, getReturnUrl, OneYearInMilliseconds } from "ao/utils/helpers";
import React, { FC, memo, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";

interface IProps {
	menu: ParentMenu;
	userSecurity: IUserSecurity;
	currentPageMenuId: number | undefined;
	openMenuId: number | undefined;
	updateOpenMenu: (open: boolean, menuId?: number) => () => void;
	alwaysOpen?: boolean;
}

/* Login menu shown to users who are not logged in */
export const LoginMenu: FC<IProps> = memo((props) => {
	const { alwaysOpen, menu, currentPageMenuId, openMenuId, updateOpenMenu } = props;

	return (
		<>
			{!alwaysOpen && (
				<DropdownMenu
					placement="bottom-end"
					variant="headerDropdown"
					onOpen={updateOpenMenu(true, menu.id)}
					onClose={updateOpenMenu(false)}
				>
					<DropdownTrigger>
						<HeaderMenuButton
							isDropdown
							active={menu.id === currentPageMenuId}
							open={menu.id === openMenuId}
						>
							<Box
								as="span"
								maxWidth="150px"
								color="#3273c3"
								display="inline-block"
								verticalAlign="middle"
							>
								Login
							</Box>
						</HeaderMenuButton>
					</DropdownTrigger>
					<DropdownList>
						<DropdownMenuItem useChildButton>
							<LoginMenuContents></LoginMenuContents>
						</DropdownMenuItem>
					</DropdownList>
				</DropdownMenu>
			)}
			{alwaysOpen && <LoginMenuContents fullWidth></LoginMenuContents>}
		</>
	);
});

interface IContentsProps {
	fullWidth?: boolean;
	includeExplanation?: boolean;
}

export const LoginMenuContents: FC<IContentsProps> = memo((props) => {
	const { fullWidth, includeExplanation } = props;
	const baseUrl = useMemo(() => getBaseUrl(), []);
	const [searchParams] = useSearchParams();
	const { siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);

	const vendorLoginClicked = useCallback((): boolean => {
		var date = new Date();
		date.setTime(date.getTime() + OneYearInMilliseconds);

		var oldDate = new Date();
		oldDate.setTime(oldDate.getTime() - OneYearInMilliseconds);

		createCookie("AppMarketVendorLogin", "true", date.toUTCString(), ".epic.com");
		createCookie("FHIRLogin", "false", oldDate.toUTCString(), ".epic.com");
		createCookie("UserWebLogin", "false", date.toUTCString(), ".epic.com");
		sessionStorage.removeItem("dismissedBanners");

		const returnUrl = getReturnUrl(searchParams);

		setTimeout(function () {
			window.open(getFullUrl("/Account/SignIn?returnUrl=" + returnUrl), "_self");
		}, 50);
		return false;
	}, [searchParams]);

	const customerLoginClicked = useCallback((): boolean => {
		var date = new Date();
		date.setTime(date.getTime() + OneYearInMilliseconds);

		var oldDate = new Date();
		oldDate.setTime(oldDate.getTime() - OneYearInMilliseconds);

		createCookie("AppMarketVendorLogin", "false", oldDate.toUTCString(), ".epic.com");
		createCookie("FHIRLogin", "false", oldDate.toUTCString(), ".epic.com");
		createCookie("UserWebLogin", "true", date.toUTCString(), ".epic.com");
		sessionStorage.removeItem("dismissedBanners");

		const returnUrl = getReturnUrl(searchParams);

		setTimeout(function () {
			window.open(getFullUrl("/Account/SignIn?returnUrl=" + returnUrl), "_self");
		}, 50);
		return false;
	}, [searchParams]);

	return (
		<Box w={fullWidth ? "unset" : "250px"} m="14px">
			{includeExplanation && (
				<Text textAlign="center" mb="14px" mx={fullWidth ? "unset" : "40px"}>
					Log in to select offerings or manage products.
				</Text>
			)}
			<Box fontSize="1.25rem" textAlign="center" fontWeight="500" mb="14px">
				Log in with
			</Box>
			<Button
				title="Log in with your Epic UserWeb account"
				aria-label="Log in with your Epic UserWeb account"
				w="100%"
				h="54px"
				background="#fff"
				border="solid 2px #B9E8FA"
				borderRadius="30px;"
				boxShadow="rgba(0, 0, 0, 0.12) 0px 4px 7px 0px"
				textAlign="center"
				_hover={{}}
				onClick={customerLoginClicked}
			>
				<Image
					src={`${baseUrl}Content/images/UserWeb.png`}
					alt="Epic UserWeb"
					display="inline-block"
					m="10px"
					h="30px"
				/>
			</Button>
			<Box
				textAlign="center"
				fontWeight="500"
				m="2px 0 10px"
				h="1rem"
				border="none 1px rgb(110,110,110)"
				borderBottomStyle="solid"
			>
				<Box as="span" fontSize="1rem" color="rgb(110,110,110)" backgroundColor="#fff" p="0 0.5rem">
					or
				</Box>
			</Box>
			<Button
				title={`Log in with your ${config.SiteName} account`}
				aria-label={`Log in with your ${config.SiteName} account`}
				w="100%"
				h="54px"
				background="#fff"
				border="solid 2px #dbdbdb"
				borderRadius="30px;"
				boxShadow="rgba(0, 0, 0, 0.12) 0px 4px 7px 0px"
				textAlign="center"
				_hover={{}}
				onClick={vendorLoginClicked}
			>
				<Box as="span" fontSize="1.3rem" fontWeight="500">
					{config.SiteName}
				</Box>
			</Button>
			<Box textAlign="center" m="10px 0 14px" h="1rem">
				<AOLink
					title="Learn about how to get a developer account"
					aria-label="Learn about how to get a developer account"
					url={`${siteInfo.vendorServicesUrl}Developer`}
				>
					Request an account
				</AOLink>
			</Box>
		</Box>
	);
});

LoginMenu.defaultProps = { alwaysOpen: false };
LoginMenuContents.defaultProps = { fullWidth: false };
