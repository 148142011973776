/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Used to format a dropdown menu button in the site header
 * @module Epic.AppOrchard.Frame.HeaderMenuButton
 */

import { TriangleDownIcon } from "@chakra-ui/icons";
import { As, Button, ButtonProps } from "@chakra-ui/react";
import React, { FC, forwardRef, memo, ReactElement, ReactNode, Ref } from "react";

interface IProps {
	children: ReactNode;
	isDropdown?: boolean;
	isIconButton?: boolean;
	/** whether this menu contains the menu item for the current page */
	active?: boolean;
	/** use if you want to render a different component e.g. an anchor "a" element */
	as?: As;
	/** URL to link to if rendering as an link element */
	url?: string;
	/** whether this menu is expanded */
	open?: boolean;
}

type Props = ButtonProps & IProps;

/* Used to format a dropdown menu button in the site header */
export const HeaderMenuButton: FC<Props> = memo(
	forwardRef((props, ref: Ref<HTMLButtonElement>) => {
		const { isDropdown, isIconButton, active, as, url, open, ...rest } = props;
		let children = props.children;

		if (isIconButton) {
			// children is icon to show in button this case
			children = React.cloneElement(children as ReactElement, {
				fontSize: { base: "1.2em", lg: "1.7em" },
				verticalAlign: { base: "text-top", lg: "bottom" },
			});
		}

		return (
			<Button
				{...rest}
				position="relative"
				as={as}
				url={url}
				ref={ref}
				top="20px"
				m="0 0.25em 0 0"
				textAlign="center"
				float={rest.float || "left"}
				variant="unstyled"
				background={active ? "#E6F3F9" : open ? "rgb(230, 243, 249)" : undefined}
				border="1px solid rgb(200,230,240)"
				borderRadius="50px"
				lineHeight="20px"
				display="block"
				p={rest.padding || (isIconButton ? { base: "6px", lg: "10px" } : "10px 15px")}
				color={active ? "rgb(10,100,150)" : isIconButton ? "#337ab7" : "rgb(110,110,110)"}
				fontFamily="Segoe UI"
				fontSize="16px"
				fontWeight={active ? 500 : "normal"}
				height="unset"
				boxShadow={active ? "inset 0 3px 10px #9fd7ff" : undefined}
				_hover={{ background: "#E6F3F9", color: "rgb(10,100,150)", textDecoration: "none" }}
				rightIcon={isDropdown ? <TriangleDownIcon w="0.55em" h="0.55em" /> : undefined}
			>
				{children}
			</Button>
		);
	}),
);
