/**
 * @copyright Copyright 2021-2023 Epic Systems Corporation
 * @file PaginationControl component
 * @module Epic.AppOrchard.Core.PaginationControl
 */

import { Box, BoxProps, Flex, IconButton, Select, SimpleGrid, Spacer, Text } from "@chakra-ui/react";
import React, { FC, memo } from "react";
import {
	HiOutlineChevronDoubleLeft,
	HiOutlineChevronDoubleRight,
	HiOutlineChevronLeft,
	HiOutlineChevronRight,
} from "react-icons/hi";

interface IProps {
	currentPage: number;
	numPages: number;
	handleUpdatePage: (page: number) => void;
	buttonVariant?: string;
	showPageChooser?: boolean;
	pageChooserOptions?: number[];
	currentPageSize?: number;
	handleUpdatePageSize?: (pageSize: number) => void;
	recordName?: string;
	showRecordCount?: boolean;
	recordTotal?: number;
}

type PaginationControlProps = IProps & BoxProps;
export type ChildrenArray = (React.ReactChild | React.ReactFragment | React.ReactPortal)[];

export const PaginationControl: FC<PaginationControlProps> = memo((props: PaginationControlProps) => {
	const {
		currentPage,
		numPages,
		handleUpdatePage,
		buttonVariant,
		showPageChooser,
		pageChooserOptions,
		currentPageSize,
		handleUpdatePageSize,
		recordName,
		showRecordCount,
		recordTotal,
		...rest
	} = props;

	// Defaults
	const buttonVariantToUse = buttonVariant || "ghost";
	const pageChooserOptionsToUse = pageChooserOptions || [10, 25, 50, 100];
	const recordNameToUse = recordName || "Records";

	const firstRecordIndex = currentPageSize ? (currentPage - 1) * currentPageSize + 1 : undefined;
	const lastRecordIndex =
		recordTotal && currentPageSize ? Math.min(recordTotal, currentPage * currentPageSize) : undefined;

	return (
		<SimpleGrid
			columns={{
				base: 1,
				md: 1 + (showPageChooser || showRecordCount ? 1 : 0),
				xl: 1 + +!!showPageChooser + +!!showRecordCount,
			}}
			{...rest}
		>
			{showPageChooser && handleUpdatePageSize && (
				<Box display="flex" alignItems={"baseline"}>
					<Text marginRight={"3"}>{recordNameToUse} per page</Text>
					<Select
						size={"sm"}
						width={"20"}
						onChange={(e) => {
							handleUpdatePageSize(parseInt(e.target.value));
						}}
						value={currentPageSize}
					>
						{pageChooserOptionsToUse.map((option) => (
							<option value={option}>{option}</option>
						))}
					</Select>
				</Box>
			)}
			<Flex>
				<IconButton
					variant={buttonVariantToUse}
					w={{ md: "30px", lg: "50px" }}
					size="sm"
					onClick={() => handleUpdatePage(1)}
					disabled={currentPage <= 1}
					icon={<HiOutlineChevronDoubleLeft size={20} />}
					title="Go to the first page"
					aria-label="Go to the first page"
					mr="1"
				></IconButton>
				<IconButton
					variant={buttonVariantToUse}
					w={{ md: "30px", lg: "50px" }}
					size="sm"
					onClick={() => handleUpdatePage(currentPage - 1)}
					disabled={currentPage <= 1}
					icon={<HiOutlineChevronLeft size={20} />}
					title="Go to the previous page"
					aria-label="Go to the previous page"
				></IconButton>
				<Spacer />
				<Box pt="5px" title={`Page ${currentPage} of ${numPages} total pages`}>
					{currentPage} of {numPages}
				</Box>
				<Spacer />
				<IconButton
					variant={buttonVariantToUse}
					size="sm"
					w={{ md: "30px", lg: "50px" }}
					onClick={() => handleUpdatePage(currentPage + 1)}
					disabled={currentPage >= numPages}
					icon={<HiOutlineChevronRight size={20} />}
					title="Go to the next page"
					aria-label="Go to the next page"
					mr="1"
				></IconButton>
				<IconButton
					variant={buttonVariantToUse}
					size="sm"
					w={{ md: "30px", lg: "50px" }}
					onClick={() => handleUpdatePage(numPages)}
					disabled={currentPage >= numPages}
					icon={<HiOutlineChevronDoubleRight size={20} />}
					title="Go to the last page"
					aria-label="Go to the last page"
				></IconButton>
			</Flex>
			{showRecordCount && currentPageSize && (
				<Box textAlign={{ base: "left", lg: "right" }}>
					{recordTotal ? `Showing ${firstRecordIndex}-${lastRecordIndex} of ${recordTotal}` : ""}
				</Box>
			)}
		</SimpleGrid>
	);
});
