/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file React specific helper functions
 * @module Epic.AppOrchard.Utils.ReactHelpers
 */

import { isHTMLElement, normalizeEventKey } from "@chakra-ui/utils";
import { Descendant, DescendantsManager } from "ao/components/Core/descendant";
import React, { ReactElement } from "react";
import * as ReactIs from "react-is";

/**
 * Adds a click handler to all immediate children
 *
 * Could potentially be made more generic for other kinds of handlers in the future
 * @param children child components of the current component
 * @param newHandler new click handler to add
 * @returns cloned children with new click handler
 */
export function childrenWithAddedClickHandler(
	children: React.ReactNode,
	newHandler: (event?: React.MouseEventHandler<any>) => void,
): React.ReactNode {
	children = childrenWithoutFragment(children);

	return React.Children.map(children, (child) => {
		if (!React.isValidElement(child)) {
			return child;
		}

		const eventHandler = (event: React.MouseEventHandler<any>) => {
			// call new handler
			newHandler(event);

			// still call any existing handlers
			if (child.props.onClick) {
				child.props.onClick(event);
			}
		};
		return React.cloneElement(child as ReactElement, { onClick: eventHandler });
	});
}

/**
 * Removes Fragment (<></>) wrapping children if present
 * @param children children components
 * @returns children with fragment stripped off
 */
export function childrenWithoutFragment(children: React.ReactNode): React.ReactNode {
	if (ReactIs.isFragment(children)) {
		return childrenWithoutFragment(children.props.children);
	} else {
		return children;
	}
}

/**
 * Checks if the event target is a menu item.
 * Copied from
 * 	https://github.com/chakra-ui/chakra-ui/blob/bb99f3c0855455f0ad1343536ace2defcfbf492c/packages/menu/src/use-menu.ts#L339
 * @param target evnet target
 * @returns true if menu item else false
 */
export function isTargetMenuItem(target: EventTarget | null) {
	// this will catch `menuitem`, `menuitemradio`, `menuitemcheckbox`
	return isHTMLElement(target) && !!target.getAttribute("role")?.startsWith("menuitem");
}

/**
 * Chooses the next descendent to select based on a keyboard event.
 * Used for keyboard navigation in dropdowns
 * @param event keyboard event being handled
 * @param selectedIndex index of currently selected descendant
 * @param descendants list of descendant
 * @returns next descendant to select
 */
export function getSelectedDescendentForKey<T extends HTMLElement>(
	event: React.KeyboardEvent,
	selectedIndex: number,
	descendants: DescendantsManager<T, {}>,
): Descendant<T, {}> | undefined {
	const eventKey = normalizeEventKey(event);
	let toSelect;

	switch (eventKey) {
		case "Home":
			toSelect = descendants.firstEnabled();
			break;

		case "End":
			toSelect = descendants.lastEnabled();
			break;

		case "ArrowDown":
			// if arrowing down from input controls always choose the first item
			toSelect = !isTargetMenuItem(event.target)
				? descendants.firstEnabled()
				: descendants.nextEnabled(selectedIndex);
			break;

		case "ArrowUp":
			// if arrowing up from input controls always choose the last item
			toSelect = !isTargetMenuItem(event.target)
				? descendants.lastEnabled()
				: descendants.prevEnabled(selectedIndex);
	}

	return toSelect;
}
