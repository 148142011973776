/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file state/store for UI state organizations Epic users can select
 * @module Epic.AppOrchard.State.UserOrganizations
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "../store";

/// TYPES ///

export interface IState {
	readonly showingOrgsDropdown: boolean;
}

/// INIT ///

/**
 * Returns an empty initial state
 */
export function getInitialState(): IState {
	return {
		showingOrgsDropdown: false,
	};
}

/// REDUCERS ///

/**
 * Update showing dropdown status
 * @param _state current state
 * @param showingDropdown whether org selection dropdown is being shown
 * @returns
 */
export function updateShowingDropdown(_state: IState, showingDropdown: boolean): IState {
	return { showingOrgsDropdown: showingDropdown };
}

/// SELECTORS ///

/**
 * Get current state
 * @param state current state
 * @returns
 */
export function getState(state: IState): IState {
	return state;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		updateShowingDropdown,
	},
	selectors: {
		getState,
	},
});
store.addSharedState(builtState.sharedState, "userOrganizationsUI");

export const {
	actionCreators: userOrganizationsUIActions,
	useSharedState: useUserOrganizationsUIState,
} = builtState;
