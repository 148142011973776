/**
 * @copyright Copyright 2018-2021 Epic Systems Corporation
 * @file Basic placeholder to indicate that an asynchronous operation is in place.
 *     Based off of \PatientEngagement\Packages\client-sdk\src\ui\layout\components\AsyncPlaceholder.tsx
 * @module Epic.AppOrchard.Core.AsyncPlaceholder
 */

import { Spinner, VisuallyHidden } from "@chakra-ui/react";
import { getNumFailed, getNumInFlight, getNumStarted, IAsyncState } from "@epic/react-async-hook";
import { logFromPlaceholder } from "ao/data";
import React, { memo, PropsWithChildren, ReactElement, useEffect } from "react";
import { StatusAlert } from ".";
import { getLastError, lastExecutionErrored, lastExecutionIsComplete } from "../../utils/useAsyncHelpers";
import { BodyContent } from "../Frame";

interface IProps {
	/** Async state this placeholder will render in response to */
	asyncState: IAsyncState;
	/** Whether to only check the last execution/request for errors or all executions */
	useLastRequestForErrors?: boolean;
	/** JSX element to display when execution is in in progress. If not passed, a default indicator will be used. */
	inProgressIndicator?: JSX.Element;
	/** Allows using the default indicator but with a different message (e.g. 'Saving' instead of 'Loading')  */
	inProgressMessage?: string;
	/** Whether to only check the last execution/request for in progress state when ignoring previous executions */
	useLastRequestForInProgress?: boolean;
	/** An ID for the default in progress indicator (for styling). */
	errorIndicator?: JSX.Element;
	/** Allows using the default indicator but with a different title  */
	errorTitle?: string;
	/** Allows using the default indicator but with a different message  */
	errorMessage?: string;
	/** JSX element to display when execution has not started. If not passed, a default indicator will be used. */
	notStartedIndicator?: JSX.Element;
	/** Allows using the default indicator but with a different message  */
	notStartedMessage?: string;
	/**
	 * Specify that an indicator is for a request that relates to an entire page; this gives the indicator
	 * special styling.
	 */
	forFullPage?: boolean;
	/**
	 * Whether to log errors. Default is true. Set to false if you are already explicitly logging errors in the onFailure handler.
	 */
	logErrors?: boolean;
}

/**
 * A UI placeholder to communicate the status of an asynchronous operation to the user.
 * When the asynchronous operation is complete, the Placeholder will display its children.
 *
 * This placeholder has the following behavior:
 * - When the async operation has never been started, it defaults to rendering its children, with an option to
 *   render a not-started indicator instead.
 * - When the async operation has even one execution in flight, it renders an in-progress indicator instead of
 *   its children, regardless of the status of the other executions.
 * - If all existing executions of the async operation have errored, it renders an error indicator instead of
 *   its children.
 * - When all existing executions of the async operation have successfully completed, it renders its children.
 */
export const AsyncPlaceholder = memo(
	(props: PropsWithChildren<IProps>): ReactElement => {
		const {
			asyncState,
			useLastRequestForErrors,
			children,
			notStartedIndicator,
			notStartedMessage,
			inProgressMessage,
			useLastRequestForInProgress,
			errorTitle,
			errorMessage,
			forFullPage,
			logErrors,
		} = props;

		const shouldLogErrors = logErrors !== false;
		const notStarted = notStartedIndicator ? (
			notStartedIndicator
		) : children ? (
			<>{children}</>
		) : (
			<Spinner role="status">
				<VisuallyHidden>{notStartedMessage || "Waiting to start..."}</VisuallyHidden>
			</Spinner>
		);

	const inProgressIndicator = props.inProgressIndicator || (
		<Spinner role="status">
			<VisuallyHidden>{inProgressMessage || "Loading..."}</VisuallyHidden>
		</Spinner>
	);

	const errorIndicator = props.errorIndicator || (
		<StatusAlert title={errorTitle} message={errorMessage || "Error loading"} status="error" />
	);

	const numStarted = getNumStarted(asyncState);

		let hasError: boolean;
		const lastExecutionHasError = lastExecutionErrored(asyncState);
		if (useLastRequestForErrors) {
			hasError = lastExecutionHasError;
		} else {
			hasError = numStarted > 0 && numStarted === getNumFailed(asyncState);
		}

		useEffect(() => {
			if (shouldLogErrors && lastExecutionHasError) {
				const error = getLastError(asyncState);
				logFromPlaceholder(error);
			}
		}, [asyncState, lastExecutionHasError, shouldLogErrors]);

		let toReturn;
		if (!asyncState || hasError) {
			toReturn = forFullPage ? <BodyContent>{errorIndicator}</BodyContent> : errorIndicator;
		} else if (numStarted === 0) {
			toReturn = forFullPage && !children ? <BodyContent>{notStarted}</BodyContent> : notStarted;
		} else if (
			useLastRequestForInProgress
				? !lastExecutionIsComplete(asyncState)
				: getNumInFlight(asyncState) > 0
		) {
			toReturn = forFullPage ? <BodyContent>{inProgressIndicator}</BodyContent> : inProgressIndicator;
		} else {
			toReturn = <>{children}</>;
		}

		return toReturn;
	},
);
