/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Site header component
 * @module Epic.AppOrchard.Frame.Header
 */

import { Box, Container, Flex, Spacer, useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import { AOLink, DropdownList, DropdownMenu, DropdownMenuItem, DropdownTrigger } from "ao/components/Core";
import {
	Favorites,
	HeaderMenuButton,
	HeaderMenuItem,
	LoginMenu,
	MobileMainMenu,
	Notifications,
	RemoteAccessing,
	SiteSearch,
	UserInfo,
	WhatsNewBanner,
} from "ao/components/Frame";
import { IUserSecurity, MenuId, UserAccountType } from "ao/types";
import { getCurrentLocation, hasSecurity } from "ao/utils/helpers";
import cloud from "images/cloud.png";
import React, { FC, useCallback, useEffect, useState } from "react";
import { menuIsCurrentPage } from "./helpers";
interface IProps {
	userSecurity: IUserSecurity;
	useFullWidth?: boolean;
}

/**
 * Site header
 *
 * NOTE: do not wrap this component in memo since it needs to rerender when the current page changes and that is not a prop
 */
export const Header: FC<IProps> = (props) => {
	const { userSecurity, useFullWidth } = props;

	//#region state/hooks
	const [currentPageMenuId, setCurrentPageMenuId] = useState<number | undefined>();
	const [currentPageMenuItemId, setCurrentPageMenuItemId] = useState<number | undefined>();
	const [openMenuId, setOpenMenuId] = useState<number | undefined>();
	const currentPath = getCurrentLocation().toLowerCase();
	const isMobile = useBreakpointValue({ base: true, lg: false }) || false;

	const toolbarMenuItems = userSecurity.menus.find((menu) => menu.id === MenuId.Toolbar)?.children || [];
	const isRemoteAccessing =
		userSecurity.userDBOrganizationId !== null &&
		userSecurity.userOrganizationId !== userSecurity.userDBOrganizationId;

	const updateOpenMenu = useCallback(
		(open: boolean, menuId?: number) => () => setOpenMenuId(open ? menuId : undefined),
		[],
	);

	// figure out which menu and menu item the current page represents, if any
	useEffect(() => {
		const currentPageUrls = [currentPath, currentPath + "/index"];

		for (const menu of userSecurity.menus) {
			for (const menuItem of menu.children) {
				if (menuIsCurrentPage(menuItem.link, currentPageUrls)) {
					setCurrentPageMenuId(menu.id);
					setCurrentPageMenuItemId(menuItem.id);
					return;
				}
			}

			setCurrentPageMenuId(undefined);
			setCurrentPageMenuItemId(undefined);
		}
	}, [userSecurity.menus, currentPath]);

	useEffect(() => {
		let link: any = document.querySelector("link[rel~='icon']");

		if (link) {
			link.href = "favicon.ico";
		}
	}, []);

	const [isPrinting] = useMediaQuery("print");

	//#endregion
	if (!isPrinting)
		return (
			<Box as="header" position="fixed" right="0" left="0" zIndex={1030}>
				{userSecurity.showWhatsNew && <WhatsNewBanner />}
				<Box
					backgroundImage={`url(${cloud})`}
					backgroundSize="90px"
					backgroundPosition="0% -1.5em"
					backgroundRepeat="repeat-x"
					minH="100px"
				>
					<Container
						as="nav"
						maxW={{
							base: "100%",
							md: useFullWidth ? "none" : "750px",
							lg: useFullWidth ? "none" : "970px",
							xl: useFullWidth ? "none" : "1170px",
							"3xl": useFullWidth ? "none" : "1440px",
						}}
					>
						<Flex>
							{
								//commenting this out entirely to make sure it doesn't add unnecessary spacing
								/* site logo
						<Box pt="10px" float="left" display="none">
							<Link url="" display="inline-block">
								<Image
									h="60px"
									w={isMobile ? "140px" : "166px"}
									maxW="unset"
									objectFit={"scale-down"}
									display="inline-block"
									src={logo}
								/>
							</Link>
						</Box> */
							}

							{/* desktop main menu */}
							{!isMobile && (
								<Box pl="30px" float="left">
									{toolbarMenuItems.map((menuItem) => (
										<HeaderMenuButton
											active={menuItem.id === currentPageMenuId}
											as={AOLink}
											url={menuItem.link}
										>
											{menuItem.name}
										</HeaderMenuButton>
									))}
									{userSecurity.menus
										.filter(
											(menu) =>
												menu.id !== MenuId.Toolbar &&
												menu.id !== MenuId.UserMenu &&
												menu.id !== MenuId.Login,
										)
										.map((menu) => (
											/* make lazy since some menu items need to be 
										rerendered each time they are shown to make API calls */
											<DropdownMenu
												isLazy
												lazyBehavior="unmount"
												variant="headerDropdown"
												onOpen={updateOpenMenu(true, menu.id)}
												onClose={updateOpenMenu(false)}
											>
												<DropdownTrigger>
													<HeaderMenuButton
														active={menu.id === currentPageMenuId}
														open={menu.id === openMenuId}
														isDropdown
													>
														{menu.name}
													</HeaderMenuButton>
												</DropdownTrigger>
												<DropdownList>
													{menu.children.map((menuItem) => (
														<DropdownMenuItem useChildButton>
															<HeaderMenuItem
																menuId={menuItem.id}
																as={AOLink}
																url={menuItem.link}
																active={menuItem.id === currentPageMenuItemId}
																userSecurity={userSecurity}
																newTab={menu.id === MenuId.JumpTo}
																showIcon={menu.id !== MenuId.Admin}
																p={
																	menu.id === MenuId.Admin
																		? "5px"
																		: undefined
																}
																h={
																	menu.id === MenuId.Admin
																		? "unset"
																		: undefined
																}
															>
																{menuItem.name}
															</HeaderMenuItem>
														</DropdownMenuItem>
													))}
												</DropdownList>
											</DropdownMenu>
										))}
								</Box>
							)}
							<Spacer />
							{/* site search, favorites, notifications, user menu */}
							<Box m="0" position="relative">
								{hasSecurity(userSecurity, UserAccountType.VendorDeveloper) && (
									<>
										<SiteSearch isMobile={isMobile} />
										<Favorites isMobile={isMobile} />
										{isRemoteAccessing ? (
											<RemoteAccessing
												isMobile={isMobile}
												orgName={userSecurity.organizationName}
											/>
										) : (
											<Notifications isMobile={isMobile} userSecurity={userSecurity} />
										)}
									</>
								)}

								{/* User menu dropdown */}
								{!isMobile &&
									userSecurity.menus
										.filter((menu) => menu.id === MenuId.UserMenu)
										.map((menu) => (
											<DropdownMenu
												placement="bottom-end"
												variant="headerDropdown"
												onOpen={updateOpenMenu(true, menu.id)}
												onClose={updateOpenMenu(false)}
											>
												<DropdownTrigger>
													<HeaderMenuButton
														isDropdown
														padding={
															userSecurity.userPhotoSrc ? "6px 15px" : undefined
														}
														active={menu.id === currentPageMenuId}
														open={menu.id === openMenuId}
													>
														<UserInfo userSecurity={userSecurity} />
													</HeaderMenuButton>
												</DropdownTrigger>
												<DropdownList>
													{menu.children.map((menuItem) => (
														<DropdownMenuItem useChildButton>
															<HeaderMenuItem
																menuId={menuItem.id}
																as={AOLink}
																url={menuItem.link}
																active={menuItem.id === currentPageMenuItemId}
																userSecurity={userSecurity}
															>
																{menuItem.name}
															</HeaderMenuItem>
														</DropdownMenuItem>
													))}
												</DropdownList>
											</DropdownMenu>
										))}

								{/* Login dropdown */}
								{!isMobile &&
									userSecurity.menus
										.filter((menu) => menu.id === MenuId.Login)
										.map((menu) => (
											<LoginMenu
												menu={menu}
												userSecurity={userSecurity}
												currentPageMenuId={currentPageMenuItemId}
												openMenuId={openMenuId}
												updateOpenMenu={updateOpenMenu}
											></LoginMenu>
										))}
							</Box>
							{isMobile && (
								<MobileMainMenu
									userSecurity={userSecurity}
									currentPageMenuId={currentPageMenuId}
									currentPageMenuItemId={currentPageMenuItemId}
									toolbarMenuItems={toolbarMenuItems}
								/>
							)}
						</Flex>
					</Container>
				</Box>
			</Box>
		);
	else return null;
};
