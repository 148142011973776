/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Saving Popup for create/edit/review apps
 */

import { ModalOverlay, ModalContent, Modal, Center, Stack, SkeletonCircle, HStack } from "@chakra-ui/react";
import React, { memo } from "react";

interface IProps {
	text: string;
}

export const LoadingOverlay: React.FunctionComponent<IProps> = memo(
	(props: IProps): JSX.Element => {
		const { text } = props;

		return (
			<Modal closeOnOverlayClick={true} onClose={() => {}} isOpen={true} isCentered>
				<ModalOverlay />
				<ModalContent bg="none" boxShadow="none">
					<Center>
						<Stack>
							<Center fontSize={"24px"} color={"white"}>
								{text}
							</Center>
							<Center>
								<HStack>
									<SkeletonCircle size="3" startColor="white" />
									<SkeletonCircle size="3" startColor="white" />
									<SkeletonCircle size="3" startColor="white" />
								</HStack>
							</Center>
						</Stack>
					</Center>
				</ModalContent>
			</Modal>
		);
	},
);
