/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file Wrapper component for ApiLicenseTermsButton component that handles whether to show the agreement button, a disclaimer about the offline agreement or a message to contact a person with permission for users without permission to sign the agreement
 * @module Epic.AppOrchard.AppListing.ApiLicenseTermsBanner
 */

import { AlertProps, Button } from "@chakra-ui/react";
import { ApiLicenseTermsButton } from "ao/components/Core";
import { IUserSecurity, PricingApiLicenseType } from "ao/types";
import { FunctionComponent, memo } from "react";
import { StatusAlert } from "./StatusAlert";

interface IProps {
	/** current open.epic API license terms ID */
	apiLicenseAgreementId: number;
	userSecurity: IUserSecurity;
	successHandler: () => void;
	apiLicenseType?: PricingApiLicenseType | null;
	isShowroom: boolean;
}

type Props = IProps & AlertProps;

/** Wrapper component for ApiLicenseTermsButton component that handles whether to show the agreement button, a disclaimer about the offline agreement or a message to contact a person with permission for users without permission to sign the agreement
 */
export const ApiLicenseTermsBanner: FunctionComponent<Props> = memo((props: Props) => {
	const {
		apiLicenseAgreementId,
		userSecurity,
		apiLicenseType,
		successHandler,
		isShowroom,
		...rest
	} = props;

	const orgNeedsOfflineAgreement =
		(apiLicenseType ?? userSecurity.apiLicenseType) === PricingApiLicenseType.ALaCarte;
	const userCanSignAgreement = userSecurity.isEpicCustomer && userSecurity.canPurchase;

	return orgNeedsOfflineAgreement ? (
		<StatusAlert
			status="warning"
			message={
				"Your organization has not yet licensed the open.epic APIs. Contact your Epic representative to request the open.epic API Subscription Amendment."
			}
			{...rest}
		/>
	) : !userCanSignAgreement ? (
		<StatusAlert
			status="warning"
			message={
				"Your organization has not yet licensed the open.epic APIs. Contact your Epic representative to learn who from your organization has the security to accept the open.epic API Subscription Agreement."
			}
			{...rest}
		/>
	) : (
		<>
			<StatusAlert
				status="warning"
				message={
					"Your organization has not yet licensed the open.epic APIs. Click the button below to review and accept the open.epic API Subscription Agreement on your organization's behalf to start using open.epic APIs."
				}
				{...rest}
			/>
			<ApiLicenseTermsButton
				apiLicenseAgreementId={apiLicenseAgreementId}
				orgSignedPreviousTerms={false}
				successHandler={successHandler}
				buttonAs={Button}
				mb="0.5em"
				isShowroom={isShowroom}
			/>
		</>
	);
});
