import { getIdleTime, validateSession } from "ao/data";
import { getFullUrl } from "ao/utils/helpers";

/**
 * Typing for the parts of the quick sign in UserWeb module we use
 */
export declare namespace UserWeb {
	export class QuickSignIn {
		static Show: (
			completeUrl: string,
			onSuccess: any,
			onAbort: any,
			onFailure: any,
			noWindow: boolean,
		) => void;
	}
}

export function QuickSignInShow(
	completeUrl: string,
	onSuccess: any,
	onAbort: any,
	onFailure: any,
	noWindow: boolean,
): void {
	UserWeb.QuickSignIn.Show(completeUrl, onSuccess, onAbort, onFailure, noWindow);
}

export class Shell {
	private static readonly ErrorCodeForSessionExpired = "SESSION-EXPIRED";
	private static readonly ErrorCodeForFederatedSessionExpired = "FEDERATED-SESSION-EXPIRED";

	private static readonly AttestationPastGraceError = "ATTESTATION-PAST-GRACE";
	private static readonly PrivacyPolicyMissingError = "PRIVACY-POLICY-MISSING";

	public static readonly DefaultLogoutIdleTimeInMinutes = 59;

	/** Controls if the user should forced to logout */
	private static ForcingLogout: boolean;
	/** Logout warning timer */
	private static LogoutWarningEventTimer: number | undefined;
	/** Force logout timer */
	private static ForceLogoutTimer: number | undefined;
	/** After warning is shown, this holds the schedule to check to see if we should keep showing the warning */
	private static ShouldKeepShowingWarningInterval: number | undefined;
	/** Logout time that is used on static pages to force an idle user to logout (not changed after initial set) */
	private static LogoutIdleTimeInMinutes: number;
	/** Logout warning time that is used on static pages to force an idle user to logout (not changed after initial set) */
	private static LogoutWarningIdleTimeInMs: number;
	/** Timer used upon a user action within the UI, will persist the user's session on the server */
	private static UserActionThrottleTimer: number | undefined;
	private static IsInitialized: boolean;
	private static ShowLogoutWarning: boolean;

	/** Initializes the shared context settings for settings defined on the server.
	 * @param logoutIdleTimeInMinutes - The number of minutes that can elapse before a user is forced to logout.
	 */
	static InitializeContext(logoutIdleTimeInMinutes: number): void {
		if (Shell.IsInitialized) {
			return;
		}
		Shell.IsInitialized = true;
		Shell.LogoutIdleTimeInMinutes = logoutIdleTimeInMinutes || Shell.DefaultLogoutIdleTimeInMinutes;
		Shell.LogoutWarningIdleTimeInMs = (Shell.LogoutIdleTimeInMinutes - 1) * 60000;

		// register the keep-alive function in the window to ensure actions taken by the user will persist the session
		window.addEventListener("keyup", (ev) => Shell.OnUserKeyAction(ev));
		window.addEventListener("mousedown", (ev) => Shell.OnUserMouseAction(ev));
		Shell.ResetLogoutTimer();
	}

	/**
	 * Force the logout of the user session
	 * */
	private static ForceLogout(): void {
		Shell.ForcingLogout = true;
		window.location.href = Shell.GetLogoutPath();
	}

	/**
	 * Check whether user is being logged out so that pages that prompt to save unsaved changes
	 * before closing can ignore that prompt when logout is forced
	 */
	public static IsForceLogout(): boolean {
		return Shell.ForcingLogout;
	}

	/**
	 * Reset the timer for forcing the logout, this is typically done on user interaction.
	 * */
	private static ResetLogoutTimer(idleTimeout: number = 0): void {
		if (!Shell.ShowingLogoutWarning()) {
			window.clearTimeout(Shell.LogoutWarningEventTimer);
		} else {
			Shell.HideLogoutWarning();
		}

		if (idleTimeout <= 0) {
			idleTimeout = Shell.LogoutWarningIdleTimeInMs;
		}

		Shell.LogoutWarningEventTimer = window.setTimeout(Shell.ShouldShowLogoutWarning, idleTimeout);
	}

	/**
	 * Check to see if we should keep showing the logout warning by making an API call to the server to check for user interaction
	 * @param fromWarningDialog whether this is being called from the logout warning
	 */
	private static async ShouldShowLogoutWarning(fromWarningDialog: boolean = false): Promise<void> {
		try {
			const data = await getIdleTime();
			const idleTime = data.Data;
			if (idleTime < Shell.LogoutWarningIdleTimeInMs) {
				Shell.ResetLogoutTimer(Shell.LogoutWarningIdleTimeInMs - idleTime);
				return;
			}
			if (!fromWarningDialog) {
				Shell.DisplayLogoutWarning();
			}
		} catch {
			if (!fromWarningDialog) {
				Shell.DisplayLogoutWarning();
			}
		}
	}

	/**
	 * Show the logout warning and set up associated timers
	 */
	private static DisplayLogoutWarning(): void {
		window.clearTimeout(Shell.LogoutWarningEventTimer);
		Shell.ShowLogoutWarning = true;

		// force logout after 60 seconds (unless user interact with page in that time)
		Shell.ForceLogoutTimer = window.setTimeout(() => Shell.ForceLogout(), 60000);

		// check if we should keep showing the logout warning every 5 seconds (involves an API call so throttle it)
		Shell.ShouldKeepShowingWarningInterval = window.setInterval(
			() => Shell.ShouldShowLogoutWarning(true),
			5000,
		);
	}

	/**
	 * Whether the logout warning is showing. Read by the LogOutWarning component.
	 */
	public static ShowingLogoutWarning(): boolean {
		return Shell.ShowLogoutWarning;
	}

	/**
	 * Hide the logout warning and clear associated timers
	 */
	private static HideLogoutWarning(): void {
		Shell.ShowLogoutWarning = false;
		window.clearTimeout(Shell.ForceLogoutTimer);
		window.clearInterval(Shell.ShouldKeepShowingWarningInterval);
	}

	/**
	 * Force logout if the session is expired or show quick sign in popup to refresh session.
	 * Called by API callbacks to check if server indicates log out or prompt for login should happen.
	 */
	public static ForceLogoutOrRefreshSession(result: any): boolean {
		if (typeof result !== "object" || !result || !("Message" in result)) {
			return false;
		}

		if (result.Message === Shell.ErrorCodeForSessionExpired) {
			Shell.ForceLogout();
			return true;
		} else if (result.Message === Shell.ErrorCodeForFederatedSessionExpired) {
			QuickSignInShow(
				getFullUrl("Account/SignIn?returnUrl=/Account/QuickSignIn"),
				null,
				null,
				null,
				false,
			);
		}

		return false;
	}

	/**
	 * Force redirect in case of known internal server errors like self-audit attestation past grace or privacy policy missing.
	 * Called by API callbacks to check if server indicates one of these errors.
	 */
	public static ForceRedirectOnError(result: any): boolean {
		if (typeof result !== "string" || !result) {
			return false;
		}

		if (result === Shell.AttestationPastGraceError) {
			window.location.href = getFullUrl("/Attestation/Index");
			return true;
		} else if (result === Shell.PrivacyPolicyMissingError) {
			window.location.href = getFullUrl("/Home/Index");
			return true;
		}

		return false;
	}

	private static OnUserKeyAction(ev: KeyboardEvent): void {
		Shell.OnAction();
	}

	private static OnUserMouseAction(eventData: Object): void {
		Shell.OnAction();
	}

	/**
	 * Called when a user takes an action to keep the session alive
	 */
	private static async OnAction(): Promise<void> {
		// if the timer is already defined there is no need to do additional work as we are throttling the callbacks
		if (Shell.UserActionThrottleTimer) {
			return;
		}

		// set a timer for 5 seconds to reset our timer to ensure we send another keep-alive in a few seconds
		Shell.UserActionThrottleTimer = window.setTimeout(() => {
			if (Shell.UserActionThrottleTimer) {
				window.clearTimeout(Shell.UserActionThrottleTimer);
			}
			Shell.UserActionThrottleTimer = undefined;
		}, 5000);

		await validateSession();
	}

	private static GetLogoutPath(): string {
		return getFullUrl(
			"Account/Logoff?returnUrl=" +
				encodeURIComponent(window.location.pathname + window.location.search + window.location.hash) +
				"&forcedLogout=true",
		);
	}
}
