/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Showroom Questionnaire admin view route
 * @module Epic.AppOrchard.Routes.showroomQuestionnaireViewAdmin
 */

import { IRouteInfo } from "../ao/routes";
import lazyRetry from "../ao/utils/lazy-retry";

/*
 *
 * IMPORTANT! READ THIS!
 *
 * The contents of this file are loaded and executed during the initial load of the application
 * in the browser even if the route is never actually accessed. DO NOT put any additional code
 * in this file, or import any addition modules as doing so would just add extra bloat to the
 * initial load / startup time for the application.
 *
 * IMPORTANT! READ THIS!
 *
 */

const path = process.env.REACT_APP_DEV_URL_PREFIX + "/Showroom/Questionnaire";

const route: IRouteInfo = {
	path,
	component: lazyRetry(
		() =>
			import(
				"../ao/components/Showroom/frameworkComponents/GalleryListingComponents/AppSections/ShowroomQuestionnaireView"
			),
	),
	title: "ShowroomQuestionnaireViewAdmin",
};

export default route;
