/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file Combines select and input components to handle URLs
 * @module Epic.AppOrchard.Core.UrlInput
 * Can be reused in AO
 */

import { Input } from "@chakra-ui/input";
import { Box, InputProps } from "@chakra-ui/react";
import { Select } from "@chakra-ui/select";
import React, { memo, useCallback } from "react";
import { extractURIScheme, stripURIScheme } from "../../utils/helpers";

interface IProps {
	/** Url with header included */
	url: string;
	onChangeCallback: (e: string) => void;
	isInvalid?: boolean;
	isInvalidSelect?: boolean;
	readonly?: boolean;
	/**If true, lock header dropdown to https:// */
	lockPrefix?: boolean;
	placeholder?: string;
	maxInputLength?: number;
	allowCustomScheme?: boolean;
}

type Props = IProps & InputProps;

export const UrlInput: React.FunctionComponent<Props> = memo(
	(props: Props): JSX.Element => {
		const {
			url,
			placeholder,
			isInvalid,
			isInvalidSelect,
			readonly,
			lockPrefix,
			onChangeCallback,
			maxInputLength,
			allowCustomScheme,
			...rest
		} = props;

		const extractedPrefix = url ? extractURIScheme(url) : "https://";
		const strippedUrl = stripURIScheme(url);

		const handleOnChangeUrlPrefix = useCallback(
			(e: { target: { value: string } }): void => {
				onChangeCallback(e.target.value + strippedUrl);
			},
			[onChangeCallback, strippedUrl],
		);

		const handleOnChangeUrl = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>): void => {
				onChangeCallback(extractedPrefix + stripURIScheme(e.target.value));
			},
			[extractedPrefix, onChangeCallback],
		);

		return (
			<>
				<Box display={{ sm: "flex" }}>
					<Select
						onChange={handleOnChangeUrlPrefix}
						value={extractedPrefix}
						isDisabled={readonly || lockPrefix}
						width="15%"
						minWidth="95px"
						borderRightRadius={0}
						bg={lockPrefix && !readonly ? "#eee" : "white"}
						iconSize={lockPrefix ? "0" : undefined}
						isInvalid={isInvalidSelect ?? false}
						aria-label={rest["aria-label"] ?? ""}
					>
						<option value="https://">https://</option>
						<option value="http://">http://</option>
						{allowCustomScheme && <option value="custom">custom</option>}
					</Select>

					<Input
						marginTop={0}
						onChange={handleOnChangeUrl}
						value={strippedUrl}
						placeholder={placeholder}
						isInvalid={isInvalid}
						readOnly={readonly}
						borderLeftRadius={0}
						maxLength={maxInputLength ?? 2000}
						{...rest}
					/>
				</Box>
			</>
		);
	},
);
