/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file connector admin app and org APIs
 * @module Epic.AppOrchard.ConnectorManagement.Data
 */
import { ISaveConnectorAppAPIResponse } from "ao/data";
import {
	IConnectorApp,
	IConnectorAppListItem,
	IConnectorManagementLookups,
} from "ao/types/connectorManagement";
import api, { fileApi } from "ao/utils/api";
import { getInstantForRecordExport } from "ao/utils/helpers";

export async function getConnectorManagementLookups(): Promise<IConnectorManagementLookups> {
	return await api.get<IConnectorManagementLookups>("/api/Connector/GetConnectorManagementLookups");
}

export async function getConnectorApp(id: number): Promise<IConnectorApp> {
	return await api.get<IConnectorApp>(`/api/Connector/GetApp?id=${id}`);
}

export interface IRecordList {
	recordList: IConnectorAppListItem[];
	recordTotal: number;
}

export async function getConnectorApps(listParameterRecords: Record<string, string>): Promise<IRecordList> {
	return await api.get<IRecordList>("/api/Connector/GetConnectorAppList", listParameterRecords);
}

export async function getFilteredConnectorAppExport(
	listParameterRecords: Record<string, string>,
): Promise<void> {
	return await fileApi.get<void>(
		"/ConnectorManagementExport/FilteredConnectorApps",
		"text/csv",
		`ConnectorAppsExport-${getInstantForRecordExport()}.csv`,
		listParameterRecords,
	);
}

export async function saveConnectorApp(data: IConnectorApp): Promise<ISaveConnectorAppAPIResponse> {
	return await api.post<ISaveConnectorAppAPIResponse>("/api/Connector/SaveApp", data);
}
