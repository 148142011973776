/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file ConnectorManagement Shared List Row Tag
 * @module Epic.AppOrchard.ConnectorManagement.Shared.ListRowTag
 */

import { Tag, TagLabel, TagProps } from "@chakra-ui/react";
import { FC, memo } from "react";

interface IProps {
	label: string;
}

type Props = IProps & TagProps;

export const ListRowTag: FC<Props> = memo((props: Props) => {
	const { label, title, lineHeight, ...rest } = props;

	return (
		<Tag title={title} lineHeight={lineHeight ?? 2} {...rest}>
			<TagLabel lineHeight="inherit">{label}</TagLabel>
		</Tag>
	);
});
