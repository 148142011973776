/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file functions to get data for Showroom
 * @module Epic.AppOrchard.Data.Showroom
 */

import { IGenericAPIResponse, IGenericAPIResponseWrongCasing, QuestionnaireType } from "ao/data";
import { IThumbnailDictionary } from "ao/state/showroomThumbnails";
import { ISelectionOption } from "ao/types";
import {
	ICategory,
	IGalleryStageWithChildren,
	INominateAppResponse,
	IShowroomListingUniversalModel,
	IShowroomQuestionnaireViewModel,
} from "ao/types/showroom";
import api from "ao/utils/api";

export async function getDetails(listingId: number, appId: number): Promise<IShowroomListingModelResponse> {
	return await api.get<IShowroomListingModelResponse>(
		`/api/Showroom/Details?listingId=${listingId.toString()}&appId=${appId.toString()}`,
	);
}

export interface IShowroomListingModelResponse extends IGenericAPIResponse {
	model: IShowroomListingUniversalModel;
}

/**
 * Get showroom homepage
 * @returns homepage stageTree with teasers
 */
export async function getShowroom(): Promise<IShowroomHomepage> {
	const data: IShowroomHomepage = await api.get<IShowroomHomepage>("/api/Showroom/GetShowroomHomepage");
	return data;
}
export interface IShowroomHomepage extends IGenericAPIResponseWrongCasing {
	stageTree: IGalleryStageWithChildren[];
	categories: ICategory[];
	contactSubjects: ISelectionOption[];
}

export async function getShowroomThumbnails(listingIds: number[]): Promise<IThumbnailDictionary> {
	return await api.post<IThumbnailDictionary>("/api/Showroom/GetShowroomThumbnails", listingIds);
}

export async function nominateApp(appId: number): Promise<INominateAppResponse> {
	const response: INominateAppResponse = await api.post<INominateAppResponse>(
		"/api/Showroom/AddInterest",
		appId,
	);
	return response;
}

export async function getClientIdMatch(clientIdSearch: string): Promise<IGetClientIdMatch> {
	return await api.get<IGetClientIdMatch>(`/api/Showroom/GetClientIdMatch?clientId=${clientIdSearch}`);
}

export interface IGetClientIdMatch extends IGenericAPIResponse {
	appId: number;
}

export async function requestDownload(
	appId: number,
	termsId: number,
	appTermsId: number | null,
	accessTokenValidity: number | null,
	refreshTokenValidity: number | null,
	disableIndefiniteAccess: boolean | null,
): Promise<IGenericAPIResponseWrongCasing> {
	const data: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		`/api/Showroom/Activate`,
		{
			AppId: appId,
			TermsId: termsId,
			AppTermsId: appTermsId,
			AccessTokenValidity: accessTokenValidity,
			RefreshTokenValidity: refreshTokenValidity,
			DisableIndefiniteAccess: disableIndefiniteAccess,
		},
	);
	return data;
}

export async function loadQuestionnairesForApp(
	appId: number,
	type: QuestionnaireType,
): Promise<IShowroomQuestionnaireViewModel> {
	const data: IShowroomQuestionnaireViewModel = await api.get<IShowroomQuestionnaireViewModel>(
		"/api/Showroom/LoadQuestionnaires",
		{
			appId: appId.toString(),
			type: type.toString(),
		},
	);
	return data;
}
