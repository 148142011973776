/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Tag that shows Client App status
 * @module Epic.AppOrchard.Core.AppStatusTag
 */

import { Tag, TagProps } from "@chakra-ui/tag";
import { ClientApplicationStatus, clientAppStatusInfo } from "ao/data";
import React, { FC, memo, useMemo } from "react";

interface IProps {
	status: ClientApplicationStatus;
}

type Props = IProps & TagProps;

/** Tag that shows Client App status */
export const AppStatusTag: FC<Props> = memo((props: Props) => {
	const { status, ...rest } = props;
	const { name, color, backgroundColor, helptext } = useMemo(() => clientAppStatusInfo(status), [status]);
	return (
		<Tag variant="solid" color={color} background={backgroundColor} title={helptext} {...rest}>
			{name}
		</Tag>
	);
});
