/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file File not found page component
 * @module Epic.AppOrchard.Modules.Notfound.NotFound
 */

import { Box, Heading, Text } from "@chakra-ui/layout";
import { BodyContent } from "ao/components/Frame";
import routes from "ao/routes";
import { getFullUrl } from "ao/utils/helpers";
import { FC } from "react";
import { Link as RouteLink, useLocation } from "react-router-dom";

/**
 * File Not Found (404) page component
 */
const NotFound: FC = () => {
	const location = useLocation();

	return (
		<BodyContent>
			<Heading as="h1">Page not found</Heading>
			<Text as="span">
				The requested{" "}
				<Text as="span" fontWeight="bold">
					{getFullUrl(location.pathname)}
				</Text>{" "}
				path does not exist.
			</Text>

			{process.env.NODE_ENV === "development" && (
				<div style={{ padding: "20px" }}>
					<Heading as="h2" mb="10px">
						Registered routes:
					</Heading>
					{routes.map(({ title, path }) => (
						<Box key={path} fontSize="1.3em" ml="10px">
							<RouteLink to={path}>{title}</RouteLink>
						</Box>
					))}
				</div>
			)}
		</BodyContent>
	);
};

export default NotFound;
