/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file state/store for showroomThumbnails
 * @module Epic.AppOrchard.State.showroomThumbnails
 */

import { buildSharedState } from "@epic/react-redux-booster";
import store from "../store";

/// TYPES ///

export interface IState {
	readonly thumbnailDictionary: IThumbnailDictionary;
	readonly hasLoaded: boolean;
}

export interface IThumbnailDictionary {
	[id: number]: IGalleryScreenshot;
}

interface IGalleryScreenshot {
	ImageData: string;
	Caption: string;
	MimeType: string;
	Width: string;
	Height: string;
	Order: number;
}

/// INIT ///

/**
 * Returns an empty Images initial state
 */
function getInitialState(): IState {
	return { thumbnailDictionary: [], hasLoaded: false };
}

/// REDUCERS ///

export function setState(state: IState, thumbnailDictionary: IThumbnailDictionary): IState {
	return { thumbnailDictionary: { ...state.thumbnailDictionary, ...thumbnailDictionary }, hasLoaded: true };
}

/// SELECTORS ///

export function getState(state: IState): IState {
	return state;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		setState,
	},
	selectors: {
		getState,
	},
});

store.addSharedState(builtState.sharedState, "thumbnails");

export const { actionCreators: ThumbnailActions, useSharedState: useShowroomThumbnailState } = builtState;
