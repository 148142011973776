/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file ShowroomHeader component
 * @module Epic.AppOrchard.Frame.ShowroomHeader
 */
import { ChevronDownIcon, HamburgerIcon, LinkIcon, SearchIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	ButtonProps,
	Center,
	Divider,
	Flex,
	Grid,
	GridItem,
	HStack,
	IconButton,
	Image,
	Input,
	InputGroup,
	InputGroupProps,
	InputRightElement,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	Text,
	useBreakpointValue,
	useDisclosure,
	VStack,
} from "@chakra-ui/react";
import { showroomAccent, showroomPrimary, showroomText } from "ao/chakraTheme";
import { useSiteInfoState } from "ao/state/siteInfo";
import { useUserSecurityState } from "ao/state/userSecurity";
import { IUserSecurity, MenuItemId } from "ao/types";
import { IGalleryStageWithChildren } from "ao/types/showroom";
import { getBaseUrl, hasMenu, isLocalhost } from "ao/utils/helpers";
import * as CSS from "csstype";
import headerLights from "images/header-lights.svg";
import marqueeLights from "images/marquee-lights.svg";
import React, { FC, FunctionComponent, memo, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getShowroomSearchUrl } from "routes/showroomSearch";
import { getShowroomSearchAdminUrl } from "routes/showroomSearchAdmin";
import { RemoteOrgSelection } from ".";
import { AOLink, AOTooltip, LinkButton } from "../Core";
import { ShowroomLogoDark } from "../Showroom/data/assets";
import { CleanSearchQuery, ContentWidth } from "../Showroom/Helper";
import { EpicSiteNavbar } from "./EpicSiteNavbar/EpicSiteNavbar";
import { LoginMenuContents } from "./LoginMenu";
import { ShowroomHeaderMenu, ShowroomHeaderMenuMobile } from "./ShowroomHeaderMenu";

interface IProps {
	userSecurity: IUserSecurity;
	hasLoadedUserSecurity: boolean;
	stageTree: IGalleryStageWithChildren[];
	title?: string;
}

/**
 * Site header
 *
 * NOTE: do not wrap this component in memo since it needs to rerender when the current page changes and that is not a prop
 */
export const ShowroomHeader: FC<IProps> = (props) => {
	const { userSecurity, hasLoadedUserSecurity, stageTree, title } = props;

	//#region state and hooks
	const useCompactMenu = useBreakpointValue({ base: true, lg: false }) || false;
	const isHomePage = title === "Showroom" || title === "ShowroomAdmin";

	const headerHeight = isHomePage && !useCompactMenu ? "170px" : "70px";
	const baseUrl = useMemo(() => getBaseUrl(), []);

	const { isOpen: isMobileOpen, onToggle: onMobileToggle, onClose: onMobileClose } = useDisclosure();

	const { siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);

	const [userImageLoaded, setUserImageLoaded] = useState<boolean>(false);

	const isRemoteAccessing =
		userSecurity.userDBOrganizationId !== null &&
		userSecurity.userOrganizationId !== userSecurity.userDBOrganizationId;

	useEffect(() => {
		let link: any = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement("link");
			link.rel = "icon";
			document.getElementsByTagName("head")[0].appendChild(link);
		}
		link.href = `${baseUrl}Content/images/Showroom_Icon.svg`;

		if (siteInfo) {
			document.title = "Epic " + siteInfo.galleryTokens.galleryName;
		}
	}, [baseUrl, siteInfo]);

	//#endregion

	return (
		<Box style={{ position: "fixed", right: "0", left: "0", zIndex: 1030 }}>
			{!useCompactMenu && (
				<Flex
					justify="center"
					h="36px"
					backgroundColor="white"
					boxShadow="0px 3px 6px #00000029;"
					pos="relative"
				>
					<HStack spacing="1rem">
						<EpicSiteNavbar isMobile={false} />
					</HStack>
				</Flex>
			)}

			{!isHomePage && (
				<Box>
					{!useCompactMenu && (
						<Box
							backgroundColor={showroomText}
							h="14px"
							backgroundImage={`url(${marqueeLights})`}
							backgroundSize="600px"
							backgroundPosition="center"
						/>
					)}

					<Box
						h={headerHeight}
						background={`showroomAccent.500`}
						backgroundColor={showroomAccent}
						backgroundImage={!useCompactMenu ? `url(${headerLights})` : ""}
						backgroundRepeat="no-repeat"
						backgroundPosition="center"
						backgroundSize={"430px"}
					>
						<Box maxW={ContentWidth} m="0 auto" h="100%">
							<Grid templateColumns="repeat(12, 1fr)" h="100%">
								<GridItem colSpan={useCompactMenu ? (isRemoteAccessing ? 8 : 9) : 3}>
									<Box display="flex" justifyContent="flex-start" h="100%">
										<Center h="100%" w="100%">
											<ShowroomSiteSearch
												onMobileClose={onMobileClose}
												useCompactMenu={useCompactMenu}
												inputGroupProps={{
													background: "#F4AE2A",
													boxShadow: "inset 0px 3px 6px #00000029",
													border: "1px solid #EF9C00",
													borderRadius: "full",
													h: { base: 10, md: "28px" },
													ml: { base: "2rem", md: "0" },
												}}
											/>
										</Center>
									</Box>
								</GridItem>
								{!useCompactMenu && (
									<GridItem colSpan={6}>
										<Center h="100%" p={useCompactMenu ? "10px" : ""}>
											<Box>
												<AOLink
													url={siteInfo.showroomRelativeUrl}
													onClick={() => window.scrollTo(0, 0)}
													display="inline-block"
												>
													<Box maxW={"235px"} m="0 auto">
														<ShowroomLogoDark
															width="100%"
															height="100%"
															aria-label="Showroom"
															role="img"
														/>
													</Box>
												</AOLink>
											</Box>
										</Center>
									</GridItem>
								)}
								<GridItem
									colSpan={useCompactMenu && isRemoteAccessing ? 4 : 3}
									justifySelf="right"
								>
									{hasLoadedUserSecurity && (
										<Box display="flex" justifyContent="flex-end" h="100%">
											<Center h="100%">
												{isRemoteAccessing && (
													<AOTooltip
														helptext={`Remote Accessing: ${userSecurity.organizationName}`}
														asButton
														buttonProps={{
															...buttonProps,
															p: 0,
															variant: "ghost",
															mr: "0.5em",
															colorScheme: "whiteAlpha",
															top: useCompactMenu ? "-3px" : "0",

															_hover: {
																background: "#7c0020",
																color: "white",
																boxShadow:
																	"0px 0px 7px -3px inset rgb(116,0,30)",
															},
														}}
														isIconButton
													>
														<LinkIcon
															h={useCompactMenu ? "4" : "6"}
															w={useCompactMenu ? "4" : "6"}
														/>
													</AOTooltip>
												)}
												{!useCompactMenu && !userSecurity.userId && (
													<LoginMenu useCompactMenu={false} />
												)}
												{!useCompactMenu && userSecurity.userId && (
													<LogoutMenu
														useCompactMenu={false}
														userSecurity={userSecurity}
														userImageLoaded={userImageLoaded}
														setUserImageLoaded={setUserImageLoaded}
													/>
												)}

												{useCompactMenu && (
													<MobileMenu
														userSecurity={userSecurity}
														stageTree={stageTree}
														isMobileOpen={isMobileOpen}
														onMobileToggle={onMobileToggle}
														onMobileClose={onMobileClose}
														userImageLoaded={userImageLoaded}
														setUserImageLoaded={setUserImageLoaded}
														buttonPropsToUse={{
															...buttonProps,
															top: "-3px",
															mr: useCompactMenu
																? "2rem"
																: buttonProps.marginRight || buttonProps.mr,
														}}
													/>
												)}
											</Center>
										</Box>
									)}
								</GridItem>
							</Grid>
						</Box>
					</Box>
					{!useCompactMenu && (
						<Box
							backgroundColor={showroomText}
							h="14px"
							backgroundImage={`url(${marqueeLights})`}
							backgroundSize="600px"
							backgroundPosition="center"
						/>
					)}

					{!useCompactMenu && (
						<Box>
							<Box background="#FCE39F" boxShadow="0px 3px 6px #00000029">
								<HStack justify="center" spacing="10px">
									<ShowroomHeaderMenu stageTree={stageTree} title={title} />
								</HStack>
							</Box>
						</Box>
					)}
				</Box>
			)}
		</Box>
	);
};

interface IShowroomHomeHeader {
	stageTree: IGalleryStageWithChildren[];
	title?: string;
}

export const ShowroomHomeHeader: FC<IShowroomHomeHeader> = (props: IShowroomHomeHeader) => {
	const { stageTree, title } = props;

	//#region state and hooks
	const useCompactMenu = useBreakpointValue({ base: true, lg: false }) || false;
	const headerHeight = useCompactMenu ? "70px" : "170px";
	const baseUrl = useMemo(() => getBaseUrl(), []);
	const { userSecurity, hasLoaded } = useUserSecurityState((selectors) => selectors.getState(), []);

	const { isOpen: isMobileOpen, onToggle: onMobileToggle, onClose: onMobileClose } = useDisclosure();

	const { siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);

	const [userImageLoaded, setUserImageLoaded] = useState<boolean>(false);

	const isRemoteAccessing =
		userSecurity.userDBOrganizationId !== null &&
		userSecurity.userOrganizationId !== userSecurity.userDBOrganizationId;

	useEffect(() => {
		let link: any = document.querySelector("link[rel~='icon']");
		if (!link) {
			link = document.createElement("link");
			link.rel = "icon";
			document.getElementsByTagName("head")[0].appendChild(link);
		}
		link.href = `${baseUrl}Content/images/Showroom_Icon.svg`;

		if (siteInfo) {
			document.title = "Epic " + siteInfo.galleryTokens.galleryName;
		}
	}, [baseUrl, siteInfo]);

	//#endregion

	return (
		<Box>
			<Box
				backgroundColor={showroomText}
				h={useCompactMenu ? "14px" : "40px"}
				backgroundImage={`url(${marqueeLights})`}
				backgroundSize={useCompactMenu ? "600px" : "1700px"}
				backgroundPosition="center"
			/>

			<Box
				h={headerHeight}
				background={`showroomAccent.500`}
				backgroundColor={showroomAccent}
				backgroundImage={`url(${headerLights})`}
				backgroundRepeat="no-repeat"
				backgroundPosition="center"
				backgroundSize={useCompactMenu ? "430px" : "1060px"}
			>
				<Box maxW={ContentWidth} m="0 auto" h="100%">
					<Center h="100%" p={useCompactMenu ? "10px" : ""}>
						<AOLink
							w="100%"
							h="100%"
							url={siteInfo.showroomRelativeUrl}
							onClick={() => window.scrollTo(0, 0)}
							display="inline-block"
						>
							<Box maxW="644px" h="100%" m="0 auto">
								<ShowroomLogoDark
									width="100%"
									height="100%"
									aria-label="Showroom"
									role="img"
								/>
							</Box>
						</AOLink>
					</Center>
				</Box>
			</Box>
			<Box
				backgroundColor={showroomText}
				h={useCompactMenu ? "14px" : "40px"}
				backgroundImage={`url(${marqueeLights})`}
				backgroundSize={useCompactMenu ? "600px" : "1700px"}
				backgroundPosition="center"
			/>

			<Box maxW={ContentWidth} m="13px auto 0">
				<Grid templateColumns="repeat(12, 1fr)" mx={{ base: "15px", lg: "0px" }}>
					<GridItem colSpan={useCompactMenu ? 9 : 3}>
						<Box display="flex" justifyContent="flex-start" h="100%">
							<ShowroomSiteSearch
								useCompactMenu={useCompactMenu}
								inputGroupProps={{
									background: "#F2F2FF",
									boxShadow: "inset 0px 3px 6px #00000029",
									border: "1px solid #D3D3FF",
									borderRadius: "full",
									h: "36px",
									ml: { base: "5px", md: "0" },
								}}
								iconOverride={<SearchIcon color="#A78EFB" />}
								iconHeight={12}
								iconWidth={12}
							/>
						</Box>
					</GridItem>
					{!useCompactMenu && (
						<GridItem colSpan={7}>
							<Center h="100%" w="100%">
								<ShowroomHeaderMenu stageTree={stageTree} title={title} hideHomeButton />
							</Center>
						</GridItem>
					)}

					<GridItem colSpan={2} justifySelf="right">
						{hasLoaded && (
							<Box display="flex" justifyContent="flex-end" h="100%">
								<Center h="100%">
									{isRemoteAccessing && (
										<AOTooltip
											helptext={`Remote Accessing: ${userSecurity.organizationName}`}
											asButton
											buttonProps={{
												p: 0,
												variant: "showroomHome",
												mr: "0.5em",
												borderRadius: "50%",
												color: showroomText,
												top: useCompactMenu ? "-3px" : "0",

												_hover: {
													background: "#bbbbef",
													boxShadow: "0px 0px 7px -3px inset rgb(116,0,30)",
												},
											}}
											isIconButton
										>
											<LinkIcon
												h={useCompactMenu ? "4" : "6"}
												w={useCompactMenu ? "4" : "6"}
											/>
										</AOTooltip>
									)}

									{!useCompactMenu && userSecurity.userId && (
										<LogoutMenu
											useCompactMenu={false}
											userSecurity={userSecurity}
											userImageLoaded={userImageLoaded}
											setUserImageLoaded={setUserImageLoaded}
											isHomepage
										/>
									)}
								</Center>
							</Box>
						)}
					</GridItem>
					{useCompactMenu && (
						<Flex width="100%" justify="right">
							<MobileMenu
								userSecurity={userSecurity}
								stageTree={stageTree}
								isMobileOpen={isMobileOpen}
								onMobileToggle={onMobileToggle}
								onMobileClose={onMobileClose}
								userImageLoaded={userImageLoaded}
								setUserImageLoaded={setUserImageLoaded}
								buttonPropsToUse={{
									borderRadius: "full",
									justifyContent: "center",
									variant: "showroomHome",
									margin: "0px",
								}}
								isHome
							/>
						</Flex>
					)}
				</Grid>
			</Box>
		</Box>
	);
};

interface ISiteSearchProps {
	useCompactMenu?: boolean;
	onMobileClose?: () => void;
	inputGroupProps?: InputGroupProps;
	iconOverride?: React.ReactNode;
	iconHeight?: CSS.Property.Height | number;
	iconWidth?: CSS.Property.Height | number;
}

export const ShowroomSiteSearch: FunctionComponent<ISiteSearchProps> = memo((props) => {
	const { onMobileClose, useCompactMenu, inputGroupProps, iconOverride, iconHeight, iconWidth } = props;

	const { siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);

	const searchPageUrl =
		siteInfo.isAdminSite || isLocalhost() ? getShowroomSearchAdminUrl() : getShowroomSearchUrl();

	const [query, setQuery] = useState<string>("");
	const navigate = useNavigate();

	const handleSearch = useCallback((): void => {
		const cleanedQuery = CleanSearchQuery(query);
		if (!cleanedQuery) {
			setQuery("");
			return;
		}
		if (onMobileClose) {
			onMobileClose();
		}
		navigate(`${searchPageUrl}?q=${encodeURIComponent(cleanedQuery)}`);
		setQuery("");
	}, [navigate, onMobileClose, query, searchPageUrl]);

	return (
		<Box w="100%" maxW="400px">
			<InputGroup w="unset" mb={useCompactMenu ? "5px" : "0"} {...inputGroupProps}>
				<Input
					pl="10px"
					type="text"
					placeholder={`Search...`}
					color={showroomText}
					maxLength={100}
					value={query}
					variant="unstyled"
					onChange={(event) => {
						setQuery(event.target.value);
					}}
					onKeyDown={(event) => {
						if (event.key === "Enter" && query && query.trim()) {
							handleSearch();
							return;
						}
					}}
					_placeholder={{ opacity: 0.8, color: showroomText }}
				/>
				<InputRightElement
					h={iconHeight || { base: 12, md: 10 }}
					w={iconWidth || { base: 12, md: 10 }}
				>
					<IconButton
						onClick={handleSearch}
						variant="ghost"
						h="2rem"
						w="1.75rem"
						minW="1.75rem"
						mt="-8px"
						borderRadius="3xl"
						aria-label="Search"
						icon={iconOverride ? <>{iconOverride}</> : <SearchIcon color={showroomText} />}
						_hover={{ color: showroomPrimary }}
					/>
				</InputRightElement>
			</InputGroup>
		</Box>
	);
});

const buttonProps: ButtonProps = {
	borderRadius: "full",
	fontFamily: "Jost",
	color: showroomText,
	fontSize: "1.1rem",
	justifyContent: "center",
	background: "#F4AE2A",
	boxShadow: "inset 0px 3px 6px #00000029",
	border: "1px solid #EF9C00",
};

const homepageUserMenuButtonProps: ButtonProps = {
	borderRadius: "full",
	fontFamily: "Jost",
	color: showroomText,
	fontSize: "1.1rem",
	justifyContent: "center",
	background: "#E5E5FF",
	boxShadow: "inset 0px 3px 6px #00000029",
	border: "1px solid #B9B9FF",
	_hover: {
		background: "#bbbbef",
		boxShadow: "0px 0px 7px -3px inset rgb(116,0,30)",
		textDecoration: "none",
	},
};

interface ILoginMenuProps {
	useCompactMenu: boolean;
	isHomepage?: boolean;
}

const LoginMenu: FunctionComponent<ILoginMenuProps> = memo((props) => {
	const { useCompactMenu, isHomepage } = props;

	const variant = isHomepage ? "showroomHome" : "showroomLight";

	return (
		<>
			{useCompactMenu ? (
				<LoginMenuContents includeExplanation fullWidth />
			) : (
				<Popover gutter={1}>
					<PopoverTrigger>
						<Button variant={variant} h="36px" borderRadius="18px" flexDir="column" px="8">
							<Text fontSize="0.75rem" fontWeight="normal">
								Customers &amp; Vendors
							</Text>
							<Text fontSize="1.125rem" fontWeight="500">
								Log In
							</Text>
						</Button>
					</PopoverTrigger>

					<PopoverContent boxShadow="0px 2px 4px gray !important">
						<LoginMenuContents includeExplanation />
					</PopoverContent>
				</Popover>
			)}
		</>
	);
});

interface ILogoutMenuProps {
	useCompactMenu: boolean;
	userSecurity: IUserSecurity;
	userImageLoaded: boolean;
	setUserImageLoaded: (value: boolean) => void;
	isHomepage?: boolean;
}

const LogoutMenu: FunctionComponent<ILogoutMenuProps> = memo((props) => {
	const { setUserImageLoaded, useCompactMenu, userImageLoaded, userSecurity, isHomepage } = props;

	const canSwitchOrgs = useMemo(() => hasMenu(userSecurity.menus, MenuItemId.SwitchOrgs), [
		userSecurity.menus,
	]);

	const isRemoteAccessing =
		userSecurity.userDBOrganizationId !== null &&
		userSecurity.userOrganizationId !== userSecurity.userDBOrganizationId;

	const [showRemoteOrgSelection, setShowRemoteOrgSelection] = useState(false);

	const buttonPropsToUse = !!isHomepage
		? homepageUserMenuButtonProps
		: {
				_hover: {
					background: "#7c0020",
					color: "white",
					boxShadow: "0px 0px 7px -3px inset rgb(116,0,30)",
				},
				...buttonProps,
		  };

	return (
		<>
			{canSwitchOrgs && (
				<RemoteOrgSelection
					isOpen={showRemoteOrgSelection}
					onClose={() => setShowRemoteOrgSelection(false)}
					stayOnCurrentPage
				/>
			)}
			{useCompactMenu ? (
				<>
					{canSwitchOrgs && (
						<Button
							variant="outline"
							borderRadius="full"
							w="100%"
							color={showroomText}
							borderColor={showroomText}
							onClick={() => setShowRemoteOrgSelection(true)}
						>
							Switch Orgs
						</Button>
					)}
					<LinkButton
						variant="outline"
						borderRadius="full"
						w="100%"
						url="Account/LogOff?relativeUri=showroom"
						opacity={userImageLoaded || !userSecurity.userPhotoSrc ? 1 : 0}
					>
						{userSecurity.userPhotoSrc && (
							<Image
								src={userSecurity.userPhotoSrc}
								h="calc(100% - 2px)"
								display="inline-block"
								alt="User Photo"
								pos="relative"
								mr="5px"
								my="2px"
								borderRadius="100px"
								background="white"
								onLoad={() => {
									setUserImageLoaded(true);
								}}
							/>
						)}
						Log Out
					</LinkButton>
				</>
			) : (
				<Popover gutter={1}>
					<PopoverTrigger>
						<Button
							rightIcon={<ChevronDownIcon />}
							{...buttonPropsToUse}
							colorScheme="whiteAlpha"
						>
							{userSecurity.userPhotoSrc && (
								<Box
									style={{ aspectRatio: "1/1" }}
									h="calc(100% - 2px)"
									overflow="hidden"
									borderRadius="50%"
									background="white"
									ml="-13px"
									mr="5px"
									my="2px"
								>
									<Image
										src={userSecurity.userPhotoSrc}
										h="100%"
										w="auto"
										m="0 auto"
										display="inline-block"
										alt="User Photo"
										pos="relative"
										onLoad={() => {
											setUserImageLoaded(true);
										}}
										fallback={<></>}
									/>
								</Box>
							)}
							<Box
								as="span"
								overflow="hidden"
								textOverflow="ellipsis"
								whiteSpace="nowrap"
								maxW={
									isHomepage
										? isRemoteAccessing
											? {
													base: "0",
													xl: "75px",
											  }
											: {
													base: "85px",
													xl: "120px",
											  }
										: {
												base: "25px",
												sm: "50px",
												md: "75px",
												lg: "100px",
												xl: "175px",
										  }
								}
								display="inline-block"
								verticalAlign="middle"
							>
								{userSecurity.displayName}
							</Box>
						</Button>
					</PopoverTrigger>
					<PopoverContent boxShadow={"0px 2px 4px gray !important"} w="125px">
						{canSwitchOrgs && (
							<>
								<Button
									w="100%"
									color={showroomText}
									variant="ghost"
									borderColor={showroomText}
									onClick={() => setShowRemoteOrgSelection(true)}
								>
									Switch Orgs
								</Button>
								<Divider borderColor="black" />
							</>
						)}
						<LinkButton
							w={"100%"}
							color={showroomText}
							url="Account/LogOff?relativeUri=showroom"
							variant="ghost"
						>
							Log Out
						</LinkButton>
					</PopoverContent>
				</Popover>
			)}
		</>
	);
});

interface IMobileMenuProps {
	isMobileOpen: boolean;
	onMobileClose: () => void;
	onMobileToggle: () => void;
	setUserImageLoaded: (value: boolean) => void;
	userImageLoaded: boolean;
	stageTree: IGalleryStageWithChildren[];
	userSecurity: IUserSecurity;
	buttonPropsToUse: ButtonProps;
	isHome?: boolean;
}

const MobileMenu: FunctionComponent<IMobileMenuProps> = memo((props) => {
	const {
		isMobileOpen,
		onMobileClose,
		onMobileToggle,
		setUserImageLoaded,
		stageTree,
		userImageLoaded,
		userSecurity,
		buttonPropsToUse,
		isHome,
	} = props;

	return (
		<Box>
			<Popover isOpen={isMobileOpen} onClose={onMobileClose}>
				<PopoverTrigger>
					<IconButton
						aria-label="Navigation"
						icon={<HamburgerIcon w="6" h="6" />}
						{...buttonPropsToUse}
						onClick={onMobileToggle}
						mr={buttonPropsToUse.marginRight || buttonPropsToUse.mr || "5px"}
					></IconButton>
				</PopoverTrigger>
				<PopoverContent overflowY="auto" maxH="600px">
					<PopoverBody>
						<VStack align="left" spacing={0}>
							{!userSecurity.userId && !isHome && <LoginMenu useCompactMenu />}
							{userSecurity.userId && (
								<LogoutMenu
									setUserImageLoaded={setUserImageLoaded}
									useCompactMenu
									userSecurity={userSecurity}
									userImageLoaded={userImageLoaded}
								/>
							)}
							<ShowroomHeaderMenuMobile
								stageTree={stageTree}
								onMenuItemClick={onMobileClose}
								hideHomeButton={isHome}
							/>
						</VStack>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		</Box>
	);
});
