/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Generate a client secret and hash it.
 * @module Epic.AppOrchard.Core.GenerateClientSecret
 */

import { Box, Button, Input, ListItem, OrderedList } from "@chakra-ui/react";
import { generateRandomSecret, sha1Hash, sha256hash } from "ao/utils/helpers";
import React, { FC, memo, useCallback, useState } from "react";
import { ConfirmationModal } from "../ConfirmationModal";

interface IProps {
	/** called when user choses to save
	 * the secret hashes that were generated or input */
	onStoreHashClicked: (secretHash: string, secretHash256: string) => void;
	/** caption of the 'store hash' / save button */
	storeButtonCaption: string;
	/** called when client secret workflow cancelled */
	onCancelClicked?: () => void;
	/** whether currently saving so a loading indicator can be shown */
	isSaving?: boolean;
	/** hash provided by parent component */
	providedHash?: string;
	/** use modal popup */
	useModal?: boolean;
}

/** Generate a client secret and hash it.
 * User can also enter their own secret hash the component will hash it for them. */
export const GenerateClientSecret: FC<IProps> = memo((props: IProps) => {
	const {
		onStoreHashClicked,
		storeButtonCaption,
		onCancelClicked,
		isSaving,
		providedHash,
		useModal,
	} = props;

	//#region state and handlers
	const [secret, setSecret] = useState<string>("");
	const [secretGenerated, setSecretGenerated] = useState<boolean>(false);
	const [showClientSecretStepsModal, setShowClientSecretStepsModal] = useState<boolean>(false);

	const handleStoreHashedSecretClicked = useCallback(async () => {
		const [hash, hash256] = await Promise.all([sha1Hash(secret), sha256hash(secret)]);
		onStoreHashClicked(hash, hash256);
		// reset state
		setSecretGenerated(false);
		setSecret("");
	}, [onStoreHashClicked, secret]);

	const handleGenerateSecret = useCallback(() => {
		if (providedHash) {
			onStoreHashClicked("", "");
		}
		setSecret(generateRandomSecret());
		setSecretGenerated(true);
		setShowClientSecretStepsModal(true);
	}, [onStoreHashClicked, providedHash]);

	const handleFocusSecret = useCallback((ev: React.FocusEvent<HTMLInputElement>) => ev.target.select(), []);

	const handleSecretChange = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>) => setSecret(ev.target.value),
		[],
	);

	const handleShowClientSecretSteps = useCallback(() => {
		setShowClientSecretStepsModal(false);
	}, []);
	//#endregion

	const clientSecretSteps = (
		<Box mt="1em">
			<Box fontWeight="bold">We have generated a client secret for you.</Box>
			<Box>Make sure you take the following steps or you won't be able to use your client secret:</Box>
			<OrderedList>
				<ListItem>Copy the secret so you can reference it later in your app's code.</ListItem>
				<ListItem>
					Click the '{storeButtonCaption}' button to make sure we save the secure version of the
					secret and sync it to the environments where it will be used.
				</ListItem>
			</OrderedList>
		</Box>
	);
	return (
		<>
			{(secretGenerated || providedHash !== undefined) && (
				<Input
					mb="1em"
					value={providedHash ? providedHash : secret}
					disabled={!secretGenerated}
					onChange={handleSecretChange}
					onFocus={handleFocusSecret}
				/>
			)}
			<Button colorScheme="blue" mr="0.5em" onClick={handleGenerateSecret} disabled={isSaving}>
				Generate Secret
			</Button>
			<Button
				colorScheme="green"
				mr="0.5em"
				disabled={(providedHash && providedHash.length > 0) || !secret}
				isLoading={isSaving}
				loadingText="Saving"
				onClick={handleStoreHashedSecretClicked}
			>
				{storeButtonCaption}
			</Button>
			{onCancelClicked && (
				<Button onClick={onCancelClicked} disabled={isSaving}>
					Cancel
				</Button>
			)}
			{secretGenerated && !useModal && clientSecretSteps}
			{useModal && (
				<ConfirmationModal
					message={clientSecretSteps}
					title="Client Secret Steps"
					isOpen={showClientSecretStepsModal}
					onAccept={handleShowClientSecretSteps}
				/>
			)}
		</>
	);
});
