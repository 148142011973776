/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Text indicating Epic internal content
 * @module Epic.AppOrchard.Core.EpicInternalText
 */

import { Box, BoxProps, Icon } from "@chakra-ui/react";
import React, { memo } from "react";

type Props = BoxProps;
/** Text indicating Epic internal content */
export const EpicInternalText = memo((props: Props) => {
	const { ...rest } = props;
	return (
		<Box
			title="This is Epic internal content. Only Epic users can see this."
			display={"inline-block"}
			color={"#C80000"}
			fontSize="12px"
			pl={rest.pl ?? 1}
			{...rest}
		>
			<Icon viewBox="0 0 160 160" mb={1}>
				<path fill="currentColor" d="M150 40l10-40H50L10 160h110l10-40H60l5-20h70l10-40H75l5-20z" />
			</Icon>
		</Box>
	);
});
