/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Frame helper functions
 */

export function menuIsCurrentPage(menuUrl: string, currentPageUrls: string[]) {
	if (!menuUrl) return false;
	const lowerMenuUrl = menuUrl.toLowerCase();
	return currentPageUrls.some((currentUrl) => currentUrl === lowerMenuUrl);
}
