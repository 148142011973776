/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Dropdown favorite button used by the DropdownMenu component
 * @module Epic.AppOrchard.Core.DropdownFavoriteButton
 */
import React, { FC, memo, useContext, useEffect } from "react";
import { DropdownMenuContext } from "./DropdownMenu";

interface IProps {
	children: React.ReactNode;
}

/** button to mark current selected item as favorite in the dropdown menu */
export const DropdownFavoriteButton: FC<IProps> = memo((props: IProps) => {
	const { children } = props;
	const { setFavoriteButton } = useContext(DropdownMenuContext);

	useEffect(() => {
		setFavoriteButton && setFavoriteButton(children);
	}, [children, setFavoriteButton]);

	return null;
});
