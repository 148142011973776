/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Used to format a menu item button in a site header dropdown
 * @module Epic.AppOrchard.Frame.HeaderMenuItem
 */

import { EditIcon, TriangleDownIcon, WarningIcon, WarningTwoIcon } from "@chakra-ui/icons";
import { As, Box, Button, ButtonProps, Flex, Image, Spacer } from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { orgHasUnapprovedDownloads } from "ao/data";
import { IUserSecurity, MenuItemId, OrganizationSelfAuditStatus } from "ao/types";
import React, { FC, forwardRef, ReactNode, Ref, useCallback, useEffect, useState } from "react";
import { AiFillFlag } from "react-icons/ai";
import { RemoteOrgSelection } from "./RemoteOrgSelection";

interface IProps {
	children: ReactNode;
	/** ID of the menu item */
	menuId?: number;
	/** whether this menu is for the current page */
	active?: boolean;
	/** whether to show the icon */
	showIcon?: boolean;
	/** use if you want to render a different component e.g. an anchor "a" element */
	as?: As;
	/** URL to link to if rendering as an link element */
	url?: string;
	isMobile?: boolean;
	isDropdown?: boolean;
	userSecurity?: IUserSecurity;
	/** whether to open in a new tab */
	newTab?: boolean;
	isForFavorites?: boolean;
}

type Props = ButtonProps & IProps;

/* Used to format a menu item button in a site header dropdown */
export const HeaderMenuItem: FC<Props> = forwardRef((props, ref: Ref<HTMLButtonElement>) => {
	const {
		children,
		active,
		as,
		url,
		menuId,
		isMobile,
		isDropdown,
		userSecurity,
		newTab,
		showIcon,
		isForFavorites,
		...rest
	} = props;

	//#region state hooks
	const shouldShowIcon = showIcon !== false;
	let helptext;
	let disabled = false;

	if (menuId === MenuItemId.DisabledSherlock) {
		helptext = "You do not have access to Sherlock currently. Contact Epic for assistance.";
		disabled = true;
	}

	const [showRemoteOrgSelection, setShowRemoteOrgSelection] = useState(false);
	const isRemoteOrgSelection = menuId === MenuItemId.SwitchOrgs;
	const updateShowRemoteOrgSelection = useCallback(
		(show: boolean) => () => {
			setShowRemoteOrgSelection(show);
		},
		[],
	);

	// whether this is a true link to a different page vs a button that runs some javascript code
	const isTrueLink = url !== "javascript";

	let iconHelptext;
	const auditStatus = userSecurity?.organizationAuditStatus;
	const [hasUnapprovedDownloads, setHasUnapprovedDownloads] = useState(false);
	const [, getUnapprovedDownloads] = useAsync(orgHasUnapprovedDownloads, {
		executeImmediately: false,
		displayName: "hasUnapprovedDownloads",
		onSuccess: (response) => setHasUnapprovedDownloads(response.Success && response.Data === true),
	});

	useEffect(() => {
		if (
			!isForFavorites &&
			menuId === MenuItemId.InterestedOrgs &&
			auditStatus !== OrganizationSelfAuditStatus.PastGrace
		) {
			getUnapprovedDownloads();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// get the left icon from the menu id
	let icon;
	if (shouldShowIcon) {
		const iconMeta = active ? "_hover" : "";
		try {
			icon = require(`images/Menu/${menuId}${iconMeta}.png`);
		} catch (err) {
			// fallback in case the icon for the menu doesn't exist
			icon = require(`images/Menu/0${iconMeta}.png`);
		}
	}

	// some menus use special logic to show a right icon
	let rightIcon;
	if (isForFavorites) {
		// no op we just don't want to set an icon in this case
	} else if (isDropdown) {
		rightIcon = <TriangleDownIcon w="0.55em" h="0.55em" />;
	} else {
		switch (menuId) {
			case MenuItemId.Attestation:
				if (auditStatus === OrganizationSelfAuditStatus.Open) {
					iconHelptext = "Your organization's self-audit attestation is due soon.";
					rightIcon = <EditIcon />;
				} else if (auditStatus === OrganizationSelfAuditStatus.InGrace) {
					iconHelptext =
						"Your organization's self-audit attestation is overdue. Fill it out soon to ensure you can continue to access other pages on this site.";
					rightIcon = <WarningTwoIcon />;
				} else if (auditStatus === OrganizationSelfAuditStatus.PastGrace) {
					iconHelptext =
						"Your organization's self-audit attestation is overdue. You must fill it out in order to access other pages on this site.";
					rightIcon = <WarningIcon />;
				}
				break;
			case MenuItemId.InterestedOrgs:
				if (hasUnapprovedDownloads) {
					iconHelptext = "There are unapproved download requests for your applications.";
					rightIcon = <AiFillFlag style={{ color: "#a94442" }} />;
				}
				break;
		}
	}
	//#endregion

	//#region subcomponents
	const content = (
		<>
			{icon && <Image src={icon} display="inline-block" h="25px" verticalAlign="middle" mr="5px" />}
			{children}
		</>
	);

	const fullContent =
		!isDropdown && rightIcon ? (
			<Flex alignItems="center">
				{content}
				<Spacer />
				<Box title={iconHelptext} aria-label={iconHelptext}>
					{rightIcon}
				</Box>
			</Flex>
		) : (
			content
		);

	//#endregion
	return (
		<>
			{isMobile ? (
				<Button
					title={helptext}
					cursor={disabled ? "not-allowed" : undefined}
					role="menuitem"
					position="relative"
					width={rest.width || "100%"}
					as={isTrueLink ? as : Button}
					url={isTrueLink ? url : undefined}
					textAlign={shouldShowIcon ? "left" : "center"}
					variant="unstyled"
					background={active ? "#E6F3F9" : undefined}
					border="1px solid rgb(200,230,240)"
					lineHeight="20px"
					display="block"
					color={active ? "rgb(10,100,150)" : "rgb(110,110,110)"}
					fontFamily="Segoe UI"
					fontSize="16px"
					fontWeight={active ? 500 : "normal"}
					height="unset"
					boxShadow={active ? "inset 0 3px 10px #9fd7ff" : undefined}
					_hover={{ background: "#E6F3F9", color: "rgb(10,100,150)", textDecoration: "none" }}
					rightIcon={isDropdown ? rightIcon : undefined}
					target={newTab ? "_blank" : undefined}
					{...rest}
					p={rest.p || rest.padding || (shouldShowIcon ? "8px" : "10px 0")}
					onClick={isRemoteOrgSelection ? updateShowRemoteOrgSelection(true) : rest.onClick}
				>
					{fullContent}
				</Button>
			) : (
				<Button
					title={helptext}
					cursor={disabled ? "not-allowed" : undefined}
					role="menuitem"
					variant="unstyled"
					as={isTrueLink ? as : Button}
					ref={ref}
					url={isTrueLink ? url : undefined}
					display={isTrueLink ? "block" : "inline-block"}
					textAlign="left"
					minW={rest.minW || "250px"}
					border="1px solid rgb(200,230,240)"
					borderTop="0"
					borderRadius="0"
					fontFamily="'Segoe UI', Tahoma, Geneva, Verdana, sans-serif"
					fontWeight="normal"
					color="#333"
					h={rest.h || rest.height || "42px"}
					whiteSpace="nowrap"
					fontSize="14px"
					background={active ? "rgba(0,145,234,.1)" : "transparent"}
					_hover={{ background: "#f5f5f5", textDecoration: "none", color: "#333" }}
					rightIcon={isDropdown ? rightIcon : undefined}
					target={newTab ? "_blank" : undefined}
					{...rest}
					padding={rest.p || rest.padding || "8px"}
					onClick={isRemoteOrgSelection ? updateShowRemoteOrgSelection(true) : rest.onClick}
				>
					{fullContent}
				</Button>
			)}
			{isRemoteOrgSelection && (
				<RemoteOrgSelection
					isOpen={showRemoteOrgSelection}
					onClose={updateShowRemoteOrgSelection(false)}
				/>
			)}
		</>
	);
});
