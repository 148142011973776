/**
 * @copyright Copyright 2021-2023 Epic Systems Corporation
 * @file App config
 */

export const config = {
	ToastDuration: 4500, // how long to show confirmation toast in ms
	MinEpicJkuVersion: 9.7, // JKU support was added in Epic May 2021, PRJ 230062
	EpicVersionForLocalJKUWithFlag: 10.8, // Local JKU support was added in Epic Feb 2024
	EpicVersionForLocalJKUWithoutFlag: 11.2, // Show Local JKU warning for customers on Epic Nov 2024 w/o flag
	NumAppThumbnailColors: 7, // colors themselves are defined in chakraTheme.ts
	Dashboard: {
		WidgetAppRowsPerPage: 2,
		MonthsAgoForLiveAppsHeader: 6,
		NumWidgetColsMd: 1, // number of columns of widgets when medium screen size (small will always be 1 column)
		NumWidgetColsLg: 6,
	},
	SiteName: "Vendor Services",
	ProgramDisplayName: "Vendor Services",
	SiteHelpEmail: "VendorServices@epic.com",
	SiteUrl: "VendorServices.epic.com",
	SitePath: "VendorServices",
	SitePathAlternate: "Showroom",
	PricingManagementWiki: "https://wiki.epic.com/main/Vendor_Services/API_Pricing_Management",
	EditingLiveAppsWiki:
		"https://wiki.epic.com/main/Interconnect/Client_IDs/Client_ID_Team_Processes#Updating_Live_Applications",
};
