/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Dropdown header used by the DropdownMenu component
 * @module Epic.AppOrchard.Core.DropdownHeader
 */
import React, { FC, memo, useContext, useEffect } from "react";
import { DropdownMenuContext } from "./DropdownMenu";

interface IProps {
	children: React.ReactNode;
}

/** header in the dropdown menu */
export const DropdownHeader: FC<IProps> = memo((props: IProps) => {
	const { children } = props;
	const { setHeader } = useContext(DropdownMenuContext);

	useEffect(() => {
		setHeader && setHeader(children);
	}, [children, setHeader]);

	return null;
});
