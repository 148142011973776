/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file siteInfo store updater methods
 * @module Epic.AppOrchard.StoreMethods.SiteInfo
 */

import { IDispatch } from "@epic/react-redux-booster";
import { siteInfoActions } from "../state/siteInfo";
import { ISiteInfo } from "../types";

/**
 * Update selected dashboard in the store
 * @param dispatch 	for useDispatch
 * @param dashboard new selected dashboard
 */
export function updateSiteInfo(dispatch: IDispatch, siteInfo: ISiteInfo): void {
	dispatch(siteInfoActions.updateSiteInfo(siteInfo));
}
