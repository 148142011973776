/**
 * @copyright Copyright 2021-2022 Epic Systems Corporation
 * @file Modal that shows a confirmation message
 * @module Epic.AppOrchard.Core.ConfirmationModal
 */

import {
	Button,
	ButtonProps,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	ModalProps,
} from "@chakra-ui/react";
import React, { FC, memo } from "react";

interface IProps {
	message: React.ReactNode;
	title: React.ReactNode;
	isOpen: boolean;
	onAccept: () => void;
	acceptCaption?: string;
	showCancel?: boolean;
	onCancel?: () => void;
	cancelCaption?: string;
	acceptButtonProps?: ButtonProps;
	cancelButtonProps?: ButtonProps;
}

type Props = IProps & Omit<ModalProps, "onClose" | "children">;

export const ConfirmationModal: FC<Props> = memo((props: Props) => {
	const {
		message,
		title,
		isOpen,
		onCancel,
		onAccept,
		showCancel,
		acceptCaption,
		cancelCaption,
		acceptButtonProps,
		cancelButtonProps,
		...rest
	} = props;

	const showCancelButton = showCancel !== false && onCancel;

	return (
		<Modal
			isOpen={isOpen}
			{...rest}
			onClose={onCancel || onAccept}
			size={rest.size ?? "2xl"}
			returnFocusOnClose
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader id="confirm-modal-header">{title}</ModalHeader>
				<ModalCloseButton />
				<ModalBody id="confirm-modal-body">{message}</ModalBody>
				<ModalFooter>
					<Button
						colorScheme={acceptButtonProps?.colorScheme || "blue"}
						onClick={onAccept}
						mr="2"
						{...acceptButtonProps}
					>
						{acceptCaption || "Ok"}
					</Button>
					{showCancelButton && (
						<Button onClick={onCancel} {...cancelButtonProps}>
							{cancelCaption || "Cancel"}
						</Button>
					)}
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
});
