/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file Error message pill
 * @module Epic.AppOrchard.Core.ErrorMessage
 */

import { Flex, FlexProps, FormErrorMessage } from "@chakra-ui/react";
import { FC, memo } from "react";

interface IErrorProps {
	message: string | undefined;
	/**
	 * @deprecated This can safely be removed. Current behavior is to assume this component is being used in a form. For cases where it is not, use the isNotForm attribute.
	 */
	isForm?: boolean;
	isNotForm?: boolean;
	isWarning?: boolean;
}

type IProps = FlexProps & IErrorProps;

export const ErrorMessage: FC<IProps> = memo((props: IProps) => {
	const { message, isForm, isNotForm, isWarning, ...rest } = props;
	let content = null;

	if (message && message.length > 0) {
		content = (
			<Flex
				{...rest}
				backgroundColor={
					rest.backgroundColor || isWarning ? "rgba(255,180,0,0.1)" : "rgba(255,0,0,0.1)"
				}
				display="inline-block"
				marginTop={rest.mt || rest.marginTop || isNotForm ? "7px" : ""}
				padding="2px 8px"
				borderRadius="15px"
				color={rest.color || isWarning ? "rgb(80,50,0)" : "darkred"}
			>
				{message}
			</Flex>
		);
	}

	return isNotForm ? content : <FormErrorMessage fontSize="1rem">{content}</FormErrorMessage>;
});
