/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file hook for input debouncing
 * @module Epic.AppOrchard.Hooks.UseDebounce
 */

import { useEffect, useState } from "react";

/**
 * Set up a debounce to limit the updating of a variable
 * @param value value to watch for debounce
 * @param delay milliseconds of delay
 * @returns
 */
export function useDebounce<T>(value: T, delay?: number): T {
	const [debouncedValue, setDebouncedValue] = useState<T>(value);

	useEffect(() => {
		const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

		return () => {
			clearTimeout(timer);
		};
	}, [value, delay]);

	return debouncedValue;
}
