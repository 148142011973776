/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file App View route
 * @module Epic.AppOrchard.Routes.ConnectorAppView
 */

import { generatePath } from "react-router";
import { IRouteInfo } from "../ao/routes";
import lazyRetry from "../ao/utils/lazy-retry";
/*
 *
 * IMPORTANT! READ THIS!
 *
 * The contents of this file are loaded and executed during the initial load of the application
 * in the browser even if the route is never actually accessed. DO NOT put any additional code
 * in this file, or import any addition modules as doing so would just add extra bloat to the
 * initial load / startup time for the application.
 *
 * IMPORTANT! READ THIS!
 *
 */

const relativePath = "/Connectors/Management/Apps/View";
const path = process.env.REACT_APP_DEV_URL_PREFIX + relativePath;

export const getConnectorAppRelativePath = (): string => {
	return relativePath;
};

export const getConnectorAppViewUrl = (id: number) => {
	return generatePath(`${path}?id=:id`, { id: id.toString() });
};

export const getConnectorAppViewRelativeUrl = (id: number) => {
	return generatePath(`${relativePath}?id=:id`, { id: id.toString() });
};

const route: IRouteInfo = {
	path,
	component: lazyRetry(
		() => import("../ao/components/ConnectorManagement/frameworkComponents/App/AppView"),
	),
	title: "App View",
};

export default route;
