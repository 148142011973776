/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Simple file upload that allows uploading a single file at a time.
 * Could be enhanced in the future to allow uploading multiple files or doing other things as needed
 * like showing a file preview after uploading
 * @module Epic.AppOrchard.Core.FileUpload
 */

import { Button, Input, InputGroup } from "@chakra-ui/react";
import { ISuccessHandler, useAsync } from "@epic/react-async-hook";
import React, { FC, memo, useCallback, useRef, useState } from "react";

interface IProps {
	/** accepted file types */
	acceptedFileTypes?: string;
	uploadCaption: string;
	/** called when file contents are successfully loaded, and validated if a validation callback is supplied*/
	onFileLoaded: (contents: string, ...args: any[]) => void;
	/** called to validate file contents async */
	onUploadAsyncValidator?: (contents: string) => Promise<any>;
	/** called when file content validation fails (server validation) */
	onValidationFailure?: () => void;
	/** called when loaded file is removed */
	onFileRemoved?: () => void;
	/** get filename from parent */
	providedFileName?: string;
}

/** Simple file upload that allows uploading a single file at a time.
 * Could be enhanced in the future to allow uploading multiple files or doing other things as needed
 * like showing a file preview after uploading
 */
export const FileUpload: FC<IProps> = memo((props: IProps) => {
	const {
		acceptedFileTypes,
		uploadCaption,
		onFileLoaded,
		onValidationFailure,
		onFileRemoved,
		providedFileName,
	} = props;

	//#region state and handlers

	// needed to satisfy the type constraint for useAsync,
	// but if onUploadValidation is undefined in props this won't be used
	let onUploadAsyncValidator: (contents: string) => Promise<any>;
	if (props.onUploadAsyncValidator) {
		onUploadAsyncValidator = props.onUploadAsyncValidator;
	} else {
		onUploadAsyncValidator = (_contents: string) => Promise.resolve(true);
	}

	const fileInputRef = useRef<HTMLInputElement>(null);
	const [fileName, setFileName] = useState<string>("");
	const [fileUploaded, setFileUploaded] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);

	const resetFileInput = useCallback(() => {
		setFileName("");
		setFileUploaded(false);
		setIsLoading(false);
		onFileRemoved && onFileRemoved();
		if (fileInputRef?.current) {
			fileInputRef.current.value = "";
		}
	}, [onFileRemoved]);

	const handleUploadValidationSuccess = useCallback<ISuccessHandler<typeof onUploadAsyncValidator>>(
		(response, extras) => {
			const [contents] = extras.params;
			setFileUploaded(true);
			setIsLoading(false);
			onFileLoaded(contents, response);
		},
		[onFileLoaded],
	);

	const handleUploadFailure = useCallback(() => {
		resetFileInput();
		onValidationFailure && onValidationFailure();
	}, [onValidationFailure, resetFileInput]);

	const [, onUploadValidationFn] = useAsync(onUploadAsyncValidator, {
		executeImmediately: false,
		displayName: "onFileUploadValidation",
		// `extras` contains a `params` field which has the params passed to the API call
		onSuccess: (response, extras) => handleUploadValidationSuccess(response, extras),
		onFailure: handleUploadFailure,
	});

	const handleFileInputChanged = useCallback(
		(ev: React.ChangeEvent<HTMLInputElement>) => {
			const files = ev.target.files;
			setIsLoading(true);

			if (files && files.length > 0) {
				const fileReader = new FileReader();
				const file = files[0];
				setFileName(file.name);

				fileReader.onload = (event: ProgressEvent<FileReader>) => {
					const contents = (event.target?.result || "") as string;
					if (contents) {
						if (props.onUploadAsyncValidator) {
							// validate contents using provided callback if supplied
							onUploadValidationFn(contents);
						} else {
							setFileUploaded(true);
							setIsLoading(false);
							onFileLoaded(contents);
						}
					} else {
						// empty file
						handleUploadFailure();
					}
				};

				fileReader.readAsText(file);
			}
		},
		[handleUploadFailure, onFileLoaded, onUploadValidationFn, props.onUploadAsyncValidator],
	);

	const handleUploadClicked = useCallback(() => {
		if (fileInputRef?.current) {
			fileInputRef.current.click();
		}
	}, []);

	//#endregion

	return (
		<>
			{fileUploaded || providedFileName ? (
				<InputGroup>
					<Input
						value={fileName.length > 0 ? fileName : providedFileName}
						type="text"
						readOnly
						mr="2"
					/>
					<Button onClick={resetFileInput} colorScheme="red" title="Remove file">
						Remove
					</Button>
				</InputGroup>
			) : (
				<>
					<Button onClick={handleUploadClicked} colorScheme="blue" isLoading={isLoading}>
						{uploadCaption}
					</Button>
					<Input
						ref={fileInputRef}
						type="file"
						accept={acceptedFileTypes}
						onChange={handleFileInputChanged}
						display="none"
					/>
				</>
			)}
		</>
	);
});
