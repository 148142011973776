/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Lazy load with retries
 * @author Erv Walter
 * @module Epic.Template.Utils.LazyRetry
 */

 import { ComponentType, lazy } from "react";

 const retry = (factory: () => any, retriesLeft = 3, interval = 200): Promise<any> => {
	 return new Promise<JSX.Element>((resolve, reject) => {
		 factory()
			 .then(resolve)
			 .catch((error: any) => {
				 setTimeout(() => {
					 if (retriesLeft === 1) {
						 // reject('maximum retries exceeded');
						 reject(error);
						 return;
					 }
 
					 // Passing on "reject" is the important part
					 retry(factory, retriesLeft - 1, interval).then(resolve, reject);
				 }, interval);
			 });
	 });
 };
 
 function lazyRetry<T extends ComponentType<any>>(factory: () => Promise<{ default: T }>) {
	 return lazy(() => retry(factory));
 }
 
 export default lazyRetry;
 