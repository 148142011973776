/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Helper functions for useAsync hook
 * @module Epic.AppOrchard.Utils.UseAsyncHelpers
 */

import {
	getError,
	getExecutionIds,
	getNumInFlight,
	getNumStarted,
	IAsyncState,
	isComplete,
} from "@epic/react-async-hook";

/**
 * Whether there are executions in flight
 * @param asyncState raw async state
 * @returns true if executions are in flight
 */
export function isInFlight(asyncState: IAsyncState): boolean {
	return getNumInFlight(asyncState) > 0;
}

/**
 * Whether the last async execution errored
 * @param asyncState raw async state
 * @returns true if the last execution errored
 */
export function lastExecutionErrored(asyncState: IAsyncState): boolean {
	const executionIDs = getExecutionIds(asyncState);
	return getNumStarted(asyncState) > 0 && getError(asyncState, executionIDs[executionIDs.length - 1]);
}

/**
 * Get error from last async execution
 * @param asyncState raw async state
 * @returns error message
 */
export function getLastError(asyncState: IAsyncState): Error {
	const executionIDs = getExecutionIds(asyncState);
	return getError(asyncState, executionIDs[executionIDs.length - 1]);
}

/**
 * Whether the last async execution is complete
 * @param asyncState raw async state
 * @returns true if the last execution is complete
 */
export function lastExecutionIsComplete(asyncState: IAsyncState): boolean {
	const executionIDs = getExecutionIds(asyncState);
	return getNumStarted(asyncState) > 0 && isComplete(asyncState, executionIDs[executionIDs.length - 1]);
}
