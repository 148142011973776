/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file Shared component adding and removing a single image
 * Can be reused across Vendor Services
 */

import { Image } from "@chakra-ui/image";
import { Box, Center, Square, Text } from "@chakra-ui/layout";
import { Button, IconButton, Input } from "@chakra-ui/react";
import React, { FC, memo, useCallback, useRef, useState } from "react";
import { AiFillCamera } from "react-icons/ai";
import { BsFillXCircleFill } from "react-icons/bs";
import { IAppImage } from "../../data";

interface IProps {
	image: IAppImage | undefined;
	imageWidth: number;
	imageHeight: number;
	typeOfImage?: string; //ex: thumbnail, profile photo, etc - defaults to Thumbnail
	onChangeCallback: (value: IAppImage | undefined) => void;
	disabled?: boolean;
}

export const SingleImageUpload: FC<IProps> = memo((props: IProps) => {
	const { disabled, image, imageWidth, imageHeight, typeOfImage, onChangeCallback } = props;
	const [hover, setHover] = useState<boolean>();
	const fileInputRef = useRef<HTMLInputElement>(null);

	const imageTypeString = typeOfImage ?? "Thumbnail";

	//#region handlers

	const handleDeleteImage = (): void => {
		if (fileInputRef?.current) {
			fileInputRef.current.value = "";
		}
		onChangeCallback(undefined);
	};

	const handleAddImage = (e: React.ChangeEvent<HTMLInputElement>): void => {
		if (e.target.files && e.target.files[0]) {
			const reader = new FileReader();
			reader.onload = function () {
				const image = reader.result;
				onChangeCallback({
					Id: 0,
					ImageData: image as string,
				});
			};
			reader.readAsDataURL(e.target.files[0]);
		}
	};

	const handleAddClicked = useCallback(() => {
		if (fileInputRef?.current) {
			fileInputRef.current.click();
		}
	}, []);

	//#endregion

	return (
		<>
			{image?.ImageData ? (
				<Box position="relative">
					<Image
						src={
							image.ImageData.substring(0, 4) !== "data"
								? "data:image/jpeg;base64," + image.ImageData
								: image.ImageData
						}
						alt={imageTypeString}
						border="1px dashed #5c95a0"
						borderRadius="10px"
						width={imageWidth}
						height={imageHeight}
						objectFit="contain"
						marginRight="10px"
						display="inline-block"
						position="relative"
						onMouseOver={() => setHover(true)}
						onMouseOut={() => setHover(false)}
						opacity={hover ? 0.7 : 1}
					/>

					{!disabled && (
						<>
							<IconButton
								aria-label={"Delete " + imageTypeString}
								title={"Delete " + imageTypeString}
								onClick={handleDeleteImage}
								position="absolute"
								left={`${imageWidth - 22}px`}
								top="1px"
								zIndex={imageWidth}
								backgroundColor="rgba(0, 0, 0, 0.5)"
								size="xs"
								fontSize="15px"
								color="white"
								_hover={{ color: "red" }}
								icon={<BsFillXCircleFill />}
							></IconButton>

							<IconButton
								aria-label={"Replace " + imageTypeString}
								title={"Replace " + imageTypeString}
								onClick={handleAddClicked}
								position="absolute"
								left={`${imageWidth / 2 - 12}px`}
								top={`${imageHeight / 2 - 12}px`}
								zIndex="100"
								backgroundColor="rgba(0, 0, 0, 0.5)"
								size="sm"
								fontSize="12px"
								borderRadius="0px"
								opacity={hover ? 0.7 : 0}
								color="white"
								_hover={{ opacity: 0.7, color: "yellow" }}
								_focus={{ opacity: 0.7, color: "yellow" }}
								icon={
									<Center>
										<AiFillCamera fontSize="20px" />
									</Center>
								}
							></IconButton>
						</>
					)}
				</Box>
			) : (
				<Button
					overflow="hidden"
					backgroundColor="#d5eef3"
					border="1px dashed #5c95a0"
					borderRadius="10px"
					width={imageWidth}
					height={imageHeight}
					textAlign="center"
					color="#1a798c"
					fontWeight="normal"
					cursor={disabled ? "not-allowed" : "pointer"}
					disabled={disabled}
					onClick={handleAddClicked}
				>
					<Square size="98px">
						<Box>
							<Center>
								<AiFillCamera fontSize="2em" />
							</Center>
							{disabled ? "No " + imageTypeString : "Add " + imageTypeString}

							{!disabled && (
								<Text color="gray" fontSize="xs">
									jpg, jpeg, png, gif
								</Text>
							)}
						</Box>
					</Square>
				</Button>
			)}

			<Input
				ref={fileInputRef}
				type="file"
				accept={"image/x-png,image/gif,image/jpeg,image/jpg"}
				onChange={handleAddImage}
				display="none"
			/>
		</>
	);
});
