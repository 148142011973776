/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Chakra Theme customizations
 *
 * We may want to follow this structure if this gets too large:
 * 		https://chakra-ui.com/docs/theming/customize-theme#scaling-out-your-project
 * @module Epic.AppOrchard
 */

import { extendTheme, theme } from "@chakra-ui/react";
import { createBreakpoints } from "@chakra-ui/theme-tools";

const breakpoints = createBreakpoints({ ...theme.breakpoints, "3xl": "105em" });
export const showroomPrimary = "#B30732";
export const showroomSecondary = "#F2634C";
export const showroomAccent = "#F9C22F";
export const showroomText = "#414042";
export const showroomStageTextDefault = "#047b80";
export const showroomPeopleCategoryColor = "#155CCD";
export const showroomAppCategoryColor = "#007980";
export const showroomGray = "#E5E5E5";
export const successGreen = "#247f16";
export const primaryBlue = "#2b7ca7";
export const showroomBorder = `1px solid ${showroomGray}`;

export const chakraTheme = extendTheme({
	breakpoints,
	styles: {
		global: {
			html: {
				fontSize: "14px",
			},
			// only show focus border for keyboard navigation
			// after upgrade to Chakra-UI 2.x Chakra does this by default so we can remove focus-visible
			".js-focus-visible :focus:not(.focus-visible)": {
				shadow: "none",
			},
			input: {
				_readOnly: {
					backgroundColor: "#eee",
				},
			},
			textarea: {
				_readOnly: {
					backgroundColor: "#eee",
				},
			},
			a: {
				color: "blue.500",
				_hover: {
					color: "blue.500",
					textDecoration: "underline",
				},
			},
			h1: {
				fontSize: "2.0rem",
				fontWeight: "500",
				lineHeight: "1.2",
				marginBottom: "2",
			},
			h2: {
				fontSize: "1.7rem",
				fontWeight: "500",
				lineHeight: "1.25",
			},
			h3: {
				fontSize: "1.25rem",
				fontWeight: "500",
				lineHeight: "1.25",
			},
			h4: {
				fontSize: "1.125rem",
				fontWeight: "500",
				lineHeight: "1.25",
			},
			[`@media screen and (max-width: ${theme.breakpoints.lg})`]: {
				h1: {
					fontSize: "1.5rem",
					fontWeight: "500",
					lineHeight: "1.25",
				},
				h2: {
					fontSize: "1.35rem",
					fontWeight: "500",
					lineHeight: "1.25",
				},
				h3: {
					fontSize: "1.20rem",
					fontWeight: "500",
					lineHeight: "1.25",
				},
				h4: {
					fontSize: "1.1rem",
					fontWeight: "500",
					lineHeight: "1.25",
				},
			},
		},
	},
	colors: {
		aoGreen: {
			foreground: "#458c72",
			background: "rgba(69, 140, 114, .2)",
			hover: "rgba(69, 140, 114, .1)",
		},
		appThumbnailBackground: {
			// these match the gallery placeholder background colors
			0: "#d63649",
			1: "#fbb040",
			2: "#6e55a4",
			3: "#a02384",
			4: "#e95724",
			5: "#d5d126",
			6: "#e5356d",
		},
		showroomPrimary: {
			100: "#f7d6de",
			200: "#fd98b2",
			300: "#ed6285",
			400: "#e12f5c",
			500: "#CD1543",
			600: "#b30732",
			700: "#ad032d",
			800: "#7b0120",
			900: "#63011a",
		},
		showroomAccent: {
			100: "#ffdfac",
			200: "#fbd775",
			300: "#fbd263",
			400: "#f5c84f",
			500: "#FAC73F",
			600: "#f9c22f",
			700: "#f1b617",
			800: "#edb10e",
			900: "#dfa200",
		},
		white: {
			50: "#ffffff",
			100: "#ffffff",
			200: "#ffffff",
			300: "#ffffff",
			400: "#ffffff",
			500: "#ffffff",
			600: "#ffffff",
			700: "#ffffff",
			800: "#ffffff",
			900: "#ffffff",
		},
		successGreen: {
			50: successGreen,
			100: successGreen,
			200: successGreen,
			300: successGreen,
			400: successGreen,
			500: successGreen,
			600: successGreen,
			700: successGreen,
			800: successGreen,
			900: successGreen,
		},
	},
	components: {
		Button: {
			_focusVisible: {
				boxShadow: "outline",
			},
			sizes: {
				dashboardButtonSize: {
					h: "45px",
					w: { base: "100%", lg: "unset" },
					fontSize: "md",
					px: 4,
					minW: 10,
				},
			},
			variants: {
				link: ({ colorScheme = "blue" }) => ({
					color: `${colorScheme}.500`,
					fontWeight: "normal",
					padding: "1px 2px",
					_hover: {
						background: `${colorScheme}.50`,
						textDecoration: "none",
						color: `${colorScheme}.900`,
					},
				}),
				linkUnderline: ({ colorScheme = "blue" }) => ({
					color: `${colorScheme}.600`,
					fontWeight: "normal",
					padding: "1px 2px",
					_hover: {
						textDecoration: "underline",
						color: `${colorScheme}.700`,
					},
				}),
				outline: ({ colorScheme = "black" }) => ({
					color: colorScheme === "black" ? "black" : `${colorScheme}.600`,
					borderColor: colorScheme,
					_hover: {
						background: colorScheme === "black" ? "gray.100" : `${colorScheme}.100`,
						textDecoration: "none",
						color: colorScheme === "black" ? "black" : `${colorScheme}.600`,
					},
				}),
				solid: ({ colorScheme = "gray", color }) => ({
					color: color
						? color
						: colorScheme === "white" || colorScheme === "gray"
						? "black"
						: "white",
					_hover: {
						textDecoration: "none",
						color: color
							? color
							: colorScheme === "white" || colorScheme === "gray"
							? "black"
							: "white",
					},
				}),
				ghost: ({ colorScheme = "gray" }) => ({
					_hover: {
						textDecoration: "none",
						background: `${colorScheme}.100`,
						color: `${colorScheme}.600`,
					},
				}),
				showroomDark: {
					background: "#910024",
					border: "2px solid #7C001F",
					borderRadius: "2px",
					color: "white",
					colorScheme: "whiteAlpha",
					_hover: {
						background: "#7c0020",
						boxShadow: "0px 0px 7px -3px inset rgb(116,0,30)",
						color: "white",
						textDecoration: "none",
					},
				},
				showroomLight: {
					background: "#F4AE2D",
					border: "2px solid #F1A52C",
					borderRadius: "2px",
					color: "#3A3A3C",
					colorScheme: "whiteAlpha",
					_hover: {
						background: "#7c0020",
						boxShadow: "0px 0px 7px -3px inset rgb(116,0,30)",
						color: "white",
						textDecoration: "none",
					},
				},
				showroomHome: {
					background: "#EDEDFF",
					border: "1px solid #B9B9FA",
					borderRadius: "18px",
					color: "#3A3A3C",
					colorScheme: "whiteAlpha",
					_hover: {
						background: "#bbbbef",
						boxShadow: "0px 0px 7px -3px inset rgb(116,0,30)",
						textDecoration: "none",
					},
				},
				card: {
					color: `#3467F4`,
					_hover: {
						background: "gray.200",
						textDecoration: "none",
					},
				},
			},
			defaultProps: {
				colorScheme: "gray",
				variant: "solid",
			},
		},
		// make link have standard link color by default
		Link: {
			variants: {
				primary: ({ colorScheme = "blue" }) => ({
					color: `${colorScheme}.500`,
					_hover: {
						color: `${colorScheme}.600`,
					},
				}),
				black: ({ colorScheme = "black" }) => ({
					color: `${colorScheme}.500`,
				}),
				dark: ({ colorScheme = "blue" }) => ({
					color: `${colorScheme}.600`,
					_hover: {
						textDecoration: "underline",
						color: `${colorScheme}.700`,
					},
				}),
			},
			defaultProps: {
				variant: "primary",
			},
		},
		Tag: {
			variants: {
				showroom: (props) => {
					const { colorScheme } = props;

					return {
						container: {
							color: `#3F3D3F`,
							borderRadius: "full",
							borderColor: `${colorScheme}.700`,
							borderWidth: `1px`,
							borderStyle: `solid`,
							backgroundColor: `${colorScheme}.100`,
							fontFamily: "jost",
						},
					};
				},
			},
		},
		// make checkbox easier to see when unchecked
		Checkbox: {
			baseStyle: {
				// checkbox is a multipart component https://github.com/chakra-ui/chakra-ui/discussions/2452
				control: {
					borderColor: "black",
				},
				label: {
					_disabled: {
						opacity: 1,
					},
				},
			},
		},
		Radio: {
			baseStyle: {
				control: {
					borderColor: "black",
					cursor: "pointer",
					_disabled: {
						cursor: "not-allowed",
					},
				},
				label: {
					cursor: "pointer",
					_disabled: {
						opacity: 1,
						cursor: "not-allowed",
					},
				},
			},
			sizes: {
				sm: {
					control: {
						width: "12px",
						height: "12px",
					},
				},
				md: {
					control: {
						width: "16px",
						height: "16px",
					},
				},
				lg: {
					control: {
						width: "20px",
						height: "20px",
					},
				},
			},
		},
		Switch: {
			baseStyle: {
				// Switch is a multipart component https://github.com/chakra-ui/chakra-ui/discussions/2452
				track: {
					bg: "gray.400",
					_disabled: {
						opacity: "0.4",
						cursor: "not-allowed",
					},
				},
				label: {
					_disabled: {
						opacity: 1,
						cursor: "not-allowed",
					},
				},
				container: {
					cursor: "pointer",
					_disabled: {
						cursor: "not-allowed",
					},
				},
			},
		},
		Select: {
			baseStyle: {
				// Select is a multipart component https://github.com/chakra-ui/chakra-ui/discussions/2452
				field: {
					opacity: "1.0 !important",
					_disabled: {
						opacity: "1.0 !important",
						backgroundColor: "#eee",
					},
				},
				icon: {
					_disabled: {
						opacity: "0.4",
					},
				},
			},
		},
		FormErrorMessage: {
			baseStyle: {
				fontSize: "1.5rem",
			},
		},
		Modal: {
			variants: {
				// allows users to interact with the content behind the modal
				clickThrough: {
					dialogContainer: {
						pointerEvents: "none",
					},
					dialog: {
						pointerEvents: "auto",
					},
				},
			},
		},
		Popover: {
			baseStyle: {
				content: {
					background: "white",
				},
			},
			variants: {
				// removes border radius and bottom/size borders, adds box shadow
				headerDropdown: {
					content: {
						borderWidth: "1px 0 0 0",
						borderRadius: 0,
						boxShadow: "0 6px 12px rgb(0 0 0 / 18%)!important",
					},
				},
			},
		},
		Alert: {
			baseStyle: {
				description: {
					whiteSpace: "pre-line",
				},
			},
			variants: {
				// removes border radius and bottom/size borders, adds box shadow
				error: {
					container: {
						color: "white",
						background: "#EB0000",
					},
				},
				success: {
					container: {
						color: "white",
						background: `green.600`,
					},
				},
				info: {
					container: {
						color: "white",
						background: `blue.600`,
					},
				},
			},
		},
		Heading: {
			sizes: (null as unknown) as undefined,
			baseStyle: {
				fontWeight: (null as unknown) as undefined,
			},
		},
		Table: {
			baseStyle: {
				td: {
					whiteSpace: "normal",
				},
			},
			variants: {
				striped: {
					td: {
						whitespace: "normal",
					},
				},
				unstyled: {
					td: {
						whitespace: "normal",
					},
				},
			},
		},
		Textarea: {
			baseStyle: {
				transition: "all 0.2s ease 0s, height 0s",
			},
		},
	},
});
