/**
 * @copyright Copyright 2023-2023 Epic Systems Corporation
 * @file ShowroomHeader menu bar components
 * @module Epic.AppOrchard.Frame.ShowroomHeaderMenu
 */

import { ChevronDownIcon } from "@chakra-ui/icons";
import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Popover,
	PopoverContent,
	PopoverTrigger,
	Text,
	useDisclosure,
	VStack,
} from "@chakra-ui/react";
import { showroomText } from "ao/chakraTheme";
import { useSiteInfoState } from "ao/state/siteInfo";
import { IGalleryStageWithChildren, stageHasComingSoonCardStyle } from "ao/types/showroom";
import React, { FunctionComponent, memo, useMemo, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import { LinkButton } from "../Core";
import { getIntFromSearchParam, getNodeFromTree } from "../Showroom/Helper";
import { EpicSiteNavbar } from "./EpicSiteNavbar/EpicSiteNavbar";

interface Props {
	stageTree: IGalleryStageWithChildren[];
	title?: string;
	hideHomeButton?: boolean;
	onMenuItemClick?: () => void;
}

const menuButtonProps = {
	color: showroomText,
	fontWeight: "400",
	fontSize: "1.1rem",
	padding: "14px",
	variant: "ghost",
	zIndex: 2,
};

export const ShowroomHeaderMenu: FunctionComponent<Props> = memo((props) => {
	const { stageTree, title, hideHomeButton } = props;

	const contentRefs = useRef<Array<HTMLButtonElement | null>>([]);

	const [searchParams] = useSearchParams();
	const urlStageId = title === "ShowroomStage" ? getIntFromSearchParam(searchParams, "id") ?? -1 : -1;

	const { siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);

	return stageTree && stageTree.length > 0 ? (
		<>
			{hideHomeButton !== true && (
				<LinkButton
					{...menuButtonProps}
					url={siteInfo.showroomRelativeUrl}
					ref={(myRef: any) => (contentRefs.current[0] = myRef)}
					transition="none"
					onClick={() => window.scrollTo(0, 0)}
					textShadow={
						title === "Showroom" || title === "ShowroomAdmin" ? `1px 0 0 ${showroomText}` : ""
					}
				>
					Home
				</LinkButton>
			)}
			{stageTree.map((stage) => (
				<ShowroomHeaderMenuButton
					stage={stage}
					contentRefs={contentRefs}
					key={stage.id}
					urlStageId={urlStageId}
				/>
			))}
		</>
	) : (
		<></>
	);
});

interface IProps {
	stage: IGalleryStageWithChildren;

	contentRefs: React.MutableRefObject<(HTMLButtonElement | null)[]>;
	urlStageId: number;
}

const ShowroomHeaderMenuButton: FunctionComponent<IProps> = memo((props) => {
	const { stage, contentRefs, urlStageId } = props;

	const { isOpen, onOpen, onClose } = useDisclosure();

	const { siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);
	const isSelected = useMemo(() => getNodeFromTree(urlStageId, stage) !== null, [stage, urlStageId]);

	// No menu items for Coming Soon stages
	if (stageHasComingSoonCardStyle(stage)) {
		return null;
	}
	const filteredChildStages = stage.childStages.filter(
		(childStage) => !stageHasComingSoonCardStyle(childStage),
	);

	if (!stage.flattenTier && filteredChildStages?.length > 0) {
		return (
			<Popover
				isOpen={isOpen}
				gutter={1}
				closeOnBlur={false}
				returnFocusOnClose={false}
				trigger="hover"
			>
				<PopoverTrigger>
					<LinkButton
						url={`${siteInfo.showroomRelativeUrl}stage?id=${stage.id}`}
						rightIcon={<ChevronDownIcon m="0px" />}
						{...menuButtonProps}
						ref={(myRef: any) => {
							contentRefs.current[stage.id] = myRef;
						}}
						onMouseEnter={onOpen}
						onMouseLeave={onClose}
						onFocus={() => {
							onOpen();
							contentRefs.current[stage.id]?.focus();
						}}
						onBlur={onClose}
						textShadow={isSelected ? `1px 0 0 ${showroomText}` : ""}
						title={stage.groupName}
					>
						<Text
							overflow="hidden"
							textOverflow="ellipsis"
							maxW="200px"
							borderBottom={isSelected ? `2px solid ${showroomText}` : ""}
						>
							{stage.groupName}
						</Text>
					</LinkButton>
				</PopoverTrigger>

				<PopoverContent
					onFocus={onOpen}
					onMouseEnter={onOpen}
					onMouseLeave={onClose}
					boxShadow={"0px 2px 4px gray !important"}
				>
					{filteredChildStages.map((child, index) => (
						<LinkButton
							url={`${siteInfo.showroomRelativeUrl}stage?id=${child.id}`}
							{...menuButtonProps}
							lineHeight="1.5"
							justifyContent="left"
							color={showroomText}
							variant="ghost"
							onClick={onClose}
							onKeyDown={(event) => {
								const key = event.key;

								if (
									key === "Escape" ||
									key === "Enter" ||
									(key === "Tab" && index === filteredChildStages.length - 1)
								) {
									onClose();
								}
							}}
						>
							<Text overflow="hidden" textOverflow="ellipsis">
								{child.groupName}
							</Text>
						</LinkButton>
					))}
				</PopoverContent>
			</Popover>
		);
	} else {
		return (
			<LinkButton
				{...menuButtonProps}
				url={`${siteInfo.showroomRelativeUrl}stage?id=${stage.id}`}
				ref={(myRef: any) => (contentRefs.current[stage.id] = myRef)}
				color={showroomText}
				textShadow={isSelected ? `1px 0 0 ${showroomText}` : ""}
				title={stage.groupName}
			>
				<Text
					overflow="hidden"
					textOverflow="ellipsis"
					maxW="200px"
					borderBottom={isSelected ? `2px solid ${showroomText}` : ""}
				>
					{stage.groupName}
				</Text>
			</LinkButton>
		);
	}
});

export const ShowroomHeaderMenuMobile: FunctionComponent<Props> = memo((props) => {
	const { stageTree, onMenuItemClick, hideHomeButton } = props;

	const { siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);

	const buttonProps = {
		background: "white",
		width: "100%",
		justifyContent: "left",
		fontWeight: "400",
		padding: "7px 14px 7px 14px",
		borderRadius: "0px",
		textOverflow: "ellipsis",
		overflow: "hidden",
		margin: "2px",
	};

	return (
		<Box paddingY="5px">
			<Accordion allowToggle>
				<AccordionItem>
					<h2>
						<AccordionButton>
							<Box as="span" flex="1" textAlign="left">
								Epic Websites
							</Box>
							<AccordionIcon />
						</AccordionButton>
					</h2>
					<AccordionPanel pb={4} px={4}>
						<EpicSiteNavbar isMobile={true} />
					</AccordionPanel>
				</AccordionItem>
				{!hideHomeButton && (
					<AccordionItem>
						<LinkButton url={`/`} {...buttonProps} onClick={onMenuItemClick}>
							Home
						</LinkButton>
					</AccordionItem>
				)}
				{stageTree.map((stage) => {
					// No menu items for Coming Soon stages
					if (stageHasComingSoonCardStyle(stage)) {
						return null;
					}
					const filteredChildStages = stage.childStages.filter(
						(childStage) => !stageHasComingSoonCardStyle(childStage),
					);

					return (
						<AccordionItem>
							{stage.flattenTier ? (
								<LinkButton
									url={`${siteInfo.showroomRelativeUrl}stage?id=${stage.id}`}
									{...buttonProps}
									onClick={onMenuItemClick}
								>
									{stage.groupName}
								</LinkButton>
							) : (
								<>
									<h2>
										<AccordionButton {...buttonProps}>
											<Box as="span" flex="1" textAlign="left">
												{stage.groupName}
											</Box>
											<AccordionIcon />
										</AccordionButton>
									</h2>
									<AccordionPanel py={2} px={4}>
										<VStack justify="left" spacing={"2px"}>
											<LinkButton
												url={`${siteInfo.showroomRelativeUrl}stage?id=${stage.id}`}
												onClick={onMenuItemClick}
												{...buttonProps}
											>
												All {stage.groupName}
											</LinkButton>
											{filteredChildStages.map((child) => (
												<LinkButton
													url={`${siteInfo.showroomRelativeUrl}stage?id=${child.id}`}
													onClick={onMenuItemClick}
													{...buttonProps}
												>
													<Text overflow="hidden" textOverflow="ellipsis">
														{child.groupName}
													</Text>
												</LinkButton>
											))}
										</VStack>
									</AccordionPanel>
								</>
							)}
						</AccordionItem>
					);
				})}
			</Accordion>
		</Box>
	);
});
