/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file state/store for general website info
 * @module Epic.AppOrchard.State.UserSecurity
 */

import { buildSharedState } from "@epic/react-redux-booster";
import { Shell } from "ao/components/Frame/shell";
import store from "../store";
import { ISiteInfo } from "../types";

/// TYPES ///

export interface IState {
	readonly siteInfo: ISiteInfo;
	readonly hasLoaded: boolean;
}

/// INIT ///

/**
 * Returns an empty user security initial state
 */
export function getInitialState(): IState {
	return {
		siteInfo: {
			siteName: "",
			programDisplayName: "",
			clientGardenUrl: "",
			googleAnalyticsTrackingId: "",
			recaptchaPublicKey: "",
			userInactivityLogoutTimeInMinutes: Shell.DefaultLogoutIdleTimeInMinutes,
			emc2BaseUrl: "",
			abacusBaseUrl: "",
			galleryTokens: {
				galleryName: "",
				periodName: "",
				galleryBlurb: "",
				galleryDisclaimer: "",
			},
			userWebUrl: "",
			guruBaseUrl: "",
			customerAgreementName: "",
			customerAgreementDisplayName: "",
			vendorServicesUrl: "",
			showroomUrl: "",
			showroomRelativeUrl: "",
		},
		hasLoaded: false,
	};
}

/// REDUCERS ///

/**
 * Update site info
 * @param _state current state
 * @param newUserSecurity new site info state
 * @returns
 */
export function updateSiteInfo(_state: IState, newSiteInfo: ISiteInfo): IState {
	return {
		siteInfo: newSiteInfo,
		hasLoaded: true,
	};
}

/// SELECTORS ///

/**
 * Get current state
 * @param state current state
 * @returns
 */
export function getState(state: IState): IState {
	return state;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		updateSiteInfo,
	},
	selectors: {
		getState,
	},
});
store.addSharedState(builtState.sharedState, "siteInfo");

export const { actionCreators: siteInfoActions, useSharedState: useSiteInfoState } = builtState;
