/**
 * @copyright Copyright 2018-2024 Epic Systems Corporation
 * @file application data
 * @module Epic.AppOrchard.Data.Data
 */

import { config } from "ao/appConfig";
import { Shell } from "ao/components/Frame/shell";
import { getShowroom, IShowroomHomepage } from "ao/components/Showroom/data/data";
import { InstallStatus } from "ao/data";
import {
	IFavorite,
	IFavoritesResults,
	IGenerateClientIDResponse,
	IHasUnapprovedDownloadsResponse,
	IIdleTimeResult,
	INotification,
	INotificationCount,
	INotificationListResults,
	IRemoteOrgResult,
	ISaveFavoriteResult,
	ISendMessageResponse,
	ISiteSearchResult,
	ISiteSearchResults,
	IUpdateChangeRequestModel,
} from "ao/types";
import {
	IGenericResponseModel,
	IPostMessageModel,
	IPreviewDownloadBuildResponseModel,
	ISaveDefaultOrgResponse,
	ISiteInfo,
	IUserSecurity,
	OrganizationStatus,
	ProgramLevel,
	UserAccountType,
} from "../types";
import api from "../utils/api";
import {
	IClientError,
	IGenericAPIDataResponse,
	IGenericAPIResponseWrongCasing,
	IJwtSigningKeyValidationResponse,
	IUserAvailableOrganizations,
	RootDependentData,
} from "./types";
/**
 * Get organizations available for the user to select from
 * @returns list of organizations and the default
 */
export async function getAvailableOrgs(): Promise<IUserAvailableOrganizations> {
	const data: IUserAvailableOrganizations = await api.get<IUserAvailableOrganizations>(
		"/api/UserOrganizations/AvailableOrganizations",
	);
	return data;
}

/**
 * Set an org as the current user's default org for display in org dropdowns
 * @param orgId org ID to set as default
 * @returns success or error
 */
export async function setOrgAsDefault(orgId: number): Promise<ISaveDefaultOrgResponse> {
	const response: ISaveDefaultOrgResponse = await api.post<ISaveDefaultOrgResponse>(
		`/api/UserOrganizations/SetOrgAsDefault?orgId=${orgId}`,
	);
	return response;
}

/**
 * Get user security for the current user from the server
 * @returns user security
 */
export async function getUserSecurity(): Promise<IUserSecurity> {
	const data: IUserSecurity = await api.get<IUserSecurity>("/api/UserSecurity/GetUserSecurity");
	return data;
}

/**
 * Get required data that isn't already in the store from the server
 * @param loadUserSecurity whether user security data is needed (false if already in store)
 * @param loadUserOrganizations whether user orgs data is needed (false if already in store)
 * @param loadSiteInfo whether site info  data is needed (false if already in store)
 * @returns promise for loading any combination of the 2 data types as needed
 */
export async function getRootDependentData(
	loadUserSecurity: boolean,
	loadUserOrganizations: boolean,
	loadSiteInfo: boolean,
	loadShowroomHomepage: boolean,
): Promise<RootDependentData> {
	const promises: [
		Promise<IUserSecurity> | undefined,
		Promise<IUserAvailableOrganizations> | undefined,
		Promise<ISiteInfo> | undefined,
		Promise<IShowroomHomepage> | undefined,
	] = [
		loadUserSecurity ? getUserSecurity() : undefined,
		loadUserOrganizations ? getAvailableOrgs() : undefined,
		loadSiteInfo ? getSiteInfo() : undefined,
		loadShowroomHomepage ? getShowroom() : undefined,
	];

	return Promise.all(promises);
}

/**
 * Contact app creator / vendor user by sending an email from a template
 *  @param appId client app ID
 * @returns generic response on success
 */
export async function contactAuthor(appId: number): Promise<IGenericResponseModel> {
	const data: IGenericResponseModel = await api.get<IGenericResponseModel>(
		`/api/Showroom/ContactAuthor?appId=${appId}`,
	);
	return data;
}

/**
 * Accept the open.epic API terms
 * @param termsId participant agreement terms
 * @returns generic response model
 */
export async function acceptApiLicenseTerms(termsId: number): Promise<IGenericResponseModel> {
	const data: IGenericResponseModel = await api.post<IGenericResponseModel>(
		`/api/GalleryApi/AcceptApiLicense?termsId=${termsId}`,
	);
	return data;
}

/**
 * Get general website info
 * @returns site info
 */
export async function getSiteInfo(): Promise<ISiteInfo> {
	const data: ISiteInfo = await api.get<ISiteInfo>("/api/SiteHelpers/GetSiteInfo");
	return data;
}

export function getFallbackSiteInfo(): ISiteInfo {
	return {
		siteName: config.SiteName,
		programDisplayName: config.ProgramDisplayName,
		googleAnalyticsTrackingId: "",
		recaptchaPublicKey: "",
		userInactivityLogoutTimeInMinutes: Shell.DefaultLogoutIdleTimeInMinutes,
		emc2BaseUrl: "",
		abacusBaseUrl: "",
		galleryTokens: {
			galleryName: "",
			periodName: "",
			galleryBlurb: "",
			galleryDisclaimer: "",
		},
		vendorServicesUrl: "",
		showroomUrl: "",
		showroomRelativeUrl: "",
		userWebUrl: "",
		guruBaseUrl: "",
		customerAgreementName: "",
		customerAgreementDisplayName: "",
	};
}

/**
 * Gets information about turbocharger (TC) packages for an app for download build preview
 * @param appId app ID to update install status for
 * @param tcPackageIds list of TC package IDs
 * @returns list of packages available to the app
 */
export async function previewDownloadableBuild(appId: number): Promise<IPreviewDownloadBuildResponseModel> {
	const response: IPreviewDownloadBuildResponseModel = await api.get<IPreviewDownloadBuildResponseModel>(
		"/api/GalleryApi/PreviewDownloadBuild",
		{
			clientApplicationId: appId.toString(),
		},
	);
	return response;
}

/**
 * Update install status for an app
 * @param appId app ID to update install status for
 * @param _orgID customer org ID, not used in API call directly, but passed here so it can be availble to the success handler
 * @param newStatus new install status
 * @returns generic response on success or throws on error
 */
export async function updateInstallStatus(
	appId: number,
	_orgId: number,
	newStatus: InstallStatus,
): Promise<IGenericResponseModel> {
	const response: IGenericResponseModel = await api.post<IGenericResponseModel>(
		`/api/GalleryApi/UpdateInstallStatus?appId=${appId}&newStatus=${newStatus}`,
	);
	return response;
}

/**
 * Badger app creator / vendor for an update on the pending download requst by sending an email from a template
 *  @param appId client app ID
 * @returns generic response on success, throws error on failure
 */
export async function badgerAuthor(appId: number): Promise<IGenericResponseModel> {
	const data: IGenericResponseModel = await api.post<IGenericResponseModel>(
		`/api/GalleryApi/BadgerAuthor?appId=${appId}`,
	);
	return data;
}

/**
 * Mark customer app as internal
 *  @param appId client app ID
 * @returns generic response on success, throws error on failure
 */
export async function markAsInternal(appId: number): Promise<IGenericResponseModel> {
	const data: IGenericResponseModel = await api.post<IGenericResponseModel>(
		`/api/DeveloperApi/MarkAsInternal?appId=${appId}`,
	);
	return data;
}

/**
 * Add/update/delete request from a customer developer for a user
 *  with elevated security to make a change for a customer app
 *  @param updateRequest change requested
 * @returns generic response on success, throws error on failure
 */
export async function updateCustomerAppChangeRequest(
	updateRequest: IUpdateChangeRequestModel,
): Promise<IGenericResponseModel> {
	const data: IGenericResponseModel = await api.post<IGenericResponseModel>(
		`/api/DeveloperApi/UpdateCustomerAppLicenseChangeRequest`,
		updateRequest,
	);
	return data;
}

/**
 *
 * @param data X.509 public key file contents text validate
 * @returns validated model on success, or error on failure
 */
export async function validateJWTSigningKey(data: string): Promise<IJwtSigningKeyValidationResponse> {
	const response: IJwtSigningKeyValidationResponse = await api.post<IJwtSigningKeyValidationResponse>(
		"/api/DeveloperApi/ValidateJWTSigningKey",
		data,
	);
	return response;
}

export async function sendMessageForm(data: IPostMessageModel): Promise<ISendMessageResponse> {
	const response: ISendMessageResponse = await api.post<ISendMessageResponse>("/Home/Message", data);
	return response;
}

/**
 * Get a client ID generated by the server
 * @returns generated client ID
 */
export async function generateClientId(): Promise<IGenerateClientIDResponse> {
	const data: IGenerateClientIDResponse = await api.get<IGenerateClientIDResponse>(
		"/api/DeveloperApi/NewClientId",
	);
	return data;
}

/**
 * Whether the org has unapproved downloads
 * @returns object containing true/false
 */
export async function orgHasUnapprovedDownloads(): Promise<IHasUnapprovedDownloadsResponse> {
	const data: IHasUnapprovedDownloadsResponse = await api.get<IHasUnapprovedDownloadsResponse>(
		`/Developer/HasUnapprovedDownloads`,
	);
	return data;
}

/**
 * Search the site for menus, documents, apis
 *  @param searchTerm text to search for
 *  @param page page of results to load
 *  @param _currentResults needed in success handler
 * @returns matching results
 */
export async function siteSearch(
	searchTerm: string,
	page: number,
	_currentResults: ISiteSearchResult[],
): Promise<ISiteSearchResults> {
	const data: ISiteSearchResults = await api.post<ISiteSearchResults>("/Home/Search", {
		data: searchTerm,
		page: page,
	});
	return data;
}

//#region favorites

/**
 * Get user's favorite pages
 * @returns matching results
 */
export async function getFavorites(): Promise<IFavoritesResults> {
	const data: IFavoritesResults = await api.get<IFavoritesResults>("/Home/FavoriteList");
	return data;
}

/**
 * Add favorite menu for user
 *  @param path URL path to add favorite for
 *  @param _favorites current favorites (used in callback)
 * @returns new favorite
 */
export async function addFavorite(path: string, _favorites: IFavorite[]): Promise<ISaveFavoriteResult> {
	const data: ISaveFavoriteResult = await api.post<ISaveFavoriteResult>("/Home/AddFavorite", {
		data: path,
	});
	return data;
}

/**
 * Remove favorite menu for user
 *  @param id id of the favorite to remove
 *  @param _favorites current favorites (used in callback)
 * @returns result of operation
 */
export async function removeFavorite(id: number, _favorites: IFavorite[]): Promise<IGenericAPIDataResponse> {
	const data: IGenericAPIDataResponse = await api.post<IGenericAPIDataResponse>("/Home/RemoveFavorite", {
		data: id,
	});
	return data;
}

//#endregion

//#region remote orgs

/**
 * Get a customer user's remote organizations they can switch between
 */
export async function remoteOrganizations(): Promise<IRemoteOrgResult> {
	const data: IRemoteOrgResult = await api.get<IRemoteOrgResult>("/Home/RemoteOrganizations");
	return data;
}

/**
 * Customer switch to accessing a different organizations
 */
export async function switchOrganization(orgId: number): Promise<IGenericAPIDataResponse> {
	const data: IGenericAPIDataResponse = await api.post<IGenericAPIDataResponse>(
		`/Home/ConfirmRemoteSelection?orgId=${orgId}`,
	);
	return data;
}
//#endregion

//#region notifications

/**
 * Get a list of actionable notifications for the user
 */
export async function notificationList(): Promise<INotificationListResults> {
	const data: INotificationListResults = await api.get<INotificationListResults>("/Notifications/List");
	return data;
}

/**
 * Get a count of unread notifications for the user
 */
export async function newNotificationsCount(): Promise<INotificationCount> {
	const data: INotificationCount = await api.get<INotificationCount>("/Notifications/Count");
	return data;
}

/**
 * Dismiss notifications for the user
 *  @param dismissedNotificationIds ids of the notifications to dismiss
 *  @param _allNotifications all notifications (used in callback)
 * @returns result of operation
 */
export async function dismissNotifications(
	dismissedNotificationIds: number[],
	_allNotifications: INotification[],
): Promise<IGenericAPIDataResponse> {
	const data: IGenericAPIDataResponse = await api.post<IGenericAPIDataResponse>("/Notifications/Dismiss", {
		data: dismissedNotificationIds,
	});
	return data;
}

//#endregion

//#region inactivity timeouts

/**
 * Validate user session (keep it active)
 */
export async function validateSession(): Promise<void> {
	await api.post("/Account/ValidateSession");
}

/**
 * Get how long a user's session has been idle
 */
export async function getIdleTime(): Promise<IIdleTimeResult> {
	const data = await api.post<IIdleTimeResult>("/Account/GetIdleTime");
	return data;
}

//#endregion

/**
 * Agree to overall site privacy policy
 */
export async function agreeToPrivacyPolicy(): Promise<IGenericAPIDataResponse> {
	const data: IGenericAPIDataResponse = await api.post<IGenericAPIDataResponse>(
		"/Account/AgreeToPrivacyPolicy",
	);
	return data;
}

//#region error logging

/**
 * Log an event
 * @param success success or failure
 * @param errorMessage message to log
 * @param status status
 * @param errorSubMessage submessage to log
 * @param ajaxUrl AJAX URL the error happened on
 * @returns
 */
export async function logEvent(
	success: boolean,
	errorMessage: string,
	status?: string,
	errorSubMessage?: string,
	ajaxUrl?: string,
): Promise<IGenericAPIResponseWrongCasing> {
	const eventInfo: IClientError = {
		Success: success,
		Status: status || (success ? "Success" : "Error"),
		ErrorMsg: errorMessage,
		ErrorSubMsg: errorSubMessage || "",
		AjaxUrl: ajaxUrl || "",
	};
	const data: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		"/Error/LogReactEvent",
		eventInfo,
	);
	return data;
}

/**
 * Log an error from an error boundary component
 * @param error error instance
 * @param info additional information about the error
 */
export async function logFromErrorBoundary(
	error: Error,
	info: {
		componentStack: string;
	},
): Promise<void> {
	try {
		const message = ` ${error.name} - ${error.message} at ${window.location.href} ${info.componentStack}`;
		await logEvent(false, message);
	} catch {}
}

/**
 * Log an error from an error boundary component
 * @param error error instance
 * @param info additional information about the error
 */
export async function logFromPlaceholder(error: Error): Promise<void> {
	try {
		const message = ` ${error.name} - ${error.message} at ${window.location.href} ${error.stack}`;
		await logEvent(false, message);
	} catch {}
}

//#endregion

export function getEmptyUserSecurity(): IUserSecurity {
	const emptyUserSecurity: IUserSecurity = {
		userId: null,
		displayName: "",
		email: "",
		userAccountType: UserAccountType.Undefined,
		userOrganizationId: null,
		userDBOrganizationId: null,
		organizationName: "",
		epicCommunityMemberId: null,
		programLevel: ProgramLevel.Undefined,
		organizationStatus: OrganizationStatus.Undefined,

		canPurchase: false,
		canManageUsers: false,
		canSubmit: false,
		canActivateClients: false,
		canCreateRemoteApps: false,
		canManageDocumentation: false,
		canManageStargate: false,
		canManageSigil: false,
		canManageQuestionnaire: false,
		canManageConnectors: false,
		canViewApiConnectors: false,
		canManageConnectorsInterface: false,
		canManageConnectorsAppsOrgs: false,
		canManageOpenEpicApiPricing: false,
		canManageAgreements: false,
		canToggleOpenEpicApiPricingParams: false,
		canViewAllRelatedOrgApps: false,
		canManageApiCatalogs: false,
		canEditLiveApps: false,

		isEpicCustomer: false,
		isSuperAdministrator: false,
		isEpicAdministrator: false,
		isEpicUser: false,
		isVendorUser: false,
		isVendorAdministrator: false,
		isInCustomerMode: false,

		ActiveAppListingCount: 0,
		FreeAppListingThreshold: 0,
		canCreateNewAppVersions: false,
		userPhotoSrc: "",
		menus: [],
		showWhatsNew: false,
		hasWhatsNew: false,
		organizationAuditStatus: null,
		agreedToPrivacyPolicy: null,

		organizationCountry: "",
		organizationGovernanceMessage: "",
		organizationGovernanceUrl: "",
		hasAccessToClarityApis: false,
	};

	return emptyUserSecurity;
}
