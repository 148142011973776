/**
 * @copyright Copyright 2020-2022 Epic Systems Corporation
 * @file Generates React Bootstrap checkboxes given an array of option objects
 * @returns
 */

import { Checkbox } from "@chakra-ui/checkbox";
import { Box, Wrap, Text } from "@chakra-ui/react";
import React, { memo, useCallback } from "react";
import { ICheckboxCascadeNode } from "../../data";

interface IProps {
	checkboxOptions: ICheckboxCascadeNode[];
	onChangeCallback: (e: ICheckboxCascadeNode[]) => void;
	disabled?: boolean;
}

interface ICheckboxNodeProps {
	checkboxOption: ICheckboxCascadeNode;
	handleOnChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
	disabled?: boolean;
}

function ToggleNode(node: ICheckboxCascadeNode, Id: number): boolean {
	if (node.Id === Id) {
		node.Selected = !node.Selected;
		return true;
	} else if (node.Children.length > 0) {
		for (let k = 0; k < node.Children.length; k++) {
			if (ToggleNode(node.Children[k], Id)) {
				return true;
			}
		}
	}
	return false;
}

const CheckboxNode: React.FunctionComponent<ICheckboxNodeProps> = (
	props: ICheckboxNodeProps,
): JSX.Element => {
	const { checkboxOption, handleOnChange, disabled } = props;

	const checkBoxHtml = (
		<Checkbox
			spacing="0.2rem"
			paddingLeft="4px"
			key={checkboxOption.Id}
			id={String(checkboxOption.Id)}
			isChecked={checkboxOption.Selected}
			onChange={handleOnChange}
			isDisabled={disabled}
			isFocusable
		>
			{checkboxOption.Name}
		</Checkbox>
	);

	return (
		<>
			{checkboxOption.Children.length > 0 && checkboxOption.Selected ? (
				<>
					<Box w="100%">{checkBoxHtml}</Box>
					<Box
						marginLeft="25px !important"
						marginTop="0px !important"
						marginBottom="0px !important"
						w="100%"
					>
						<Text
							paddingTop="0px !important"
							paddingBottom="5px !important"
							fontSize={"0.9em"}
							fontWeight={"500"}
							color={"#3f7d67"}
						>
							Sub-Categories of {checkboxOption.Name}
						</Text>
						<Box marginLeft="5px !important" borderLeft="solid 1px #ddd" paddingLeft="4px">
							<Wrap>
								{checkboxOption.Children.map((input: ICheckboxCascadeNode) => (
									<CheckboxNode
										checkboxOption={input}
										handleOnChange={handleOnChange}
										disabled={disabled}
									/>
								))}
							</Wrap>
						</Box>
					</Box>
				</>
			) : (
				checkBoxHtml
			)}
		</>
	);
};

export const CheckboxCascade: React.FunctionComponent<IProps> = memo(
	(props: IProps): JSX.Element => {
		const { checkboxOptions, onChangeCallback, disabled } = props;

		const handleOnChange = useCallback(
			(e: React.ChangeEvent<HTMLInputElement>): void => {
				const data = [...checkboxOptions];
				const id = e.target.id;
				data.some((e) => ToggleNode(e, parseInt(id)));
				onChangeCallback(data);
			},
			[onChangeCallback, checkboxOptions],
		);

		return (
			<Wrap spacing="8px">
				{checkboxOptions.map((input: ICheckboxCascadeNode) => (
					<CheckboxNode
						checkboxOption={input}
						handleOnChange={handleOnChange}
						disabled={disabled}
					/>
				))}
			</Wrap>
		);
	},
);
