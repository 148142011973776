/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Epic version warning for a content type
 * @module Epic.AppOrchard.Stargate.StargateVersionWarning
 */

import { WarningTwoIcon } from "@chakra-ui/icons";
import { IEpicVersion } from "ao/types";
import React, { FC, memo } from "react";
import { AOTooltip } from "../Core";

interface IProps {
	prodEpicVersion: IEpicVersion | null;
	contentMinEpicVersion: IEpicVersion;
	/** if true a more compact view will be used */
	compact?: boolean;
}

/** Content Type Version warning */
export const StargateContentVersionWarning: FC<IProps> = memo((props: IProps) => {
	const { prodEpicVersion, contentMinEpicVersion, compact } = props;

	const versionWarningBase = `This content type requires an Epic version of ${contentMinEpicVersion.name} or higher`;
	let versionWarning: string = "";

	if (prodEpicVersion != null && prodEpicVersion.epicCode < contentMinEpicVersion.epicCode) {
		versionWarning =
			versionWarningBase +
			` and your Epic Production environment is on the Epic ${prodEpicVersion.name} version.`;
	} else if (prodEpicVersion === null) {
		versionWarning =
			versionWarningBase +
			` and we don't know what Epic version your Epic Production environment is on.`;
	}

	return versionWarning ? (
		<AOTooltip helptext={versionWarning}>
			<WarningTwoIcon
				w={compact ? 4 : 5}
				h={compact ? 4 : 5}
				m={compact ? "0.25em" : "0 0 0 0.25em"}
				color="yellow.500"
			/>
		</AOTooltip>
	) : null;
});
