/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file What's New banner component
 * @module Epic.AppOrchard.Frame.WhatsNewBanner
 */

import { Icon, keyframes } from "@chakra-ui/react";
import { useDispatch } from "@epic/react-redux-booster";
import { AOLink } from "ao/components/Core";
import { hideWhatsNew } from "ao/store-methods/userSecurity";
import React, { FC, memo, useCallback } from "react";

export const WhatsNewBanner: FC = memo(() => {
	const spin = keyframes` from {transform: rotate(0deg);} to {transform: rotate(360deg)}`;
	const dispatch = useDispatch();
	const handleClicked = useCallback(() => hideWhatsNew(dispatch), [dispatch]);

	return (
		<AOLink
			url={`ProgramDetails/ExtendedContent?useType=WhatIsNew`}
			target="_blank"
			top={0}
			width="100%"
			h="1.5em"
			textAlign="center"
			color="white"
			fontSize="1.1em"
			backgroundColor="#36715b"
			display="block"
			textDecoration="none"
			_hover={{ color: "white", textDecoration: "none" }}
			onClick={handleClicked}
		>
			<Icon
				viewBox="0 0 160 160"
				fill="white"
				animation={`${spin} infinite 1.25s linear`}
				mb="4px"
				mr="3px"
			>
				<g>
					<path d="M70 9.5h20v47H70zm0 92.5h20v48.3H70zm-6.3-41.2L54.4 77 15 54.2 24.3 38zm81.3 45l-9.3 16.2-40.2-23.3 9.2-16.2zM54.6 83.1l9.5 16.2L23.9 122l-9.5-16.2zM135.7 38l9.3 16.2-39.6 22.9-9.2-16.2z" />
				</g>
			</Icon>
			View What's New
		</AOLink>
	);
});
