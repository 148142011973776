/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Link to a page, either using React Router (no browser reload) if the route is registered or a normal link if not
 * @module Epic.AppOrchard.Core.AOLink
 */

import { Link, LinkProps } from "@chakra-ui/react";
import routes from "ao/routes";
import { getBaseUrl } from "ao/utils/helpers";
import React, { forwardRef, memo, ReactNode, Ref } from "react";
import { Link as RouteLink, useLocation } from "react-router-dom";

interface IProps {
	url: string;
	children: ReactNode;
	omitDevUrlPrefix?: boolean;
}

type Props = IProps & LinkProps;

/** Link to a page, either using React Router (no browser reload) if the is route registered or a normal link if not */
export const AOLink = memo(
	forwardRef((props: Props, ref: Ref<HTMLAnchorElement>) => {
		const { url, children, omitDevUrlPrefix, ...rest } = props;

		const location = useLocation();

		let urlForRoute = url;
		const hasSlash = urlForRoute.indexOf("/") === 0;
		if (!hasSlash) {
			urlForRoute = "/" + urlForRoute;
		}

		urlForRoute = (omitDevUrlPrefix ? "" : process.env.REACT_APP_DEV_URL_PREFIX) + urlForRoute;

		// remove query params
		const urlForCompare = urlForRoute.split("?")[0].toLocaleLowerCase();

		// check if route is registered
		const useRouter = routes.some((r) => r.path.toLocaleLowerCase() === urlForCompare);

		let urlToUse = url;
		if (!useRouter) {
			const absoluteUrlPattern = /((https?:\/\/)|(www\.)|(emc2:\/\/)).*/i;
			if (!absoluteUrlPattern.test(url)) {
				// add base URL if not an absolute URL
				urlToUse = getBaseUrl() + (hasSlash ? url.substring(1) : url);
			}
		}

		return useRouter ? (
			<Link as={RouteLink} to={urlForRoute} state={{ from: location.pathname }} ref={ref} {...rest}>
				{children}
			</Link>
		) : (
			<Link href={urlToUse} ref={ref} {...rest}>
				{children}
			</Link>
		);
	}),
);
