/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file Error list for error oject. Uses react form hook error object
 * @module Epic.AppOrchard.Core.ErrorList
 */

import { Text, TextProps } from "@chakra-ui/react";
import { ErrorText } from "ao/components/Core/ErrorText";
import React, { FC } from "react";
import { FieldErrors } from "react-hook-form/dist/types/errors";

interface IErrorListProps {
	errorObject: FieldErrors;
}

type IProps = TextProps & IErrorListProps;

/*Error list for error oject. Uses react form hook error object */
export const ErrorList: FC<IErrorListProps> = (props: IProps) => {
	const { errorObject } = props;

	return (
		<>
			{Object.entries(errorObject).map(([idx, errors]) => (
				<ErrorText key={idx}>
					{Array.isArray(errors)
						? (errors as any)
								.filter(
									(error: any, index: number, array: any[]) =>
										!!error &&
										array
											.map((err2) => (err2 ? err2.message : "")) // filter out duplicates
											.indexOf(error.message) === index,
								)
								.map((arrayError: any, index: number) => (
									<Text key={index}>{arrayError.message}</Text>
								))
						: (errors as any).message}
				</ErrorText>
			))}
		</>
	);
};
