/**
 * @copyright Copyright 2024-2024 Epic Systems Corporation
 * @file Component for editing and adding resource links.
 */

import { CloseIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	ButtonProps,
	Flex,
	Input,
	InputGroup,
	InputRightElement,
	Select,
	VStack,
} from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { testResourceLink } from "ao/components/CreateEditApp/data";
import { IAppResourceLink } from "ao/data";
import { IResourceLinkTypeDict } from "ao/types/buildApps";
import { getBaseUrl } from "ao/utils/helpers";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { memo, useCallback } from "react";
import { FieldError } from "react-hook-form";
import { ErrorMessage } from "..";

interface IResourceLinkRow {
	onChangeCallback: (link: IAppResourceLink, remove: boolean, index: number) => void;
	types: IResourceLinkTypeDict;
	readonly?: boolean;
	value: IAppResourceLink;
	index: number;
	error?: FieldError;
}

const ResourceLinkRow: React.FunctionComponent<IResourceLinkRow> = (props: IResourceLinkRow): JSX.Element => {
	const { onChangeCallback, types, readonly, value, index, error } = props;

	return (
		<>
			<Flex>
				<Box display={{ sm: "flex" }} flex="1">
					<Select
						onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
							onChangeCallback({ ...value, Type: Number(e.target.value) }, false, index)
						}
						maxWidth={{ sm: "unset", md: "250px" }}
						value={value.Type}
						borderRightRadius={0}
						borderBottomLeftRadius={{ base: "0px", sm: "4px" }}
						borderTopRightRadius={readonly ? { base: "4px", sm: "0px" } : "0px"}
						isDisabled={readonly}
					>
						{Object.keys(types).map((key) => (
							<option value={key}>{types[Number(key)]}</option>
						))}
					</Select>
					<InputGroup size="md">
						<Input
							value={value.Link}
							readOnly={readonly}
							onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
								onChangeCallback({ ...value, Link: e.target.value }, false, index)
							}
							isInvalid={!!error?.message}
							aria-label={"Edit Link"}
							maxlength={500}
							pr="5rem"
							backgroundColor="white"
							borderTopLeftRadius={0}
							borderBottomLeftRadius={{ base: "4px", sm: "0px" }}
							borderTopRightRadius={readonly ? { base: "0px", sm: "4px" } : "0px"}
							borderBottomRightRadius={readonly ? "4px" : "0px"}
						/>
						{value.Link.trim() && !readonly && (
							<InputRightElement width="4.5rem" pr="5px">
								<TestLinkButton url={value.Link} h="1.75rem" size="sm" variant="outline">
									Test Link
								</TestLinkButton>
							</InputRightElement>
						)}
					</InputGroup>
				</Box>

				<Box alignItems="center" display="flex" bg="rgb(239,239,239)" borderRightRadius="4px">
					{!readonly && (
						<Button
							colorScheme="gray"
							variant="ghost"
							display="inline"
							onClick={() => onChangeCallback(value, true, index)}
							tabIndex={0}
							aria-label={"Remove Link"}
							focus
						>
							<CloseIcon fontSize="0.75rem" />
						</Button>
					)}
				</Box>
			</Flex>
			<ErrorMessage message={error?.message} isNotForm />
		</>
	);
};

interface IProps {
	types: IResourceLinkTypeDict;
	rows: IAppResourceLink[];
	readonly?: boolean;
	onChangeCallback: (value: IAppResourceLink[]) => void;
	errors: FieldError[];
}

export const ResourceLinks: React.FunctionComponent<IProps> = memo(
	(props: IProps): JSX.Element => {
		const { types, rows, readonly, onChangeCallback, errors } = props;

		const handleNewRow = (): void => {
			onChangeCallback([...rows, { Type: Number(Object.keys(types)[0]), Link: "" }]);
		};

		const updateOrRemoveUrl = useCallback(
			(resource: IAppResourceLink, remove: boolean, index: number) => {
				let updatedRows: IAppResourceLink[];

				if (remove) {
					updatedRows = rows.filter((_item, itemIndex) => itemIndex !== index);
				} else {
					// update
					updatedRows = rows.map((item, itemIndex) => (itemIndex === index ? resource : item));
				}
				onChangeCallback(updatedRows);
			},

			[onChangeCallback, rows],
		);

		return (
			<>
				{readonly && rows.length === 0 && <Input placeholder="None" readOnly={true} />}
				<VStack spacing={1} align="stretch">
					{rows.map((resource: IAppResourceLink, index: number) => {
						return (
							<ResourceLinkRow
								index={index}
								key={index}
								types={types}
								readonly={readonly}
								value={resource}
								error={errors?.[index]}
								onChangeCallback={updateOrRemoveUrl}
							/>
						);
					})}
					{!readonly && (
						<Button
							bg="none"
							color="royalblue"
							border="dotted"
							borderColor="gray"
							borderWidth={"1px"}
							fontWeight="400px"
							onClick={handleNewRow}
							aria-label={"Add Resource Link"}
							title={"Add Resource Link"}
						>
							Add Resource Link
						</Button>
					)}
				</VStack>
			</>
		);
	},
);

interface ITestLinkProps {
	url: string;
}

type Props = ITestLinkProps & ButtonProps;

const TestLinkButton: React.FunctionComponent<Props> = memo(
	(props: Props): JSX.Element => {
		const { url } = props;

		const handleSuccess = useCallback((response: string): void => {
			let url = decodeURIComponent(response);

			const hasSlash = url.indexOf("/") === 0;
			const absoluteUrlPattern = /^https?:\/\//i;

			if (!absoluteUrlPattern.test(url)) {
				// add base URL if not an absolute URL
				url = getBaseUrl() + (hasSlash ? url.substring(1) : url);
			}
			window.open(url, "_blank");
		}, []);

		const [loadingState, testLink] = useAsync(testResourceLink, {
			displayName: "findApp",
			onSuccess: handleSuccess,
			onFailure: () => {},
		});

		const isSearching = isInFlight(loadingState);

		return (
			<Button isLoading={isSearching} onClick={() => encodeURIComponent(testLink(url))} {...props}>
				Test Link
			</Button>
		);
	},
);
