/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file Helper functions for showroom page
 * @module Epic.AppOrchard.Showroom.Helper
 */

import { ConsumerTypes } from "ao/data";
import {
	GalleryHeaderStyle,
	GalleryListingSearchType,
	GalleryListingStyle,
	IAppTeaserModel,
	IAppTeaserModelWithStageAndParentStage,
	ICategory,
	IGalleryListingSearchData,
	IGalleryListingSearchResult,
	IGallerySearchResultGroup,
	IGallerySearchResults,
	IGalleryStageModel,
	IGalleryStageWithChildren,
} from "ao/types/showroom";
import {
	dangerouslyGetTextFromHtml,
	getCaseInsensitiveParam,
	getHtmlEncodedString,
	isNullOrEmpty,
} from "ao/utils/helpers";
import { getDescendantCategoryNodes } from "./frameworkComponents/GalleryListingComponents/AppHelperComponents/CategorySelect";

/**
 * Given a stageId, returns the full lineage
 * @param stageId
 * @param stageTree
 * @returns
 */
export function getStagePathFromList(
	stageId: number,
	stageTree: IGalleryStageWithChildren[],
): IGalleryStageWithChildren[] {
	const stagePath: IGalleryStageWithChildren[] = [];

	let node = null;

	while (stageId > 0) {
		node = getStageFromList(stageId, stageTree);
		if (node !== null) {
			stagePath.push(node);
			stageId = node.parentId ?? 0;
		} else {
			stageId = 0;
		}
	}

	return stagePath;
}

export function parseStagePath(stagePath: IGalleryStageWithChildren[]) {
	const stage = stagePath[stagePath.length - 1];
	const parentStage = stagePath[stagePath.length - 2];
	const topLevelParent = stagePath[0];

	return [stage, parentStage, topLevelParent];
}

/**
 * Returns a stageId's top level parent
 * @param stageId
 * @param stageTree
 * @returns
 */
export function getTopLevelStage(
	stageId: number,
	stageTree: IGalleryStageWithChildren[],
): IGalleryStageWithChildren | null {
	const stagePath = getStagePathFromList(stageId, stageTree).reverse();

	return stagePath.length > 0 ? stagePath[0] : null;
}

/**
 * Gets the bottom-most non-flattened stage for the path for a given stage
 * @param stageId
 * @param stageTree
 */
export const getBottommostNonFlattenedStage = (
	stageId: number,
	stageTree: IGalleryStageWithChildren[],
): IGalleryStageWithChildren | null => {
	const stagePath = getStagePathFromList(stageId, stageTree).reverse();

	if (stagePath.length < 1) {
		return null;
	}

	return stagePath.find((x) => x.flattenTier) ?? stagePath[stagePath.length - 1];
};

/**
 * Returns a stage node from the stageTree list
 * @param stageId
 * @param stageTree
 * @returns
 */
export function getStageFromList(
	stageId: number,
	stageTree: IGalleryStageWithChildren[],
): IGalleryStageWithChildren | null {
	return stageTree.map((x) => getNodeFromTree(stageId, x)).find((x) => x !== null) ?? null;
}

export function getNodeFromTree(
	stageId: number,
	stageTree: IGalleryStageWithChildren,
): IGalleryStageWithChildren | null {
	if (stageTree.id === stageId) {
		return stageTree;
	} else if (stageTree.childStages && stageTree.childStages.length > 0) {
		return (
			stageTree.childStages.map((child) => getNodeFromTree(stageId, child)).find((x) => x !== null) ??
			null
		);
	}
	return null;
}

export function getListingStyleForTree(stageTree: IGalleryStageWithChildren): GalleryListingStyle {
	if (stageTree.listingStyle !== GalleryListingStyle.Undefined) {
		return stageTree.listingStyle;
	} else if (stageTree.childStages && stageTree.childStages.length > 0) {
		return (
			stageTree.childStages
				.map((child) => getListingStyleForTree(child))
				.find((x) => x !== GalleryListingStyle.Undefined) ?? GalleryListingStyle.Undefined
		);
	}
	return GalleryListingStyle.Undefined;
}

/**
 * Gets all teasers in all nodes in a Tree
 * @param stageTree
 * @returns
 */
export function getAllTeasersInTree(
	stageTree: IGalleryStageWithChildren,
	parentStage?: IGalleryStageWithChildren,
): IAppTeaserModelWithStageAndParentStage[] {
	if (stageTree.childStages.length === 0) {
		return getTeasersWithExtra(stageTree, parentStage);
	} else {
		let teasersList: IAppTeaserModelWithStageAndParentStage[] = [];
		stageTree.childStages.forEach(
			(childeNode) => (teasersList = [...teasersList, ...getAllTeasersInTree(childeNode, stageTree)]),
		);

		return teasersList;
	}
}

/**
 * Appends stage info to teasers
 * @param stage
 * @returns
 */
function getTeasersWithExtra(
	stage: IGalleryStageWithChildren,
	parentStage?: IGalleryStageWithChildren,
): IAppTeaserModelWithStageAndParentStage[] {
	if (stage.teasers.length > 0) {
		return stage.teasers.map((teaser) => {
			return {
				...teaser,
				stage: { ...stage, teasers: [] },
				parentStage: parentStage ? { ...parentStage, teasers: [] } : undefined,
			};
		});
	}

	return [];
}

/** returns only category nodes used by the input stage's teasers */
export function getCategoryTreeForStage(
	stage: IGalleryStageWithChildren,
	categories: ICategory[],
): ICategory[] {
	const teasers = getAllTeasersInTree(stage);

	let categoryIds: number[] = [];
	teasers.forEach((teaser) => (categoryIds = [...categoryIds, ...teaser.categoryIds]));
	categoryIds = Array.from(new Set(categoryIds));

	return categories
		.map((category) => trimCategoryTree(category, categoryIds))
		.filter((x) => x !== null) as ICategory[];
}

function trimCategoryTree(category: ICategory, ids: number[]): ICategory | null {
	if (!ids.includes(category.id)) {
		return null;
	} else if (!category.children || category.children.length === 0) {
		return { ...category };
	} else {
		const childrenToInclude: ICategory[] = [];

		category.children.forEach((child) => {
			const node = trimCategoryTree(child, ids);

			if (node !== null) {
				childrenToInclude.push(node);
			}
		});
		return { ...category, children: childrenToInclude };
	}
}

/**
 * Returns a node from the category list
 * @param id
 * @param categories
 * @returns
 */
export function getCategory(id: number, categories: ICategory[]): ICategory | null {
	return categories.map((x) => getNodeFromCategoryTree(id, x)).find((x) => x !== null) ?? null;
}

export function getNodeFromCategoryTree(id: number, categories: ICategory): ICategory | null {
	if (categories.id === id) {
		return categories;
	} else if (categories.children && categories.children.length > 0) {
		return (
			categories.children.map((child) => getNodeFromCategoryTree(id, child)).find((x) => x !== null) ??
			null
		);
	}
	return null;
}

export function areListingsInStageInteractive(stage: IGalleryStageModel) {
	return isListingStyleInteractive(stage.listingStyle);
}

export function isListingStyleInteractive(style: GalleryListingStyle) {
	return style !== GalleryListingStyle.Partner;
}

export function canListingsInStageBeAccessed(stage: IGalleryStageModel) {
	return isListingPageAccessible(stage.listingStyle);
}

export function isListingPageAccessible(style: GalleryListingStyle) {
	return style !== GalleryListingStyle.Partner && style !== GalleryListingStyle.ExternalLink;
}

const allowedSearchCharacters = /[^\w 0-9'-.:]+/g;
export function CleanSearchQuery(query: string): string {
	if (!query || !query.trim()) return "";

	const cleanedQuery = query.replace(allowedSearchCharacters, "");

	if (!cleanedQuery || !cleanedQuery.trim()) return "";

	return cleanedQuery.trim();
}

export function SearchStages(
	query: string,
	appId: number,
	stages: IGalleryStageWithChildren[],
	categories: ICategory[],
): IGallerySearchResults {
	let resultGroups: Map<string, IGallerySearchResultGroup> = new Map<string, IGallerySearchResultGroup>();
	if (!query || !query.trim()) {
		return { resultGroups: [], resultCount: 0 };
	}

	const matchingCategories = MatchingCategories(query, categories);

	stages.forEach((stage) =>
		SearchNodeInTree(
			query.toLocaleLowerCase(),
			appId,
			stage.groupName,
			stage.disclaimer,
			stage,
			undefined,
			undefined,
			"",
			matchingCategories,
			resultGroups,
		),
	);

	let orderedGroups: IGallerySearchResultGroup[] = [];

	const mapIterator = resultGroups.values();
	let group: IGallerySearchResultGroup = mapIterator.next().value;
	let listingCount: number = 0;
	while (group) {
		orderedGroups[group.order] = group;
		listingCount += group.listings.length;
		group = mapIterator.next().value;
	}

	return { resultGroups: orderedGroups, resultCount: listingCount };
}

function SearchNodeInTree(
	query: string,
	appId: number,
	topLevelStageName: string,
	topLevelDisclaimer: string,
	currentStage: IGalleryStageWithChildren,
	directParentStage: IGalleryStageWithChildren | undefined,
	intermediateStage: IGalleryStageWithChildren | undefined,
	matchingStageName: string,
	matchingCategories: Map<number, string>,
	resultGroups: Map<string, IGallerySearchResultGroup>,
): void {
	if (currentStage.childStages && currentStage.childStages.length > 0) {
		if (intermediateStage && intermediateStage.groupName.length > 0) {
			return; //should only get here if there are too many stage layers
		}
		let groupNameMatches: boolean = currentStage.groupName.toLocaleLowerCase().indexOf(query) >= 0;

		currentStage.childStages.forEach((childStage) =>
			SearchNodeInTree(
				query,
				appId,
				topLevelStageName,
				topLevelDisclaimer,
				childStage,
				currentStage,
				directParentStage,
				matchingStageName || groupNameMatches ? currentStage.groupName : "",
				matchingCategories,
				resultGroups,
			),
		);
	}
	if (!currentStage.teasers || currentStage.teasers.length === 0) {
		return;
	}

	for (let teaserIndex = 0; teaserIndex < currentStage.teasers.length; teaserIndex++) {
		const teaser = currentStage.teasers[teaserIndex];

		let matches = GetMatchingFields(
			query,
			appId,
			teaser,
			matchingStageName,
			currentStage.groupName,
			matchingCategories,
		);

		if (matches.length > 0 && directParentStage) {
			const isStyleInteractive = areListingsInStageInteractive(currentStage);
			const linkToListing = isStyleInteractive && canListingsInStageBeAccessed(currentStage);
			const linkToExternal = isStyleInteractive && !linkToListing && !isNullOrEmpty(teaser.website);
			const isResultClickable = linkToListing || linkToExternal;

			AddToSearchResults(
				teaser,
				matches,
				resultGroups,
				topLevelStageName,
				topLevelDisclaimer,
				currentStage,
				directParentStage,
				intermediateStage,
				isResultClickable,
				linkToExternal ? teaser.website : undefined,
			);
		}
	}
}

export function MatchingCategories(query: string, categories: ICategory[]): Map<number, string> {
	const categoryMap = new Map<number, string>();

	const safeQuery = getHtmlEncodedString(query).toLocaleLowerCase().trim();

	if (categories && categories.length > 0) {
		categories.forEach((topCategory) => {
			getDescendantCategoryNodes(topCategory).forEach((subCategory) => {
				if (subCategory.name.toLocaleLowerCase().indexOf(safeQuery) !== -1) {
					categoryMap.set(subCategory.id, subCategory.name);
				}
			});
		});
	}

	return categoryMap;
}

export function GetMatchingFields(
	query: string,
	appId: number,
	teaser: IAppTeaserModel,
	matchingAncestorStagename: string,
	stageName: string,
	matchingCategories?: Map<number, string>,
): IGalleryListingSearchData[] {
	let matches: IGalleryListingSearchData[] = [];

	query = query.trim().toLocaleLowerCase();

	if (appId && teaser.appId === appId) {
		matches.push({ boldedText: "<strong>" + query + "</strong>", matchStrength: 1000000 });
	}

	if (teaser.prodClientId) {
		const prodClientIdMatch = SearchListingProperty(
			teaser.prodClientId,
			query,
			GalleryListingSearchType.FindExact,
			100000,
		);
		if (prodClientIdMatch) {
			matches.push(prodClientIdMatch);
		}

		const nonProdClientIdMatch = SearchListingProperty(
			teaser.nonProdClientId,
			query,
			GalleryListingSearchType.FindExact,
			100000,
		);
		if (nonProdClientIdMatch) {
			matches.push(nonProdClientIdMatch);
		}
	}

	const ancestorNameMatch = SearchListingProperty(
		matchingAncestorStagename,
		query,
		GalleryListingSearchType.FindAny,
		100000,
	);
	if (ancestorNameMatch) {
		matches.push(ancestorNameMatch);
	}

	const stageNameMatch = SearchListingProperty(stageName, query, GalleryListingSearchType.FindAny, 10000);
	if (stageNameMatch) {
		matches.push(stageNameMatch);
	}

	const primaryCategoryMatch = SearchListingProperty(
		teaser.primaryCategoryName,
		query,
		GalleryListingSearchType.FindAnyPrioritizeExactAndStart,
		1000,
	);
	if (primaryCategoryMatch) {
		matches.push(primaryCategoryMatch);
	}

	const nameResult = SearchListingProperty(
		teaser.name,
		query,
		GalleryListingSearchType.FindAnyPrioritizeExactAndStart,
		1000,
	);
	if (nameResult) {
		matches.push(nameResult);
	}

	const orgNameResult = SearchListingProperty(
		teaser.orgName,
		query,
		GalleryListingSearchType.FindAnyPrioritizeExactAndStart,
		100,
	);
	if (orgNameResult) {
		matches.push(orgNameResult);
	}

	if (teaser.appId) {
		const summaryResult = SearchListingProperty(
			teaser.summary,
			query,
			GalleryListingSearchType.FindAll,
			10,
		);
		if (summaryResult) {
			matches.push(summaryResult);
		}
	} else {
		const summaryResult = SearchListingProperty(
			dangerouslyGetTextFromHtml(teaser.summary),
			query,
			GalleryListingSearchType.FindAll,
			10,
		);
		if (summaryResult) {
			matches.push(summaryResult);
		}
	}

	if (!primaryCategoryMatch && matchingCategories) {
		teaser.categoryIds.forEach((id) => {
			let matchingName = matchingCategories.get(id);
			if (!matchingName) return;

			const categoryResult = SearchListingProperty(
				matchingName,
				query,
				GalleryListingSearchType.FindAnyPrioritizeExactAndStart,
				100,
			);

			if (categoryResult) {
				matches.push(categoryResult);
			}
		});
	}

	return matches;
}

function AddToSearchResults(
	teaser: IAppTeaserModel,
	matches: IGalleryListingSearchData[],
	resultGroups: Map<string, IGallerySearchResultGroup>,
	topLevelStageName: string,
	topLevelDisclaimer: string,
	currentStage: IGalleryStageWithChildren,
	directParentStage: IGalleryStageWithChildren,
	intermediateStage: IGalleryStageWithChildren | undefined,
	isClickable: boolean,
	externalLink?: string,
): void {
	let result: IGalleryListingSearchResult = {
		teaser: teaser,
		currentStageName: currentStage.groupName,
		subStageName: intermediateStage ? intermediateStage.groupName : directParentStage.groupName,
		subStageColor:
			(intermediateStage ? intermediateStage.textColor : directParentStage.textColor) || "#666",
		subStageGalleryIcon: currentStage.showGalleryIcon
			? currentStage.iconPath
			: directParentStage.showGalleryIcon
			? directParentStage.iconPath
			: intermediateStage?.showGalleryIcon
			? intermediateStage.iconPath
			: undefined,
		matchingText: !matches || matches.length === 0 ? [] : matches.map((match) => match.boldedText),
		matchStrength:
			!matches || matches.length === 0
				? 1
				: matches
						.map((match) => match.matchStrength)
						.reduce((accumulator, matchStrength) => accumulator + matchStrength),
		isClickable,
		externalLink,
	};

	const groupKey: string = topLevelStageName;

	if (!resultGroups.has(groupKey)) {
		resultGroups.set(groupKey, {
			topLevelStageName,
			topLevelDisclaimer,
			order: resultGroups.size,
			listings: [],
		});
	}
	resultGroups.get(groupKey)!.listings.push(result);
}

function SearchListingProperty(
	listingProperty: string,
	query: string,
	searchType: GalleryListingSearchType,
	matchValue: number,
): IGalleryListingSearchData | null {
	if (!listingProperty || !listingProperty.trim()) {
		return null;
	}
	const safeString = getHtmlEncodedString(listingProperty);
	const safeQuery = getHtmlEncodedString(query);

	if (searchType === GalleryListingSearchType.FindExact) {
		if (safeString.toLocaleLowerCase().trim() === safeQuery.trim()) {
			return { boldedText: "<strong>" + safeQuery + "</strong>", matchStrength: matchValue };
		} else {
			return null;
		}
	}

	const queryLength = safeQuery.length;
	const withQueryRemoved = safeString.toLocaleLowerCase().split(safeQuery) ?? [];
	const numberOfMatches = withQueryRemoved.length - 1;
	if (numberOfMatches === 0) {
		return null;
	}

	let matchStrength = 0;
	switch (searchType) {
		case GalleryListingSearchType.FindAny:
			matchStrength = matchValue;
			break;
		case GalleryListingSearchType.FindAnyPrioritizeExactAndStart:
			let multiplier = 1;
			if (withQueryRemoved[0] === "") {
				//matches the start
				if (withQueryRemoved.length === 2 && withQueryRemoved[1] === "") {
					//matches the whole thing
					multiplier = 5;
				} else {
					multiplier = 2;
				}
			}
			matchStrength = matchValue * multiplier;
			break;
		case GalleryListingSearchType.FindAll:
			matchStrength = matchValue * numberOfMatches;
			break;
	}

	let stringStartIndex = 0;
	let textToReturn: string = "";
	for (let fragmentIndex = 0; fragmentIndex < withQueryRemoved.length; fragmentIndex++) {
		const currentFragment = withQueryRemoved[fragmentIndex];
		const fragmentLength = currentFragment.length;
		const isEmptyFragment = fragmentLength === 0;
		const isFirstFragment = fragmentIndex === 0;
		const isLastFragment = fragmentIndex === withQueryRemoved.length - 1;
		const isMiddleFragement = !isFirstFragment && !isLastFragment;

		if (isFirstFragment) {
			if (isEmptyFragment) {
				textToReturn += "<strong>" + safeString.substr(0, queryLength) + "</strong>";
				stringStartIndex += queryLength;
			} else {
				textToReturn += GetLeadingText(safeString.substr(0, fragmentLength));
				stringStartIndex += fragmentLength;
				textToReturn += "<strong>" + safeString.substr(stringStartIndex, queryLength) + "</strong>";
				stringStartIndex += queryLength;
			}
		} else if (isMiddleFragement) {
			if (isEmptyFragment) {
				textToReturn += "<strong>" + safeString.substr(stringStartIndex, queryLength) + "</strong>";
				stringStartIndex += queryLength;
			} else {
				textToReturn += GetTextBetweenMatches(safeString.substr(stringStartIndex, fragmentLength));
				stringStartIndex += fragmentLength;
				textToReturn += "<strong>" + safeString.substr(stringStartIndex, queryLength) + "</strong>";
				stringStartIndex += queryLength;
			}
		} else if (isLastFragment) {
			if (isEmptyFragment) {
				textToReturn += "<strong>" + safeString.substr(stringStartIndex) + "</strong>";
			} else {
				textToReturn += GetTrailingText(safeString.substr(stringStartIndex));
			}
		}
	}

	return {
		boldedText: textToReturn,
		matchStrength,
	};
}

const TrimmedNonMatchingTextLength: number = 30;
const MaxNonMatchingTextLength: number = 33; //a single ... is 3 characters, so we add 3 to our check to make sure we don't make it /longer/ when we trim

/**
 * Gets the last @see TrimmedNonMatchingTextLength characters from the last line. Prepends ellipses if any text is trimmed.
 * @param multilineText
 * @returns
 */
function GetLeadingText(multilineText: string): string {
	const splitLines = multilineText.split(/((\r\n)|(\r)|(\n))+/);
	if (splitLines.length === 0) {
		return "";
	}

	if (splitLines.length === 1) {
		if (multilineText.length <= MaxNonMatchingTextLength) {
			return multilineText;
		} else {
			return "..." + multilineText.substr(multilineText.length - TrimmedNonMatchingTextLength);
		}
	}

	const lineToInclude = splitLines[splitLines.length - 1];

	if (lineToInclude.length <= MaxNonMatchingTextLength) {
		return "..." + lineToInclude;
	} else {
		return "..." + lineToInclude.substr(lineToInclude.length - TrimmedNonMatchingTextLength);
	}
}

/**
 * Gets the first and last @see TrimmedNonMatchingTextLength characters from the first and last line (half from each). Adds ellipses in the middle if any text is trimmed.
 * @param multilineText
 * @returns
 */
function GetTextBetweenMatches(multilineText: string): string {
	let splitLines = multilineText.split(/((\r\n)|(\r)|(\n))+/);
	if (splitLines.length === 0) {
		return "";
	}

	if (splitLines.length === 1) {
		if (multilineText.length <= MaxNonMatchingTextLength) {
			return multilineText;
		} else {
			return (
				multilineText.substr(0, Math.floor(TrimmedNonMatchingTextLength / 2)) +
				"..." +
				multilineText.substr(multilineText.length - Math.ceil(TrimmedNonMatchingTextLength / 2))
			);
		}
	}

	const leadingText = splitLines[0];
	const endingText = splitLines[splitLines.length - 1];
	if (leadingText.length + endingText.length <= MaxNonMatchingTextLength) {
		return splitLines[0].substr(0) + "... " + splitLines[splitLines.length - 1];
	} else {
		return (
			leadingText.substr(0, Math.floor(TrimmedNonMatchingTextLength / 2)) +
			"..." +
			endingText.substr(-1 * Math.ceil(TrimmedNonMatchingTextLength / 2))
		);
	}
}

/**
 * Gets the first @see TrimmedNonMatchingTextLength characters from the first line. Appends ellipses if any text is trimmed.
 * @param multilineText
 * @returns
 */
function GetTrailingText(multilineText: string): string {
	let splitLines = multilineText.split(/((\r\n)|(\r)|(\n))+/);
	if (splitLines.length === 0) {
		return "";
	}

	if (splitLines.length === 1) {
		if (multilineText.length <= MaxNonMatchingTextLength) {
			return multilineText;
		} else {
			return multilineText.substr(0, TrimmedNonMatchingTextLength) + "...";
		}
	}

	const lineToInclude = splitLines[0];

	if (lineToInclude.length <= MaxNonMatchingTextLength) {
		return lineToInclude + "...";
	} else {
		return lineToInclude.substr(0, TrimmedNonMatchingTextLength) + "...";
	}
}

/** Parses int from search params and validates */
export function getIntFromSearchParam(searchParams: URLSearchParams, param: string, defaultValue?: number) {
	defaultValue = defaultValue ?? 0;

	const paramValue = getCaseInsensitiveParam(searchParams, param) ?? defaultValue;
	const parsedValue = parseInt(paramValue);

	return isNaN(parsedValue) || parsedValue > 2147483647 ? defaultValue : parsedValue;
}

export function getIntArrayFromSearchParam(searchParams: URLSearchParams, param: string) {
	const paramValue = getCaseInsensitiveParam(searchParams, param) ?? 0;

	return paramValue ? paramValue.split(",").map((v: string) => parseInt(v)) : [];
}

export const GridGap: any = {
	base: "6px",
	md: "20px",
	lg: "36px",
};

export const ContentWidth: any = {
	base: "100%",
	md: "750px",
	lg: "970px",
	xl: "1170px",
	"3xl": "1170px",
};

export function isAutoDownloaded(downloadedBy: string) {
	return !isNullOrEmpty(downloadedBy) && downloadedBy === "__auto__";
}

export function canFilterSubTiers(stage: IGalleryStageWithChildren) {
	return (
		stage.headerStyle === GalleryHeaderStyle.TitleButtons &&
		stage.flattenTier &&
		stage.childStages.length > 1
	);
}
/**
 * If no filteredStageId is provided, the the first child tier with includeLiveAppsWhenFiltering set will be selected by default on load.
 * This was a special requirement for Connection Hub. The plan is that only Connection Hub will have this flag set
 * @param stage
 * @param filteredStageId
 * @returns SubTier to filter on
 */
export function getFilteredSubTier(
	stage: IGalleryStageWithChildren,
	filteredStageId: number,
): IGalleryStageWithChildren {
	const defaultFilterStage =
		stage.childStages.find((x) => x.includeLiveAppsWhenFiltering) ?? stage.childStages[0];
	let cleanSubTierFilterId = defaultFilterStage.id;

	if (filteredStageId > 0 && stage.childStages.map((x) => x.id).includes(filteredStageId)) {
		cleanSubTierFilterId = filteredStageId;
	}

	return getNodeFromTree(cleanSubTierFilterId, stage) ?? stage;
}

export function getConsumerTypeName(consumerType: ConsumerTypes): string {
	switch (consumerType) {
		case ConsumerTypes.Patient:
			return "Patients";
		case ConsumerTypes.Employees:
			return "Clinicians, Staff, or Administrative Users";
		case ConsumerTypes.Backend:
			return "Backend Systems";
		case ConsumerTypes.PatientContact:
			return "Patient Contact";
		default:
			return "";
	}
}

export function isIdValid(id: number | undefined): boolean {
	return id !== undefined && !isNaN(id) && id > 0;
}
