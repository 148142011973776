/**
 * @copyright Copyright 2021-2022 Epic Systems Corporation
 * @file Alert/banner to show a status message (e.g. error)
 * @module Epic.AppOrchard.Core.StatusAlert
 */

import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertProps,
	AlertStatus,
	AlertTitle,
	ButtonProps,
	CloseButton,
	Flex,
	Spacer,
	useDisclosure,
} from "@chakra-ui/react";
import React, { FC, memo } from "react";

interface IProps {
	title?: string;
	message: string | React.ReactElement;
	status: AlertStatus;
	closeable?: boolean;
	closeButtonProps?: ButtonProps;
}

type Props = IProps & AlertProps;

/** Alert/banner to show a status message (e.g. error) */
export const StatusAlert: FC<Props> = memo((props: Props) => {
	const { title, message, status, closeable, closeButtonProps, ...rest } = props;
	const { isOpen: isVisible, onClose } = useDisclosure({ defaultIsOpen: true });

	return (
		<>
			{isVisible && (
				<Alert w="100%" m="auto" status={status} {...rest}>
					<Flex w="100%" alignItems="center">
						<AlertIcon />
						{title && <AlertTitle>{title}</AlertTitle>}
						<AlertDescription>{message}</AlertDescription>
						{closeable && (
							<>
								<Spacer />
								<CloseButton
									alignSelf="flex-start"
									position="relative"
									right={-1}
									top={-1}
									onClick={onClose}
									aria-label="Close this alert"
									title="Close this alert"
									{...closeButtonProps}
								/>
							</>
						)}
					</Flex>
				</Alert>
			)}
		</>
	);
});
