/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Drop down filter control component
 * @module Epic.AppOrchard.Core.Filter.FilterControl
 */

import { Box, Button, Center, Checkbox, Flex, Icon, IconButton, Input, Spacer, Text } from "@chakra-ui/react";
import { IFilterDefinition, IFilterListItem, SelectOptionType } from "ao/types";
import React, { FC, memo, useState } from "react";
import { FaAngleDoubleDown, FaAngleDoubleUp } from "react-icons/fa";
import { RiFilterFill, RiFilterOffLine } from "react-icons/ri";
import Select, { MultiValue, SingleValue } from "react-select";

export interface IFilterItemProps {
	filterDef: IFilterDefinition;
	filterListItem: IFilterListItem;
	handleUpdateFilter: (filter: IFilterListItem, setColsWithFilter?: boolean, filterColumnMappings?: Record<string, string>) => void;
	handleClearFilter: (filterKey: string) => void;
	setColsWithFilter?: boolean;
	filterToColumnMapping?: Record<string,string>
}

export const FilterControl: FC<IFilterItemProps> = memo((props: IFilterItemProps) => {
	const { filterDef, filterListItem, handleUpdateFilter, handleClearFilter, setColsWithFilter, filterToColumnMapping } = props;
	const filterMaxSelection = 10;
	const shouldAllowClear = filterDef.allowClear !== undefined ? filterDef.allowClear : true;

	//#region state/hooks/handlers
	const [isFilterExpanded, setIsFilterExpanded] = useState<boolean>(filterDef.expandedByDefault ?? false);

	const handleChangeExclude = () => {
		handleUpdateFilter({ ...filterListItem, isExcluded: !filterListItem.isExcluded });
	};

	const handleChangeValue = (option: SingleValue<SelectOptionType>) => {
		if (option) handleUpdateFilter({ ...filterListItem, value: option.value }, setColsWithFilter, filterToColumnMapping);
	};

	const handleChangeValues = (options: MultiValue<SelectOptionType>) => {
		if (options) handleUpdateFilter({ ...filterListItem, values: options.map((o) => parseInt(o.value)) }, setColsWithFilter, filterToColumnMapping);
	};

	const handleFilterClear = () => {
		handleClearFilter(filterListItem.key);
	};

	const filterHasValue =
		!!filterListItem.value || (!!filterListItem.values && !!filterListItem.values.length);

	const filterIsExcluded = filterHasValue && filterListItem.isExcluded;

	const filterCanClear = filterHasValue || filterListItem.isExcluded;

	let lookupOptions = filterDef.options ? filterDef.options : [];
	lookupOptions = Array.isArray(lookupOptions) ? lookupOptions : [];

	const filterOptions: readonly SelectOptionType[] = lookupOptions.map<SelectOptionType>((record) => {
		const label = filterDef.includeIdInOption ? `${record.name} [${record.id}]` : record.name;
		return { label, value: record.id.toString() };
	});

	//#endregion
	return (
		<Box key={filterDef.key} w="100%" borderWidth="1px" borderRadius="3px">
			<Box
				as="button"
				w="100%"
				padding={2}
				onClick={() => setIsFilterExpanded(!isFilterExpanded)}
				_hover={{ bg: "gray.100" }}
			>
				<Flex align={"center"}>
					<Flex title={filterDef.tooltip}>
						{filterHasValue ? (
							<Text fontWeight={"bold"}>{filterDef.name}</Text>
						) : (
							<Text>{filterDef.name}</Text>
						)}
					</Flex>
					<Spacer />
					{filterIsExcluded ? (
						<Center>
							<Icon
								verticalAlign=""
								aria-label="Filter Excluded"
								title="Filter Excluded"
								as={RiFilterOffLine}
								color={"black"}
							></Icon>
						</Center>
					) : (
						filterHasValue && (
							<Center>
								<Icon
									verticalAlign=""
									aria-label="Filter Applied"
									title="Filter Applied"
									as={RiFilterFill}
								></Icon>
							</Center>
						)
					)}
					<IconButton
						size="xs"
						aria-label="Expand/Collapse Filter"
						title="Expand/Collapse Filter"
						icon={isFilterExpanded ? <FaAngleDoubleUp /> : <FaAngleDoubleDown />}
						onClick={() => setIsFilterExpanded(!isFilterExpanded)}
						variant="ghost"
						tabIndex={-1}
					></IconButton>
				</Flex>
			</Box>
			{isFilterExpanded && (
				<Box m={2}>
					<Box mb="3px">
						{(filterDef.type === undefined || filterDef.type === "select") && (
							<>
								{filterDef.isMultiResponse ? (
									<Select
										isMulti
										value={lookupOptions
											.filter((o) => filterListItem.values?.includes(o.id))
											.map<SelectOptionType>(
												(r): SelectOptionType => {
													return { label: r.name, value: r.id.toString() };
												},
											)}
										options={filterOptions}
										onChange={handleChangeValues}
										isClearable={false}
										isOptionDisabled={() => {
											return (
												lookupOptions.filter((o) =>
													filterListItem.values?.includes(o.id),
												).length > filterMaxSelection
											);
										}}
									></Select>
								) : (
									<Select
										value={lookupOptions
											.filter((o) => filterListItem.value === o.id.toString())
											.map<SelectOptionType>(
												(r): SelectOptionType => {
													return { label: r.name, value: r.id.toString() };
												},
											)}
										options={filterOptions}
										onChange={handleChangeValue}
										isClearable={false}
									></Select>
								)}
							</>
						)}
						{filterDef.type === "input" && (
							<>
								{filterDef.inputType === "date" || filterDef.inputType === "number" ? (
									<Input
										type={filterDef.inputType}
										value={filterListItem.value || ""}
										min={filterDef.minValue || ""}
										max={filterDef.maxValue || ""}
										onChange={(ev) =>
											handleUpdateFilter({ ...filterListItem, value: ev.target.value })
										}
									/>
								) : (
									<Input
										type={filterDef.inputType || "text"}
										value={filterListItem.value || ""}
										onChange={(ev) =>
											handleUpdateFilter({ ...filterListItem, value: ev.target.value })
										}
									/>
								)}
							</>
						)}
					</Box>
					<Flex>
						{shouldAllowClear && (
							<>
								<Button
									size="xs"
									variant="outline"
									onClick={() => handleFilterClear()}
									isDisabled={!filterCanClear}
								>
									Clear
								</Button>
								<Spacer></Spacer>
							</>
						)}
						{filterDef.allowExclude && (
							<Checkbox
								size="sm"
								isChecked={filterListItem.isExcluded}
								isDisabled={!filterHasValue}
								onChange={() => {
									handleChangeExclude();
								}}
							>
								Exclude
							</Checkbox>
						)}
					</Flex>
				</Box>
			)}
		</Box>
	);
});
