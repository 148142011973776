/**
 * @copyright Copyright 2021-2022 Epic Systems Corporation
 * @file Tabbed section (toggling the tab shows different contents)
 * @module Epic.AppOrchard.Core.TabbedSection
 */

import { Box, Circle, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, TabsProps } from "@chakra-ui/react";
import React, { FC, memo, useCallback, useState } from "react";

export interface ITab {
	tabName: React.ReactNode;
	/** number to show in front of the tab name,  e.g. count of records in tab */
	countPrefix?: number;
	/** defines how to style the prefix number, other statuses can be added as needed*/
	prefixStatus?: "warning";
	helptext: string;
	/** Actual content show when this tab is selected */
	content: React.ReactNode;
}

interface IProps {
	/** A tab will be created for each entry in the `tabs` array */
	tabs: ITab[];
	/** whether to show the border for unselected tabs */
	showUnselectedBorder?: boolean;
}

/**
 * Show a border to the right if current tab is not selected, next tab is not selected, and current tab is not last tab
 * @param index current tab index
 * @param selectedIndex selected tab index
 * @param numTabs total number of tabs
 * @param showUnselectedBorder whether to show border for unselected tabs
 * @returns
 */
function showRightBorder(
	index: number,
	selectedIndex: number,
	numTabs: number,
	showUnselectedBorder?: boolean,
) {
	if (index === selectedIndex) {
		return false;
	} else if (index === selectedIndex - 1) {
		return false;
	} else if (!showUnselectedBorder && index === numTabs - 1) {
		return false;
	}

	return true;
}

/**
 * Show a border to the left if current tab is not selected, prev tab is not selected, and current tab is not first tab
 * @param index current tab index
 * @param selectedIndex selected tab index
 * @param showUnselectedBorder whether to show border for unselected tabs
 * @returns
 */
function showLeftBorder(index: number, selectedIndex: number, showUnselectedBorder?: boolean) {
	if (index === selectedIndex) {
		return false;
	} else if (index === selectedIndex + 1) {
		return false;
	} else if (!showUnselectedBorder && index === 0) {
		return false;
	}

	return true;
}

type Props = IProps & Omit<TabsProps, "children">;

/** Tabbed section (toggling the tab shows different contents) */
export const TabbedSection: FC<Props> = memo((props: Props) => {
	const { tabs, showUnselectedBorder, ...rest } = props;
	const [selectedIndex, setSelectedIndex] = useState<number>(0);
	const handleTabChange = useCallback((index: number) => setSelectedIndex(index), []);
	const fontSizes = { base: "1em", lg: "1.4em" };

	return (
		<Tabs isFitted isLazy lazyBehavior="keepMounted" onChange={handleTabChange} {...rest}>
			<TabList>
				{tabs.map((tab, index) => (
					<Tab
						key={index}
						p="0.8em"
						title={tab.helptext}
						borderTopLeftRadius="5px"
						borderTopRightRadius="5px"
						backgroundColor="white"
						borderBottom="2px solid lightgrey"
						borderColor="lightgrey"
						borderTop={showUnselectedBorder ? "1px solid lightgrey" : "unset"}
						borderRight={
							showRightBorder(index, selectedIndex, tabs.length, showUnselectedBorder)
								? "1px solid lightgrey"
								: "unset"
						}
						borderLeft={
							showLeftBorder(index, selectedIndex, showUnselectedBorder)
								? "1px solid lightgrey"
								: "unset"
						}
						_selected={{
							backgroundColor: "aoGreen.background",
							borderBottomColor: "aoGreen.foreground",
							borderBottomStyle: "solid",
							borderBottomWidth: "5px",
							color: "aoGreen.foreground",
							borderTop: "unset",
						}}
						_hover={{
							backgroundColor: "aoGreen.hover",
						}}
					>
						{tab.countPrefix !== undefined &&
							(tab.prefixStatus ? (
								/* This would need to be modified to use different colors based 
								on status if more statuses are added later */
								<Circle
									fontSize={fontSizes}
									mr="0.25em"
									color="white"
									background="orange.500"
									size={{ base: 6, lg: 10 }}
								>
									{tab.countPrefix}
								</Circle>
							) : (
								<Box fontSize={fontSizes} mr="5px">
									{tab.countPrefix}
								</Box>
							))}
						<Heading as="h3" fontSize={fontSizes} fontWeight="normal">
							{tab.tabName}
						</Heading>
					</Tab>
				))}
			</TabList>
			<TabPanels>
				{tabs.map((tab, index) => (
					<TabPanel key={index} tabIndex={-1} p="7px 7px 0 7px">
						{tab.content}
					</TabPanel>
				))}
			</TabPanels>
		</Tabs>
	);
});
