/**
 * @copyright Copyright 2021-2022 Epic Systems Corporation
 * @file user security store updater methods
 * @module Epic.AppOrchard.StoreMethods.UserSecurity
 */

import { IDispatch } from "@epic/react-redux-booster";
import { userSecurityActions } from "../state/userSecurity";
import { IUserSecurity } from "../types";

/**
 * Update user security in the store
 * @param dispatch for useDispatch
 * @param userSecurity user security for the user
 */
export function updateUserSecurity(dispatch: IDispatch, userSecurity: IUserSecurity): void {
	dispatch(userSecurityActions.updateUserSecurity(userSecurity));
}

/**
 * Hide what's new after the user views it
 * @param dispatch for useDispatch
 */
export function hideWhatsNew(dispatch: IDispatch): void {
	dispatch(userSecurityActions.hideWhatsNew());
}

/**
 * Set customer mode
 * @param dispatch for useDispatch
 * @param isInCustomerMode the new customer mode value for the user
 */
export function setCustomerMode(dispatch: IDispatch, isInCustomerMode: boolean): void {
	dispatch(userSecurityActions.setCustomerMode(isInCustomerMode));
}
