/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Icon for Sherlock Release Authorization
 * @module Epic.AppOrchard.Core.SherlockReleaseAuthorizationIcon
 */

import { Box, BoxProps } from "@chakra-ui/react";
import { ReactComponent as ReleaseAuth } from "images/External/releaseauth.svg";
import React, { memo } from "react";

type Props = BoxProps;
/** Icon for Sherlock Release Authorization */
export const SherlockReleaseAuthorizationIcon = memo((props: Props) => {
	const { ...rest } = props;
	return (
		<Box minW={4} maxW={4} title="Sherlock Release Authorization" {...rest}>
			<ReleaseAuth />
		</Box>
	);
});
