/* eslint-disable @typescript-eslint/no-unused-vars */
/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Organization (customer, vendor) selection
 * @module Epic.AppOrchard.Core.OrganizationSelection
 */
import { TriangleDownIcon } from "@chakra-ui/icons";
import { Box, Button, Checkbox, useBreakpointValue } from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { useDispatch } from "@epic/react-redux-booster";
import { IOrganizationWithAssignment, IOrgSelectionProps, setOrgAsDefault } from "ao/data";
import { useUserOrganizationsUIState } from "ao/state/userOrganizationsUI";
import {
	updateDefaultOrganizationById,
	updateSelectedOrganization,
	updateShowingDropdown,
} from "ao/store-methods/userOrganizations";
import { ISaveDefaultOrgResponse } from "ao/types";
import { searchHasMatch } from "ao/utils/helpers";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { FC, memo, useCallback, useRef, useState } from "react";
import { batch } from "react-redux";
import {
	DropdownFavoriteButton,
	DropdownHeader,
	DropdownList,
	DropdownMenu,
	DropdownMenuItem,
	DropdownTrigger,
	FavoriteButton,
	OrganizationLogo,
	SearchInput,
	StatusModal,
} from ".";

/** Organization (customer, vendor) selection */
export const OrganizationSelection: FC<IOrgSelectionProps> = memo((props: IOrgSelectionProps) => {
	const {
		selectedOrg,
		defaultOrg,
		availableOrgs,
		buttonHelptext,
		toggleEnabled,
		logoSize,
		allowChangeFilterByAssigned,
		triggerButtonSize,
		setAsDefaultButtonSize,
		orgsPerPageInDropdown,
	} = props;

	//#region state and handlers
	const defaultSelected = selectedOrg.id === defaultOrg.id;
	const dispatch = useDispatch();
	const buttonGroupAttached = useBreakpointValue({ base: false, lg: true });
	const orgsPerPage = orgsPerPageInDropdown || 6;
	const searchInputRef = useRef<HTMLInputElement>(null);

	const [searchTerm, setSearchTerm] = useState<string>("");
	const [filterToAssigned, setFilterToAssigned] = useState<boolean>(
		// check filter to assigned to me by default if user has any assignments
		!!allowChangeFilterByAssigned && availableOrgs.some((org) => org.isUserAssigned),
	);

	const { showingOrgsDropdown: showDropdown } = useUserOrganizationsUIState(
		(selectors) => selectors.getState(),
		[],
	);

	const handleOnOrgChange = useCallback(
		(org: IOrganizationWithAssignment) => () => {
			batch(() => {
				updateSelectedOrganization(dispatch, org);
				updateShowingDropdown(dispatch, false);
			});
		},
		[dispatch],
	);

	const handleChangeSearchTerm = useCallback((newSearchTerm: string) => setSearchTerm(newSearchTerm), []);
	const handleChangeFilterToAssigned = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => setFilterToAssigned(e.target.checked),
		[],
	);

	const handleOnToggle = useCallback((): void => {
		updateShowingDropdown(dispatch, !showDropdown);
	}, [dispatch, showDropdown]);

	// set up a few things related to saving the default org
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const handleCloseErrorModal = useCallback(() => setShowErrorModal(false), []);

	const [savingOrgState, saveDefaultOrg] = useAsync(setOrgAsDefault, {
		executeImmediately: false,
		displayName: "saveDefaultOrg",
		onSuccess: (response: ISaveDefaultOrgResponse) =>
			updateDefaultOrganizationById(dispatch, response.orgId),
		onFailure: () => setShowErrorModal(true),
	});

	const isSaving = isInFlight(savingOrgState);

	const handleSaveDefaultOrg = useCallback(() => {
		saveDefaultOrg(selectedOrg.id);
	}, [saveDefaultOrg, selectedOrg.id]);

	//#endregion

	//#region subcomponents
	const orgRows = availableOrgs
		.filter((org) => {
			return (
				(!searchTerm || searchHasMatch(searchTerm, org.name)) &&
				(!allowChangeFilterByAssigned || !filterToAssigned || org.isUserAssigned)
			);
		})
		.map((org) => {
			return (
				<DropdownMenuItem key={org.id} onClick={handleOnOrgChange(org)} h="43px" textAlign="left">
					<OrganizationLogo org={org} logoSize={logoSize}></OrganizationLogo>
					<Box
						as="span"
						verticalAlign="middle"
						display="inline-block"
						whiteSpace="normal"
						w="90%"
						title={org.name}
					>
						{org.name}
					</Box>
				</DropdownMenuItem>
			);
		});

	//#endregion

	return (
		<>
			<DropdownMenu
				isOpen={showDropdown}
				onOpen={handleOnToggle}
				onClose={handleOnToggle}
				menuWidth={{ base: "75vw", lg: "23vw" }}
				favoriteButtonAttached={buttonGroupAttached}
				noMatchingChildrenMessage="No matching organizations"
				initialFocusRef={searchInputRef}
				itemsPerPage={orgsPerPage}
				buttonGroupProps={{ display: "inline-block", w: { base: "100%", lg: "unset" } }}
			>
				<>
					<DropdownTrigger>
						<Button
							color="#333"
							background="white"
							border="1px solid #A0AEC0"
							disabled={!toggleEnabled}
							title={buttonHelptext}
							aria-label={buttonHelptext}
							overflow="hidden"
							size={triggerButtonSize}
							rightIcon={<TriangleDownIcon w="0.75em" h="0.75em" />}
						>
							<OrganizationLogo org={selectedOrg} logoSize={logoSize}></OrganizationLogo>
							<Box
								as="span"
								id="selected-org-name"
								verticalAlign="middle"
								display="inline-block"
								overflow="hidden"
								maxW={{ base: "180px", md: "200px", lg: "250px", xl: "300px" }}
								textOverflow="ellipsis"
							>
								{selectedOrg.name}
							</Box>
						</Button>
					</DropdownTrigger>
					<DropdownFavoriteButton>
						<FavoriteButton
							onClick={handleSaveDefaultOrg}
							favoriteSelected={defaultSelected}
							isSaving={isSaving}
							size={setAsDefaultButtonSize}
							favoriteSelectedTitle={
								"This is the default organization you see in organization selection workflows"
							}
							favoriteNotSelectedTitle={
								"Set this as the default organization you see in organization selection workflows"
							}
						/>
					</DropdownFavoriteButton>
					<DropdownHeader>
						<SearchInput
							placeholder="Search for organizations"
							onChangeCallback={handleChangeSearchTerm}
							inputProps={{
								ref: searchInputRef,
							}}
							inputGroupProps={{ mt: "5px", w: "96%", ml: "2%" }}
						></SearchInput>
						{allowChangeFilterByAssigned && (
							<Checkbox
								type="checkbox"
								onChange={handleChangeFilterToAssigned}
								isChecked={filterToAssigned}
								mt="10px"
								ml="13px"
								verticalAlign="middle"
							>
								Orgs I work with
							</Checkbox>
						)}
					</DropdownHeader>
					<DropdownList>{orgRows}</DropdownList>
				</>
			</DropdownMenu>
			<StatusModal
				title="Error saving"
				message="Error setting this as your default organization. Please try again later or contact your Epic representative if this continues to happen."
				isOpen={showErrorModal}
				onClose={handleCloseErrorModal}
				status="error"
			></StatusModal>
		</>
	);
});

OrganizationSelection.defaultProps = { allowChangeFilterByAssigned: true };
