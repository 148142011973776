/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file state/store for the showroom homepage and menu
 * @module Epic.AppOrchard.State.showroom
 */

import { buildSharedState } from "@epic/react-redux-booster";
import { IShowroomHomepage } from "ao/components/Showroom/data/data";
import { ISelectionOption } from "ao/types";
import {
	ICategory,
	IGalleryStageWithChildren,
	ILinearNavigationModel,
	stageHasComingSoonCardStyle,
} from "ao/types/showroom";
import store from "../store";

/// TYPES ///

export interface IState {
	readonly categoryTree: ICategory[];
	readonly stageTree: IGalleryStageWithChildren[];
	readonly contactSubjects: ISelectionOption[];
	readonly linearTrack: ILinearNavigationModel[];
	readonly hasLoaded: boolean;
}

/// INIT ///

/**
 * Returns an empty Images initial state
 */
function getInitialState(): IState {
	return { stageTree: [], categoryTree: [], contactSubjects: [], linearTrack: [], hasLoaded: false };
}

/// REDUCERS ///

export function setState(_state: IState, homepage: IShowroomHomepage): IState {
	const createLinearModelForNav = (
		stageTree: IGalleryStageWithChildren[],
		parentName?: string,
	): ILinearNavigationModel[] => {
		let linearTrack: ILinearNavigationModel[] = [];
		stageTree.forEach((stage) => {
			if (stageHasComingSoonCardStyle(stage)) return;
			linearTrack.push({ id: stage.id, name: stage.groupName, parentName: parentName });
			if (!stage.flattenTier) {
				const childTracks = createLinearModelForNav(stage.childStages, stage.groupName);
				linearTrack.push(...childTracks);
			}
		});
		return linearTrack;
	};

	return {
		stageTree: homepage.stageTree ?? [],
		categoryTree: homepage.categories ?? [],
		contactSubjects: homepage.contactSubjects ?? [],
		linearTrack: createLinearModelForNav(homepage.stageTree) ?? [],
		hasLoaded: true,
	};
}

/// SELECTORS ///

export function getState(state: IState): IState {
	return state;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		setState,
	},
	selectors: {
		getState,
	},
});

store.addSharedState(builtState.sharedState, "showroom");

export const { actionCreators: showroomActions, useSharedState: useShowroomState } = builtState;
