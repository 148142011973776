/**
 * @copyright Copyright 2018-2023 Epic Systems Corporation
 * @file application data
 * @module Epic.AppOrchard.Data.Data
 */

import {
	IAppImage,
	IConnectionHubRequest,
	IGenericAPIResponseWrongCasing,
	IRequestQuestionnaireReview,
	IScreenshotSaveModel,
	IUpdateAllowSyncOverrideOrgsRequestModel,
	IUpdateCustomerAppLicenseRequest,
	IUpdateSubmittedRequest,
} from "ao/data";
import { IGenericResponseModel } from "ao/types";
import {
	CreateNewRequestModel,
	IAppModel,
	IEditAppsData,
	IUpdateCustomerAppLicenseResponse,
	IUpdateSandboxRequest,
	IUpdateStatusRequest,
} from "ao/types/buildApps";
import api, { fileApi } from "ao/utils/api";

/**
 * Get all required app data that isn't already in the store from the server
 * @param loadAppData whether app data is needed (false if already in store)
 * @param loadScreenshots whether screenshot data is needed (false if already loaded)
 * @param loadUserSecurity whether user security data is needed (false if already in store)
 * @param loadIntegrations whether integration data is needed (false if already in store)
 * @param appId Id of App
 * @returns promise for loading any combination of the 4 data types as needed
 */
export async function getAllAppsData(
	loadAppData: boolean,
	loadScreenshots: boolean,
	appId: number,
): Promise<IEditAppsData> {
	const promises: [Promise<IAppModel> | undefined, Promise<IAppImage[]> | undefined] = [
		loadAppData ? getApp(appId) : undefined,
		loadScreenshots ? getAppImages(appId) : undefined,
	];

	return Promise.all(promises);
}

/**
 * Get all required app data that isn't already in the store from the server
 * @param loadAppData whether app data is needed (false if already in store)
 * @param loadScreenshots whether screenshot data is needed (false if already loaded)
 * @param appId Id of App
 * @returns promise for loading any combination of the 4 data types as needed
 */
export async function getAllReviewAppsData(
	appId: number,
	clientId: string,
	editMode: boolean,
	loadScreenshots?: boolean,
): Promise<IEditAppsData> {
	const promises: [Promise<IAppModel> | undefined, Promise<IAppImage[]> | undefined] = [
		getAppForReview(appId, clientId, editMode),
		loadScreenshots ? getAppImages(appId) : undefined,
	];

	return Promise.all(promises);
}

/**
 * save all app data to server
 * @param appData app model object
 * @param saveType the type of save that will happen (regular save = 0, submit = ?)
 * @param imageSaveData object received by updateImages API
 * @returns promise for the 2 post APIs
 */
export async function saveAppData(
	appData: IAppModel,
	imageSaveData: IScreenshotSaveModel,
	isSubmit: boolean,
): Promise<IGenericAPIResponseWrongCasing[]> {
	let promises = [];
	if (isSubmit === true) {
		promises = [submitApp(appData), setScreenShotsData(imageSaveData)];
	} else {
		promises = [saveApp(appData), setScreenShotsData(imageSaveData)];
	}
	return Promise.all(promises);
}

export async function saveNewApp(appData: IAppModel): Promise<IGenericAPIResponseWrongCasing> {
	const response: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		"/api/Apps/SaveNewApp",
		appData,
	);
	return response;
}

export async function createNewVersion(data: CreateNewRequestModel): Promise<IGenericAPIResponseWrongCasing> {
	const response: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		"/api/Apps/CreateNewVersion",
		data,
	);
	return response;
}

export async function getApp(appId: number): Promise<IAppModel> {
	const data: IAppModel = await api.get<IAppModel>("/api/Apps/LoadApp", { id: appId.toString() });
	return data;
}

export async function getAppForReview(
	appId: number,
	clientId: string,
	editMode: boolean,
): Promise<IAppModel> {
	const result: IAppModel = await api.get<IAppModel>(
		`/api/Apps/LoadReviewApp?appId=${appId}&clientId=${clientId}&editMode=${editMode}`,
	);
	return result;
}

export async function getNewApp(): Promise<IAppModel> {
	const data: IAppModel = await api.get<IAppModel>("/api/Apps/LoadNewApp");
	return data;
}

export async function getAppImages(appId: number): Promise<IAppImage[]> {
	const data: IAppImage[] = await api.get<IAppImage[]>("/api/Apps/LoadImages", {
		appId: appId.toString(),
	});
	return data;
}

export async function deleteApp(appId: number): Promise<IGenericResponseModel> {
	const result: IGenericResponseModel = await api.post<IGenericResponseModel>("/api/Apps/Delete", appId);
	return result;
}

export async function updateSubmittedApp(
	data: IUpdateSubmittedRequest,
): Promise<IGenericAPIResponseWrongCasing> {
	const result: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		"/api/Apps/UpdateSubmitted",
		data,
	);
	return result;
}

export async function updateAppStatus(data: IUpdateStatusRequest): Promise<boolean> {
	const result: boolean = await api.post<boolean>("/api/Apps/UpdateStatus", data);
	return result;
}

export async function reviewerSaveAppData(
	appData: IAppModel,
	imageSaveData: IScreenshotSaveModel,
): Promise<IGenericAPIResponseWrongCasing[]> {
	let promises = [];
	promises = [reviewerSaveApp(appData), setScreenShotsData(imageSaveData)];

	return Promise.all(promises);
}

export async function reviewerSaveApp(data: IAppModel): Promise<IGenericAPIResponseWrongCasing> {
	const success: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		"/api/Apps/ReviewerSave",
		data,
	);
	return success;
}

export async function retractApp(appId: number): Promise<boolean> {
	const result: boolean = await api.post<boolean>("/api/Apps/RetractApp", appId);
	return result;
}

export async function activateTEFCAApp(appId: number): Promise<boolean> {
	const result: boolean = await api.post<boolean>("/api/Apps/ActivateTEFCAApp", appId);
	return result;
}

export async function saveApp(data: IAppModel): Promise<IGenericAPIResponseWrongCasing> {
	const success: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		"/api/Apps/SaveApp",
		data,
	);
	return success;
}

export async function submitApp(data: IAppModel): Promise<IGenericAPIResponseWrongCasing> {
	const success: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		"/api/Apps/SubmitApp",
		data,
	);
	return success;
}

export async function setScreenShotsData(
	data: IScreenshotSaveModel,
): Promise<IGenericAPIResponseWrongCasing> {
	const success: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		"/api/Apps/UpdateImages",
		data,
	);
	return success;
}

export async function deleteAppAgreement(appId: number): Promise<boolean> {
	const success: boolean = await api.post<boolean>("/api/Apps/DeleteAppAgreement", appId);
	return success;
}

export async function updateSandboxLicense(data: IUpdateSandboxRequest): Promise<IGenericResponseModel> {
	const success: IGenericResponseModel = await api.post<IGenericResponseModel>(
		"/api/Apps/UpdateSandboxLicense",
		data,
	);
	return success;
}

export async function uploadAppAgreement(formData: FormData): Promise<IGenericAPIResponseWrongCasing> {
	const success: IGenericAPIResponseWrongCasing = await fileApi.post<IGenericAPIResponseWrongCasing>(
		"/api/Apps/UploadAppAgreement",
		formData,
	);
	return success;
}

export async function updateCustomerAppLicenseStateBuildApps(
	data: IUpdateCustomerAppLicenseRequest,
): Promise<IUpdateCustomerAppLicenseResponse> {
	const result: IUpdateCustomerAppLicenseResponse = await api.post<IUpdateCustomerAppLicenseResponse>(
		"/api/Apps/UpdateCustomerAppLicenseState",
		data,
	);

	return result;
}

export async function UpdateQuestionnaire(appId: number): Promise<boolean> {
	const success: boolean = await api.post<boolean>("/api/Apps/UpdateQuestionnaire?AppId=" + appId);
	return success;
}

export async function checkJKUEndpoint(data: string): Promise<IGenericAPIResponseWrongCasing> {
	const result: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		"/api/Apps/CheckJKUEndpoint",
		data,
	);

	return result;
}

export async function testResourceLink(url: string): Promise<string> {
	const result: string = await api.post<string>("/api/Apps/TestResourceLink", url);

	return result;
}

export async function connectionHubRequest(
	data: IConnectionHubRequest,
): Promise<IGenericAPIResponseWrongCasing> {
	const result: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		"/api/Apps/ConnectionHubRequested",
		data,
	);

	return result;
}

export async function submitQuestionnaireForReview(
	data: IRequestQuestionnaireReview,
): Promise<IGenericAPIResponseWrongCasing> {
	const result: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		"/api/Apps/SubmitQuestionnaireForReview",
		data,
	);
	return result;
}

export async function clearDisableSyncToCustomers(appId: number): Promise<IGenericAPIResponseWrongCasing> {
	const success: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		`/api/Apps/ClearDisableSyncToCustomers?appId=${appId}`,
	);
	return success;
}

export async function updateAllowSyncOverrideOrgs(
	data: IUpdateAllowSyncOverrideOrgsRequestModel,
): Promise<IGenericAPIResponseWrongCasing> {
	const success: IGenericAPIResponseWrongCasing = await api.post<IGenericAPIResponseWrongCasing>(
		"/api/Apps/UpdateAllowSyncOverrideOrgs",
		data,
	);
	return success;
}
