export enum NavLogoKind {
	Epic = 1,
	EpicShare = 2,
	EpicResearch = 3,
	MyChart = 4,
	UserWeb = 5,
	OpenDotEpic = 6,
	Showroom = 7,
	ConnectionHub = 8,
	Cosmos = 9,
	GetConnected = 1000,
}

export interface ITopItem extends ITopItemCore {
	readonly subItems?: ITopSubItem[];
}
export interface ITopSubItem extends ITopItemCore {}
export interface ITopItemCore {
	readonly navLogoKind: NavLogoKind;
	readonly tooltip: string;
	readonly selected?: boolean;
}
export interface IBottomItem extends IBottomItemCore {
	readonly subItems?: IBottomSubItem[];
}
export interface IBottomSubItem extends IBottomItemCore {}

export interface IBottomItemCore {
	readonly label: string;
	readonly tooltip: string;
	readonly href: string;
}

export const messages = {
	layout: {
		defaultPageTitle: {
			value: "Epic | ...With the patient at the heart",
			comment: "Title for the website. Show as caption on the browser tab.",
		},
		defaultPageDescription: {
			value:
				"Founded in a basement in 1979, Epic develops software to help people get well, help people stay well, and help future generations be healthier.",
			comment: "Default description used as metadata in the page by default for all pages of epic.com.",
		},
		epicLogoTooltip: {
			value: "Visit Epic.com",
			comment: "Tooltip for a logo in the header of epic.com that links to epic.com",
		},
		epicshareLogoTooltip: {
			value: "Visit EpicShare",
			comment: "Tooltip for a logo in the header of epic.com that links to epicshare.org",
		},
		epicresearchLogoTooltip: {
			value: "Visit Epic Research",
			comment: "Tooltip for a logo in the header of epic.com that links to epicresearch.org",
		},
		cosmosLogoTooltip: {
			value: "Visit Cosmos",
			comment: "Tooltip for a logo in the header of epic.com that links to cosmos.epic.com",
		},
		mychartLogoTooltip: {
			value: "Visit MyChart",
			comment: "Tooltip for a logo in the header of epic.com that links to mychart.org",
		},
		getConnectedLogoTooltip: {
			value: "View ways to get connected",
			comment:
				"Tooltip for a logo in the header of epic.com that contains a few sites you can visit to get connected",
		},
		openDotEpicLogoTooltip: {
			value: "Visit open.epic",
			comment: "Tooltip for a logo in the header of epic.com that links to open.epic.com",
		},
		showroomLogoTooltip: {
			value: "Visit Showroom",
			comment: "Tooltip for a logo in the header of epic.com that links to showroom.epic.com",
		},
		connectionHubLogoTooltip: {
			value: "Visit connection hub",
			comment: "Tooltip for a logo in the header of epic.com that links to connection hub",
		},
		userwebLogoTooltip: {
			value: "Visit UserWeb",
			comment: "Tooltip for a logo in the header of epic.com that links to userweb.epic.org",
		},
		softwareLinkCaption: {
			value: "Software",
			comment: "Caption for an item in the header of epic.com that links to a software page",
		},
		softwareLinkTooltip: {
			value: "Visit the software page",
			comment: "Tooltip for an item in the header of epic.com that links to a software page",
		},
		aboutUsSubmenuItemCaption: {
			value: "About Us",
			comment:
				"Caption for an submenu item in the header of epic.com that shows a list of pages that tell about Epic",
		},
		aboutUsSubmenuItemTooltip: {
			value: "View about us related pages",
			comment:
				"Tooltip for an submenu item in the header of epic.com that shows a list of pages that tell about Epic",
		},
		ourStoryLinkCaption: {
			value: "Our Story",
			comment: "Caption for an item in the header of epic.com that links to an about page",
		},
		ourStoryLinkTooltip: {
			value: "Read our story",
			comment:
				"Tooltip for an item in the header of epic.com that links to an about page (to show our story)",
		},
		deiLinkCaption: {
			value: "Diversity, Equity & Inclusion",
			comment:
				"Caption for an item in the header of epic.com that links to a diversity, equity and inclusion page",
		},
		deiLinkTooltip: {
			value: "Learn about diversity, equity, and inclusion at Epic",
			comment:
				"Tooltip for an item in the header of epic.com that links to an a diversity, equity and inclusion page",
		},
		contactLinkCaption: {
			value: "Contact Us",
			comment: "Caption for an item in the header of epic.com that links to a contact page",
		},
		contactLinkTooltip: {
			value: "Call or communicate with us",
			comment: "Tooltip for an item in the header of epic.com that links to an a contact page",
		},
		visitingLinkCaption: {
			value: "Visiting",
			comment: "Caption for an item in the header of epic.com that links to a visiting page",
		},
		visitingLinkTooltip: {
			value: "Learn about our campus and area",
			comment: "Tooltip for an item in the header of epic.com that links to an a visiting page",
		},
		communityLinkCaption: {
			value: "Epic Health System Community",
			comment: "Caption for an item in the header of epic.com that links to a community page",
		},
		communityLinkTooltip: {
			value: "Visit our community page",
			comment: "Tooltip for an item in the header of epic.com that links to a community page",
		},
		newsroomLinkCaption: {
			value: "Newsroom",
			comment: "Caption for an item in the header of epic.com that links to a newsroom page",
		},
		newsroomLinkTooltip: {
			value: "Visit the newsroom",
			comment: "Tooltip for an item in the header of epic.com that links to a newsroom page",
		},
	},
};
