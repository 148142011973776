/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file Link to view saved credentials (JWT public key, client secret hashes, JKUs) for an app
 * @module Epic.AppOrchard.Core.AppCredentialsLink
 */

import { WarningTwoIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	GridItem,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	SimpleGrid,
	useDisclosure,
} from "@chakra-ui/react";
import { config } from "ao/appConfig";
import { getBaseUrl } from "ao/utils/helpers";
import React, { FC, memo } from "react";
import { FcKey } from "react-icons/fc";
import { AOLink, AOTooltip } from "..";

interface IProps {
	/** The customer's current Epic version */
	epicVersion: number;
	/** whether the app is a customer app created to use with their own environment */
	isOwnApp: boolean;
	nonProdJsonWebKeySetUrl: string | null;
	prodJsonWebKeySetUrl: string | null;
	testJwtSigningPublicKeyThumbprint: string | null;
	prodJwtSigningPublicKeyThumbprint: string | null;
	/** if true, the key is present but was imported from Client Garden and we don't have the thumbprint to display */
	testPublicKeyBlobIsCSP: boolean;
	/** if true, the key is present but was imported from Client Garden and we don't have the thumbprint to display */
	prodPublicKeyBlobIsCSP: boolean;
	testClientSecretHash: string | null;
	prodClientSecretHash: string | null;
}

/** Link to view saved credentials (JWT public key, client secret hashes, JKU) for an app  */
export const AppCredentialsLink: FC<IProps> = memo((props: IProps) => {
	const {
		epicVersion,
		isOwnApp,
		nonProdJsonWebKeySetUrl,
		prodJsonWebKeySetUrl,
		testClientSecretHash,
		testJwtSigningPublicKeyThumbprint,
		prodClientSecretHash,
		prodJwtSigningPublicKeyThumbprint,
		testPublicKeyBlobIsCSP,
		prodPublicKeyBlobIsCSP,
	} = props;

	const oauth2GuideLink = getBaseUrl() + "Article/Index?docId=oauth2";
	const { isOpen, onOpen, onClose } = useDisclosure();
	const cantUseJKU = isOwnApp && epicVersion < config.MinEpicJkuVersion;

	return (
		<>
			<Button
				variant="link"
				colorScheme="blue"
				onClick={onOpen}
				title="View credentials this app can use for OAuth 2.0 authentication (JWT public keys, client secrets, JWKS URLs)"
			>
				<FcKey style={{ display: "inline-block", marginRight: "4px" }} />
				App Credentials
			</Button>
			<Modal isOpen={isOpen} onClose={onClose} size="4xl">
				<ModalOverlay />
				<ModalContent>
					<ModalHeader>
						<FcKey style={{ display: "inline-block", marginRight: "4px" }} />
						App Credentials
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						{(testClientSecretHash || prodClientSecretHash) && (
							<CredRow
								label="Client Secret Hash (SHA-1)"
								testVal={testClientSecretHash}
								prodVal={prodClientSecretHash}
							/>
						)}
						{(nonProdJsonWebKeySetUrl || prodJsonWebKeySetUrl) && (
							<>
								<CredRow
									label="JWKS URL"
									warning={
										cantUseJKU && (
											<AOTooltip helptext="Your system is not on an Epic version yet that supports using JWKS URLs to retrieve public keys for JWT authentication for OAuth 2.0 workflows. Support for JWKS URLs in that workflow was added in the May 2021 Epic version.">
												<WarningTwoIcon w={4} h={4} ml="4px" color="yellow.500" />
											</AOTooltip>
										)
									}
									testVal={nonProdJsonWebKeySetUrl}
									prodVal={prodJsonWebKeySetUrl}
								/>
							</>
						)}
						{(testPublicKeyBlobIsCSP ||
							testJwtSigningPublicKeyThumbprint ||
							prodPublicKeyBlobIsCSP ||
							prodJwtSigningPublicKeyThumbprint) && (
							<CredRow
								label="JWT Signature Verification Public Key Thumbprint"
								testVal={
									testPublicKeyBlobIsCSP
										? "Imported public key"
										: testJwtSigningPublicKeyThumbprint
								}
								prodVal={
									prodPublicKeyBlobIsCSP
										? "Imported public key"
										: prodJwtSigningPublicKeyThumbprint
								}
							/>
						)}
						<AOLink url={oauth2GuideLink} target="_blank">
							View documentation on OAuth 2.0
						</AOLink>
					</ModalBody>
					<ModalFooter pt="0">
						<Button onClick={onClose}>Close</Button>
					</ModalFooter>
				</ModalContent>
			</Modal>
		</>
	);
});

interface ICredRowProps {
	label: string;
	testVal: React.ReactNode;
	prodVal: React.ReactNode;
	warning?: React.ReactNode;
}

const CredRow: FC<ICredRowProps> = memo((props: ICredRowProps) => {
	const { label, testVal, prodVal, warning } = props;
	return (
		<SimpleGrid mb="1em" columns={{ base: 1, lg: 12 }}>
			<GridItem colSpan={{ base: 1, lg: 6 }}>
				<Box fontWeight="bold">
					{"Test " + label} {warning}
				</Box>

				{testVal ? (
					<Box>{testVal}</Box>
				) : (
					<Box fontStyle="italic" color="grey">
						None
					</Box>
				)}
			</GridItem>
			<GridItem colSpan={{ base: 1, lg: 6 }}>
				<Box fontWeight="bold">
					{"Production " + label} {warning}
				</Box>

				{prodVal ? (
					<Box>{prodVal}</Box>
				) : (
					<Box fontStyle="italic" color="grey">
						None
					</Box>
				)}
			</GridItem>
		</SimpleGrid>
	);
});
