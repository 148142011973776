/**
 * @copyright Copyright 2021-2022 Epic Systems Corporation
 * @file Dropdown menu (similar to Chakra Menu component) that allows having filter controls, favorite button, and pagination
 * @module Epic.AppOrchard.Core.DropDownMenu
 */
import {
	Box,
	ButtonGroup,
	ButtonGroupProps,
	Divider,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverProps,
	PopoverTrigger,
} from "@chakra-ui/react";
import { createDescendantContext } from "ao/components/Core/descendant";
import { childrenWithAddedClickHandler, getSelectedDescendentForKey } from "ao/utils/reactHelpers";
import React, {
	Dispatch,
	FC,
	memo,
	ReactElement,
	ReactNode,
	SetStateAction,
	useCallback,
	useMemo,
	useRef,
	useState,
} from "react";
import { PaginatedList } from "..";

const [DescendantsProvider, , useDescendants, useDescendant] = createDescendantContext<HTMLButtonElement>();

// export this for DropdownMenuItem to use too
export { useDescendant };

interface IDropdownMenuContext {
	/** selected child index */
	selected: number;
	setSelected?: Dispatch<SetStateAction<number>>;
	setHeader?: Dispatch<SetStateAction<ReactNode>>;
	setFooter?: Dispatch<SetStateAction<ReactNode>>;
	setFavoriteButton?: Dispatch<SetStateAction<ReactNode>>;
	setDropdownItems?: Dispatch<SetStateAction<ReactNode>>;
	setDropdownTrigger?: Dispatch<SetStateAction<ReactElement | null>>;
}

// export this for other dropdown components to use
export const DropdownMenuContext = React.createContext<IDropdownMenuContext>({ selected: 1 });

export interface IDropdownMenuProps {
	/** whether the favorite button is attached to the trigger button in a button group*/
	favoriteButtonAttached?: boolean;
	/** message to show when no items match the current filters */
	noMatchingChildrenMessage?: string;
	/** width of the dropdown menu */
	menuWidth?: any;
	/** if passed dropdown items will be paginated */
	itemsPerPage?: number;
	/** props for the trigger + favorite button group */
	buttonGroupProps?: ButtonGroupProps;
	children: ReactNode;
	showNoMatchesMessage?: boolean;
	closeOnDropdownItemClick?: boolean;
	showHeaderDivider?: boolean;
}

type DropDownProps = IDropdownMenuProps & Omit<PopoverProps, "children">;

/** Dropdown menu that allows having filters. Behaves mostly like the Menu Chakra component but renders a Popover */
export const DropdownMenu: FC<DropDownProps> = memo((props: DropDownProps) => {
	const {
		favoriteButtonAttached,
		noMatchingChildrenMessage,
		menuWidth,
		itemsPerPage,
		buttonGroupProps,
		children,
		showNoMatchesMessage,
		closeOnDropdownItemClick,
		showHeaderDivider,
		...rest
	} = props;

	const shouldCloseOnDropdownItemClick = closeOnDropdownItemClick !== false;
	const shouldShowHeaderDivider = showHeaderDivider !== false;
	const descendants = useDescendants();
	const triggerRef = useRef<HTMLButtonElement>();

	// index of selected descendant
	const [selected, setSelected] = useState(1);

	/**
	 * The children are all returned via dedicated subcomponents (e.g. DropdownTrigger, DropdownHeader),
	 * so that they can be handled individually here without passing them all as props
	 */

	// header displayed at the top of the dropdown
	const [header, setHeader] = useState<ReactNode>(null);
	// button to mark current selected item as favorite
	const [favoriteButton, setFavoriteButton] = useState<ReactNode>(null);
	// dropdown items themselves
	const [dropdownItems, setDropdownItems] = useState<ReactNode>(null);
	// trigger element for dropdown (e.g. button)
	const [dropdownTrigger, setDropdownTrigger] = useState<ReactElement | null>(null);
	// footer
	const [footer, setFooter] = useState<ReactNode>(null);

	const context = useMemo(
		() => ({
			selected,
			setSelected,
			setHeader,
			setFavoriteButton,
			setDropdownItems,
			setDropdownTrigger,
			setFooter,
		}),
		[selected],
	);

	/** Allows keyboard navigation through the menu using arrow keys, home, end */
	const handleArrowKeyNavigation = useCallback(
		(event: React.KeyboardEvent) => {
			const toSelect = getSelectedDescendentForKey<HTMLButtonElement>(event, selected, descendants);
			if (toSelect) {
				toSelect.node.focus();
				setSelected(toSelect.index);
				event.preventDefault();
				return;
			}
		},
		[descendants, selected],
	);

	return (
		<DescendantsProvider value={descendants}>
			<DropdownMenuContext.Provider value={context}>
				<Popover placement={rest.placement || "bottom-start"} gutter={rest.gutter || 2} {...rest}>
					{/* Access internal onClose handler in case this is an uncontrolled component.
					    This handler will be passed to children so they close the dropdown when selected */}
					{({ onClose: internalOnClose }) => {
						const dropdownMenuItems = shouldCloseOnDropdownItemClick
							? childrenWithAddedClickHandler(dropdownItems, internalOnClose)
							: dropdownItems;

						// close dropdown when SHIFT+CLICK on trigger element to workaround Chakra-UI issue https://github.com/chakra-ui/chakra-ui/issues/6920
						const handleShiftClick = (e: React.KeyboardEvent<HTMLButtonElement>) => {
							if (e.key === "Tab" && e.shiftKey) {
								internalOnClose();
								e.preventDefault();
								e.stopPropagation();
								triggerRef?.current?.focus();
								return false;
							}
						};

						return (
							<>
								{favoriteButton ? (
									<ButtonGroup isAttached={!!favoriteButtonAttached} {...buttonGroupProps}>
										{dropdownTrigger && (
											<PopoverTrigger>{dropdownTrigger}</PopoverTrigger>
										)}
										{favoriteButton}
									</ButtonGroup>
								) : (
									dropdownTrigger && (
										<PopoverTrigger>
											{React.cloneElement(dropdownTrigger, {
												onKeyUp: handleShiftClick,
												ref: triggerRef,
											})}
										</PopoverTrigger>
									)
								)}
								{/* Render children even though they will basically just return their
									    children to this component through context */}
								{children}
								<PopoverContent
									width={menuWidth}
									role="menu"
									aria-orientation="vertical"
									onKeyDown={handleArrowKeyNavigation}
								>
									<PopoverBody p="unset">
										{header && (
											<>
												{header}
												{shouldShowHeaderDivider && (
													<Divider marginTop="12px"></Divider>
												)}
											</>
										)}
										<Box>
											{React.Children.count(dropdownItems) === 0 &&
											showNoMatchesMessage !== false ? (
												<Box as="span" pl="10px">
													{noMatchingChildrenMessage || "No matches found"}
												</Box>
											) : itemsPerPage ? (
												<PaginatedList
													entriesPerPage={itemsPerPage}
													w="95%"
													ml="3%"
													mt="5px"
												>
													{dropdownMenuItems}
												</PaginatedList>
											) : (
												dropdownMenuItems
											)}
										</Box>
										{footer}
									</PopoverBody>
								</PopoverContent>
							</>
						);
					}}
				</Popover>
			</DropdownMenuContext.Provider>
		</DescendantsProvider>
	);
});
