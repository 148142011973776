/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file A box that has an invisible space for the site header above it so can be the target of a link in the same page without the site header covering it up
 */

import { Box, BoxProps } from "@chakra-ui/react";
import { useUserSecurityState } from "ao/state/userSecurity";
import React, { FC, memo } from "react";

export const BoxWithHeaderGap: FC<BoxProps> = memo(
	(props: BoxProps): JSX.Element => {
		const { children, ...rest } = props;

		const { userSecurity } = useUserSecurityState((selectors) => selectors.getState(), []);
		const marginTop = `calc(${rest._before?.marginTop || "-115px"}${
			userSecurity.showWhatsNew ? " - 1em" : ""
		})`;
		const height = `calc(${rest._before?.height || "115px"}${userSecurity.showWhatsNew ? " + 1em" : ""})`;

		return (
			<Box
				{...rest}
				_before={{
					display: "block",
					content: `""`,
					marginTop: marginTop,
					height: height,
					visibility: "hidden",
					pointerEvents: "none",
				}}
			>
				{children}
			</Box>
		);
	},
);
