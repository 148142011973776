/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Filtered API list component
 * Can be reused in AO
 */

import { CheckIcon, ExternalLinkIcon, ViewOffIcon, WarningIcon } from "@chakra-ui/icons";
import {
	Box,
	Center,
	Flex,
	Heading,
	IconButton,
	Image,
	ListItem,
	Spacer,
	Text,
	UnorderedList,
	VStack,
} from "@chakra-ui/react";
import { StargateContentVersionWarning } from "ao/components/Stargate/StargateContentVersionWarning";
import { IAPIForOrgModel } from "ao/data";
import { IEpicVersion } from "ao/types";
import { getBaseUrl, searchHasMatch } from "ao/utils/helpers";
import React, {
	createRef,
	forwardRef,
	KeyboardEventHandler,
	memo,
	useCallback,
	useEffect,
	useMemo,
	useRef,
	useState,
} from "react";
import { BsFillBarChartFill } from "react-icons/bs";
import { HiOutlineChevronDoubleLeft, HiOutlineChevronDoubleRight } from "react-icons/hi";
import { SearchInput } from "..";
import { AOLink } from "../AOLink";

interface IApiItem extends IAPIForOrgModel {
	Active: boolean;
	FilterValue: string;
	/** index of this API in the array. Used to correlate with the refs array */
	Index: number;
}

interface IAPIListItemProps {
	listItem: IApiItem;
	idHeader: string;
	isAdmin?: boolean;
	isSelected?: boolean;
	handleSingleClick: (e: React.MouseEvent<HTMLLIElement>) => void;
	handleDoubleClick: (e: React.MouseEvent<HTMLLIElement>) => void;
	handleKeyPress: (e: React.KeyboardEvent<HTMLLIElement>) => void;
	specificationsBaseUrl: string;
	fhirLogo: string;
	/** Customer prod version */
	prodEpicVersion: IEpicVersion | null;
	/** Min Epic version an API works in */
	apiMinEpicVersion: IEpicVersion;
}

const ApiListItem = memo(
	forwardRef<HTMLLIElement, IAPIListItemProps>(
		(props, ref): JSX.Element => {
			const {
				listItem,
				idHeader,
				isAdmin,
				isSelected,
				handleSingleClick,
				handleDoubleClick,
				handleKeyPress,
				specificationsBaseUrl,
				fhirLogo,
				prodEpicVersion,
				apiMinEpicVersion,
			} = props;

			/** don't propagate spec link click so we don't select the API when clicking the specification */
			const handleLinkClick = useCallback(
				(ev: React.MouseEvent<HTMLAnchorElement>) => ev.stopPropagation(),
				[],
			);

			const handleLinkKeyPress = useCallback<KeyboardEventHandler<HTMLAnchorElement>>((ev) => {
				if (ev.key === "Enter") {
					ev.stopPropagation();
				}
			}, []);

			const icon = useMemo(() => {
				if (isAdmin) {
					if (listItem.HasOrgAccess) {
						return (
							<Center title="Organization has access from Org-level" mt="3px">
								<CheckIcon color="green.600" />
							</Center>
						);
					} else if (listItem.HasPermissionAccess) {
						return (
							<Center title="Organization has access from Tier-level." mt="3px">
								<BsFillBarChartFill style={{ color: "#0091ea" }} />
							</Center>
						);
					} else {
						if (isSelected) {
							return (
								<Center
									title="Organization does not have access to this API. This cannot be added until access is given."
									mt="3px"
								>
									<WarningIcon color="red.400" />
								</Center>
							);
						} else {
							return (
								<Center
									title="Organization does not have access to this API. This cannot be added until access is given."
									mt="3px"
								>
									<ViewOffIcon color="gray.400" />
								</Center>
							);
						}
					}
				}
				if (listItem.IsStargate) {
					return (
						<StargateContentVersionWarning
							prodEpicVersion={prodEpicVersion}
							contentMinEpicVersion={apiMinEpicVersion}
							compact
						/>
					);
				}
			}, [
				apiMinEpicVersion,
				isAdmin,
				isSelected,
				listItem.HasOrgAccess,
				listItem.HasPermissionAccess,
				listItem.IsStargate,
				prodEpicVersion,
			]);

			const alternateStyle = listItem.Active ? {} : listItem.Style;

			return (
				<ListItem
					id={listItem.Id + idHeader}
					color={listItem.Active ? "#fff" : ""}
					backgroundColor={listItem.Active ? "#337ab7 !important" : ""}
					borderColor={listItem.Active ? "#337ab7" : ""}
					paddingLeft="2px"
					cursor={"pointer"}
					tabIndex={0}
					userSelect={"none"}
					role="option"
					aria-selected={listItem.Active}
					onDoubleClick={handleDoubleClick}
					onClick={handleSingleClick}
					onKeyDown={handleKeyPress}
					_hover={{ bg: !listItem.Active ? "#f5f5f5" : "" }}
					ref={ref}
					title={listItem.Tooltip ?? ""}
					{...alternateStyle}
				>
					<Flex>
						{listItem.IsFhir && (
							<Image
								key={listItem.Id + "fhir"}
								marginLeft="-15px"
								display="inline"
								height="19px"
								width="32px"
								draggable={false}
								src={fhirLogo}
							/>
						)}
						<Text as="span">{listItem.Name}</Text>
						<Spacer />
						{listItem.HasPublishedGrouper && listItem.HasPermissionAccess && (
							<AOLink
								url={`${specificationsBaseUrl}${listItem.Id}`}
								title={"Open the specification for more details"}
								target="_blank"
								color={listItem.Active ? "#fff" : ""}
								onClick={handleLinkClick}
								onKeyDown={handleLinkKeyPress}
							>
								<ExternalLinkIcon w={4} h={4} m="0.25em" />
							</AOLink>
						)}
						{icon && <Box display="inline">{icon}</Box>}
					</Flex>
				</ListItem>
			);
		},
	),
);

//#endregion

interface IProps {
	/**List of APIs */
	APIs: IAPIForOrgModel[];
	/**List of selected API Ids */
	selectedIds: number[];
	/**Is Admin Flag. Icons with appear if true */
	isAdmin?: boolean;
	editable?: boolean;
	onChangeCallback: (value: number[]) => void;
	/** Used to refer to the list items in fields, e.g. Incoming Web Service or Kit */
	apiType: string;
	/** Customer prod version */
	prodEpicVersion?: IEpicVersion | null;
}

export const FilteredAPIListSelect: React.FunctionComponent<IProps> = memo(
	(props: IProps): JSX.Element => {
		const { APIs, selectedIds, isAdmin, onChangeCallback, apiType, prodEpicVersion } = props;

		//#region state/hooks
		const isEditable = !!props.editable;
		const [APIOptions, setAPIOptions] = useState<IApiItem[]>([]);
		const [searchTerm, setSearchTerm] = useState("");
		const addButtonRef = useRef<HTMLButtonElement>(null);
		const removeButtonRef = useRef<HTMLButtonElement>(null);
		/** array of refs to each API so we can focus them during keyboard navigation */
		const [itemRefs, setItemRefs] = useState(new Array<React.RefObject<HTMLLIElement>>());
		const baseUrl = useMemo(() => getBaseUrl(), []);
		const fhirLogo = `${baseUrl}Content/images/fhir-flame.png`;
		const specificationsBaseUrl = `${baseUrl}Sandbox?api=`;
		const anyAvailableActive = useMemo(
			() => APIOptions.some((api) => api.Active && !selectedIds.includes(api.Id)),
			[APIOptions, selectedIds],
		);
		const anySelectedActive = useMemo(
			() => APIOptions.some((api) => api.Active && selectedIds.includes(api.Id)),
			[APIOptions, selectedIds],
		);
		const maxEpicVersion: IEpicVersion = useMemo(() => {
			return { epicCode: Number.MAX_VALUE, name: "" };
		}, []);

		useEffect(() => {
			const tempItemRefs = new Array<React.RefObject<HTMLLIElement>>();
			setAPIOptions(
				APIs.sort((a, b) => a.Name.localeCompare(b.Name)).map(
					(api: IAPIForOrgModel, index): IApiItem => {
						tempItemRefs[index] = createRef<HTMLLIElement>();
						return {
							...api,
							FilterValue:
								api.Name +
								";" +
								api.Groups +
								";" +
								api.Keywords +
								";" +
								(api.IsFhir ? "FHIR" : ""),
							Active: false,
							Index: index,
						};
					},
				),
			);
			setItemRefs(tempItemRefs);
		}, [APIs]);

		const handleSearchTermChange = useCallback((searchTerm: string) => setSearchTerm(searchTerm), []);

		const handleDoubleClick = useCallback(
			(e: React.MouseEvent<HTMLLIElement>): void => {
				if (!isEditable) {
					return;
				}
				const id = parseInt(e.currentTarget.id);
				const api = APIOptions.find((api) => api.Id === id);
				if (!api) {
					return;
				}

				setAPIOptions(toggleActive(APIOptions, selectedIds, api.Id, false));

				if (selectedIds.includes(api.Id)) {
					onChangeCallback(selectedIds.filter((item) => item !== api.Id));
				} else {
					onChangeCallback([...selectedIds, api.Id]);
				}
			},
			[APIOptions, isEditable, onChangeCallback, selectedIds],
		);

		const handleSingleClick = useCallback(
			(e: React.MouseEvent<HTMLLIElement>): void => {
				if (e.detail === 2) {
					// 2 means it was clicked twice. Already handling this in handleDoubleClick.
					return;
				}
				const clickedApiId = parseInt(e.currentTarget.id);
				let selectedApis: IApiItem[];

				if (e.shiftKey) {
					selectedApis = makeRangeActive(APIOptions, selectedIds, clickedApiId, searchTerm);
				} else if (e.ctrlKey) {
					selectedApis = toggleActive(APIOptions, selectedIds, clickedApiId);
				} else {
					selectedApis = clearActive(APIOptions);
					selectedApis = toggleActive(selectedApis, selectedIds, clickedApiId);
				}
				setAPIOptions(selectedApis);
			},
			[APIOptions, selectedIds, searchTerm],
		);

		const handleKeyPress = useCallback(
			(e: React.KeyboardEvent<HTMLLIElement>): boolean => {
				const clickedApiId = parseInt(e.currentTarget.id);
				const isSelected = selectedIds.includes(clickedApiId);
				const filteredOptions =
					isSelected || !searchTerm
						? APIOptions
						: APIOptions.filter((api) => searchHasMatch(searchTerm, api.FilterValue));
				let curIndex: number = 0;

				switch (e.key) {
					case "Enter":
					case " ":
						// activate the current item and jump to the add/remove button
						const currentItem = filteredOptions.find((api) => api.Id === clickedApiId);

						let selectedApis = [];
						if (currentItem?.Active) {
							//If item is active, then do not clear other active ids
							selectedApis = makeRangeActive(APIOptions, selectedIds, clickedApiId, searchTerm);
						} else {
							selectedApis = clearActive(APIOptions);
							selectedApis = toggleActive(selectedApis, selectedIds, clickedApiId);
						}

						setAPIOptions(selectedApis);

						if (isSelected && removeButtonRef?.current) {
							removeButtonRef.current.removeAttribute("disabled");
							removeButtonRef.current.focus();
						} else if (addButtonRef?.current) {
							addButtonRef.current.removeAttribute("disabled");
							addButtonRef.current.focus();
						}
						break;
					case "Home":
						// go to the first item and activate it
						const first = filteredOptions.find(
							(api) => selectedIds.includes(api.Id) === isSelected,
						);
						if (first) {
							// focus item
							itemRefs[first.Index]?.current?.focus();

							// activate item
							let selectedApis = clearActive(APIOptions);
							selectedApis = toggleActive(selectedApis, selectedIds, first.Id);
							setAPIOptions(selectedApis);
						}
						break;
					case "End":
						// go to the last item and activate it
						let last: IApiItem | undefined = undefined;
						for (let i = filteredOptions.length - 1; i >= 0; i--) {
							const api = filteredOptions[i];
							if (selectedIds.includes(api.Id) === isSelected) {
								last = api;
								break;
							}
						}

						if (last) {
							// focus item
							itemRefs[last.Index]?.current?.focus();

							// activate item
							let selectedApis = clearActive(APIOptions);
							selectedApis = toggleActive(selectedApis, selectedIds, last.Id);
							setAPIOptions(selectedApis);
						}
						break;
					case "ArrowUp":
						// go to the previous item and activate it
						// if holding shift key add to active range
						let prev: IApiItem | undefined = undefined;
						curIndex = filteredOptions.findIndex((api) => api.Id === clickedApiId);
						for (let i = curIndex - 1; i >= 0; i--) {
							const api = filteredOptions[i];
							if (selectedIds.includes(api.Id) === isSelected) {
								prev = api;
								break;
							}
						}

						if (prev) {
							// focus item
							itemRefs[prev.Index]?.current?.focus();

							// activate item
							let selectedApis: IApiItem[];
							if (e.shiftKey) {
								selectedApis = makeRangeActive(
									APIOptions,
									selectedIds,
									clickedApiId,
									searchTerm,
								);
								selectedApis = makeRangeActive(
									selectedApis,
									selectedIds,
									prev.Id,
									searchTerm,
								);
							} else {
								selectedApis = clearActive(APIOptions);
								selectedApis = toggleActive(selectedApis, selectedIds, prev.Id);
							}

							setAPIOptions(selectedApis);
						}
						break;
					case "ArrowDown":
						// go to the next item and activate it
						// if holding shift key add to active range
						let next: IApiItem | undefined = undefined;
						curIndex = filteredOptions.findIndex((api) => api.Id === clickedApiId);
						for (let i = curIndex + 1; i < APIOptions.length; i++) {
							const api = filteredOptions[i];
							if (selectedIds.includes(api.Id) === isSelected) {
								next = api;
								break;
							}
						}

						if (next) {
							// focus item
							itemRefs[next.Index]?.current?.focus();

							// activate item
							let selectedApis: IApiItem[];
							if (e.shiftKey) {
								selectedApis = makeRangeActive(
									APIOptions,
									selectedIds,
									clickedApiId,
									searchTerm,
								);
								selectedApis = makeRangeActive(
									selectedApis,
									selectedIds,
									next.Id,
									searchTerm,
								);
							} else {
								selectedApis = clearActive(APIOptions);
								selectedApis = toggleActive(selectedApis, selectedIds, next.Id);
							}

							setAPIOptions(selectedApis);
						}
						break;
				}

				if (e.key !== "Tab") {
					e.preventDefault();
					e.stopPropagation();
				}

				return false;
			},
			[APIOptions, itemRefs, selectedIds, searchTerm],
		);

		const handleAddClicked = useCallback((): void => {
			const selectedApis = APIOptions.filter((api) => api.Active).map((api) => api.Id);
			onChangeCallback([...selectedIds, ...selectedApis]);
			setAPIOptions(clearActive(APIOptions));
		}, [APIOptions, onChangeCallback, selectedIds]);

		const handleRemoveClicked = useCallback((): void => {
			const selectedApis = APIOptions.filter((api) => api.Active).map((api) => api.Id);

			onChangeCallback(selectedIds.filter((item) => !selectedApis.includes(item)));
			setAPIOptions(clearActive(APIOptions));
		}, [APIOptions, onChangeCallback, selectedIds]);

		//#endregion

		const optionsAvailable = useMemo(
			() =>
				APIOptions.filter(
					(api) =>
						!selectedIds.includes(api.Id) &&
						(!searchTerm || searchHasMatch(searchTerm, api.FilterValue)),
				).map((api: IApiItem) => {
					return (
						<ApiListItem
							key={api.Id}
							listItem={api}
							idHeader="_NotSelected"
							isAdmin={isAdmin}
							handleSingleClick={handleSingleClick}
							handleDoubleClick={handleDoubleClick}
							handleKeyPress={handleKeyPress}
							fhirLogo={fhirLogo}
							specificationsBaseUrl={specificationsBaseUrl}
							prodEpicVersion={prodEpicVersion || null}
							apiMinEpicVersion={api.MinEpicVersion || maxEpicVersion}
							ref={itemRefs[api.Index]}
						></ApiListItem>
					);
				}),
			[
				APIOptions,
				fhirLogo,
				handleDoubleClick,
				handleKeyPress,
				handleSingleClick,
				isAdmin,
				itemRefs,
				maxEpicVersion,
				prodEpicVersion,
				searchTerm,
				selectedIds,
				specificationsBaseUrl,
			],
		);

		const optionsSelected = useMemo(
			() =>
				APIOptions.filter((api) => selectedIds.includes(api.Id)).map((api: IApiItem) => {
					return (
						<ApiListItem
							key={api.Id}
							listItem={api}
							idHeader="_selected"
							isAdmin={isAdmin}
							handleSingleClick={handleSingleClick}
							handleDoubleClick={handleDoubleClick}
							handleKeyPress={handleKeyPress}
							specificationsBaseUrl={specificationsBaseUrl}
							fhirLogo={fhirLogo}
							prodEpicVersion={prodEpicVersion || null}
							apiMinEpicVersion={api.MinEpicVersion || maxEpicVersion}
							ref={itemRefs[api.Index]}
							isSelected={true}
						></ApiListItem>
					);
				}),
			[
				APIOptions,
				fhirLogo,
				handleDoubleClick,
				handleKeyPress,
				handleSingleClick,
				isAdmin,
				itemRefs,
				maxEpicVersion,
				prodEpicVersion,
				selectedIds,
				specificationsBaseUrl,
			],
		);

		return (
			<Box width={{ base: "unset", sm: "66%", lg: "unset" }} marginLeft={"2px"}>
				{isEditable && (
					<SearchInput
						placeholder={`Search available ${apiType}s`}
						onChangeCallback={handleSearchTermChange}
						inputGroupProps={{ w: { lg: "40%" }, mb: "0.5em" }}
					/>
				)}
				<Box display={{ lg: "flex" }}>
					{isEditable && (
						<>
							<Box width={{ lg: "40%" }} as="section" aria-label={`Available ${apiType}s`}>
								<Heading as="h3" fontSize="md" title={`Available ${apiType}s`}>
									Available
								</Heading>
								<UnorderedList
									role="listbox"
									height={200}
									overflow="auto"
									border="1px solid lightgray;"
									marginLeft={0}
								>
									{optionsAvailable}
								</UnorderedList>
							</Box>
							<Box
								width={{ lg: "20%" }}
								display="flex"
								maxWidth={{ base: "unset", lg: 250 }}
								alignItems="center"
								verticalAlign="central"
								paddingTop={{ base: 19, lg: 34 }}
							>
								<VStack width="100%" padding={15}>
									<IconButton
										ref={addButtonRef}
										width="100%"
										colorScheme="lightgray"
										variant="outline"
										title="Add Selected"
										aria-label="Add Selected"
										onClick={handleAddClicked}
										icon={<HiOutlineChevronDoubleRight size={20} />}
										disabled={!anyAvailableActive}
									></IconButton>
									<IconButton
										ref={removeButtonRef}
										width="100%"
										colorScheme="lightgray"
										variant="outline"
										title="Remove Selected"
										aria-label="Remove Selected"
										onClick={handleRemoveClicked}
										icon={<HiOutlineChevronDoubleLeft size={20} />}
										disabled={!anySelectedActive}
									></IconButton>
								</VStack>
							</Box>
						</>
					)}
					<Box width={{ lg: "40%" }} as="section" aria-label={`Selected ${apiType}s`}>
						<Heading as="h3" fontSize="md" title={`Selected ${apiType}s`}>
							Selected
						</Heading>
						<UnorderedList
							role="listbox"
							height={200}
							overflow="auto"
							border="1px solid lightgray;"
							marginLeft={0}
						>
							{optionsSelected}
						</UnorderedList>
					</Box>
				</Box>
			</Box>
		);
	},
);

//#region list functions

/**
 * Toggle an API's active/highlighted state
 * @param apis list of all APIs
 * @param selectedIds IDs of selected APIs
 * @param id ID of item to update
 * @param active active status to set. If undefined, toggle status.
 * @returns updated list of APIs
 */
function toggleActive(apis: IApiItem[], selectedIds: number[], id: number, active?: boolean): IApiItem[] {
	const api = apis.find((api) => api.Id === id);
	//check if api exists to satisfy linter but API should always exist
	if (!api) return apis;

	const isActive = active === undefined ? !api.Active : active;
	const isSelected = selectedIds.includes(id);

	return apis.map((api) => {
		if (api.Id === id) {
			return { ...api, Active: isActive };
		} else if (isActive && selectedIds.includes(api.Id) !== isSelected) {
			// de-activate items in other column when activating one from this column
			// since it doesn't make sense to have both items from both columns active at the same time
			return { ...api, Active: false };
		} else {
			return api;
		}
	});
}

/**
 * Clear active state for all APIs
 * @param apis list of all APIs
 * @returns updated list of APIs
 */
function clearActive(apis: IApiItem[]): IApiItem[] {
	return apis.map((api) => (api.Active ? { ...api, Active: false } : api));
}

/**
 * Activate a contiguous range of APIs
 * @param apis list of all APIs
 * @param selectedIds IDs of selected APIs
 * @param clickedApiId API that was just clicked. Will either begin or extend the range from this one.
 * @param searchTerm current search term filter, if any.
 * @returns updated list of APIs
 */
function makeRangeActive(
	apis: IApiItem[],
	selectedIds: number[],
	clickedApiId: number,
	searchTerm: string,
): IApiItem[] {
	const isSelected = selectedIds.includes(clickedApiId);

	// find index of first active API
	const index1 = apis.findIndex((api) => api.Active);

	if (index1 === -1) {
		return toggleActive(apis, selectedIds, clickedApiId);
	}
	const index2 = apis.findIndex((api) => api.Id === clickedApiId);
	const start = Math.min(index1, index2);
	const end = Math.max(index1, index2);

	return apis.map((api, index) => {
		if (
			index >= start &&
			index <= end &&
			selectedIds.includes(api.Id) === isSelected &&
			// make sure API matches filter if in the available group
			(!searchTerm || isSelected || searchHasMatch(searchTerm, api.FilterValue))
		) {
			return { ...api, Active: true };
		} else {
			return api;
		}
	});
}

//#endregion
