/**
 * @copyright Copyright 2020-2022 Epic Systems Corporation
 * @file render App on web page
 * @module Epic.AppOrchard
 */

import { ChakraProvider } from "@chakra-ui/react";
// allows only showing focus border for keyboard navigation
// after upgrade to Chakra-UI 2.x Chakra does this by default so we can remove focus-visible
import "focus-visible/dist/focus-visible";
import React from "react";
import { render } from "react-dom";
import { ErrorBoundary } from "react-error-boundary";
import { HelmetProvider } from "react-helmet-async";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { chakraTheme } from "./chakraTheme";
import { ErrorFallbackFullPage } from "./components/Core";
import { StandardLayout } from "./components/Frame";
import NotFound from "./components/NotFound/NotFound";
import { logFromErrorBoundary } from "./data";
import routes from "./routes";
import store from "./store";


const element = document.getElementById("root");
if (!element) {
	throw new Error("couldn't find element with id root");
}

render(
	<BrowserRouter>
		<HelmetProvider>
			<ChakraProvider theme={chakraTheme}>
				<Provider store={store}>
					<Routes>
						{routes.map(({ title, path, component, showFullWidth, children }) => {
							return (
								<Route
									path={path}
									key={path}
									element={
										<ErrorBoundary
											FallbackComponent={ErrorFallbackFullPage}
											onError={logFromErrorBoundary}
										>
											<StandardLayout
												component={component}
												title={title}
												showFullWidth={showFullWidth}
											/>
										</ErrorBoundary>
									}
								>
									{children?.map(
										({ childPath, ChildElement, index, redirectUrl, replace }) => {
											// Check if we should redirect to a child route instead of rendering a component at the parent route
											if (index && redirectUrl) {
												return (
													<Route
														index
														element={
															redirectUrl ? (
																<ChildElement
																	to={redirectUrl}
																	replace={replace}
																/>
															) : (
																<ChildElement />
															)
														}
													/>
												);
											}
											return (
												<Route
													path={childPath}
													key={childPath}
													element={<ChildElement />}
												/>
											);
										},
									)}
								</Route>
							);
						})}
						<Route
							path="*"
							element={
								<ErrorBoundary
									FallbackComponent={ErrorFallbackFullPage}
									onError={logFromErrorBoundary}
								>
									<StandardLayout component={NotFound} title="File Not Found" />
								</ErrorBoundary>
							}
						/>
					</Routes>
				</Provider>
			</ChakraProvider>
		</HelmetProvider>
	</BrowserRouter>,
	element,
);
