/**
 * @copyright Copyright 2024-2024 Epic Systems Corporation
 * @file Contact Us form
 * @module Epic.AppOrchard.Core.NoneText
 */
import { Text, TextProps } from "@chakra-ui/react";
import { FC, memo } from "react";

export const NoneText: FC<TextProps> = memo((props: TextProps) => {
	const { ...rest } = props;
	return (
		<Text fontStyle="italic" color="grey" {...rest}>
			None
		</Text>
	);
});
