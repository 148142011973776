/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Main menu that shows on mobile
 * @module Epic.AppOrchard.Frame.MobileMainMenu
 */

import { HamburgerIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	ButtonProps,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
} from "@chakra-ui/react";
import { IMenu, IUserSecurity, MenuId } from "ao/types";
import React, { FC, memo, useCallback, useMemo, useState } from "react";
import { HeaderMenuItem, LoginMenu, UserInfo } from ".";
import { AOLink } from "../Core";

interface IProps {
	userSecurity: IUserSecurity;
	currentPageMenuId: number | undefined;
	currentPageMenuItemId: number | undefined;
	toolbarMenuItems: IMenu[];
}

type Props = ButtonProps & IProps;

/* Main menu that shows on mobile */
export const MobileMainMenu: FC<Props> = memo((props) => {
	const { userSecurity, currentPageMenuId, currentPageMenuItemId, toolbarMenuItems } = props;
	const userMenuItems = userSecurity.menus.find((menu) => menu.id === MenuId.UserMenu)?.children || [];
	const [openMenuId, setOpenMenuId] = useState<number | undefined>();
	const isUserSignedIn = useMemo(() => {
		return userSecurity.userId !== null;
	}, [userSecurity.userId]);

	const handleMenuClicked = useCallback(
		(menuId: number) => () => {
			// open the new menu or close the currently open one if clicked again
			const menuToOpen = openMenuId === menuId ? undefined : menuId;
			setOpenMenuId(menuToOpen);
		},
		[openMenuId],
	);

	return (
		<Popover placement="bottom-end">
			{/* Get get a reference to the uncontrolled component's open/close state so we can tap into it */}
			{({ isOpen, onClose }) => {
				const handleMenuItemClicked = () => {
					setOpenMenuId(undefined); // close any open menus
					onClose(); // close dropdown
				};

				return (
					<>
						<PopoverTrigger>
							<Button
								leftIcon={<HamburgerIcon w="6" h="6" />}
								h="9"
								variant="outline"
								_focus={{ outline: "1px dotted -webkit-focus-ring-color" }}
								p="5px"
								mt="20px"
								mb="8px"
								border="1px solid transparent"
								borderRadius="4px"
								color="rgb(65,143,237)"
								aria-haspopup="menu"
								aria-expanded={isOpen ? "true" : "false"}
								aria-controls="MobileMenu"
								background={isOpen ? "rgb(230, 243, 249)" : undefined}
							>
								Menu
							</Button>
						</PopoverTrigger>
						<PopoverContent w={{ base: "100vw", sm: "60vw" }}>
							<PopoverBody id="MobileMenu" role="menu" aria-orientation="vertical">
								{isUserSignedIn && (
									<HeaderMenuItem
										as="span"
										mb="0.5em"
										isMobile
										showIcon={false}
										userSecurity={userSecurity}
									>
										<UserInfo userSecurity={userSecurity} />
									</HeaderMenuItem>
								)}
								{/* Toolbar menu items (i.e. non dropdowns) */}
								{toolbarMenuItems.map((menuItem) => (
									<HeaderMenuItem
										as={AOLink}
										url={menuItem.link}
										active={menuItem.id === currentPageMenuId}
										onClick={handleMenuItemClicked}
										isMobile
										menuId={menuItem.id}
										showIcon={false}
										userSecurity={userSecurity}
									>
										{menuItem.name}
									</HeaderMenuItem>
								))}
								{/* Dropdown menus */}
								{userSecurity.menus
									.filter(
										(menu) =>
											menu.id !== MenuId.Toolbar &&
											menu.id !== MenuId.UserMenu &&
											menu.id !== MenuId.Login,
									)
									.map((menu) => (
										<Box background={menu.id === openMenuId ? "#E6F3F9" : ""}>
											<HeaderMenuItem
												active={menu.id === currentPageMenuId}
												onClick={handleMenuClicked(menu.id)}
												isDropdown
												isMobile
												showIcon={false}
												userSecurity={userSecurity}
												aria-haspopup="menu"
												aria-expanded={menu.id === openMenuId ? "true" : "false"}
												aria-controls={`MobileMenu${menu.id}`}
											>
												{menu.name}
											</HeaderMenuItem>
											{menu.id === openMenuId && (
												<Box
													id={`MobileMenu${menu.id}`}
													role="menu"
													aria-orientation="vertical"
												>
													{menu.children.map((menuItem) => (
														<HeaderMenuItem
															menuId={menuItem.id}
															as={AOLink}
															url={menuItem.link}
															active={menuItem.id === currentPageMenuItemId}
															onClick={handleMenuItemClicked}
															isMobile
															userSecurity={userSecurity}
															newTab={menu.id === MenuId.JumpTo}
															showIcon={menu.id !== MenuId.Admin}
															p={menu.id === MenuId.Admin ? "5px" : undefined}
														>
															{menuItem.name}
														</HeaderMenuItem>
													))}
												</Box>
											)}
										</Box>
									))}

								{!isUserSignedIn &&
									userSecurity.menus
										.filter((menu) => menu.id === MenuId.Login)
										.map((menu) => (
											<LoginMenu
												menu={menu}
												userSecurity={userSecurity}
												currentPageMenuId={currentPageMenuItemId}
												openMenuId={openMenuId}
												updateOpenMenu={() => () => {}}
												alwaysOpen
											></LoginMenu>
										))}

								<Box mt="0.5em">
									{userMenuItems.map((menuItem) => (
										<HeaderMenuItem
											as={AOLink}
											url={menuItem.link}
											textAlign="center"
											active={menuItem.id === currentPageMenuItemId}
											onClick={handleMenuItemClicked}
											showIcon={false}
											menuId={menuItem.id}
											isMobile
											userSecurity={userSecurity}
										>
											{menuItem.name}
										</HeaderMenuItem>
									))}
								</Box>
							</PopoverBody>
						</PopoverContent>
					</>
				);
			}}
		</Popover>
	);
});
