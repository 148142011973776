/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Dropdown footer used by the DropdownMenu component
 * @module Epic.AppOrchard.Core.DropdownFooter
 */
import React, { FC, memo, useContext, useEffect } from "react";
import { DropdownMenuContext } from "./DropdownMenu";

interface IProps {
	children: React.ReactNode;
}

/** footer of the dropdown menu */
export const DropdownFooter: FC<IProps> = memo((props: IProps) => {
	const { children } = props;
	const { setFooter } = useContext(DropdownMenuContext);

	useEffect(() => {
		setFooter && setFooter(children);
	}, [children, setFooter]);

	return null;
});
