/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Navbar for other Epic sites
 * @module Epic.AppOrchard.Frame.Navbar
 */

import { Box } from "@chakra-ui/react";
import React, { FunctionComponent, memo } from "react";
import { NavLogo, topItems } from "./assets";

interface IProps {
	isMobile?: boolean;
}

export const EpicSiteNavbar: FunctionComponent<IProps> = memo((props) => {
	const { isMobile } = props;
	return (
		<>
			{topItems.map((topItem) => (
				<Box>
					<NavLogo
						key={`nav_bar_full_logo_${topItem.navLogoKind}`}
						navLogoKind={topItem.navLogoKind}
						selected={topItem.selected}
						tooltip={topItem.tooltip}
						isMobile={isMobile}
					/>
				</Box>
			))}
		</>
	);
});
