/**
 * @copyright Copyright 2023-2024 Epic Systems Corporation
 * @file components for category display and filtering
 * @returns
 */

import { Checkbox } from "@chakra-ui/checkbox";
import { ChevronDownIcon, ChevronUpIcon, SmallCloseIcon } from "@chakra-ui/icons";
import {
	Box,
	Button,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverTrigger,
	VStack,
	Wrap,
} from "@chakra-ui/react";
import { chakraTheme } from "ao/chakraTheme";
import { ICategory } from "ao/types/showroom";
import React, { memo, useCallback, useMemo } from "react";
import { BiFilterAlt } from "react-icons/bi";
import { RiFilter2Fill } from "react-icons/ri";

interface IProps {
	categories: ICategory[];
	selectedCategoryIds: number[];
	onChange: (e: number[]) => void;
}

export const CategorySelect: React.FunctionComponent<IProps> = memo(
	(props: IProps): JSX.Element => {
		const { categories, onChange, selectedCategoryIds } = props;

		return (
			<Popover gutter={1}>
				<PopoverTrigger>
					<Button
						fontWeight="normal"
						variant="ghost"
						leftIcon={
							selectedCategoryIds.length > 0 ? (
								<RiFilter2Fill
									color={chakraTheme.colors.showroomPrimary["500"]}
									style={{ marginRight: "-4px" }}
								/>
							) : (
								<BiFilterAlt
									color={chakraTheme.colors.showroomPrimary["500"]}
									style={{ marginRight: "-4px" }}
								/>
							)
						}
					>
						Filter by Category
					</Button>
				</PopoverTrigger>
				<PopoverContent>
					<PopoverBody overflowY="auto" maxHeight="300px" overflowX="auto">
						<VStack spacing="8px" justify="left" alignItems="left">
							{categories.map((input: ICategory) => (
								<CategoryCheckbox
									key={input.id}
									node={input}
									onChange={onChange}
									selectedCategoryIds={selectedCategoryIds}
								/>
							))}
						</VStack>
					</PopoverBody>
				</PopoverContent>
			</Popover>
		);
	},
);

interface INodeProps {
	node: ICategory;
	selectedCategoryIds: number[];
	onChange: (e: number[]) => void;
}
const CategoryCheckbox: React.FunctionComponent<INodeProps> = (props: INodeProps): JSX.Element => {
	const { node, selectedCategoryIds, onChange } = props;

	const isChecked = selectedCategoryIds.includes(node.id);

	const childIds = getDescendantCategoryNodes(node)
		.map((x) => x.id)
		.filter((x) => x !== node.id);

	const hasChildChecked = childIds.some((x) => selectedCategoryIds.includes(x));
	const hasChildren = node.children && node.children.length > 0;

	const handleOnChange = useCallback((): void => {
		let newIds = [];
		if (isChecked || hasChildChecked) {
			newIds = selectedCategoryIds.filter((x) => x !== node.id && !childIds.includes(x));
		} else {
			newIds = [...selectedCategoryIds, node.id];
		}

		onChange(newIds);
	}, [node, isChecked, hasChildChecked, onChange, selectedCategoryIds, childIds]);

	return (
		<>
			<Checkbox
				spacing="0.2rem"
				paddingLeft="4px"
				isChecked={isChecked && !hasChildChecked}
				isIndeterminate={hasChildChecked}
				onChange={handleOnChange}
				isFocusable
			>
				{node.name}
				{hasChildren &&
					(isChecked && !hasChildChecked ? (
						<ChevronUpIcon ml="2px" />
					) : (
						<ChevronDownIcon ml="2px" />
					))}
			</Checkbox>

			{node.children && node.children.length > 0 && (isChecked || hasChildChecked) && (
				<Box marginLeft="25px !important" marginBottom="0px !important">
					<Box marginLeft="5px !important" borderLeft="solid 1px #ddd" paddingLeft="4px">
						<VStack alignItems="flex-start" justify="left">
							{node.children.map((input: ICategory) => (
								<CategoryCheckbox
									node={input}
									selectedCategoryIds={selectedCategoryIds}
									onChange={onChange}
								/>
							))}
						</VStack>
					</Box>
				</Box>
			)}
		</>
	);
};

export const CategoryDisplay: React.FunctionComponent<IProps> = memo(
	(props: IProps): JSX.Element => {
		const { categories, selectedCategoryIds, onChange } = props;

		const allNodes = useMemo(() => {
			let nodes: ICategory[] = [];
			categories.forEach((node) => (nodes = [...nodes, ...getDescendantCategoryNodes(node)]));
			return nodes;
		}, [categories]);

		const selectedNodes = useMemo(() => allNodes.filter((x) => selectedCategoryIds.includes(x.id)), [
			allNodes,
			selectedCategoryIds,
		]);

		const handleRemoveCategory = useCallback(
			(id: number) => () => {
				onChange(selectedCategoryIds.filter((x) => x !== id));
			},
			[onChange, selectedCategoryIds],
		);

		return (
			<Wrap spacing="8px" justify="center" mt="5px" mb="40px">
				{selectedNodes.length > 1 && (
					<Button
						fontWeight="normal"
						title="Remove all filters"
						aria-label="Remove all filters"
						border="1px solid #2D3748"
						borderRadius="full"
						rightIcon={<SmallCloseIcon />}
						colorScheme="gray"
						variant="outline"
						onClick={() => onChange([])}
					>
						Remove All
					</Button>
				)}

				{selectedNodes.map((category: ICategory) => (
					<Button
						key={category.id}
						fontWeight="normal"
						height="36px"
						border="1px solid #F2C1CD"
						borderRadius="full"
						colorScheme="showroomPrimary"
						variant="outline"
						title={`Remove ${category.name} filter`}
						aria-label={`Remove ${category.name} filter`}
						rightIcon={<SmallCloseIcon />}
						onClick={handleRemoveCategory(category.id)}
					>
						{category.name}
					</Button>
				))}
			</Wrap>
		);
	},
);

/** Returns flat list of all nodes in a category */
export function getDescendantCategoryNodes(node: ICategory): ICategory[] {
	if (!node.children || node.children.length === 0) {
		return [node];
	} else {
		let ids: ICategory[] = [];
		node.children.forEach((childNode) => (ids = [...ids, ...getDescendantCategoryNodes(childNode)]));

		return [node, ...ids];
	}
}

/** filters out selected Ids if the any of a node's children are selected */
export function filterParentCategoryIds(node: ICategory, selectedIds: number[]): number[] {
	const isChecked = selectedIds.includes(node.id);

	if (!node.children || node.children.length === 0) {
		return isChecked ? [node.id] : [];
	} else {
		let ids: number[] = [];
		node.children.forEach(
			(childeNode) => (ids = [...ids, ...filterParentCategoryIds(childeNode, selectedIds)]),
		);

		if (isChecked && ids.length === 0) {
			ids = [node.id];
		}

		return ids;
	}
}
