/**
 * @copyright Copyright 2021-2024 Epic Systems Corporation
 * @file View and potentially edit install status for an app.
 * The functionality could be used anywhere on the site but the styling may some things moved to props to be used more broadly.
 *
 * @module Epic.AppOrchard.Core.EditableInstallStatus
 */

import { EditIcon } from "@chakra-ui/icons";
import {
	As,
	Box,
	Button,
	ButtonGroup,
	Flex,
	IconButton,
	Popover,
	PopoverArrow,
	PopoverCloseButton,
	PopoverContent,
	PopoverTrigger,
	Select,
	Stack,
	Text,
	useDisclosure,
	Wrap,
	WrapItem,
} from "@chakra-ui/react";
import { ISuccessHandler, useAsync } from "@epic/react-async-hook";
import { updateInstallStatus } from "ao/data";
import { DownloadApprovedStatus, InstallStatus } from "ao/data/types";
import { installStatusName } from "ao/utils/helpers";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { ChangeEvent, FC, memo, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AiOutlineCloseCircle, AiOutlineSave } from "react-icons/ai";
import { ConfirmationModal, StatusModal } from "..";

interface IOptionProps {
	downloadApprovedStatus: DownloadApprovedStatus;
}

export const InstallStatusOptions: FC<IOptionProps> = memo((props: IOptionProps) => {
	const { downloadApprovedStatus } = props;

	const installStatuses = [
		InstallStatus.NotStarted,
		InstallStatus.InProgress,
		InstallStatus.Live,
		InstallStatus.Deactivated,
	];

	return (
		<>
			{installStatuses.map((installStatus) => {
				const liveStatusAndUnapproved =
					installStatus === InstallStatus.Live &&
					downloadApprovedStatus !== DownloadApprovedStatus.Approved;

				return (
					<option
						key={installStatus}
						value={installStatus}
						disabled={liveStatusAndUnapproved}
						style={{ color: liveStatusAndUnapproved ? "#BFBFBF" : "black" }}
						title={
							liveStatusAndUnapproved
								? "This app has only been enabled for your non-production environments so it cannot be marked as live"
								: ""
						}
					>
						{installStatusName(installStatus)}
					</option>
				);
			})}
		</>
	);
});

interface IProps {
	/** Called after install status is changed and saved */
	successHandler: (newStatus: InstallStatus) => void;
	/** current saved install status of the app */
	installStatus: InstallStatus | null;
	/** download status of the app */
	downloadApprovedStatus: DownloadApprovedStatus;
	appId: number;
	orgId: number;
	/** allows rendering with a different component than the standard Button */
	buttonsAs?: As;
	deactivationWarning?: string;
	/** make buttons inline */
	isForGallery?: boolean;
}

/** View and potentially edit install status for an app */
export const EditableInstallStatus: FC<IProps> = memo((props: IProps) => {
	const {
		successHandler,
		installStatus,
		downloadApprovedStatus,
		appId,
		orgId,
		deactivationWarning,
		buttonsAs,
		isForGallery,
	} = props;

	//#region state and handlers
	const hasApprovedDownloadStatus =
		downloadApprovedStatus === DownloadApprovedStatus.Approved ||
		downloadApprovedStatus === DownloadApprovedStatus.NonProdOnly;
	const confirmDeactivate = deactivationWarning !== undefined;

	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const [showConfirmDeactivate, setShowConfirmDeactivate] = useState<boolean>(false);
	const handleCloseErrorModal = useCallback(() => setShowErrorModal(false), []);
	const [currentInstallStatus, setCurrentInstallStatus] = useState<InstallStatus | null>(installStatus);
	const [isEditingInstallStatus, setIsEditingInstallStatus] = useState<boolean>(false);
	const installSelectRef = useRef<HTMLSelectElement | null>(null);
	const popoverTriggerRef = useRef<HTMLButtonElement | null>(null);

	const installStatusUnchanged = currentInstallStatus === installStatus;
	const handleToggleConfirmDeactivate = useCallback(
		(show: boolean) => () => setShowConfirmDeactivate(show),
		[],
	);

	const { onOpen, onClose, isOpen } = useDisclosure();

	const handleUpdateInstallStatusSuccess = useCallback<ISuccessHandler<typeof updateInstallStatus>>(
		(_response, extras) => {
			const [, , newStatus] = extras.params;
			successHandler(newStatus);
			setIsEditingInstallStatus(false);
		},
		[successHandler],
	);

	const [savingState, updateInstallStatusFn] = useAsync(updateInstallStatus, {
		executeImmediately: false,
		displayName: "updateInstallStatus",
		// `extras` contains a `params` field which has the params passed to the API call
		onSuccess: (response, extras) => handleUpdateInstallStatusSuccess(response, extras),
		onFailure: () => {
			toggleEditingInstallStatus(false, true)();
			setShowErrorModal(true);
		},
	});

	const handleUpdateInstallStatus = useCallback(
		(newStatus: InstallStatus | null, confirmDeactivate: boolean = false) => () => {
			if (newStatus === null) {
				return;
			}

			if (newStatus === InstallStatus.Deactivated && confirmDeactivate) {
				setShowConfirmDeactivate(true);
				return;
			}

			updateInstallStatusFn(appId, orgId, newStatus);
		},
		[appId, orgId, updateInstallStatusFn],
	);

	const isSaving = isInFlight(savingState);

	const toggleEditingInstallStatus = useCallback(
		(editing: boolean, revertToSaved?: boolean) => () => {
			setIsEditingInstallStatus(editing);
			if (revertToSaved) {
				setCurrentInstallStatus(installStatus);
			}
			onClose();
		},
		[installStatus, onClose],
	);

	const handleInstallStatusChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) => setCurrentInstallStatus(parseInt(event.target.value)),
		[],
	);

	const handleTriggerFocus = useCallback(() => {
		if (popoverTriggerRef && popoverTriggerRef?.current) {
			popoverTriggerRef.current.focus();
		}
	}, [popoverTriggerRef]);

	useEffect(() => {
		if (isEditingInstallStatus && installSelectRef?.current) {
			installSelectRef.current.focus();
		}
	}, [isEditingInstallStatus, installSelectRef]);

	//#endregion

	const EditButton = buttonsAs || Button;
	const SaveButton = buttonsAs || Button;
	const CancelButton = buttonsAs || Button;

	const CancelButtonComponent = useMemo(
		() => (
			<CancelButton
				colorScheme="red"
				title="Cancel editing install status"
				aria-label="Cancel editing install status"
				leftIcon={<AiOutlineCloseCircle size={20} />}
				onClick={toggleEditingInstallStatus(false, true)}
				onKeyDown={(event: any) => {
					if (event.key === "Tab" && installStatusUnchanged && isForGallery) {
						handleTriggerFocus();
					}
				}}
			>
				Cancel
			</CancelButton>
		),
		[CancelButton, handleTriggerFocus, installStatusUnchanged, isForGallery, toggleEditingInstallStatus],
	);

	const SaveButtonComponent = useMemo(
		() => (
			<SaveButton
				colorScheme="green"
				title={
					installStatusUnchanged
						? "No changes have been made to the install status so it cannot be saved"
						: "Save install status"
				}
				aria-label={
					installStatusUnchanged
						? "No changes have been made to the install status so it cannot be saved"
						: "Save install status"
				}
				leftIcon={<AiOutlineSave size={20} />}
				onClick={handleUpdateInstallStatus(currentInstallStatus, confirmDeactivate)}
				onKeyDown={(event: any) => {
					if (event.key === "Tab" && isForGallery) {
						handleTriggerFocus();
					}
				}}
				disabled={isSaving || installStatusUnchanged}
				isLoading={isSaving}
				loadingText="Saving"
			>
				Save
			</SaveButton>
		),
		[
			SaveButton,
			confirmDeactivate,
			currentInstallStatus,
			handleTriggerFocus,
			handleUpdateInstallStatus,
			installStatusUnchanged,
			isForGallery,
			isSaving,
		],
	);

	return hasApprovedDownloadStatus ? (
		<>
			{isForGallery ? (
				<Flex whiteSpace="nowrap" alignItems="center">
					<Box as="span" mr="0.5em">
						Install status:
					</Box>
					<Box as="span" fontWeight="bold">
						{installStatusName(installStatus)}
					</Box>

					<Popover
						isOpen={isOpen}
						onOpen={onOpen}
						onClose={toggleEditingInstallStatus(false, true)}
						placement="bottom"
						closeOnBlur={true}
						returnFocusOnClose={true}
						gutter={1}
					>
						<PopoverTrigger>
							<IconButton
								ref={popoverTriggerRef}
								icon={<EditIcon />}
								size="sm"
								title="Edit install status"
								aria-label="Edit install status"
								variant="ghost"
							/>
						</PopoverTrigger>
						<PopoverContent p={5}>
							<PopoverArrow bg="white" />
							<PopoverCloseButton />
							<Text>Edit Install Status</Text>
							<Stack spacing={4}>
								<Select
									ref={installSelectRef}
									onChange={handleInstallStatusChange}
									size="md"
									variant="outline"
									display="inline-block"
									value={currentInstallStatus!}
									w="100%"
								>
									<InstallStatusOptions downloadApprovedStatus={downloadApprovedStatus} />
								</Select>
								<ButtonGroup display="flex" justifyContent="flex-end">
									{CancelButtonComponent}
									{SaveButtonComponent}
								</ButtonGroup>
							</Stack>
						</PopoverContent>
					</Popover>
				</Flex>
			) : (
				<Box w="100%">
					{!isEditingInstallStatus && (
						<>
							<Box mb="0.5em">
								<Box as="span" mr="0.5em">
									Install status:
								</Box>
								<Box as="span" fontWeight="bold">
									{installStatusName(currentInstallStatus)}
								</Box>
							</Box>

							<EditButton
								float="right"
								colorScheme="green"
								title="Edit install status"
								aria-label="Edit install status"
								leftIcon={<EditIcon h={5} w={5} />}
								onClick={toggleEditingInstallStatus(true)}
							>
								Edit
							</EditButton>
						</>
					)}
					{isEditingInstallStatus && (
						<>
							<Box mb="0.5em">
								<Box as="span" mr="0.5em">
									Install status:
								</Box>

								<Select
									ref={installSelectRef}
									onChange={handleInstallStatusChange}
									size="md"
									variant="outline"
									display="inline-block"
									value={currentInstallStatus!}
									w="100%"
								>
									<InstallStatusOptions downloadApprovedStatus={downloadApprovedStatus} />
								</Select>
							</Box>

							<Wrap spacing="0.25em" w="100%" justify="right">
								<WrapItem w="100%">{SaveButtonComponent}</WrapItem>
								<WrapItem w="100%">{CancelButtonComponent}</WrapItem>
							</Wrap>
						</>
					)}
				</Box>
			)}
			<StatusModal
				title="Error Updating Install Status"
				message="Error updating the install status. Please try again later or contact your Epic representative if this continues to happen."
				isOpen={showErrorModal}
				onClose={handleCloseErrorModal}
				status="error"
			></StatusModal>
			{deactivationWarning && (
				<ConfirmationModal
					title="Set Deactivated Install Status"
					message={deactivationWarning}
					acceptCaption="Continue"
					cancelCaption="Cancel"
					isOpen={showConfirmDeactivate}
					onCancel={handleToggleConfirmDeactivate(false)}
					onAccept={handleUpdateInstallStatus(currentInstallStatus)}
				/>
			)}
		</>
	) : null;
});
