/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file List of DropdownMenuItems used by the DropdownMenu component
 * @module Epic.AppOrchard.Core.DropdownList
 */
import React, { FC, memo, useContext, useEffect } from "react";
import { DropdownMenuContext } from "./DropdownMenu";

interface IProps {
	children: React.ReactNode;
}

/** list of DropdownMenuItems  */
export const DropdownList: FC<IProps> = memo((props: IProps) => {
	const { children } = props;
	const { setDropdownItems } = useContext(DropdownMenuContext);

	useEffect(() => {
		setDropdownItems && setDropdownItems(children);
	}, [children, setDropdownItems]);

	return null;
});
