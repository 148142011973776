/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Connector Management App List Row
 * @module Epic.AppOrchard.ConnectorManagement.App.AppListRow
 */

import { EditIcon } from "@chakra-ui/icons";
import { Button, HStack, IconButton, Td, Text, Tr, Wrap, WrapItem } from "@chakra-ui/react";
import { AOLink } from "ao/components/Core";
import { useSiteInfoState } from "ao/state/siteInfo";
import { IColumnDefinition, ISiteInfo, IUserSecurity } from "ao/types";
import { IConnectorAppListItem, IConnectorManagementLookups } from "ao/types/connectorManagement";
import { FC, memo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getConnectorAppEditUrl } from "routes/connectorAppEdit";
import { getConnectorAppViewRelativeUrl } from "routes/connectorAppView";
import {
	getConnectorAppColumnText,
	getConnectorAppColumnTitle,
	getSharedColumnText,
	getSharedColumnTitle,
} from "../../data/columns";
import { AppListColumnKeys, Colors, SharedColumnKeys } from "../../data/constants";
import { ListRowTag } from "../Shared/ListRowTag";

interface IRowProps {
	userSecurity: IUserSecurity;
	connectorApp: IConnectorAppListItem;
	columns: IColumnDefinition[];
	lookups: IConnectorManagementLookups;
	siteInfo: ISiteInfo;
}

export const AppListRow: FC<IRowProps> = memo((props: IRowProps) => {
	const { userSecurity, connectorApp, columns, lookups } = props;
	const { siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);

	const location = useLocation();
	const navigate = useNavigate();

	const isConnectorsAdmin = userSecurity.canManageConnectorsAppsOrgs && siteInfo.isAdminSite;

	return (
		<Tr display="table-row" color="inherit">
			{columns.map((column) => {
				return column.key === AppListColumnKeys.clientAppId ? (
					<Td
						key={column.key}
						title={getConnectorAppColumnTitle(connectorApp, column)}
						w={column.width}
						minWidth={column.minWidth}
						maxWidth={column.maxWidth}
					>
						{connectorApp.clientAppId &&
						(userSecurity.isEpicAdministrator || userSecurity.isSuperAdministrator) ? (
							<AOLink url={`/Developer/Apps?appId=${connectorApp.clientAppId}`}>
								{getConnectorAppColumnText(connectorApp, column, lookups)}
							</AOLink>
						) : (
							<Text>{getConnectorAppColumnText(connectorApp, column, lookups)}</Text>
						)}
					</Td>
				) : column.key === SharedColumnKeys.name ? (
					<Td
						key={column.key}
						title={
							isConnectorsAdmin
								? getSharedColumnText(connectorApp, column, lookups)
								: "Go to app view page"
						}
						w={column.width}
						minWidth={column.minWidth}
						maxWidth={column.maxWidth}
					>
						{isConnectorsAdmin ? (
							<Text>{getSharedColumnText(connectorApp, column, lookups)}</Text>
						) : (
							<AOLink url={getConnectorAppViewRelativeUrl(connectorApp?.id)}>
								{getSharedColumnText(connectorApp, column, lookups)}
							</AOLink>
						)}
						<HStack
							paddingTop={
								connectorApp.isDeleted ||
								connectorApp.isEpicOnFHIR ||
								connectorApp.isPrivateApp
									? "5px"
									: 0
							}
						>
							{connectorApp.isDeleted && (
								<ListRowTag
									label="Soft-Deleted"
									title="This app has been soft-deleted."
									colorScheme="red"
								/>
							)}

							{connectorApp.isEpicOnFHIR && (
								<ListRowTag
									label="Epic On FHIR"
									title="This app was created in Epic on FHIR. Details may not be reliable."
									colorScheme="orange"
								/>
							)}

							{connectorApp.isPrivateApp && (
								<ListRowTag
									label="Homegrown"
									title="This app is private to this organization."
									colorScheme="green"
								/>
							)}
						</HStack>
					</Td>
				) : column.key === AppListColumnKeys.appCategories ? (
					<Td
						key={column.key}
						w={column.width}
						minWidth={column.minWidth}
						maxWidth={column.maxWidth}
					>
						<Wrap>
							{connectorApp.appCategories?.map((record, index) => (
								<WrapItem
									as={ListRowTag}
									title={record.categoryName}
									key={index}
									label={record.categoryName}
									colorScheme={Colors[record.categoryId % Colors.length]}
									variant="subtle"
								/>
							))}
						</Wrap>
					</Td>
				) : column.key === SharedColumnKeys.actionLinks ? (
					<Td
						key={column.key}
						title={getConnectorAppColumnTitle(connectorApp, column)}
						w={column.width}
						minWidth={column.minWidth}
						maxWidth={column.maxWidth}
					>
						<HStack wrap="wrap">
							{userSecurity.canManageConnectorsAppsOrgs && siteInfo.isAdminSite && (
								<>
									<IconButton
										icon={<EditIcon />}
										title={"Edit this app"}
										aria-label={"Edit this app"}
										onClick={() =>
											navigate(getConnectorAppEditUrl(connectorApp.id), {
												state: { from: location.pathname },
											})
										}
									/>
									<Button
										disabled={true} // un-disable when merge pages are complete
									>
										Merge
									</Button>
								</>
							)}
						</HStack>
					</Td>
				) : Object.values(SharedColumnKeys).includes(column.key as SharedColumnKeys) ? (
					<Td
						key={column.key}
						title={getSharedColumnTitle(connectorApp, column)}
						w={column.width}
						minWidth={column.minWidth}
						maxWidth={column.maxWidth}
					>
						<Text>{getSharedColumnText(connectorApp, column, lookups)}</Text>
					</Td>
				) : (
					<Td
						key={column.key}
						title={getConnectorAppColumnTitle(connectorApp, column)}
						w={column.width}
						minWidth={column.minWidth}
						maxWidth={column.maxWidth}
					>
						<Text>{getConnectorAppColumnText(connectorApp, column, lookups)}</Text>
					</Td>
				);
			})}
		</Tr>
	);
});
