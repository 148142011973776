/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Column Picker component
 * @module Epic.AppOrchard.Core.Column.ColumnPicker
 */

import {
	Box,
	Button,
	ButtonGroup,
	ButtonProps,
	Divider,
	GridItem,
	Heading,
	IconButton,
	Popover,
	PopoverBody,
	PopoverContent,
	PopoverHeader,
	PopoverTrigger,
	SimpleGrid,
} from "@chakra-ui/react";
import { IColumnDefinition } from "ao/types";
import React, { FC, RefObject, useCallback, useEffect, useRef, useState } from "react";
import { BsPlus } from "react-icons/bs";
import { FaWrench } from "react-icons/fa";
import { FiArrowDown, FiArrowUp, FiX } from "react-icons/fi";

enum MoveType {
	Up = 1,
	Down = 2,
	Add = 3,
	Remove = 4,
}

export interface ButtonRefs {
	Up: RefObject<HTMLButtonElement> | null;
	Down: RefObject<HTMLButtonElement> | null;
	Add: RefObject<HTMLButtonElement> | null;
	Remove: RefObject<HTMLButtonElement> | null;
}

interface IProps {
	columnDefinitions: IColumnDefinition[];
	selectedColumns: string[];
	handleUpdateColumns: (columnKeys: string[]) => void;
}

type MyProps = IProps & ButtonProps;

export const ColumnPicker: FC<MyProps> = (props: MyProps) => {
	const { columnDefinitions, selectedColumns, handleUpdateColumns, ...rest } = props;

	//#region state and hooks
	const [lastColumnMoved, setLastColumnMoved] = useState<string | undefined>(undefined);
	const [lastMoveType, setLastMoveType] = useState<MoveType | undefined>(undefined);

	const handleColumnMove = useCallback(
		(key: string, moveType: MoveType): void => {
			if (columnDefinitions.find((c) => c.key === key)) {
				const idx = selectedColumns.findIndex((s) => s === key);

				switch (moveType) {
					case MoveType.Add:
						if (idx < 0) {
							const copied = [...selectedColumns, key];
							setLastColumnMoved(key);
							setLastMoveType(MoveType.Up);
							handleUpdateColumns(copied);
						}
						break;
					case MoveType.Remove:
						if (idx >= 0) {
							const copied = [...selectedColumns];
							copied.splice(idx, 1);
							if (copied.length > 0 && idx >= copied.length) {
								setLastColumnMoved(copied[idx - 1]);
								setLastMoveType(MoveType.Remove);
							} else if (copied.length > 0 && idx < copied.length) {
								setLastColumnMoved(copied[idx]);
								setLastMoveType(MoveType.Up);
							} else {
								setLastColumnMoved(key);
								setLastMoveType(MoveType.Add);
							}

							handleUpdateColumns(copied);
						}
						break;
					case MoveType.Up:
						if (idx > 0) {
							const beforeItem = selectedColumns[idx];
							const nextItem = selectedColumns[idx - 1];
							const copied = [...selectedColumns];
							copied.splice(idx - 1, 2, beforeItem, nextItem);
							setLastColumnMoved(key);
							setLastMoveType(moveType);
							handleUpdateColumns(copied);
						}
						break;
					case MoveType.Down:
						if (idx >= 0 && idx < selectedColumns.length - 1) {
							const afterItem = selectedColumns[idx];
							const prevItem = selectedColumns[idx + 1];
							const copied = [...selectedColumns];
							copied.splice(idx, 2, prevItem, afterItem);
							setLastColumnMoved(key);
							setLastMoveType(moveType);
							handleUpdateColumns(copied);
						}
						break;
				}
			}
		},
		[columnDefinitions, handleUpdateColumns, selectedColumns],
	);

	const handleDefaultClicked = useCallback((): void => {
		handleUpdateColumns(columnDefinitions.filter((c) => c.defaultShow).map((c) => c.key));
	}, [columnDefinitions, handleUpdateColumns]);

	const selectedColumnDefinitions = selectedColumns
		.map((s) => {
			return columnDefinitions.find((c) => c.key === s);
		})
		.filter((c) => c !== undefined);

	const selectedColumnOptions = selectedColumnDefinitions.map((s, index) => {
		if (s === undefined) return <></>;

		return (
			<SelectedColumnOption
				key={index}
				column={s}
				hasFocus={s.key === lastColumnMoved}
				lastMoveType={lastMoveType}
				handleMove={handleColumnMove}
				index={index}
				totalColumns={selectedColumnDefinitions.length}
			></SelectedColumnOption>
		);
	});

	const availableColumnOptions = columnDefinitions
		.filter((f) => !selectedColumns.includes(f.key))
		.map((s, index) => {
			return (
				<AvailableColumnOption
					key={index}
					column={s}
					hasFocus={s.key === lastColumnMoved}
					lastMoveType={lastMoveType}
					handleMove={handleColumnMove}
					index={index}
					totalColumns={selectedColumns.length}
				></AvailableColumnOption>
			);
		});

	//#endregion

	return (
		<Popover placement="bottom-end" gutter={2}>
			<PopoverTrigger>
				<IconButton
					width={{ base: "100%", md: "unset" }}
					ml={rest.mt || rest.marginTop || { base: 0, lg: "2px" }}
					mt={rest.mt || rest.marginTop || "unset"}
					color="#333"
					background="white"
					border="1px solid #A0AEC0"
					id="dropdown-toggle-column-prefs"
					h={rest.h || rest.height || "36px"}
					title="Choose the columns to display"
					aria-label="Choose the columns to display"
					icon={<FaWrench />}
					{...rest}
				></IconButton>
			</PopoverTrigger>
			<PopoverContent width={{ base: "75vw", lg: "27vw", xl: "25vw" }}>
				<PopoverHeader>
					<Heading as="h3">
						<span style={{ marginRight: "1em" }}>Choose Columns</span>
						<Button
							onClick={handleDefaultClicked}
							colorScheme="blue"
							title="Use Default Columns"
							aria-label="Use Default Columns"
							size="sm"
						>
							Default
						</Button>
					</Heading>
				</PopoverHeader>
				<PopoverBody maxH="400px" overflowY="auto">
					<Heading as="h4" mt="2px" mb="5px">
						Selected Columns
					</Heading>
					{selectedColumnOptions.length > 0 ? (
						selectedColumnOptions
					) : (
						<Box as="span">No columns selected.</Box>
					)}
					<Divider mt="6px"></Divider>
					<Heading as="h4" mt="9px" mb="5px">
						Available Columns
					</Heading>
					{availableColumnOptions.length > 0 ? (
						availableColumnOptions
					) : (
						<Box>No more columns available.</Box>
					)}
				</PopoverBody>
			</PopoverContent>
		</Popover>
	);
};

interface IColumnOptionProps {
	column: IColumnDefinition;
	index: number;
	totalColumns: number;
	handleMove: (key: string, moveType: MoveType) => void;
	hasFocus: boolean;
	lastMoveType: MoveType | undefined;
}

export const AvailableColumnOption: FC<IColumnOptionProps> = (props: IColumnOptionProps) => {
	const { column, handleMove, hasFocus } = props;

	const handleAdd = useCallback((): void => {
		handleMove(column.key, MoveType.Add);
	}, [column.key, handleMove]);

	const addButtonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (hasFocus) {
			addButtonRef.current?.focus();
		}
	}, [hasFocus]);

	return (
		<Box
			p="0.10rem 0.5rem"
			borderRadius="4px"
			mr="1px"
			_hover={{ backgroundColor: "#f3f4f5" }}
			data-testid={"box-for-availableColumn-" + column.key}
		>
			<SimpleGrid columns={{ base: 3, lg: 12 }}>
				<GridItem colSpan={{ base: 2, lg: 8 }}>
					<Box as="span" title={column.tooltip}>
						{column.name}
					</Box>
				</GridItem>
				<GridItem colSpan={{ base: 1, lg: 4 }}>
					<IconButton
						ref={addButtonRef}
						width="33%"
						aria-label={"Add column " + column.name + " to the display"}
						title={"Add column " + column.name + " to the display"}
						icon={<BsPlus size={15} />}
						onClick={handleAdd}
						borderColor="lightgrey"
						colorScheme="blue"
						variant="outline"
						size="sm"
					></IconButton>
				</GridItem>
			</SimpleGrid>
		</Box>
	);
};

export const SelectedColumnOption: FC<IColumnOptionProps> = (props: IColumnOptionProps) => {
	const { column, index, totalColumns, handleMove, hasFocus, lastMoveType } = props;

	const handleMoveUp = useCallback((): void => {
		handleMove(column.key, MoveType.Up);
	}, [column.key, handleMove]);

	const handleMoveDown = useCallback((): void => {
		handleMove(column.key, MoveType.Down);
	}, [column.key, handleMove]);

	const handleRemove = useCallback((): void => {
		handleMove(column.key, MoveType.Remove);
	}, [column.key, handleMove]);

	const upButtonRef = useRef<HTMLButtonElement>(null);
	const downButtonRef = useRef<HTMLButtonElement>(null);
	const removeButtonRef = useRef<HTMLButtonElement>(null);

	useEffect(() => {
		if (hasFocus) {
			switch (lastMoveType) {
				case MoveType.Up:
					if (index !== 0) {
						upButtonRef.current?.focus();
					} else if (index + 1 !== totalColumns) {
						downButtonRef.current?.focus();
					} else if (!column.hideInPicker) {
						removeButtonRef.current?.focus();
					}
					break;
				case MoveType.Down:
					if (index + 1 !== totalColumns) {
						downButtonRef.current?.focus();
					} else if (!column.hideInPicker) {
						removeButtonRef.current?.focus();
					}
					break;
				case MoveType.Remove:
					if (!column.hideInPicker) {
						removeButtonRef.current?.focus();
					}
					break;
			}
		}
	}, [
		column.key,
		upButtonRef,
		downButtonRef,
		removeButtonRef,
		index,
		totalColumns,
		hasFocus,
		lastMoveType,
		column.hideInPicker,
	]);

	return (
		<Box
			p="0.10rem 0.5rem"
			borderRadius="4px"
			mr="1px"
			_hover={{ backgroundColor: "#f3f4f5" }}
			data-testid={"box-for-selectedColumn-" + column.key}
		>
			<SimpleGrid columns={{ sm: 1, lg: 12 }}>
				<GridItem colSpan={{ sm: 1, lg: 8 }}>
					<Box
						as="span"
						fontWeight={"bold"}
						color="white"
						backgroundColor={"#919192"}
						mr="10px"
						borderRadius={"3px"}
						display="inline-block"
						pl="10px"
						pr="10px"
						fontSize={"15px"}
						title="Order of this column"
					>
						{index + 1}
					</Box>
					<Box as="span" title={column.tooltip}>
						{column.name}
					</Box>
				</GridItem>
				<GridItem colSpan={{ sm: 1, lg: 4 }}>
					<ButtonGroup
						width="100%"
						isAttached
						variant="outline"
						p={{ base: "3px 0", lg: "0px 5px 0px 0px" }}
					>
						<IconButton
							ref={upButtonRef}
							width="33%"
							aria-label={"Move column " + column.name + " forward in the display order"}
							title={"Move column " + column.name + " forward in the display order"}
							icon={<FiArrowUp size={15} />}
							onClick={handleMoveUp}
							disabled={index === 0}
							borderColor="lightgrey"
							colorScheme="blue"
							variant="outline"
							size="sm"
						></IconButton>

						<IconButton
							ref={downButtonRef}
							width="33%"
							aria-label={"Move column " + column.name + " back in the display order"}
							title={"Move column " + column.name + " back in the display order"}
							icon={<FiArrowDown size={15} />}
							onClick={handleMoveDown}
							disabled={index + 1 === totalColumns}
							borderColor="lightgrey"
							colorScheme="blue"
							variant="outline"
							size="sm"
						></IconButton>

						{!column.hideInPicker && (
							<IconButton
								ref={removeButtonRef}
								width="33%"
								aria-label={"Remove column " + column.name + " from the display"}
								title={"Remove column " + column.name + " from the display"}
								colorScheme="red"
								icon={<FiX size={15} />}
								onClick={handleRemove}
								borderColor="lightgrey"
								variant="outline"
								size="sm"
							></IconButton>
						)}
					</ButtonGroup>
				</GridItem>
			</SimpleGrid>
		</Box>
	);
};
