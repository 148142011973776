/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file User photo and name
 * @module Epic.AppOrchard.Frame.UserInfo
 */

import { Box, Image } from "@chakra-ui/react";
import { IUserSecurity } from "ao/types";
import React, { FC, memo } from "react";

interface IProps {
	userSecurity: IUserSecurity;
}

export const UserInfo: FC<IProps> = memo((props) => {
	const { userSecurity } = props;
	return (
		<>
			{userSecurity.userPhotoSrc && (
				<Image
					src={userSecurity.userPhotoSrc}
					h="30"
					maxW="35"
					display="inline-block"
					objectFit="contain"
					alt="User Photo"
					pos="relative"
					pr="5px"
				/>
			)}
			<Box
				as="span"
				overflow="hidden"
				textOverflow="ellipsis"
				whiteSpace="nowrap"
				maxWidth="150px"
				color="#3273c3"
				display="inline-block"
				verticalAlign="middle"
			>
				{userSecurity.displayName}
			</Box>
		</>
	);
});
