/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file ErrorPage component
 * @module Epic.AppOrchard.Frame.ErrorPage
 */

import { Box, Heading } from "@chakra-ui/react";
import { useSiteInfoState } from "ao/state/siteInfo";
import { useUserSecurityState } from "ao/state/userSecurity";
import { FC, memo } from "react";
import { AOLink, StatusAlert } from ".";
import { BodyContent } from "../Frame/BodyContainer";

interface IProps {
	title?: string;
	message?: string;
	noFrame?: boolean;
}
/**
 * Shows full page error message
 */
export const ErrorPage: FC<IProps> = memo((props) => {
	const { title, message, noFrame } = props;
	const { userSecurity } = useUserSecurityState((selectors) => selectors.getState(), []);
	const { siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);
	const content = (
		<>
			<Heading as="h1">{title || "Unexpected Error"}</Heading>
			<StatusAlert
				message={
					message || userSecurity.isEpicUser
						? "We ran into an unexpected error trying to show you a page. Please try again later."
						: "We ran into an unexpected error trying to show you a page. Please try again later or contact your Epic representative if this continues to happen."
				}
				status="error"
				mt="1em"
			/>
		</>
	);
	return noFrame ? (
		<Box p="2em">
			{content}
			<AOLink url={`${siteInfo.vendorServicesUrl}Home`} mt="1em" display="block">
				Go to the home page
			</AOLink>
		</Box>
	) : (
		<BodyContent>{content}</BodyContent>
	);
});
/**
 * Shows full page error message that's embedded in the frame. Need separate component to satisfy typing checking on the error boundary
 */
export const ErrorFallback: FC = memo(() => {
	return <ErrorPage />;
});

/**
 * Shows full page error message not embedded in frame. Used in case frame has failed. Need separate component to satisfy typing checking on the error boundary
 */
export const ErrorFallbackFullPage: FC = memo(() => {
	return <ErrorPage noFrame />;
});
