/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Shows which org user is remote accessing
 * @module Epic.AppOrchard.Frame.RemoteAccessing
 */

import { LinkIcon } from "@chakra-ui/icons";
import React, { FC, memo } from "react";
import { HeaderMenuButton } from ".";
import { AOTooltip } from "../Core";

interface IProps {
	isMobile: boolean;
	orgName: string;
}

/**
 * Shows which org user is remote accessing
 */
export const RemoteAccessing: FC<IProps> = memo((props: IProps) => {
	const { isMobile, orgName } = props;
	const message = `Remote accessing:  ${orgName}`;
	return (
		<AOTooltip
			helptext={message}
			as={HeaderMenuButton}
			padding={isMobile ? "8px" : "10px"}
			isIconButton
			aria-label={message}
		>
			<LinkIcon h={isMobile ? "4" : "6"} w={isMobile ? "4" : "6"} />
		</AOTooltip>
	);
});
