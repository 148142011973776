/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Table header with sortable columns
 * @module Epic.AppOrchard.Core.Filter.SortedColumnHeader
 */

import { Box, Flex, IconButton, Spacer, Td, Tr } from "@chakra-ui/react";
import { IColumnDefinition, ISorting } from "ao/types";
import React, { FC } from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

interface IProps {
	columns: IColumnDefinition[];
	sorting: ISorting;
	handleUpdateSortColumn: (columnKey: string) => void;
}

export const SortableColumnHeader: FC<IProps> = (props: IProps) => {
	const { columns, sorting, handleUpdateSortColumn } = props;

	return (
		<Tr>
			{columns.map((column) => (
				<Td
					key={column.key}
					title={column.tooltip}
					w={column.width}
					minWidth={column.minWidth}
					maxWidth={column.maxWidth}
					onClick={() => {
						if (column.isSortable) handleUpdateSortColumn(column.key);
					}}
					cursor={column.isSortable ? "pointer" : undefined}
					_hover={{ bg: "gray.100" }}
				>
					<Flex>
						<Box fontWeight={"bold"} display="flex" alignItems="center">
							{column.name}
						</Box>
						<Spacer></Spacer>
						{column.isSortable && (
							<IconButton
								size="xs"
								aria-label={"Sort on " + column.name}
								title={"Sort on " + column.name}
								icon={
									sorting.sortColumn !== column.key ? (
										<FaSort />
									) : sorting.sortDirection === 1 ? (
										<FaSortDown />
									) : (
										<FaSortUp />
									)
								}
								variant="ghost"
							></IconButton>
						)}
					</Flex>
				</Td>
			))}
		</Tr>
	);
};
