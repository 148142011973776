/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file state/store for user security
 * @module Epic.AppOrchard.State.UserSecurity
 */

import { buildSharedState } from "@epic/react-redux-booster";
import { getEmptyUserSecurity } from "../data";
import store from "../store";
import { IUserSecurity } from "../types";

/// TYPES ///

export interface IState {
	readonly userSecurity: IUserSecurity;
	readonly hasLoaded: boolean;
}

/// INIT ///

/**
 * Returns an empty user security initial state
 */
export function getInitialState(): IState {
	return {
		userSecurity: getEmptyUserSecurity(),
		hasLoaded: false,
	};
}

/// REDUCERS ///

/**
 * Update user security mark user security as loaded from server
 * @param _state current state
 * @param newUserSecurity new user security
 * @returns
 */
export function updateUserSecurity(_state: IState, newUserSecurity: IUserSecurity): IState {
	return {
		userSecurity: newUserSecurity,
		hasLoaded: true,
	};
}

/**
 * Hide What's new after the user views it
 * @param state current state
 * @returns
 */
export function hideWhatsNew(state: IState): IState {
	return {
		userSecurity: { ...state.userSecurity, showWhatsNew: false },
		hasLoaded: true,
	};
}

/**
 * Turn customer mode on or off
 * Don't call directly - call setCustomerMode in store-methods/userSecurity.ts instead
 * @param state current state
 * @param isInCustomerMode new isInCustomerMode value
 * @returns updated state
 */
export function setCustomerMode(state: IState, isInCustomerMode: boolean): IState {
	return {
		userSecurity: { ...state.userSecurity, isInCustomerMode },
		hasLoaded: true,
	};
}

/// SELECTORS ///

/**
 * Get the current state
 * @param state current state
 * @returns
 */
export function getState(state: IState): IState {
	return state;
}

/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		updateUserSecurity,
		hideWhatsNew,
		setCustomerMode: setCustomerMode,
	},
	selectors: {
		getState,
	},
});
store.addSharedState(builtState.sharedState, "userSecurity");

export const { actionCreators: userSecurityActions, useSharedState: useUserSecurityState } = builtState;
