/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Modal that shows a status message (e.g error)
 * @module Epic.AppOrchard.Core.StatusModal
 */

import {
	Alert,
	AlertDescription,
	AlertIcon,
	AlertStatus,
	Button,
	Modal,
	ModalBody,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
} from "@chakra-ui/react";
import React, { FC, memo, RefObject } from "react";

interface IProps {
	message: React.ReactNode;
	title: string;
	isOpen: boolean;
	onClose: () => void;
	status: AlertStatus;
	closeButtonText?: string;
	focusOnCloseRef?: RefObject<any>;
}

/** Alert/banner to show a status message (e.g. error) */
export const StatusModal: FC<IProps> = memo((props: IProps) => {
	const { message, title, isOpen, onClose, status, closeButtonText, focusOnCloseRef } = props;

	return (
		<Modal
			isOpen={isOpen}
			onClose={onClose}
			size="2xl"
			preserveScrollBarGap
			isCentered
			returnFocusOnClose
			finalFocusRef={focusOnCloseRef}
		>
			<ModalOverlay />
			<ModalContent>
				<ModalHeader>
					<Alert status={status}>
						<AlertIcon />
						<AlertDescription>{title}</AlertDescription>
					</Alert>
				</ModalHeader>
				<ModalBody>{message}</ModalBody>
				<ModalFooter>
					<Button colorScheme="blue" onClick={onClose}>
						{closeButtonText ?? "Close"}
					</Button>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
});
