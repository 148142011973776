/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Giving query string parameters and default values, generates a link.
 * @module Epic.AppOrchard.Core.ReturnLink
 */

import { Button, ButtonProps, LinkProps } from "@chakra-ui/react";
import { config } from "ao/appConfig";
import { ILocationState } from "ao/data";
import { BackLinkType } from "ao/types/showroom";
import { isLocalhost } from "ao/utils/helpers";
import React, { FC, memo, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { AOLink } from ".";
import { getIntFromSearchParam } from "../Showroom/Helper";

interface IProps {
	defaultText: string;
	defaultUrl?: string;
}

type Props = IProps & LinkProps & ButtonProps;

export const ReturnLink: FC<Props> = memo((props: Props) => {
	const { defaultText, defaultUrl, ...rest } = props;

	const [searchParams] = useSearchParams();

	const returnTitleInt = getIntFromSearchParam(searchParams, "returnTitle");
	const returnTitle = useMemo(() => {
		switch (returnTitleInt) {
			case BackLinkType.ToDownload:
				return "Back to Download";
			case BackLinkType.ToPickedApps:
				return "To Picked Apps";
			case BackLinkType.ToSearch:
				return "Back to Search";
			default:
				return "";
		}
	}, [returnTitleInt]);

	const location = useLocation();
	const locationState = location.state as ILocationState;

	const isReactRouted = locationState && locationState.from.length > 0;
	const isFirefox = navigator.userAgent.toLowerCase().includes("firefox");

	//previous non-react-routed link
	const previousLink = document.referrer.toLowerCase();
	const isPreviousLinkFromOtherSite =
		previousLink &&
		previousLink.indexOf(config.SitePath.toLowerCase()) < 0 &&
		previousLink.indexOf(config.SitePathAlternate.toLowerCase());

	const isPreviousLinkEpicOrLocalhost =
		previousLink.indexOf("epic.com") > 0 || (previousLink && isLocalhost());

	//this is to handle firefox, which adds the userweb sign-in url to the history and messes up the back button
	const invalidBack = isFirefox && isPreviousLinkFromOtherSite && !isReactRouted;

	const navigate = useNavigate();

	if (isPreviousLinkEpicOrLocalhost && returnTitle && !invalidBack) {
		return (
			<Button
				variant="linkUnderline"
				colorScheme="blue"
				onClick={() => navigate(-1)}
				height="20px"
				{...rest}
			>
				{"← "}
				{returnTitle}
			</Button>
		);
	} else {
		return (
			<AOLink url={defaultUrl ?? ""} {...rest}>
				{"← "}
				{defaultText}
			</AOLink>
		);
	}
});
