/**
 * @copyright Copyright 2020-2021 Epic Systems Corporation
 * @file data store
 * @module Epic.AppOrchard.Store
 */

import { createStore } from "@epic/react-redux-booster";

/**
 * Defines store
 */
const store = createStore();
export default store;
