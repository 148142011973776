/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file UserOrganizations store updater methods
 * @module Epic.AppOrchard.StoreMethods.UserOrganizations
 */

import { IDispatch } from "@epic/react-redux-booster";
import { IOrganizationWithAssignment, IUserAvailableOrganizations } from "../data";
import { userOrganizationsActions } from "../state/userOrganizations";
import { userOrganizationsUIActions } from "../state/userOrganizationsUI";

/**
 * Update selected org in the store
 * @param dispatch 	for useDispatch
 * @param org new selected org
 */
export function updateSelectedOrganization(dispatch: IDispatch, org: IOrganizationWithAssignment): void {
	dispatch(userOrganizationsActions.updateSelectedOrganization(org));
}

/**
 * Update default org in the store by ID
 * @param dispatch 	for useDispatch
 * @param orgId ID for new default org
 */
export function updateDefaultOrganizationById(dispatch: IDispatch, orgId: number): void {
	dispatch(userOrganizationsActions.updateDefaultOrganizationById(orgId));
}

/**
 * Update store with whether dropdown is being shown
 * @param dispatch for useDispatch
 */
export function updateShowingDropdown(dispatch: IDispatch, showingDropdown: boolean): void {
	dispatch(userOrganizationsUIActions.updateShowingDropdown(showingDropdown));
}

/**
 * Update store with user associated organizations
 * @param dispatch 	for useDispatch
 *  @param orgsForUser orgs for the user
 */
export function updateUserOrganizationsAfterLoad(
	dispatch: IDispatch,
	orgsForUser: IUserAvailableOrganizations,
): void {
	dispatch(userOrganizationsActions.updateUserOrganizationsAfterLoad(orgsForUser));
}
