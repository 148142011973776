/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file Contact Us form
 * @module Epic.AppOrchard.Core.MessageForm
 */

import {
	Box,
	Button,
	Divider,
	Flex,
	FormControl,
	FormLabel,
	GridItem,
	Heading,
	Input,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	SimpleGrid,
	Text,
	useToast,
} from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { config } from "ao/appConfig";
import { sendMessageForm } from "ao/data";
import { useShowroomState } from "ao/state/showroom";
import { useSiteInfoState } from "ao/state/siteInfo";
import { ContactSubject, IPostMessageModel, IUserSecurity } from "ao/types";
import { isEmailValid } from "ao/utils/helpers";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { FC, memo, useCallback, useEffect, useRef, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { StatusModal, TextareaWithRemainingChars } from ".";
import { ErrorMessage } from "./ErrorMessage";

interface IProps {
	userSecurity: IUserSecurity;
	isOpen: boolean;
	onClose: () => void;
	defaultSubject?: ContactSubject;
	contactTarget?: string;
}

const labelStyleProps = { color: "#3f7d67", fontSize: "1.2em", fontWeight: "normal" };

/** Contact Us form */
export const MessageForm: FC<IProps> = memo((props: IProps) => {
	const { userSecurity, isOpen, onClose, defaultSubject, contactTarget } = props;

	//#region state/hooks

	const { siteInfo, hasLoaded: siteInfoLoaded } = useSiteInfoState((selectors) => selectors.getState(), []);
	const { contactSubjects } = useShowroomState((selectors) => selectors.getState(), []);

	const [error, setError] = useState("");
	const [messageSendError, setMessageSendError] = useState(false);
	const [emailValid, setEmailValid] = useState(true);
	const recaptchaRef = useRef<ReCAPTCHA>(null);
	const [data, setData] = useState<IPostMessageModel>({
		Name: "",
		OrgName: "",
		Email: "",
		QueryType: 0,
		Message: "",
		CaptchaResponse: "",
	});
	const toast = useToast();

	const customSubject = contactSubjects.find((subject) => subject.id === defaultSubject) || undefined;

	const useCustomSubject = customSubject && siteInfo.isGalleryEnabled;

	useEffect(() => {
		// reset the form when it opens
		if (isOpen) {
			setData({
				Name: userSecurity?.displayName || "",
				OrgName: userSecurity?.organizationName || "",
				Email: userSecurity?.email || "",
				QueryType: defaultSubject ?? ContactSubject.GeneralInquiry,
				Message: "",
				CaptchaResponse: "",
			});
			setMessageSendError(false);
		} else {
			// reset recaptcha on close to minimize any repainting from the reset
			recaptchaRef?.current?.reset();
		}
	}, [userSecurity, isOpen, defaultSubject]);

	useEffect(() => setError(getFormErrors(data, emailValid)), [data, emailValid]);

	const [sendingState, sendMessageFn] = useAsync(sendMessageForm, {
		executeImmediately: false,
		displayName: "sendMessageForm",
		onSuccess: (response) => {
			onClose();
			if (response.Data.success) {
				toast({
					title: "Message Sent",
					description: "Thank you! We have received your message and will respond to you soon.",
					status: "success",
					duration: config.ToastDuration,
					isClosable: true,
				});
				return;
			}
			setMessageSendError(true);
		},
		onFailure: () => {
			onClose();
			setMessageSendError(true);
		},
	});

	const isSending = isInFlight(sendingState);

	const handleSubmit = useCallback(() => {
		sendMessageFn(data);
	}, [data, sendMessageFn]);

	const handleNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => setData({ ...data, Name: e.target.value }),
		[data],
	);

	const handleOrgNameChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => setData({ ...data, OrgName: e.target.value }),
		[data],
	);

	const handleEmailChange = useCallback(
		(e: React.ChangeEvent<HTMLInputElement>) => {
			const email = e.target.value;
			setEmailValid(isEmailValid(email));
			setData({ ...data, Email: email });
		},
		[data],
	);

	const handleQueryTypeChange = useCallback(
		(e: React.ChangeEvent<HTMLSelectElement>) =>
			setData({ ...data, QueryType: parseInt(e.target.value) }),
		[data],
	);

	const handleMessageChange = useCallback(
		(e: React.ChangeEvent<HTMLTextAreaElement>) => setData({ ...data, Message: e.target.value }),
		[data],
	);

	const handleRecaptchaChange = useCallback(
		(response: string | null) => {
			setData({ ...data, CaptchaResponse: response || "" });
		},
		[data],
	);

	//#endregion
	return (
		<>
			<Modal
				isOpen={isOpen && siteInfoLoaded}
				onClose={onClose}
				size="2xl"
				// don't trap focus in the modal otherwise users can't tab to the recaptcha image selection iframe
				trapFocus={false}
			>
				<ModalOverlay />
				<ModalContent maxWidth={"650px"}>
					<ModalHeader>
						<Heading
							textAlign={"center"}
							fontSize="1.5em"
							fontWeight="normal"
							paddingBottom={"5px"}
						>
							{contactTarget ? "Contact " + contactTarget : "Contact Us"}
						</Heading>
						<Divider marginBottom={"15px"} />
						<Text color="dimgray" fontSize=".95em" fontWeight="normal">
							Got a question or comment? We would love to hear from you. Send us a message.
						</Text>
					</ModalHeader>
					<ModalCloseButton />
					<ModalBody>
						<SimpleGrid spacingY={"15px"}>
							<GridItem>
								<FormControl isRequired isInvalid={!data.Name}>
									<FormLabel {...labelStyleProps}>Name</FormLabel>
									<Input
										placeholder="Name"
										value={data.Name}
										onChange={handleNameChange}
										maxLength={500}
										isDisabled={isSending}
										autoFocus
									/>
								</FormControl>
							</GridItem>
							<GridItem>
								<FormControl isRequired isInvalid={!data.OrgName}>
									<FormLabel {...labelStyleProps}>Organization Name</FormLabel>
									<Input
										placeholder="Organization Name"
										value={data.OrgName}
										onChange={handleOrgNameChange}
										maxLength={500}
										isDisabled={isSending}
									/>
								</FormControl>
							</GridItem>
							<GridItem>
								<FormControl isRequired isInvalid={!emailValid}>
									<FormLabel {...labelStyleProps}>Business Email</FormLabel>
									<Input
										placeholder="Business Email"
										value={data.Email}
										onChange={handleEmailChange}
										maxLength={250}
										isDisabled={isSending}
									/>
									{!emailValid && <ErrorMessage message="Enter a valid email address" />}
								</FormControl>
							</GridItem>
							<GridItem>
								<FormControl>
									<FormLabel {...labelStyleProps}>Subject</FormLabel>
									<Select
										value={data.QueryType}
										onChange={handleQueryTypeChange}
										isDisabled={isSending}
									>
										{useCustomSubject ? (
											<option value={customSubject!.id}>{customSubject!.name}</option>
										) : (
											<>
												<option value={ContactSubject.GeneralInquiry}>
													General Inquiry
												</option>
												<option value={ContactSubject.TechnicalQuestion}>
													Technical Question
												</option>
												{siteInfo.isGalleryEnabled && (
													<option value={ContactSubject.ShowroomInquiry}>
														Showroom Inquiry
													</option>
												)}
											</>
										)}
									</Select>
								</FormControl>
							</GridItem>
							<GridItem>
								<FormControl isRequired>
									<FormLabel {...labelStyleProps}>Message</FormLabel>
									<TextareaWithRemainingChars
										maxChars={1000}
										rows={5}
										value={data.Message}
										onChange={handleMessageChange}
										isDisabled={isSending}
									/>
								</FormControl>
							</GridItem>
							<ReCAPTCHA
								sitekey={siteInfo.recaptchaPublicKey}
								ref={recaptchaRef}
								onChange={handleRecaptchaChange}
							/>
						</SimpleGrid>
						<Divider mt="1em" />
					</ModalBody>
					<ModalFooter pt="0">
						<Box width="100%">
							<ErrorMessage message={error} isNotForm />
							<Flex>
								<Text color="red">*</Text>&nbsp;Indicates required field
							</Flex>
							<Button
								marginTop="7px"
								colorScheme="blue"
								w="100%"
								onClick={handleSubmit}
								disabled={!!error || isSending}
								title={error || ""}
							>
								Send Message
							</Button>
						</Box>
					</ModalFooter>
				</ModalContent>
			</Modal>
			<StatusModal
				title="Error"
				message={`An unexpected error occurred. Make sure you have filled out all fields. If
						you repeatedly get this error, contact us at ${config.SiteHelpEmail}.`}
				isOpen={messageSendError}
				onClose={useCallback(() => setMessageSendError(false), [])}
				status="error"
			></StatusModal>
		</>
	);
});

function getFormErrors(data: IPostMessageModel, emailValid: boolean): string {
	if (
		data.Name.trim() === "" ||
		data.Email.trim() === "" ||
		data.OrgName.trim() === "" ||
		data.Message.trim() === "" ||
		data.CaptchaResponse.trim() === ""
	) {
		return "Complete required fields.";
	}

	if (!emailValid) {
		return "Enter a valid email address.";
	}

	return "";
}
