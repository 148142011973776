/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Search input component with a search field and clear button.
 * @module Epic.AppOrchard.Core.SearchInput
 */

import { SearchIcon } from "@chakra-ui/icons";
import {
	IconButton,
	Input,
	InputGroup,
	InputGroupProps,
	InputProps,
	InputRightElement,
} from "@chakra-ui/react";
import React, { FC, memo, useCallback, useState } from "react";
import { FiX } from "react-icons/fi";

interface IOnChangeCallback {
	(searchTerm: string): void;
}

export interface ISearchInputProps {
	placeholder: string;
	onChangeCallback: IOnChangeCallback;
	inputGroupProps?: InputGroupProps;
	inputProps?: InputProps &
		React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;
	cancelButtonId?: string;
	/** whether to show a search button */
	showSearchButton?: boolean;
	/** Callback to invoke when search button clicked */
	onSearchClicked?: () => void;
	/** Callback to invoke when clear button clicked */
	onClearClicked?: () => void;
	/** whether all interactive elements (buttons, inputs) should be disabled (e.g. while searching) */
	fullyDisabled?: boolean;
	/** whether search button should be disabled */
	searchDisabled?: boolean;
	/** starting search term value */
	initialSearchTerm?: string;
}

/** Search input component with a search field and clear button. */
export const SearchInput: FC<ISearchInputProps> = memo((props: ISearchInputProps) => {
	const {
		placeholder,
		onChangeCallback,
		inputGroupProps,
		inputProps,
		cancelButtonId,
		showSearchButton,
		onSearchClicked,
		onClearClicked,
		fullyDisabled,
		searchDisabled,
		initialSearchTerm,
	} = props;

	const [searchTerm, setSearchTerm] = useState<string>(initialSearchTerm ?? "");
	const emptyVal = "";

	const handleClearSearchTerm = useCallback(() => {
		setSearchTerm(emptyVal);
		onChangeCallback(emptyVal);
		if (onClearClicked !== undefined) {
			onClearClicked();
		}
	}, [onChangeCallback, onClearClicked]);

	const handleChangeSearchTerm = useCallback(
		// Can't import type for FormControlEvent that is actually used for these FormControl components, so using any here
		(event: React.ChangeEvent<any>) => {
			const newVal = event.target.value || "";
			setSearchTerm(newVal);
			onChangeCallback(newVal);
		},
		[onChangeCallback],
	);

	return (
		<InputGroup {...inputGroupProps}>
			<Input
				role="searchbox"
				placeholder={placeholder}
				onChange={handleChangeSearchTerm}
				value={searchTerm}
				autoComplete="off"
				background="white"
				disabled={fullyDisabled}
				paddingEnd="20"
				maxLength={200}
				{...inputProps}
			/>
			<InputRightElement w="unset">
				{searchTerm && (
					<IconButton
						onClick={handleClearSearchTerm}
						disabled={fullyDisabled}
						title="Clear search field"
						aria-label="Clear search field"
						icon={<FiX />}
						size="sm"
						h="1.75rem"
						mr="5px"
						id={cancelButtonId}
					></IconButton>
				)}

				{showSearchButton && (
					<IconButton
						onClick={onSearchClicked}
						disabled={!searchTerm || fullyDisabled || searchDisabled}
						title="Search"
						aria-label="Search"
						icon={<SearchIcon />}
						size="sm"
						h="1.75rem"
						mr="5px"
					></IconButton>
				)}
			</InputRightElement>
		</InputGroup>
	);
});
