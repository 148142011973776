/**
 * @copyright Copyright 2022 Epic Systems Corporation
 * @file Link button without any link styling
 * @module Epic.AppOrchard.Core.LinkButton
 */

import { Box, Button, ButtonProps, forwardRef } from "@chakra-ui/react";
import { AOLink } from "ao/components/Core";
import React, { memo, Ref } from "react";

/** Link button without any link styling */
export const LinkButton = memo(
	forwardRef((props: ButtonProps, ref: Ref<HTMLButtonElement>) => {
		const { color, ...rest } = props;
		return (
			<Button
				as={rest.disabled ? Box : AOLink} //prevent the link from functioning when the button is disabled
				color={color || "black"}
				textDecoration="none"
				ref={ref}
				{...rest}
			></Button>
		);
	}),
);
