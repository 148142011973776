/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Panel wrapper component
 * @module Epic.AppOrchard.Core.Panel
 */

import {
	Accordion,
	AccordionButton,
	AccordionIcon,
	AccordionItem,
	AccordionPanel,
	Box,
	Text,
} from "@chakra-ui/react";
import React, { memo } from "react";

interface IProps {
	/**The label for the expanding panel header */
	name: string;
	/**Optional text that is displayed after the name. */
	subTitle?: string;
	/**Contents of panel */
	children: React.ReactNode;
}

export const Panel: React.FunctionComponent<IProps> = memo(
	(props: IProps): JSX.Element => {
		const { name, subTitle, children } = props;

		return (
			<Accordion allowToggle maxWidth="none" margin="0" padding="0">
				<AccordionItem borderWidth="1px" borderRadius="4px">
					<AccordionButton backgroundColor="rgb(239, 239, 239)">
						<Box flex="1" textAlign="left">
							<Text fontSize="lg" fontWeight="500">
								{name}
								{subTitle && (
									<Box as="span" fontSize="md" fontWeight="normal">
										&nbsp;{"-"}&nbsp;
										{subTitle}
									</Box>
								)}
							</Text>
						</Box>
						<AccordionIcon />
					</AccordionButton>

					<AccordionPanel>{children}</AccordionPanel>
				</AccordionItem>
			</Accordion>
		);
	},
);
