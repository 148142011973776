/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Trigger element for DropdownMenu component
 * @module Epic.AppOrchard.Core.DropdownTrigger
 */
import React, { FC, memo, useContext, useEffect } from "react";
import { DropdownMenuContext } from "./DropdownMenu";

interface IProps {
	children: React.ReactElement;
}

/** trigger element for DropdownMenu  */
export const DropdownTrigger: FC<IProps> = memo((props: IProps) => {
	const { children } = props;
	const { setDropdownTrigger } = useContext(DropdownMenuContext);

	useEffect(() => {
		setDropdownTrigger && setDropdownTrigger(children);
	}, [children, setDropdownTrigger]);

	return null;
});
