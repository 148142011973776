/**
 * @copyright Copyright 2024-2024 Epic Systems Corporation
 * @file Header for a TablePage
 * @module Epic.AppOrchard.Core.TablePage
 */

import { InfoIcon } from "@chakra-ui/icons";
import { Box, BoxProps, Heading } from "@chakra-ui/react";
import { AOTooltip } from "ao/components/Core";
import { memo, ReactNode } from "react";

interface IProps {
	text: string;
	children?: ReactNode;
	tooltipContent?: ReactNode;
	tooltipLink?: string;
	tooltipIcon?: ReactNode;
}

type Props = IProps & BoxProps;

/** Header for a TablePage */
export const TablePageHeader = memo((props: Props) => {
	const { text, children, tooltipContent, tooltipLink, tooltipIcon, ...rest } = props;

	return (
		<Box
			display={{ base: "flex", md: "inline-flex" }}
			pt={{ base: "0.5rem", md: 0 }}
			flexWrap="wrap"
			gridColumnGap="1.5rem"
			gridRowGap="0.5rem"
			{...rest}
		>
			<Heading as="h2" alignSelf="center" flexGrow="1">
				{text}

				{tooltipContent && (
					<AOTooltip
						helptext={tooltipContent}
						link={tooltipLink}
						boxProps={{
							verticalAlign: "top",
							ml: "0.25rem",
						}}
					>
						{tooltipIcon || <InfoIcon color="mediumseagreen" />}
					</AOTooltip>
				)}
			</Heading>
			{children}
		</Box>
	);
});
