/**
 * @copyright Copyright 2020 Epic Systems Corporation
 * @file A utility to make standard RESTful API calls to the backend
 * @author Erv Walter
 * @module Epic.Template.Utils.Api
 */

// adapted from https://github.com/center-key/fetch-json which doesn't have Typescript support

import { Shell } from "ao/components/Frame/shell";
import { getApiBaseUrl, getApiFullUrl } from "./helpers";
import { debug } from "./logging";

/**
 * The options for an API request
 */
// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface IOptions extends RequestInit {}

/**
 * The data for a log entry
 */
export interface ILogEntry {
	/** The date of the API call */
	date: string;
	/** Whether the log entry is an API request or an API response */
	type: "request" | "response";
	/** What type of API call it was */
	method?: string;
	/** The URL of the API call */
	url: string;
	/** Whether the API call was successful */
	successful?: boolean;
	/** The HTTP status code of the API call */
	status?: number;
	/** The HTTP Status text of the API call */
	statusText?: string;
	/** The content type of the API call response */
	contentType?: string | null;
}

/** Function signature for a logger */
type Logger = (entry: ILogEntry) => void;

/**
 * A utility class to help make standard RESTful API calls to the backend
 */
class FetchJson {
	private _baseUrl: string;
	private _options: IOptions;
	private _logger: Logger | undefined;

	/**
	 * Creates an instance of FetchJson.
	 * @param baseUrl The base URL to prefix on all API calls
	 * @param options Optional option overrides (see standard fetch options)
	 */
	constructor(baseUrl: string, options: IOptions = {}) {
		this._baseUrl = baseUrl;
		this._options = options;
	}

	/**
	 * Private implementation for making API requests
	 *
	 * @private
	 * @param method GET, POST, etc
	 * @param url URL to make the request to
	 * @param [data] Either an object to be serialized in the body or to be put into the query string for GET requests
	 * @returns A promise that
	 */
	private async request(method: string, url: string, data?: unknown): Promise<any> {
		const settings: IOptions = {
			method: method.toUpperCase(),
			credentials: "same-origin",
			...this._options,
		};

		url = getApiFullUrl(url, this._baseUrl);

		const isGetRequest = settings.method === "GET";
		const headers: Record<string, string> = {
			accept: "application/json",
		};
		if (!isGetRequest && data) {
			headers["Content-Type"] = "application/json";
		}
		settings.headers = Object.assign(headers, this._options && this._options.headers);
		if (data) {
			if (isGetRequest) {
				const params = data as Record<string, string>;
				const paramKeys = Object.keys(params);
				if (paramKeys && paramKeys.length) {
					url =
						url +
						(url.includes("?") ? "&" : "?") +
						paramKeys.map((key) => `${key}=${encodeURIComponent(params[key])}`).join("&");
				}
			} else {
				settings.body = JSON.stringify(data);
			}
		}
		const logUrl = url.replace(/[?].*/, ""); //security: prevent logging url parameters

		const toJson = async (response: Response): Promise<any> => {
			const contentType = response.headers.get("content-type");
			if (this._logger) {
				this._logger({
					date: new Date().toISOString(),
					type: "response",
					method: settings.method,
					url: logUrl,
					successful: response.ok,
					status: response.status,
					statusText: response.statusText,
					contentType: contentType,
				});
			}
			if (!response.ok) {
				const textResponse = await response.text();
				if (Shell.ForceRedirectOnError(textResponse)) {
					return;
				}

				throw Error(`API Call Failed, Status: ${response.status} StatusText: ${response.statusText}`);
			}
			const isJson = contentType && /json|javascript/.test(contentType); //match "application/json" or "text/javascript"
			if (!isJson) {
				throw Error(`API Return Not Json`);
			}
			const result = await response.json();
			if (Shell.ForceLogoutOrRefreshSession(result)) {
				return {};
			}
			return result;
		};

		if (this._logger) {
			this._logger({
				date: new Date().toISOString(),
				type: "request",
				method: settings.method,
				url: logUrl,
			});
		}
		return await fetch(url, settings).then(toJson);
	}

	/**
	 * Make a GET api request
	 *
	 * @template T The type of object that will be returned by the API call
	 * @param url The URL for the request
	 * @param [params] Key/value pairs to be included on the querystring
	 * @returns The object returned by the API call
	 */
	public async get<T>(url: string, params?: Record<string, string>): Promise<T> {
		return await this.request("GET", url, params);
	}

	/**
	 * Make a POST api request
	 *
	 * @template T The type of the object that will be returned by the API call
	 * @param url The URL for the request
	 * @param [data] An object to be serialized and included in the request body
	 * @returns The object returned by the API call
	 */
	public async post<T>(url: string, data?: unknown): Promise<T> {
		return await this.request("POST", url, data);
	}

	/**
	 * Make a PUT api request
	 *
	 * @template T The type of the object that will be returned by the API call
	 * @param url The URL for the request
	 * @param [data] An object to be serialized and included in the request body
	 * @returns The object returned by the API call
	 */
	public async put<T>(url: string, data?: unknown): Promise<T> {
		return await this.request("PUT", url, data);
	}

	/**
	 * Make a PATCH api request
	 *
	 * @template T The type of the object that will be returned by the API call
	 * @param url The URL for the request
	 * @param [data] An object to be serialized and included in the request body
	 * @returns The object returned by the API call
	 */
	public async patch<T>(url: string, data?: unknown): Promise<T> {
		return await this.request("PATCH", url, data);
	}

	/**
	 * Make a DELETE api request
	 *
	 * @template T The type of the object that will be returned by the API call
	 * @param url The URL for the request
	 * @param [data] An object to be serialized and included in the request body
	 * @returns The object returned by the API call
	 */
	public async delete<T>(url: string, data?: unknown): Promise<T> {
		return await this.request("DELETE", url, data);
	}

	/**
	 * Enable logging for API requests
	 *
	 * @param booleanOrFn True to use console.log, or a function to be defined for the logger
	 */
	public enableLogger(booleanOrFn: boolean | Logger): void {
		const isFn = typeof booleanOrFn === "function";
		// eslint-disable-next-line no-console
		this._logger = isFn ? (booleanOrFn as Logger) : booleanOrFn === false ? undefined : console.log;
	}
}

/**
 * A utility class to help download File from the backend
 */
class FetchFile {
	private _baseUrl: string;
	private _options: IOptions;
	private _logger: Logger | undefined;

	/**
	 * Creates an instance of FetchFile.
	 * @param baseUrl The base URL to prefix on all API calls
	 * @param options Optional option overrides (see standard fetch options)
	 */
	constructor(baseUrl: string, options: IOptions = {}) {
		this._baseUrl = baseUrl;
		this._options = options;
	}

	/**
	 * Private implementation for making API requests
	 *
	 * @private
	 * @param method GET, POST, etc
	 * @param url URL to make the request to
	 * @param accept accept http header for GET requests
	 * @param fileName what to name the downloaded file for GET requests
	 * @param [data] An object to be serialized in the body, the query string for GET requests, or the formData object for POST requets
	 * @returns A promise that
	 */
	private async request(
		method: string,
		url: string,
		accept: string,
		fileName: string,
		data?: unknown,
	): Promise<any> {
		const settings: IOptions = {
			method: method.toUpperCase(),
			credentials: "same-origin",
			...this._options,
		};

		url = getApiFullUrl(url, this._baseUrl);

		const isGetRequest = settings.method === "GET";
		const isPostRequest = settings.method === "POST";

		const headers: Record<string, string> = {
			accept: accept,
		};

		if (!isPostRequest) {
			settings.headers = Object.assign(headers, this._options && this._options.headers);
		}

		if (data) {
			if (isGetRequest) {
				const params = data as Record<string, string>;
				const paramKeys = Object.keys(params);
				if (paramKeys && paramKeys.length) {
					url =
						url +
						(url.includes("?") ? "&" : "?") +
						paramKeys.map((key) => `${key}=${encodeURIComponent(params[key])}`).join("&");
				}
			} else if (isPostRequest) {
				settings.body = data as FormData;
			} else {
				settings.body = JSON.stringify(data);
			}
		}
		const logUrl = url.replace(/[?].*/, ""); //security: prevent logging url parameters

		const toBlob = async (response: Response): Promise<any> => {
			const contentType = response.headers.get("content-type");
			if (this._logger) {
				this._logger({
					date: new Date().toISOString(),
					type: "response",
					method: settings.method,
					url: logUrl,
					successful: response.ok,
					status: response.status,
					statusText: response.statusText,
					contentType: contentType,
				});
			}
			if (!response.ok) {
				const textResponse = await response.text();
				if (Shell.ForceRedirectOnError(textResponse)) {
					return;
				}

				throw Error(`API Call Failed, Status: ${response.status} StatusText: ${response.statusText}`);
			}
			const isJson = contentType && /json|javascript/.test(contentType);

			if (isJson) {
				// might be an indication the session is expired. Try to check.
				const resultText = await response.text();
				try {
					const jsonData = JSON.parse(resultText);
					Shell.ForceLogoutOrRefreshSession(jsonData);
				} catch {
					throw Error(`File API returned invalid JSON`);
				}

				return resultText;
			}
			return response.blob();
		};
		if (this._logger) {
			this._logger({
				date: new Date().toISOString(),
				type: "request",
				method: settings.method,
				url: logUrl,
			});
		}
		if (isGetRequest) {
			return await fetch(url, settings)
				.then(toBlob)
				.then((data) => {
					// download the file
					var a = document.createElement("a");
					a.href = window.URL.createObjectURL(data);
					a.download = fileName;
					a.click();
				});
		} else {
			return await fetch(url, settings).then((response) => response.json());
		}
	}

	/**
	 * Make a GET api request
	 *
	 * @template T The type of object that will be returned by the API call
	 * @param url The URL for the request
	 * @param accept accept HTTP header
	 * @param fileName what to name the downloaded file
	 * @param [params] Key/value pairs to be included on the querystring
	 * @returns The object returned by the API call
	 */
	public async get<T>(
		url: string,
		accept: string,
		fileName: string,
		params?: Record<string, string>,
	): Promise<T> {
		return await this.request("GET", url, accept, fileName, params);
	}

	/**
	 * Make a POST api request
	 *
	 * @template T The type of object that will be returned by the API call
	 * @param url The URL for the request
	 * @param data FormData object with the file
	 * @returns The object returned by the API call
	 */
	public async post<T>(url: string, data: FormData): Promise<T> {
		return await this.request("POST", url, "", "", data);
	}

	/**
	 * Enable logging for API requests
	 *
	 * @param booleanOrFn True to use console.log, or a function to be defined for the logger
	 */
	public enableLogger(booleanOrFn: boolean | Logger): void {
		const isFn = typeof booleanOrFn === "function";
		// eslint-disable-next-line no-console
		this._logger = isFn ? (booleanOrFn as Logger) : booleanOrFn === false ? undefined : console.log;
	}
}

const baseUrl = getApiBaseUrl();
const api = new FetchJson(baseUrl);
export const fileApi = new FetchFile(baseUrl);

if (process.env.NODE_ENV === "development") {
	api.enableLogger((entry) => {
		debug(entry);
	});
}

export default api;
