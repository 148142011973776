/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Error text for error list.
 * @module Epic.AppOrchard.Core.ErrorText
 */

import { Text, TextProps } from "@chakra-ui/react";
import React, { FC } from "react";

type IProps = TextProps;

/*Error text for error list. */
export const ErrorText: FC<IProps> = (props: IProps) => {
	const { key, children, ...rest } = props;

	return (
		<Text key={key} fontSize="15px" color="#CC0000" textAlign="right" {...rest}>
			{children}
		</Text>
	);
};
