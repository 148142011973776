/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Generic helper functions for the Connectors Management UI
 * @module Epic.AppOrchard.ConnectorManagement.Helpers
 */

import { ConnectorManagementActions, ManagementTabs } from "ao/types/connectorManagement";
import { SuccessToastVerbs } from "./data/constants";

export function getTabSpecificText(tab: number): string {
	if (tab === ManagementTabs.Apps) {
		return "App";
	} else {
		return "";
	}
}

export function getEditToastSuccessDescription(action: ConnectorManagementActions, recordId: number) {
	return `App ${recordId} ${SuccessToastVerbs[action]} successfully.`;
}
