/**
 * @copyright Copyright 2023-2023 Epic Systems Corporation
 * @file Input that shows how many characters are remaining
 * @module Epic.AppOrchard.Core.TextInputWithRemainingChars
 */

import { Input, InputProps, Text } from "@chakra-ui/react";
import React, { ChangeEvent, FC, memo, useCallback, useEffect, useState } from "react";

interface IProps {
	/** Max amount of chars allowed. Will be used to drive the remaining chars message and will also
	 * be used as the max-length attribute for the underlying input element */
	maxChars: number;
}

type Props = IProps & InputProps;

export const TextInputWithRemainingChars: FC<Props> = memo((props: Props) => {
	const { maxChars, ...rest } = props;
	const [remainingChars, setRemainingChars] = useState<number>(maxChars);
	const [hasLoaded, setHasLoaded] = useState(false);
	const remainingCharsMessage = `${remainingChars} character${remainingChars !== 1 ? "s" : ""} left`;

	const handleChange = useCallback(
		(ev: ChangeEvent<HTMLInputElement>) => {
			setRemainingChars(maxChars - ev.target.value.length);
			// call any existing change handlers
			if (rest.onChange) {
				rest.onChange(ev);
			}
		},
		[maxChars, rest],
	);

	useEffect(() => {
		if (!hasLoaded && rest.value) {
			setRemainingChars(typeof rest.value === "string" ? maxChars - rest.value.length : maxChars);
			setHasLoaded(true);
		}
	}, [hasLoaded, maxChars, rest.value]);

	return (
		<>
			<Input {...rest} maxLength={maxChars} onChange={handleChange} />
			<Text fontSize="0.85em" ml="3px" mt="2px" fontWeight={remainingChars === 0 ? "bold" : "normal"}>
				{remainingCharsMessage}
			</Text>
		</>
	);
});
