/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Icon tooltip component with some features for accessibility and use on mobile.
 * @module Epic.AppOrchard.Core.AOTooltip
 */

import {
	As,
	Box,
	BoxProps,
	Button,
	ButtonProps,
	forwardRef,
	HTMLChakraProps,
	ThemingProps,
	Tooltip,
	useDisclosure,
	UseTooltipProps,
	VisuallyHidden,
} from "@chakra-ui/react";
import React, { memo } from "react";
import { AOLink } from "./AOLink";

interface IProps {
	/* Helptext shown in the tooltip. Can be either a raw string or React components */
	helptext: React.ReactNode;
	/* Allows passing different icons as children */
	children: React.ReactNode;
	/* color of the tooltip background */
	iconColor?: string;
	cssClasses?: string;
	/**link to open when clicking */
	link?: string;
	boxProps?: BoxProps;
	/** whether to render as a button. If so, the tooltip will not be triggered on click. */
	asButton?: boolean;
	/** props used for the button. Used only if asButton=true */
	buttonProps?: ButtonProps;
	/** component to render the tooltip container as */
	as?: As;
}

export interface ITooltipWithoutChildrenProps
	extends HTMLChakraProps<"div">,
		ThemingProps<"Tooltip">,
		UseTooltipProps {}

type Props = IProps & ITooltipWithoutChildrenProps;

/** Icon tooltip component with some features for accessibility and use on mobile. */
export const AOTooltip = memo(
	forwardRef((props: Props, ref) => {
		const { helptext, children, iconColor, link, boxProps, asButton, buttonProps, as, ...rest } = props;
		const defaultIconColor = "#2b7cce";
		const defaultBackgroundColor = "#dafada";
		const defaultFontSize = "md";
		const defaultTextColor = "black";

		const { isOpen, onToggle: handleToggle, onOpen, onClose } = useDisclosure();

		return (
			<>
				<Tooltip
					isOpen={isOpen}
					hasArrow
					label={helptext}
					{...rest}
					bg={rest.backgroundColor || defaultBackgroundColor}
					color={rest.color || defaultTextColor}
					fontSize={rest.fontSize || defaultFontSize}
				>
					{as ? (
						<Box
							as={as}
							onClick={handleToggle} // for touch interfaces
							onFocus={onOpen} // for keyboard navigation
							onBlur={onClose} // for keyboard navigation
							onMouseEnter={onOpen} // for mouse users
							onMouseLeave={onClose} // for mouse users
							tabIndex={0}
							ref={ref}
							{...boxProps}
							{...rest}
						>
							{children}
						</Box>
					) : asButton ? (
						<Button
							color={iconColor || defaultIconColor}
							fontSize="15px"
							onFocus={onOpen} // for keyboard navigation
							onBlur={onClose} // for keyboard navigation
							onMouseEnter={onOpen} // for mouse users
							onMouseLeave={onClose} // for mouse users
							variant="unstyled"
							borderRadius={0}
							{...buttonProps}
						>
							{children}
						</Button>
					) : (
						<Box
							as={link ? AOLink : "span"}
							url={link ?? ""}
							target={"_blank"}
							color={iconColor || defaultIconColor}
							display={boxProps?.display || "inline-block"}
							{...boxProps}
							fontSize="15px"
							onClick={handleToggle} // for touch interfaces
							onFocus={onOpen} // for keyboard navigation
							onBlur={onClose} // for keyboard navigation
							onMouseEnter={onOpen} // for mouse users
							onMouseLeave={onClose} // for mouse users
							tabIndex={0}
						>
							{children}
						</Box>
					)}
				</Tooltip>
				{isOpen && <VisuallyHidden role="alert">{helptext}</VisuallyHidden>}
			</>
		);
	}),
);
