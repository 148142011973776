/**
 * @copyright Copyright 2022-2024 Epic Systems Corporation
 * @file Filters panel comprising of filter controls
 * @module Epic.AppOrchard.Core.Filter.FilterPanel
 */

import { Box, Button, Flex, VStack } from "@chakra-ui/react";
import { SearchInput } from "ao/components/Core";
import { useDebounce } from "ao/hooks";
import { IFilterDefinition, IFilterListItem } from "ao/types";
import { FC, useEffect, useRef, useState } from "react";
import { FaUndo } from "react-icons/fa";
import { FilterControl } from "./FilterControl";

export interface IFilterPanelProps {
	filters: IFilterListItem[];
	filterDefinitions: IFilterDefinition[];
	searchText?: string;
	handleUpdateFilter: (filter: IFilterListItem) => void;
	handleClearFilter: (filterKey: string) => void;
	handleClearAllListParameters: () => void;
	showSearch?: boolean;
	minSearchChars?: number;
	setColsWithFilter?: boolean;
	filterToColumnMapping?: Record<string, string>;
}

/** filters panel component */
export const FilterPanel: FC<IFilterPanelProps> = (props: IFilterPanelProps) => {
	const {
		filters,
		filterDefinitions,
		searchText,
		handleUpdateFilter,
		handleClearFilter,
		handleClearAllListParameters,
		showSearch,
		minSearchChars,
		setColsWithFilter,
		filterToColumnMapping,
	} = props;

	const shouldShowSearch = showSearch === undefined || showSearch;
	const [search, setSearch] = useState<string>(filters.find((f) => f.key === "search")?.value ?? "");
	const debouncedSearch = useDebounce<string>(search, 500);
	const searchInputRef = useRef<HTMLInputElement>(null);

	const minSearchLength = !minSearchChars ? 3 : minSearchChars;

	useEffect(() => {
		if (debouncedSearch.length >= minSearchLength || debouncedSearch.length === 0) {
			handleUpdateFilter({ key: "search", value: debouncedSearch, isExcluded: false });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [debouncedSearch]);

	return (
		<Box>
			<VStack spacing="2">
				<Box width="100%">
					<Flex>
						<Button
							width="100%"
							variant="outline"
							onClick={() => {
								handleClearAllListParameters();
								setSearch("");
							}}
							title="Reset all filters"
							leftIcon={<FaUndo />}
						>
							Reset Filters
						</Button>
					</Flex>
				</Box>
				{shouldShowSearch && (
					<Box w="full" mb={2}>
						<SearchInput
							placeholder="Enter text to search for"
							onChangeCallback={setSearch}
							inputProps={{
								ref: searchInputRef,
								value: search,
								title: searchText || "",
							}}
						/>
						<Box aria-live="polite">
							{debouncedSearch.length > 0 && debouncedSearch.length < minSearchLength ? (
								<Box textAlign="right" textColor="red">
									Enter at least {minSearchLength} characters to search.
								</Box>
							) : (
								""
							)}
						</Box>
					</Box>
				)}
				{filterDefinitions
					.filter((filterDef) => !filterDef.hidden)
					.map((filterDef) => {
						const filter: IFilterListItem = filters.find(
							(filter) => filter.key === filterDef.key,
						) || {
							key: filterDef.key,
							isExcluded: false,
						};
						return (
							<FilterControl
								key={filterDef.key}
								filterDef={filterDef}
								filterListItem={filter}
								handleUpdateFilter={handleUpdateFilter}
								handleClearFilter={handleClearFilter}
								setColsWithFilter={setColsWithFilter}
								filterToColumnMapping={filterToColumnMapping}
							></FilterControl>
						);
					})}
			</VStack>
		</Box>
	);
};
