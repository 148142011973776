/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Button for a customer developer to request a change for an app.
 * @module Epic.AppOrchard.Core.RequestCustomerAppChangeButton
 */

import {
	Alert,
	AlertIcon,
	As,
	Box,
	Button,
	FormControl,
	FormLabel,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Select,
	useToast,
	VStack,
} from "@chakra-ui/react";
import { ISuccessHandler, useAsync } from "@epic/react-async-hook";
import { config } from "ao/appConfig";
import { updateCustomerAppChangeRequest } from "ao/data";
import { CustomerAppChangeRequestType, ICustomerAppLicense, IUserSecurity } from "ao/types";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { ChangeEvent, FC, memo, useCallback, useState } from "react";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { ConfirmationModal, StatusAlert, TextareaWithRemainingChars } from "..";

//#endregion

type OnSuccessType = (
	appId: number,
	orgId: number,
	changeType: CustomerAppChangeRequestType | null,
	changeComment: string | null,
) => void;

interface IProps {
	orgId: number;
	license: ICustomerAppLicense;
	/** button-like component to render the button as */
	buttonAs?: As;
	successHandler: OnSuccessType;
	userSecurity: IUserSecurity;
}

/** Button for a customer developer to request a change for an app */
export const RequestCustomerAppChangeButton: FC<IProps> = memo((props: IProps) => {
	//#region state and handlers
	const { orgId, buttonAs, successHandler, license, userSecurity } = props;
	const hasSavedRequest = license.changeRequestType !== null;
	const hasAvailableChanges = license.availableChanges.length > 0;

	let changeRequestButtonTooltip = "";
	if (hasSavedRequest) {
		changeRequestButtonTooltip = "Update or remove this pending change request.";
	} else if (userSecurity.canActivateClients) {
		changeRequestButtonTooltip =
			"Request changes for this app. You already have security to make these changes yourself but you can use this workflow if you want to request changes from another user with this security.";
	} else {
		// no existing request, user doesn't have security to make changes themselves
		changeRequestButtonTooltip =
			"Request changes for this app from someone from your organization who can activate apps or make changes to apps that have been activated.";
	}

	const [changeType, setChangeType] = useState<CustomerAppChangeRequestType>(
		license.changeRequestType ||
			(hasAvailableChanges
				? license.availableChanges[0].value
				: CustomerAppChangeRequestType.Undefined),
	);
	const handleChangeTypeChange = useCallback(
		(event: ChangeEvent<HTMLSelectElement>) => setChangeType(parseInt(event.target.value)),
		[],
	);
	const [changeComment, setChangeComment] = useState<string | null>(null);
	const handleSetChangeComment = useCallback((ev: ChangeEvent<HTMLTextAreaElement>) => {
		setChangeComment(ev.target.value);
	}, []);

	const isDirty =
		changeType !== license.changeRequestType || changeComment !== license.changeRequestComment;

	const [hasError, setHasError] = useState<boolean>(false);
	const [showConfirmationDelete, setShowConfirmationDelete] = useState<boolean>(false);
	const handleToggleConfirmDelete = useCallback(
		(show: boolean) => () => setShowConfirmationDelete(show),
		[],
	);

	const [showChangeRequestModal, setShowChangeRequestModal] = useState<boolean>(false);
	const handleToggleChangeRequestModal = useCallback(
		(show: boolean) => () => setShowChangeRequestModal(show),
		[],
	);

	const handleDeleteClicked = useCallback(() => {
		setShowConfirmationDelete(true);
		setShowChangeRequestModal(false);
	}, []);

	// populate form with any saved values from the app when it opens
	const handleOpenChangeRequestModal = useCallback(() => {
		setChangeType(license.changeRequestType || license.availableChanges[0].value);
		setChangeComment(license.changeRequestComment || null);
		setShowChangeRequestModal(true);
	}, [license.availableChanges, license.changeRequestComment, license.changeRequestType]);

	// success action
	const toast = useToast();
	const handleSaveSuccess = useCallback<ISuccessHandler<typeof updateCustomerAppChangeRequest>>(
		(_response, extras) => {
			const updateRequest = extras.params[0];
			setHasError(false);
			setShowChangeRequestModal(false);
			successHandler(
				license.clientApplication_Id,
				orgId,
				updateRequest.ChangeType,
				updateRequest.Comment,
			);
			toast({
				title: "Success",
				description: "Changes successfully saved",
				status: "success",
				duration: config.ToastDuration,
				isClosable: true,
			});
		},
		[license.clientApplication_Id, orgId, successHandler, toast],
	);

	// create save request handlers
	const [savingState, updateRequestFn] = useAsync(updateCustomerAppChangeRequest, {
		executeImmediately: false,
		displayName: "updateCustomerAppChangeRequest",
		// `extras` contains a `params` field which has the params passed to the API call
		onSuccess: (response, extras) => handleSaveSuccess(response, extras),
		onFailure: () => {
			setShowChangeRequestModal(true);
			setHasError(true);
		},
	});

	const handleSaveClick = useCallback(() => {
		updateRequestFn({
			AppId: license.clientApplication_Id,
			ChangeType: changeType,
			Comment: changeComment,
		});
	}, [license.clientApplication_Id, changeComment, changeType, updateRequestFn]);

	const handleConfirmDeleteClick = useCallback(() => {
		updateRequestFn({ AppId: license.clientApplication_Id, ChangeType: null, Comment: null });
		setShowConfirmationDelete(false);
	}, [license.clientApplication_Id, updateRequestFn]);

	const isSaving = isInFlight(savingState);
	//#endregion

	const ButtonComponent = buttonAs || Button;
	if (!hasAvailableChanges) {
		return null;
	} else {
		return (
			<>
				<ButtonComponent
					colorScheme="blue"
					title={changeRequestButtonTooltip}
					aria-label={changeRequestButtonTooltip}
					leftIcon={<BiMessageRoundedDetail size={20} />}
					loadingText="Saving"
					isLoading={isSaving}
					onClick={handleOpenChangeRequestModal}
				>
					{hasSavedRequest ? "Update Change Request" : "Request Change"}
				</ButtonComponent>
				<Modal
					isOpen={showChangeRequestModal}
					onClose={handleToggleChangeRequestModal(false)}
					size="2xl"
				>
					<ModalOverlay />
					<ModalContent>
						<ModalHeader>Request Changes</ModalHeader>
						<ModalCloseButton />
						<ModalBody>
							{userSecurity.canActivateClients && (
								<Alert status="info" mb="1em">
									<AlertIcon />
									Since you have security to activate apps created by your organization you
									don't need to request a change, you can make the change directly.
								</Alert>
							)}
							<FormControl id="ChangeType">
								<FormLabel>Change Type</FormLabel>
								<Select value={changeType} onChange={handleChangeTypeChange}>
									{license.availableChanges.map((change) => (
										<option
											key={change.value}
											value={change.value}
											title={change.helptext}
										>
											{change.name}
										</option>
									))}
								</Select>
							</FormControl>

							<FormControl mt="2em" id="Comment">
								<FormLabel>Comment</FormLabel>
								<TextareaWithRemainingChars
									maxChars={300}
									value={changeComment || ""}
									onChange={handleSetChangeComment}
									placeholder="Enter additional details about your request as needed"
								/>
							</FormControl>
						</ModalBody>

						<ModalFooter>
							<VStack>
								<Box>
									{hasSavedRequest && (
										<Button
											colorScheme="red"
											mr={2}
											onClick={handleDeleteClicked}
											disabled={isSaving}
											title="Remove this change request. If you did not request this change, the original requestor will be notified."
										>
											Remove Change Request
										</Button>
									)}
									<Button
										loadingText="Saving"
										isLoading={isSaving}
										disabled={isSaving || !isDirty}
										title={
											isDirty
												? ""
												: "Saving is disabled since nothing has been changed."
										}
										colorScheme="blue"
										mr={2}
										onClick={handleSaveClick}
									>
										Save
									</Button>
									<Button
										onClick={handleToggleChangeRequestModal(false)}
										disabled={isSaving}
									>
										Cancel
									</Button>
								</Box>
								{hasError && (
									<Box>
										<StatusAlert
											message="Error saving to the server. It's possible that the change request you chose is no longer valid for this app. Please refresh the page and try again, and contact your Epic representative if this continues to happen."
											status="error"
										/>
									</Box>
								)}
							</VStack>
						</ModalFooter>
					</ModalContent>
				</Modal>
				<ConfirmationModal
					title="Delete Change Request"
					message="Are you sure you want to delete this change request?"
					acceptCaption="Continue"
					cancelCaption="Cancel"
					isOpen={showConfirmationDelete}
					onCancel={handleToggleConfirmDelete(false)}
					onAccept={handleConfirmDeleteClick}
				/>
			</>
		);
	}
});
