/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Connector Management filter definitions
 * @module Epic.AppOrchard.ConnectorManagement.FilterDefinitions
 */

import {
	IFilterDefinition,
	IFilterListItem,
	IListParameters,
	IPagination,
	ISorting,
	YesNoFilterOptions,
} from "ao/types";
import { getStringForYesNoFilter } from "ao/utils/helpers";
import { AppListAdminColumnDefs, AppListColumnDefs } from "./columns";
import { SharedColumnKeys } from "./constants";

const DefaultFilters: IFilterListItem[] = [{ key: "isEpicOnFHIR", value: "0", isExcluded: false }];
const DefaultPagination: IPagination = { page: 1, pageSize: 50 };
const DefaultSorting: ISorting = { sortColumn: SharedColumnKeys.id, sortDirection: 1 };

export const DefaultAppListParameters: IListParameters = {
	filters: DefaultFilters,
	pagination: DefaultPagination,
	sorting: DefaultSorting,
	columns: AppListColumnDefs.filter((c) => c.defaultShow).map((c) => c.key),
};

export const DefaultAppListAdminParameters: IListParameters = {
	filters: DefaultFilters,
	pagination: DefaultPagination,
	sorting: DefaultSorting,
	columns: AppListAdminColumnDefs.filter((c) => c.defaultShow).map((c) => c.key),
};

export interface IConnectorManagementFilterDefinition extends IFilterDefinition {
	lookupKey?: string;
}

export const AppListFilterDefinitions: IConnectorManagementFilterDefinition[] = [
	{
		key: "id",
		name: "App Record Search",
		tooltip: "Search for a record by the App ID or name",
		isMultiResponse: true,
		allowExclude: false,
		includeIdInOption: true,
		lookupKey: "apps",
	},
	{
		key: "search",
		name: "Text Search",
		tooltip: "Search for text within a record",
		isMultiResponse: false,
		allowExclude: false,
		hidden: true,
	},
	{
		key: "appCategories",
		name: "App Categories",
		tooltip: "Filter Apps by App Category",
		isMultiResponse: true,
		allowExclude: true,
		lookupKey: "appCategories",
	},
	{
		key: "reviewStatus",
		name: "Review Status",
		tooltip: "Filter Apps by Review Status",
		isMultiResponse: true,
		allowExclude: true,
		lookupKey: "reviewStatuses",
	},
	{
		key: "reviewer",
		name: "Reviewer",
		tooltip: "Filter Apps by Reviewer",
		isMultiResponse: true,
		allowExclude: true,
		lookupKey: "reviewers",
	},
	{
		key: "isDeleted",
		name: "Soft-Deleted?",
		tooltip: "Filter Soft-Deleted Apps",
		isMultiResponse: false,
		options: (Object.keys(YesNoFilterOptions).filter((v) =>
			isNaN(Number(v)),
		) as (keyof typeof YesNoFilterOptions)[]).map((key) => {
			return {
				id: YesNoFilterOptions[key],
				name: getStringForYesNoFilter(YesNoFilterOptions[key]),
			};
		}),
	},
	{
		key: "isEpicOnFHIR",
		name: "Epic on FHIR Only",
		tooltip: "Filter Apps by Epic on FHIR Status",
		isMultiResponse: false,
		options: (Object.keys(YesNoFilterOptions).filter((v) =>
			isNaN(Number(v)),
		) as (keyof typeof YesNoFilterOptions)[]).map((key) => {
			return {
				id: YesNoFilterOptions[key],
				name: getStringForYesNoFilter(YesNoFilterOptions[key]),
			};
		}),
	},
];
