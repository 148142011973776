/**
 * @copyright Copyright 2021-2023 Epic Systems Corporation
 * @file Standard layout component
 * @module Epic.AppOrchard.StandardLayout
 */

import { Box } from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { useDispatch } from "@epic/react-redux-booster";
import { getRootDependentData, logFromErrorBoundary, RootDependentData } from "ao/data";
import { useShowroomState } from "ao/state/showroom";
import { useSiteInfoState } from "ao/state/siteInfo";
import { useUserOrganizationsState } from "ao/state/userOrganizations";
import { useUserSecurityState } from "ao/state/userSecurity";
import { updateShowroom } from "ao/store-methods/showroom";
import { updateSiteInfo } from "ao/store-methods/siteInfo";
import { updateUserOrganizationsAfterLoad } from "ao/store-methods/userOrganizations";
import { updateUserSecurity } from "ao/store-methods/userSecurity";
import { ITopLevelComponentProps } from "ao/types";
import {
	AMVendorLoginCookie,
	AOTimeZoneCookie,
	AOVendorLoginCookie,
	cookieExists,
	createCookieIfNotExists,
	gaInitialize,
	getCookieValue,
	getFullUrl,
	hoursFromNow,
	userIsInCustomerMode,
	yearsFromNow,
} from "ao/utils/helpers";
import React, { ComponentType, FC, memo, Suspense, useEffect, useMemo, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { Helmet } from "react-helmet-async";
import { BodyContainer, Footer, GAPrivacyPolicy, Header, LogOutWarning, ShowroomHeader } from ".";
import { AsyncPlaceholder, ErrorFallback, MainContentSpinner } from "../Core";
import { ShowroomBodyContainer } from "./BodyContainer";
import { OverallPrivacyPolicy } from "./OverallPrivacyPolicy";
import { Shell } from "./shell";
import { ShowroomFooter } from "./ShowroomFooter";

/**
 * The props interface ;)
 */
interface IProps {
	/**
	 * The component to be rendered as content
	 */
	component: ComponentType<ITopLevelComponentProps>;
	title?: string;
	showFullWidth?: boolean;
}

/**
 * A Standard Layout component that wraps a content component with the application's standard UI shell (header, footer, etc)
 * @param props The props ;)
 */
export const StandardLayout: FC<IProps> = memo((props) => {
	const { component: Component, title, showFullWidth } = props;
	const dispatch = useDispatch();

	//#region state/hooks
	const { userSecurity, hasLoaded: hasLoadedUserSecurity } = useUserSecurityState(
		(selectors) => selectors.getState(),
		[],
	);

	const [hasLoaded, setHasLoaded] = useState<boolean>(false);

	const isShowroom = useMemo(() => {
		return (
			title === "ShowroomListing" ||
			title === "Showroom" ||
			title === "ShowroomStage" ||
			title === "ShowroomSearch" ||
			title === "ShowroomQuestionnaireView" ||
			title === "ShowroomListingAdmin" ||
			title === "ShowroomAdmin" ||
			title === "ShowroomStageAdmin" ||
			title === "ShowroomSearchAdmin" ||
			title === "ShowroomQuestionnaireViewAdmin" ||
			title === "ShowroomError"
		);
	}, [title]);

	const { hasLoaded: hasLoadedUserOrgs } = useUserOrganizationsState(
		(selectors) => selectors.getState(),
		[],
	);

	const { hasLoaded: siteInfoLoaded, siteInfo } = useSiteInfoState((selectors) => selectors.getState(), []);

	const { hasLoaded: showroomLoaded, stageTree } = useShowroomState(
		(selectors) => selectors.getState(),
		[],
	);

	const [LoadingPlaceholder, load] = useAsync(getRootDependentData, AsyncPlaceholder, {
		initialArguments: [
			!hasLoadedUserSecurity,
			!hasLoadedUserOrgs,
			!siteInfoLoaded,
			!showroomLoaded && isShowroom,
		],
		executeImmediately:
			!hasLoadedUserSecurity ||
			!hasLoadedUserOrgs ||
			!siteInfoLoaded ||
			(!showroomLoaded && isShowroom),
		displayName: "getRootDependentData",
		onSuccess: (data: RootDependentData) => {
			const [userSecurity, userOrganizations, loadedSiteInfo, loadedShowroomInfo] = data;
			if (userSecurity) {
				userSecurity.isInCustomerMode = userIsInCustomerMode();
				updateUserSecurity(dispatch, userSecurity);
			}
			if (userOrganizations) {
				updateUserOrganizationsAfterLoad(dispatch, userOrganizations);
			}
			if (loadedSiteInfo) {
				updateSiteInfo(dispatch, loadedSiteInfo);
				gaInitialize(loadedSiteInfo.googleAnalyticsTrackingId);
			}

			if (loadedShowroomInfo) {
				updateShowroom(dispatch, loadedShowroomInfo);
			}
			setHasLoaded(true);
		},
	});

	useEffect(() => {
		if (userSecurity.userId && siteInfoLoaded) {
			Shell.InitializeContext(siteInfo.userInactivityLogoutTimeInMinutes);
		}

		createCookieIfNotExists(
			AOTimeZoneCookie,
			new Date().getTimezoneOffset().toString(),
			hoursFromNow(1).toUTCString(),
		);

		if (cookieExists(AOVendorLoginCookie)) {
			createCookieIfNotExists(
				AMVendorLoginCookie,
				getCookieValue(AOVendorLoginCookie)!,
				yearsFromNow(1).toUTCString(),
				".epic.com",
			);
		}
	}, [siteInfo.userInactivityLogoutTimeInMinutes, siteInfoLoaded, userSecurity.userId]);

	useEffect(() => {
		//this should be handled by the server, but just in case something goes wrong, we'll also handle it here
		if (isShowroom && siteInfoLoaded) {
			if (siteInfoLoaded && !siteInfo.isGalleryEnabled) {
				window.location.href = getFullUrl("/Home/Index");
			}

			if (hasLoaded && !showroomLoaded) {
				load(false, false, false, true);
			}
		}
	}, [hasLoaded, isShowroom, load, showroomLoaded, siteInfo.isGalleryEnabled, siteInfoLoaded]);

	//#endregion
	const spinner = <MainContentSpinner mt="25%" fullPage />;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<meta http-equiv="X-UA-Compatible" content="IE=10" />
				<meta name="google" content="notranslate" />
				<script src="https://code.jquery.com/jquery-3.6.1.min.js"></script>
				<script
					type="text/javascript"
					src="https://userweb.epic.com/Scripts/UserWeb.QuickSignin.js"
				/>
				<link
					rel="stylesheet"
					type="text/css"
					href="https://userweb.epic.com/Styles/UserWeb.QuickSignIn.css"
				/>
			</Helmet>
			{isShowroom ? (
				<>
					<ShowroomHeader
						userSecurity={userSecurity}
						hasLoadedUserSecurity={hasLoadedUserSecurity}
						stageTree={stageTree}
						title={title}
					/>
					<ShowroomBodyContainer>
						<LoadingPlaceholder inProgressIndicator={spinner}>
							<Suspense fallback={spinner}>
								<ErrorBoundary
									FallbackComponent={ErrorFallback}
									onError={logFromErrorBoundary}
								>
									<Component userSecurity={userSecurity} />
								</ErrorBoundary>
							</Suspense>
						</LoadingPlaceholder>
						<ShowroomFooter userSecurity={userSecurity} />
					</ShowroomBodyContainer>
					<GAPrivacyPolicy />
				</>
			) : (
				<>
					<Header useFullWidth={!!showFullWidth} userSecurity={userSecurity} />
					<BodyContainer useFullWidth={!!showFullWidth}>
						{userSecurity.showWhatsNew && <Box pt="1.5em" />}
						<LoadingPlaceholder inProgressIndicator={spinner}>
							<Suspense fallback={spinner}>
								<ErrorBoundary
									FallbackComponent={ErrorFallback}
									onError={logFromErrorBoundary}
								>
									<Component userSecurity={userSecurity} />
								</ErrorBoundary>
							</Suspense>
						</LoadingPlaceholder>
					</BodyContainer>
					<GAPrivacyPolicy />
					<Footer userSecurity={userSecurity} />
				</>
			)}

			<LogOutWarning />
			<OverallPrivacyPolicy userSecurity={userSecurity} />
		</>
	);
});
