/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Showroom Questionnaire view route
 * @module Epic.AppOrchard.Routes.showroomQuestionnaireView
 */

import { generatePath } from "react-router";
import { QuestionnaireType } from "../ao/data";
import { IRouteInfo } from "../ao/routes";
import lazyRetry from "../ao/utils/lazy-retry";

/*
 *
 * IMPORTANT! READ THIS!
 *
 * The contents of this file are loaded and executed during the initial load of the application
 * in the browser even if the route is never actually accessed. DO NOT put any additional code
 * in this file, or import any addition modules as doing so would just add extra bloat to the
 * initial load / startup time for the application.
 *
 * IMPORTANT! READ THIS!
 *
 */

const path = process.env.REACT_APP_DEV_URL_PREFIX + "/Questionnaire";

export const getShowroomQuestionnaireUrl = (id: string, type: QuestionnaireType) => {
	return generatePath(`${path}?id=:id&type=type`, { id: id, type: type.toString() });
};

const route: IRouteInfo = {
	path,
	component: lazyRetry(
		() =>
			import(
				"../ao/components/Showroom/frameworkComponents/GalleryListingComponents/AppSections/ShowroomQuestionnaireView"
			),
	),
	title: "ShowroomQuestionnaireView",
};

export default route;
