/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Connector Management List
 * @module Epic.AppOrchard.ConnectorManagement.List
 */

import { Box, Table, TableContainer, Tbody, Thead } from "@chakra-ui/react";
import { StatusAlert } from "ao/components/Core";
import { SortableColumnHeader } from "ao/components/Core/Filter";
import { PaginationControl } from "ao/components/Core/PaginationControl";
import { IColumnDefinition, IPagination, ISiteInfo, ISorting, IUserSecurity } from "ao/types";
import { IConnectorAppListItem, IConnectorManagementLookups } from "ao/types/connectorManagement";
import React, { FC, useEffect } from "react";
import { AppListRow } from "./App/AppListRow";

interface IProps {
	userSecurity: IUserSecurity;
	siteInfo: ISiteInfo;
	lookups: IConnectorManagementLookups;
	records: IConnectorAppListItem[];
	recordTotal: number;
	columns: IColumnDefinition[];
	sorting: ISorting;
	pagination: IPagination;
	handleUpdatePagination: (pagination: IPagination) => void;
	handleUpdateSortColumn: (columnKey: string) => void;
	tabSpecificText: string;
	handleUpdateScrollPosition: (event: React.UIEvent<HTMLElement, UIEvent>) => void;
	tableRef: React.RefObject<HTMLDivElement>;
	scrollPos: number;
}

export const ConnectorManagementList: FC<IProps> = (props: IProps) => {
	const {
		userSecurity,
		siteInfo,
		lookups,
		records,
		recordTotal,
		columns,
		sorting,
		pagination,
		handleUpdatePagination,
		handleUpdateSortColumn,
		tabSpecificText,
		handleUpdateScrollPosition,
		tableRef,
		scrollPos,
	} = props;

	const lastPage = Math.max(1, Math.ceil(recordTotal / pagination.pageSize));

	useEffect(() => {
		if (pagination.page > lastPage && recordTotal) {
			handleUpdatePagination({
				page: lastPage,
				pageSize: pagination.pageSize,
			});
		}
	}, [handleUpdatePagination, lastPage, pagination.page, pagination.pageSize, recordTotal, tableRef]);

	useEffect(() => {
		if (tableRef.current) {
			tableRef.current.scrollLeft = scrollPos;
		}
	});

	return (
		<>
			<Box padding={4}>
				<PaginationControl
					currentPage={pagination.page}
					currentPageSize={pagination.pageSize}
					numPages={lastPage}
					recordName={`${tabSpecificText}s`}
					recordTotal={recordTotal}
					handleUpdatePage={(page: number) => {
						handleUpdatePagination({
							page,
							pageSize: pagination.pageSize,
						});
					}}
					handleUpdatePageSize={(pageSize: number) => {
						handleUpdatePagination({
							page: pagination.page,
							pageSize,
						});
					}}
					showRecordCount
					showPageChooser
				/>
			</Box>
			<Box>
				{records?.length ? (
					<TableContainer ref={tableRef} onScroll={handleUpdateScrollPosition}>
						<Table variant="simple" size="sm">
							<Thead>
								<SortableColumnHeader
									columns={columns}
									sorting={sorting}
									handleUpdateSortColumn={handleUpdateSortColumn}
								/>
							</Thead>
							<Tbody>
								{records.map((record) => (
									<AppListRow
										userSecurity={userSecurity}
										lookups={lookups}
										siteInfo={siteInfo}
										connectorApp={record}
										columns={columns}
									/>
								))}
							</Tbody>
						</Table>
					</TableContainer>
				) : (
					<StatusAlert
						message={`No ${tabSpecificText}s found. Please try adjusting the filters.`}
						status="info"
					/>
				)}
			</Box>
		</>
	);
};

export default ConnectorManagementList;
