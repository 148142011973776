/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file ConnectorManagement constants
 * @module Epic.AppOrchard.ConnectorManagement.Constants
 */

import { ConnectorManagementActions } from "ao/types/connectorManagement";

export enum SharedColumnKeys {
	id = "id",
	trackId = "trackId",
	name = "name",
	reviewStatus = "reviewStatus",
	reviewer = "reviewer",
	reviewedOn = "reviewedOn",
	comment = "comment",
	actionLinks = "actionLinks",
}

export enum AppListColumnKeys {
	clientAppId = "clientAppId",
	appCategories = "appCategories",
}

export enum OrgListColumnKeys {
	organizationId = "organizationId",
	type = "type",
	website = "website",
}

export type ConnectorManagementFilterColumnMap = Record<string, string>;

export const AppListFilterColumnMapping: ConnectorManagementFilterColumnMap = {
	id: SharedColumnKeys.name,
	appCategories: AppListColumnKeys.appCategories,
	reviewStatus: SharedColumnKeys.reviewStatus,
	reviewer: SharedColumnKeys.reviewer,
};

export const OrgListFIlterColumnMappings: ConnectorManagementFilterColumnMap = {
	id: SharedColumnKeys.name,
	type: OrgListColumnKeys.type,
	reviewStatus: SharedColumnKeys.reviewStatus,
	reviewer: SharedColumnKeys.reviewer,
};

export enum ReviewStatus {
	NotSubmitted = 1,
	WaitingForReview = 2,
	InReview = 3,
	Approved = 4,
	Rejected = 5,
	Merged = 6,
}

export const Colors = ["orange", "green", "purple", "pink", "blue"];

export const MaxAppsToReturn = 10;

export const SuccessToastVerbs: {
	[key in ConnectorManagementActions]: string;
} = {
	Save: "saved",
};

export const SuccessToastTitles: {
	[key in ConnectorManagementActions]: string;
} = {
	Save: "Changes Saved",
};

export const FailureToastTitles: {
	[key in ConnectorManagementActions]: string;
} = {
	Save: "Save Error",
};

export const connectorAppAliasFieldTitles = {
	aliases: "Alternative names",
	alias: "An alternative name",
	comment: "Context for the alias",
};

export const vendorReportedDataTitle = "Data sourced from outside Connectors Apps cannot be changed";

export const AliasForDefaultNameId: number = -1;

export const editSpacingY: number = 4;
export const editSpacingX: number = 4;

export const InterfaceEngineCategoryName: string = "Interface Engine";
