/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Button for users to contact someone by triggering an email. For example, the Contact Author or Badger Author workflows.
 * @module Epic.AppOrchard.Core.ContactButton
 */

import { As, Button, ButtonProps, useToast } from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { config } from "ao/appConfig";
import { IGenericAPIResponseWrongCasing } from "ao/data";
import React, { FC, memo, useCallback, useState } from "react";
import { BiMessageRoundedDetail } from "react-icons/bi";
import { ConfirmationModal, StatusModal } from ".";
import { isInFlight } from "../../utils/useAsyncHelpers";

interface IProps {
	/** API call to the server to send the email */
	contactApi: (...args: any[]) => Promise<any>;
	/** arguments to the API call */
	apiArgs: any[];
	buttonCaption: string;
	buttonNotContactedHelptext: string;
	/** shown in success notification and as the title of the button after contacting */
	successMessage: string;
	errorMessage: string;
	confirmModalTitle: string;
	confirmModalPrompt: string;
	/** button-like component to render the button as */
	buttonAs?: As;
	/** ref of the component that receives focus when the modal closes */
	finalFocusRef?: React.RefObject<any>;
}

type Props = IProps & ButtonProps;

export const ContactButton: FC<Props> = memo((props: Props) => {
	const {
		contactApi,
		apiArgs,
		buttonCaption,
		buttonNotContactedHelptext,
		successMessage,
		errorMessage,
		confirmModalTitle,
		confirmModalPrompt,
		buttonAs,
		finalFocusRef,
		...rest
	} = props;

	const [contacted, setContacted] = useState<boolean>(false);
	const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
	const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
	const handleCloseErrorModal = useCallback(() => setShowErrorModal(false), []);

	const toast = useToast();
	const handleContacted = useCallback(
		(result: IGenericAPIResponseWrongCasing) => {
			setContacted(true);
			if (result.Success) {
				toast({
					title: "Success",
					description: successMessage,
					status: "success",
					duration: config.ToastDuration,
					isClosable: true,
				});
			} else {
				toast({
					title: "Error",
					description: errorMessage,
					status: "error",
					duration: config.ToastDuration,
					isClosable: true,
				});
			}
		},
		[errorMessage, successMessage, toast],
	);

	const [contactingState, contactApiFn] = useAsync(contactApi, {
		executeImmediately: false,
		displayName: "contactViaEmail",
		onSuccess: (result) => handleContacted(result),
		onFailure: () => setShowErrorModal(true),
	});

	const handleContactModalOkClicked = useCallback(() => {
		contactApiFn(...apiArgs);
		setShowConfirmation(false);
	}, [apiArgs, contactApiFn]);

	const handleToggleContactConfirm = useCallback((show: boolean) => () => setShowConfirmation(show), []);

	const isContacting = isInFlight(contactingState);
	const ButtonType = buttonAs || Button;

	return (
		<>
			<ButtonType
				colorScheme="blue"
				title={contacted ? successMessage : buttonNotContactedHelptext}
				aria-label={buttonCaption}
				leftIcon={<BiMessageRoundedDetail size={20} />}
				loadingText="Contacting"
				isLoading={isContacting}
				onClick={handleToggleContactConfirm(true)}
				disabled={contacted || rest.isDisabled}
				{...rest}
			>
				{buttonCaption}
			</ButtonType>
			<ConfirmationModal
				title={confirmModalTitle}
				message={confirmModalPrompt}
				acceptCaption="Send"
				cancelCaption="Cancel"
				isOpen={showConfirmation}
				onCancel={handleToggleContactConfirm(false)}
				onAccept={handleContactModalOkClicked}
				finalFocusRef={finalFocusRef}
				isCentered
			/>
			<StatusModal
				title="Error"
				message={errorMessage}
				isOpen={showErrorModal}
				onClose={handleCloseErrorModal}
				status="error"
			></StatusModal>
		</>
	);
});
