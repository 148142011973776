/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file ImplementationGuideLink for widgets
 * @module Epic.AppOrchard.Dashboard.ImplementationGuideLink
 */
import { Link } from "@chakra-ui/react";
import React, { FC, memo } from "react";
import { GrDocumentText } from "react-icons/gr";

interface IProps {
	url: string;
}

/** ImplementationGuideLink component for widgets */
export const ImplementationGuideLink: FC<IProps> = memo((props: IProps) => {
	const { url } = props;
	return (
		<Link href={url} title="View this app's implementation guide" target="_blank">
			<GrDocumentText style={{ display: "inline-block", marginRight: "4px" }} />
			Implementation Guide
		</Link>
	);
});
