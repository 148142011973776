/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Component for creating JWK Set URL
 * Can be reused in AO
 */

import { InputProps, Text } from "@chakra-ui/react";
import { useAsync } from "@epic/react-async-hook";
import { checkJKUEndpoint } from "ao/components/CreateEditApp/data";
import { IGenericAPIResponseWrongCasing } from "ao/data";
import { extractURIScheme, stripURIScheme } from "ao/utils/helpers";
import { isInFlight } from "ao/utils/useAsyncHelpers";
import React, { memo, useCallback, useEffect, useState } from "react";
import { UrlInput } from "..";

interface IProps {
	JsonWebKeySetUrl: string;
	onChangeCallback: (e: string) => void;
	readonly?: boolean;
	lockPrefix?: boolean;
	placeholder?: string;
	isInvalid?: boolean;
}

type Props = IProps & InputProps;

export const JKUInput: React.FunctionComponent<Props> = memo(
	(props: Props): JSX.Element => {
		const { JsonWebKeySetUrl, placeholder, readonly, lockPrefix, onChangeCallback, ...rest } = props;

		const [validationMsg, setValidationMsg] = useState<string>("");
		const header = extractURIScheme(JsonWebKeySetUrl ?? "");
		const url = stripURIScheme(JsonWebKeySetUrl ?? "");

		const handleChange = useCallback(
			(e: string): void => {
				onChangeCallback(e);
			},
			[onChangeCallback],
		);

		const handleValidationSuccess = useCallback((response: IGenericAPIResponseWrongCasing) => {
			setValidationMsg(!response.Success ? response.Message : "Validated");
		}, []);

		const [loadingState, validateJKU] = useAsync(checkJKUEndpoint, {
			executeImmediately: false,
			displayName: "checkJKUEndpoint",
			onSuccess: handleValidationSuccess,
			onFailure: () => {
				setValidationMsg("");
			},
		});
		//delay for validation api
		useEffect(() => {
			const timer = setTimeout(() => {
				if (header === "https://" && url.length > 0) {
					validateJKU(JsonWebKeySetUrl);
				}
			}, 2000);
			return () => clearTimeout(timer);
		}, [JsonWebKeySetUrl, header, url.length, validateJKU]);

		const isValidating = isInFlight(loadingState);

		return (
			<>
				<UrlInput
					url={JsonWebKeySetUrl}
					readonly={readonly}
					lockPrefix={lockPrefix}
					placeholder={placeholder}
					onChangeCallback={handleChange}
					{...rest}
				></UrlInput>
				{isValidating && <Text>Validating...</Text>}
				{!isValidating && validationMsg && <Text color="rgb(150, 100, 0)">{validationMsg}</Text>}
			</>
		);
	},
);
