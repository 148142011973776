/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file Icon for Sherlock Support Log
 * @module Epic.AppOrchard.Core.SherlockSupportLogIcon
 */

import { Box, BoxProps } from "@chakra-ui/react";
import { ReactComponent as SupportLog } from "images/External/supportlog.svg";
import React, { memo } from "react";

type Props = BoxProps;
/** Icon for Sherlock Support Log */
export const SherlockSupportLogIcon = memo((props: Props) => {
	const { ...rest } = props;
	return (
		<Box minW={4} maxW={4} title="Sherlock Support Log" {...rest}>
			<SupportLog />
		</Box>
	);
});
