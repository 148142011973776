/**
 * @copyright Copyright 2021-2022 Epic Systems Corporation
 * @file PaginatedList component
 * @module Epic.AppOrchard.Core.PaginatedList
 */

import { Box, BoxProps, Divider } from "@chakra-ui/react";
import { childrenWithoutFragment } from "ao/utils/reactHelpers";
import React, { FC, memo, useEffect, useState } from "react";
import { PaginationControl } from "./PaginationControl";

interface IProps {
	entriesPerPage: number;
	showDivider?: boolean;
	/** whether to show the pagination for a single page of results.   */
	showPageButtonsForSinglePage?: boolean;
	children: React.ReactNode;
	/** callback to notify parent of filtered children changing instead of displaying them */
	onPageChanged?: (children: ChildrenArray) => void;
	/** whether to show children (true) or just return them to parent via onPageChanged (false) */
	displayChildren?: boolean;
	buttonVariant?: string;
}

type PaginatedListProps = IProps & BoxProps;
export type ChildrenArray = (React.ReactChild | React.ReactFragment | React.ReactPortal)[];

export const PaginatedList: FC<PaginatedListProps> = memo((props: PaginatedListProps) => {
	const {
		entriesPerPage,
		children,
		showDivider,
		showPageButtonsForSinglePage,
		onPageChanged,
		displayChildren,
		buttonVariant,
		...rest
	} = props;

	const shouldDisplayChildren = displayChildren === undefined || displayChildren === true ? true : false;

	//#region state/hooks
	const [currentPage, setCurrentPage] = useState<number>(0);
	const entries = React.Children.toArray(childrenWithoutFragment(children));
	const [oldChildCount, setOldChildCount] = useState<number>(entries.length);
	const numPages = Math.ceil(entries.length / entriesPerPage);
	const startingIndex = currentPage * entriesPerPage;

	useEffect(() => {
		const pageEntries = entries.slice(startingIndex, startingIndex + entriesPerPage);
		onPageChanged && onPageChanged(pageEntries);

		// change to the first page if the number of children change
		// this is a rudimentary way of checking if the children changed
		// due to a filter value changing but it seems to work
		// and at least prevents the current page from exceeding the last page
		if (entries.length !== oldChildCount) {
			setOldChildCount(entries.length);
			setCurrentPage(0);
		}
	}, [entries, entriesPerPage, oldChildCount, onPageChanged, startingIndex]);

	const handleUpdatePage = (page: number) => {
		setCurrentPage(page - 1);
	};

	const hideControlsForSinglePage = !showPageButtonsForSinglePage && numPages === 1;
	const currentPageDisplay = entries.length === 0 ? 0 : currentPage + 1;

	//#endregion

	if (entries.length === 0 && shouldDisplayChildren) {
		return null;
	} else if (hideControlsForSinglePage && shouldDisplayChildren) {
		return children as React.ReactElement; // as required for TS compiler
	} else {
		return (
			<>
				<Box {...rest}>
					<PaginationControl
						currentPage={currentPageDisplay}
						numPages={numPages}
						handleUpdatePage={handleUpdatePage}
					></PaginationControl>
				</Box>
				{showDivider && <Divider mt="5px" />}
				{shouldDisplayChildren && (
					<Box>{entries.slice(startingIndex, startingIndex + entriesPerPage)}</Box>
				)}
			</>
		);
	}
});
