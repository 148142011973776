/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Body Container component
 * @module Epic.AppOrchard.Frame.BodyContainer
 */

import { Box, BoxProps, Container, useMediaQuery } from "@chakra-ui/react";
import { useUserSecurityState } from "ao/state/userSecurity";
import background from "images/background.png";
import birds from "images/birds.png";
import React, { FC, memo, ReactNode } from "react";

interface IProps {
	children: ReactNode;
	useFullWidth?: boolean;
}

export const BodyContainer: FC<IProps> = memo((props) => {
	const { children, useFullWidth } = props;
	const [isPrinting] = useMediaQuery("print");

	return (
		<Box
			backgroundImage={`url(${birds}), url(${background})`}
			backgroundRepeat="no-repeat, repeat-x"
			backgroundColor="#B9E8FA"
			backgroundPosition="50% 20%, 0 100%"
		>
			<Container
				minH="100%"
				maxW={{
					base: "100%",
					md: useFullWidth ? "none" : "750px",
					lg: useFullWidth ? "none" : "970px",
					xl: useFullWidth ? "none" : "1170px",
					"3xl": useFullWidth ? "none" : "1440px",
				}}
				pb={isPrinting ? 0 : "85px"}
				boxShadow={isPrinting ? "none" : "0 0 15px rgba(0,145,234,.15)"}
				paddingInline="unset"
				backgroundColor={isPrinting ? undefined : "rgba(255,255,255,0.65)"}
			>
				{children}
			</Container>
		</Box>
	);
});

interface IBodyContentProps {
	backgroundColorType?: "transparent" | "white";
	children: ReactNode;
	/** additional height to subtract from body content height */
	subtractHeight?: number;
}
type BodyContentProps = IBodyContentProps & BoxProps;
export const BodyContent: FC<BodyContentProps> = memo((props) => {
	const { children, subtractHeight, backgroundColorType, ...rest } = props;

	const [isPrinting] = useMediaQuery("print");

	const { userSecurity } = useUserSecurityState((selectors) => selectors.getState(), []);

	const minHeight = `calc(100vh - 85px - (${subtractHeight || 0}px + ${
		userSecurity.showWhatsNew ? 1.5 : 0
	}em))`; // 85px from footer

	return (
		<Box
			minH={minHeight}
			p={isPrinting ? "0 10px 0 10px" : "100px 30px 30px 30px"}
			backgroundColor={backgroundColorType === "transparent" ? "rgba(255,255,255,0.0)" : "white"}
			{...rest}
		>
			{children}
		</Box>
	);
});

export interface ShowroomBodyContainerProps {
	children?: React.ReactNode;
}

export const ShowroomBodyContainer: FC<ShowroomBodyContainerProps> = memo((props) => {
	const { children } = props;

	const minHeight = "100vh";

	return (
		<Box
			backgroundSize="cover"
			backgroundColor="white"
			minHeight={minHeight}
			display="flex"
			flexDir="column"
		>
			<Container
				tabIndex={-1}
				maxWidth="100%"
				paddingTop={"95px"} //section
				paddingInline="unset"
				fontFamily="Jost"
				backgroundColor="white"
				overflowX="hidden"
				overflowY="auto"
				display="flex"
				flexGrow="1"
				flexDir="column"
			>
				{children}
			</Container>
		</Box>
	);
});
