/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file state/store for organizations Epic users can select
 * @module Epic.AppOrchard.State.UserOrganizations
 */

import { buildSharedState } from "@epic/react-redux-booster";
import { ProgramLevel } from "ao/types";
import { IOrganizationWithAssignment, IUserAvailableOrganizations } from "../data";
import store from "../store";
import { getDefaultSelectedOrg } from "../utils/helpers";

/// TYPES ///

export interface IState {
	readonly availableOrganizations: IOrganizationWithAssignment[];
	readonly defaultOrganization: IOrganizationWithAssignment | null;
	readonly selectedOrganization: IOrganizationWithAssignment | undefined;
	readonly customerOrgs: IOrganizationWithAssignment[];
	readonly hasLoaded: boolean;
}

/// INIT ///

/**
 * Returns an empty initial state
 */
export function getInitialState(): IState {
	return {
		availableOrganizations: [],
		defaultOrganization: null,
		selectedOrganization: undefined,
		customerOrgs: [],
		hasLoaded: false,
	};
}

/// REDUCERS ///

/**
 * Update user organization information and mark org details as loaded from server
 * @param _state current state
 * @param newDashboardInfo new set of orgs data to update state with
 * @returns
 */
export function updateUserOrganizationsAfterLoad(
	_state: IState,
	userOrganizations: IUserAvailableOrganizations,
): IState {
	if (userOrganizations.organizations.length === 0) {
		return { ...getInitialState(), hasLoaded: true };
	}

	const defaultOrg = getDefaultSelectedOrg(userOrganizations);

	return {
		availableOrganizations: userOrganizations.organizations,
		customerOrgs: userOrganizations.organizations.filter(
			(o) => o.programLevel === ProgramLevel.EpicCommunityMember,
		),
		defaultOrganization: defaultOrg || null,
		// set selected to default after first loading from server
		selectedOrganization: defaultOrg || undefined,
		hasLoaded: true,
	};
}

/**
 * Update default org for user selection
 * @param state current state
 * @param newDefaultOrg new default org
 * @returns
 */
export function updateDefaultOrganization(state: IState, newDefaultOrg: IOrganizationWithAssignment): IState {
	return { ...state, defaultOrganization: newDefaultOrg };
}

/**
 * Update default org for user selection
 * @param _state current state
 * @param newDefaultOrgId new default org ID
 * @returns
 */
export function updateDefaultOrganizationById(state: IState, newDefaultOrgId: number): IState {
	const newDefaultOrg = state.availableOrganizations.find((o) => o.id === newDefaultOrgId);

	return newDefaultOrg ? { ...state, defaultOrganization: newDefaultOrg } : state;
}

/**
 * Update selected org for user selection
 * @param _state current state
 * @param newDefaultOrg new default org
 * @returns
 */
export function updateSelectedOrganization(
	state: IState,
	newSelectedOrg: IOrganizationWithAssignment,
): IState {
	return { ...state, selectedOrganization: newSelectedOrg };
}

/// SELECTORS ///

/**
 * Get current state
 * @param state current state
 * @returns
 */
export function getState(state: IState): IState {
	return state;
}
/// BUILD IT ///

const builtState = buildSharedState({
	init: getInitialState,
	reducers: {
		updateDefaultOrganization,
		updateDefaultOrganizationById,
		updateSelectedOrganization,
		updateUserOrganizationsAfterLoad,
	},
	selectors: {
		getState,
	},
});
store.addSharedState(builtState.sharedState, "userOrganizations");

export const {
	actionCreators: userOrganizationsActions,
	useSharedState: useUserOrganizationsState,
} = builtState;
