/**
 * @copyright Copyright 2021-2022 Epic Systems Corporation
 * @file Organization (vendor or customer) logo
 * @module Epic.AppOrchard.Core.OrganizationLogo
 */

import { Box } from "@chakra-ui/layout";
import { BoxProps } from "@chakra-ui/react";
import { config } from "ao/appConfig";
import { IOrgLogoProps } from "ao/data";
import { isCustomerProgramLevel } from "ao/utils/helpers";
import React, { FC, memo } from "react";

/** Organization (vendor or customer) logo */
export const OrganizationLogo: FC<IOrgLogoProps> = memo((props: IOrgLogoProps) => {
	const { org, logoSize } = props;
	const isEpicCustomer = isCustomerProgramLevel(org.programLevel);

	const titleText = isEpicCustomer ? "Epic community member" : `${config.SiteName} vendor`;

	return isEpicCustomer ? (
		<Box
			as="span"
			display="inline-block!important"
			mr="10px"
			verticalAlign={"middle"}
			className={`usw-customer-icon-${logoSize} usw-slg${org.epicCommunityMemberId}-${logoSize}`}
			title={titleText}
		></Box>
	) : (
		<VendorLogo
			as="span"
			display="inline-block!important"
			mr="10px"
			verticalAlign={"middle"}
			title={titleText}
			logoSize={logoSize}
		/>
	);
});
type VendorLogoProps = Pick<IOrgLogoProps, "logoSize"> & BoxProps;
const VendorLogo: FC<VendorLogoProps> = memo((props: VendorLogoProps) => {
	const { logoSize, ...rest } = props;
	let width = "";
	let height = "";

	switch (logoSize) {
		case "tiny":
			width = height = "16px";
			break;
		case "small":
			width = height = "24px";
			break;
		case "medium":
			width = height = "32px";
			break;
		case "large":
			width = height = "48px";
			break;
	}

	const backgroundImage = require(`images/logo_market_${logoSize}.png`).default;

	return (
		<Box
			backgroundRepeat="no-repeat"
			backgroundPosition="top left"
			backgroundColor="#FFF"
			w={width}
			h={height}
			backgroundImage={`url(${backgroundImage})`}
			{...rest}
		></Box>
	);
});
