/**
 * @copyright Copyright 2022-2023 Epic Systems Corporation
 * @file Site footer component
 * @module Epic.AppOrchard.Frame.Footer
 */

import { Box, Flex, Image, LinkProps, Text, useBreakpointValue, useMediaQuery } from "@chakra-ui/react";
import { AOLink, MessageForm } from "ao/components/Core";
import { IUserSecurity } from "ao/types";
import footer from "images/footer.png";
import footerLogo from "images/footerLogo.png";
import footerBlank from "images/footer_blank.png";
import React, { FC, memo, useCallback, useState } from "react";

interface IFooterLinkProps {
	url: string;
	name: string;
	title?: string;
}

const linkHoverColor = "#FFFFFF";

type FooterLinkProps = IFooterLinkProps & LinkProps;
const FooterLink: FC<FooterLinkProps> = memo((props) => {
	const { url, name, title, ...rest } = props;
	return (
		<Box
			display="inline-block"
			textAlign={{ base: "center", lg: "left", "2xl": "center" }}
			m={{ base: "3px 5px", md: "3px 10px", "2xl": "15px 20px" }}
			flexBasis={{ base: "auto", sm: "calc(50% - 20px)", lg: "unset" }}
			fontSize="medium"
		>
			<AOLink
				textDecoration="underline"
				url={url}
				title={title}
				_hover={{ color: linkHoverColor }}
				{...rest}
			>
				{name}
			</AOLink>
		</Box>
	);
});

interface IProps {
	userSecurity: IUserSecurity;
}

export const Footer: FC<IProps> = memo((props: IProps) => {
	const { userSecurity } = props;

	//#region state/hooks
	const currentYear = new Date().getFullYear();
	const isMobile = useBreakpointValue({ base: true, lg: false });
	const [showMessageForm, setShowMessageForm] = useState(false);
	const hideMessageForm = useCallback(() => setShowMessageForm(false), []);
	const onShowMessageFormClicked = useCallback((ev: React.MouseEvent<HTMLAnchorElement>) => {
		setShowMessageForm(true);
		ev.preventDefault();
		ev.stopPropagation();
		return false;
	}, []);

	const [isPrinting] = useMediaQuery("print");

	const linkColor = isPrinting ? "black" : "rgb(177,168,163)";

	//#endregion

	return (
		<>
			<Box
				as="footer"
				backgroundImage={isPrinting ? undefined : `url(${footer}), url(${footerBlank})`}
				minH="85px"
				h="auto"
				mt="-85px"
				paddingTop="15px"
				backgroundRepeat="repeat-x, repeat"
				display={isPrinting ? "table-footer-group" : "flex"}
			>
				<Flex
					wrap={{ base: "wrap", lg: "nowrap" }}
					pt={{ base: "10px", lg: 0 }}
					margin="auto"
					alignItems="center"
					width="100%"
				>
					<AOLink url="https://www.epic.com" title="Epic homepage" flex="1 1 auto" maxW="192px">
						<Image
							h={{ base: "30px", sm: "40px", lg: "unset" }}
							ml={{ base: "-40px", lg: "unset" }}
							maxW="none"
							src={footerLogo}
						/>
					</AOLink>

					{!isPrinting && (
						<Flex
							textAlign="left"
							wrap={{ base: "wrap", "2xl": "unset" }}
							margin={{ base: "0", md: "2px", lg: "15px 20px" }}
							grow={{ base: "unset", md: 1, lg: "unset" }}
							shrink={{ base: 1, md: "unset" }}
						>
							<FooterLink
								url={"FAQ/Index"}
								name={isMobile ? "FAQs" : "Frequently Asked Questions (FAQs)"}
								color={linkColor}
							/>
							<FooterLink url={"Resources/Privacy"} name="Privacy Policy" color={linkColor} />
							<FooterLink
								url="#"
								onClick={onShowMessageFormClicked}
								name="Contact"
								color={linkColor}
							/>
							<FooterLink url={"Resources/Index"} name="Resources" color={linkColor} />
						</Flex>
					)}

					<Box
						color={linkColor}
						float="right"
						textAlign={{ base: "left", lg: "right" }}
						pr="20px"
						flexGrow={1}
						pl={{ base: "10px", lg: "unset" }}
						lineHeight={{ base: "1em", sm: "unset" }}
					>
						<Text fontSize={{ base: "x-small", sm: "medium" }}>
							1979 Milky Way, Verona, WI 53593
						</Text>
						<Box fontSize="x-small">
							&#169; 1979-{currentYear} Epic Systems Corporation. All rights reserved. Protected
							by U.S. patents. For details visit{" "}
							<AOLink
								url="https://www.epic.com/patents"
								target="_blank"
								color={linkColor}
								_hover={{ color: linkHoverColor }}
							>
								www.epic.com/patents
							</AOLink>
							.
						</Box>
						<Box fontSize="x-small">
							Epic is a registered trademark of Epic Systems Corporation.
						</Box>
					</Box>
				</Flex>
			</Box>
			<MessageForm isOpen={showMessageForm} onClose={hideMessageForm} userSecurity={userSecurity} />
		</>
	);
});
