/**
 * @copyright Copyright 2024 Epic Systems Corporation
 * @file Connector Management route
 * @module Epic.AppOrchard.Routes.ConnectorManagement
 */

import ConnectorManagementTabPanel from "ao/components/ConnectorManagement/frameworkComponents/ConnectorManagementTabPanel";
import { IRouteInfo } from "ao/routes";
import lazyRetry from "ao/utils/lazy-retry";
import { generatePath, Navigate } from "react-router-dom";

/*
 *
 * IMPORTANT! READ THIS!
 *
 * The contents of this file are loaded and executed during the initial load of the application
 * in the browser even if the route is never actually accessed. DO NOT put any additional code
 * in this file, or import any addition modules as doing so would just add extra bloat to the
 * initial load / startup time for the application.
 *
 * IMPORTANT! READ THIS!
 *
 */

const relativePath = "/Connectors/Management/";
const path = process.env.REACT_APP_DEV_URL_PREFIX + relativePath;

export const getConnectorManagementListsUrl = () => {
	return generatePath(path);
};

export const getConnectorManagementListsRelativeUrl = () => {
	return generatePath(relativePath);
};

const route: IRouteInfo = {
	path,
	component: lazyRetry(() =>
		import("../ao/components/ConnectorManagement/frameworkComponents").then((module) => ({
			default: module.ConnectorManagement,
		})),
	),
	title: "ConnectorManagementLists",
	showFullWidth: true,
	children: [
		{
			index: true,
			ChildElement: Navigate,
			redirectUrl: "Apps",
			replace: true,
		},
		{
			childPath: "Apps",
			ChildElement: ConnectorManagementTabPanel,
		},
	],
};

export default route;
