/**
 * @copyright Copyright 2023 Epic Systems Corporation
 * @file SVG assets for Showroom
 * @module Epic.AppOrchard.Data.Assets
 */

import React, { SVGProps } from "react";

export const ShowroomLogoIcon: React.FC<SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 66.22 49.67" {...props}>
			<defs>
				<style>
					{
						".cls-1{fill:#f4b0b0;mix-blend-mode:multiply}.cls-1,.cls-10,.cls-4,.cls-6{stroke-width:0}.cls-4{fill:#f9cf71}.cls-6{fill:#f9c5a1}.cls-10{fill:#f2634c}"
					}
				</style>
			</defs>
			<g
				style={{
					isolation: "isolate",
				}}
			>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path
							d="M31.97 39.64h.05-.06Z"
							style={{
								fill: "none",
								strokeWidth: 0,
							}}
						/>
						<path
							d="M31.97 39.64c-5.17.01-9.83.58-13.22 1.48 3.38-.9 8.04-1.46 13.22-1.48ZM15.49 36.13l-2.16 8.06c-6.88-.42-12.06-2.04-12.06-3.99 0-2.15 6.26-3.91 14.22-4.07Z"
							className="cls-1"
						/>
						<path
							d="M50.86 43.94c.06.1.1.21.14.31 8.36-.27 14.83-2.16 14.83-4.45 0-2.47-7.58-4.48-16.92-4.48-.94 0-1.85.02-2.75.06l4.7 8.56ZM32.15 39.64c8.87 0 16.29 1.62 18.34 3.8-2.05-2.18-9.47-3.8-18.34-3.8h-.12.12Z"
							className="cls-4"
						/>
						<path
							d="M31.97 39.64h.06c.04-.26 9.72-12.23 9.72-12.23L27.73 2.13c-.73-1.33-2.71-1.1-3.1.37l-7.1 26.12s14.25 10.64 14.44 11.02ZM46.16 35.38c-5.08.22-9.4 1.04-11.89 2.18 2.49-1.14 6.8-1.96 11.89-2.18Z"
							className="cls-10"
						/>
						<path
							d="M31.97 39.64c-1.29.01-2.55.04-3.77.11-1.82.1-3.55.27-5.14.51-1.58.23-3.03.52-4.3.86h-.01a20.374 20.374 0 0 0-2.06.65c-.31.11-.6.23-.87.36-.27.12-.52.25-.75.38-.93.51-1.53 1.08-1.74 1.68l2.16-8.06 2.04-7.51s14.25 10.64 14.44 11.02Z"
							style={{
								fill: "#aa2332",
								strokeWidth: 0,
							}}
						/>
						<path
							d="M50.86 43.94c-.08-.15-.19-.3-.32-.45 0-.02-.04-.04-.05-.05-2.05-2.19-9.47-3.8-18.34-3.8h-.12c.04-.26 9.72-12.22 9.72-12.22l4.42 7.97 4.69 8.56Z"
							style={{
								fill: "#f0492c",
								strokeWidth: 0,
							}}
						/>
						<path
							d="M32.11 40.31c-.24 2.21-7.04 3.98-15.41 3.98-1.16 0-2.28-.04-3.37-.1 0 .03-.03.05-.04.08v.01c-.04.12-.07.24-.07.37 0 2.77 8.48 5.01 18.93 5.01s18.93-2.25 18.93-5.01c0-.13-.03-.27-.08-.4-.69.02-1.38.04-2.09.04-8.69 0-15.84-1.74-16.8-3.98Z"
							className="cls-6"
						/>
						<path
							d="M51 44.25c-.69.03-1.38.04-2.09.04-8.69 0-15.85-1.74-16.8-3.98-.24 2.2-7.04 3.98-15.41 3.98-1.16 0-2.28-.04-3.37-.11.41-1.2 2.41-2.27 5.43-3.07 3.38-.9 8.04-1.46 13.21-1.48h.18c8.87 0 16.29 1.62 18.34 3.8l.05.05c.13.14.24.29.32.45.05.11.1.21.14.31Z"
							style={{
								fill: "#f4d2bf",
								strokeWidth: 0,
							}}
						/>
						<path
							d="M18.75 41.12c-3.02.8-5.01 1.88-5.42 3.07.41-1.2 2.41-2.27 5.42-3.07ZM50.49 43.44s.04.03.06.05c-.02-.02-.04-.03-.06-.05Z"
							className="cls-6"
						/>
						<path
							d="m17.53 28.62-2.04 7.51c-7.96.16-14.22 1.92-14.22 4.07 0 0-1.27-21.27-1.27-21.32 0-.93.75-1.68 1.68-1.68.42 0 .8.15 1.09.42h.01l14.75 11Z"
							style={{
								fill: "#cd1543",
								strokeWidth: 0,
							}}
						/>
						<path
							d="m66.22 1.34-.39 38.46c0-2.47-7.57-4.48-16.92-4.48-.94 0-1.85.02-2.75.06l-4.42-7.97S63.82.45 63.83.44c.25-.27.61-.44 1.01-.44.75 0 1.36.59 1.38 1.34Z"
							style={{
								fill: "#f89c5d",
								strokeWidth: 0,
							}}
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export const ShowroomLogoDark: React.FC<SVGProps<SVGSVGElement>> = (props) => {
	return (
		<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306.03 49.67" {...props}>
			<defs>
				<style>
					{
						".cls-1{fill:#f4b0b0;mix-blend-mode:multiply}.cls-1,.cls-11,.cls-4,.cls-6,.cls-7,.cls-9{stroke-width:0}.cls-4{fill:#f9cf71}.cls-6{fill:#f9c5a1}.cls-7{fill:#414042}.cls-9{fill:#cd1543}.cls-11{fill:#f2634c}"
					}
				</style>
			</defs>
			<g
				style={{
					isolation: "isolate",
				}}
			>
				<g id="Layer_2" data-name="Layer 2">
					<g id="Layer_1-2" data-name="Layer 1">
						<path
							d="M31.97 39.64h.05-.06Z"
							style={{
								fill: "none",
								strokeWidth: 0,
							}}
						/>
						<path
							d="M31.97 39.64c-5.17.01-9.83.58-13.22 1.48 3.38-.9 8.04-1.46 13.22-1.48ZM15.49 36.13l-2.16 8.06c-6.88-.42-12.06-2.04-12.06-3.99 0-2.15 6.26-3.91 14.22-4.07Z"
							className="cls-1"
						/>
						<path
							d="M50.86 43.94c.06.1.1.21.14.31 8.36-.27 14.83-2.16 14.83-4.45 0-2.47-7.58-4.48-16.92-4.48-.94 0-1.85.02-2.75.06l4.7 8.56ZM32.15 39.64c8.87 0 16.29 1.62 18.34 3.8-2.05-2.18-9.47-3.8-18.34-3.8h-.12.12Z"
							className="cls-4"
						/>
						<path
							d="M31.97 39.64h.06c.04-.26 9.72-12.23 9.72-12.23L27.73 2.13c-.73-1.33-2.71-1.1-3.1.37l-7.1 26.12s14.25 10.64 14.44 11.02ZM46.16 35.38c-5.08.22-9.4 1.04-11.89 2.18 2.49-1.14 6.8-1.96 11.89-2.18Z"
							className="cls-11"
						/>
						<path
							d="M31.97 39.64c-1.29.01-2.55.04-3.77.11-1.82.1-3.55.27-5.14.51-1.58.23-3.03.52-4.3.86h-.01a20.374 20.374 0 0 0-2.06.65c-.31.11-.6.23-.87.36-.27.12-.52.25-.75.38-.93.51-1.53 1.08-1.74 1.68l2.16-8.06 2.04-7.51s14.25 10.64 14.44 11.02Z"
							style={{
								fill: "#aa2332",
								strokeWidth: 0,
							}}
						/>
						<path
							d="M50.86 43.94c-.08-.15-.19-.3-.32-.45 0-.02-.04-.04-.05-.05-2.05-2.19-9.47-3.8-18.34-3.8h-.12c.04-.26 9.72-12.22 9.72-12.22l4.42 7.97 4.69 8.56Z"
							style={{
								fill: "#f0492c",
								strokeWidth: 0,
							}}
						/>
						<path
							d="M32.11 40.31c-.24 2.21-7.04 3.98-15.41 3.98-1.16 0-2.28-.04-3.37-.1 0 .03-.03.05-.04.08v.01c-.04.12-.07.24-.07.37 0 2.77 8.48 5.01 18.93 5.01s18.93-2.25 18.93-5.01c0-.13-.03-.27-.08-.4-.69.02-1.38.04-2.09.04-8.69 0-15.84-1.74-16.8-3.98Z"
							className="cls-6"
						/>
						<path
							d="M51 44.25c-.69.03-1.38.04-2.09.04-8.69 0-15.85-1.74-16.8-3.98-.24 2.2-7.04 3.98-15.41 3.98-1.16 0-2.28-.04-3.37-.11.41-1.2 2.41-2.27 5.43-3.07 3.38-.9 8.04-1.46 13.21-1.48h.18c8.87 0 16.29 1.62 18.34 3.8l.05.05c.13.14.24.29.32.45.05.11.1.21.14.31Z"
							style={{
								fill: "#f4d2bf",
								strokeWidth: 0,
							}}
						/>
						<path
							d="M18.75 41.12c-3.02.8-5.01 1.88-5.42 3.07.41-1.2 2.41-2.27 5.42-3.07ZM50.49 43.44s.04.03.06.05c-.02-.02-.04-.03-.06-.05Z"
							className="cls-6"
						/>
						<path
							d="m17.53 28.62-2.04 7.51c-7.96.16-14.22 1.92-14.22 4.07 0 0-1.27-21.27-1.27-21.32 0-.93.75-1.68 1.68-1.68.42 0 .8.15 1.09.42h.01l14.75 11Z"
							className="cls-9"
						/>
						<path
							d="m66.22 1.34-.39 38.46c0-2.47-7.57-4.48-16.92-4.48-.94 0-1.85.02-2.75.06l-4.42-7.97S63.82.45 63.83.44c.25-.27.61-.44 1.01-.44.75 0 1.36.59 1.38 1.34Z"
							style={{
								fill: "#f89c5d",
								strokeWidth: 0,
							}}
						/>
						<path
							d="m75.77 39.83 4.26-2.46c1.12 1.83 2.58 3.17 5.15 3.17 2.17 0 3.55-1.08 3.55-2.58 0-1.79-1.42-2.43-3.81-3.47l-1.31-.56c-3.77-1.61-6.27-3.62-6.27-7.88 0-3.92 2.99-6.91 7.65-6.91 3.32 0 5.71 1.16 7.43 4.18l-4.07 2.61c-.9-1.61-1.87-2.24-3.36-2.24s-2.5.97-2.5 2.24c0 1.57.97 2.2 3.21 3.17l1.31.56c4.44 1.9 6.95 3.85 6.95 8.21 0 4.71-3.7 7.28-8.66 7.28s-7.99-2.31-9.52-5.34ZM99.89 19.44h5.23v12.99h10.87V19.44h5.23v25.39h-5.23V37.1h-10.87v7.73h-5.23V19.44ZM127.52 32.14c0-7.5 5.83-13.03 13.14-13.03s13.14 5.53 13.14 13.03-5.82 13.03-13.14 13.03-13.14-5.53-13.14-13.03Zm20.91 0c0-4.74-3.14-8.36-7.77-8.36s-7.77 3.62-7.77 8.36 3.14 8.36 7.77 8.36 7.77-3.62 7.77-8.36ZM155.61 19.44h5.53l3.7 10.19c.63 1.75 1.34 4.59 1.34 4.59s.75-2.88 1.57-4.59l4.89-10.42h.52l4.97 10.42c.82 1.72 1.57 4.59 1.57 4.59s.71-2.84 1.34-4.59l3.66-10.19h5.08l-9.22 25.61h-.52l-5.71-12.25c-.67-1.42-1.42-3.77-1.42-3.77s-.78 2.35-1.46 3.77l-5.94 12.25h-.52l-9.37-25.61ZM213.82 44.83h-6.05l-4.18-6.42c-.49.07-1.01.11-1.49.11h-1.79v6.31h-5.23V19.44h7.02c7.09 0 11.35 3.4 11.35 9.6 0 3.96-1.75 6.46-4.89 7.88l5.26 7.92Zm-11.87-10.87c4.26 0 6.12-1.34 6.12-4.93s-1.94-5-6.12-5h-1.64v9.93h1.64ZM218.01 32.14c0-7.5 5.83-13.03 13.14-13.03s13.14 5.53 13.14 13.03-5.82 13.03-13.14 13.03-13.14-5.53-13.14-13.03Zm20.91 0c0-4.74-3.14-8.36-7.77-8.36s-7.77 3.62-7.77 8.36 3.14 8.36 7.77 8.36 7.77-3.62 7.77-8.36ZM248.74 32.14c0-7.5 5.83-13.03 13.14-13.03s13.14 5.53 13.14 13.03-5.82 13.03-13.14 13.03-13.14-5.53-13.14-13.03Zm20.91 0c0-4.74-3.14-8.36-7.77-8.36s-7.77 3.62-7.77 8.36 3.14 8.36 7.77 8.36 7.77-3.62 7.77-8.36ZM280.34 19.18h.41l12.51 13.74 12.32-13.74h.45v25.65h-5.04v-9.52c0-1.72.19-4.44.19-4.44s-1.53 2.24-2.73 3.58l-5.04 5.64h-.49l-5.04-5.64c-1.2-1.34-2.73-3.58-2.73-3.58s.19 2.73.19 4.44v9.52h-5V19.18Z"
							className="cls-7"
						/>
						<path d="M129.24.75h-3.71l-.47 2.24h3.71l.47-2.24z" className="cls-9" />
						<path
							d="M134.65 6.13c1.41 0 2.07.48 2.16 1.32h3.84c.13-2.78-2.13-3.77-5.54-3.77s-6.2 1.27-7.32 3.84l.73-3.46h-3.71l-.68 3.21c-.16-2.23-1.85-3.46-4.59-3.46-1.5 0-2.93.54-3.92 1.5h-.03l.27-1.26h-3.6l-1.44 6.78h-8.23l.39-2.13h7.35l.55-2.59h-7.35l.4-2.08h8.34l.57-2.69h-12.5l-2.49 12.18h12.39l-.73 3.43h3.71l.91-4.28h.03c.48.7 1.62 1.24 3.27 1.24 2.81 0 4.98-1.45 6.02-3.45l-.65 3.07h3.71l.72-3.41c.02 2.58 2.08 3.79 5.7 3.79 4.08 0 6.24-1.48 7.39-4.1h-3.92c-.52 1.11-1.55 1.65-2.88 1.65-1.78 0-2.59-.96-2.23-2.67.36-1.71 1.62-2.67 3.35-2.67Zm-14.43 2.69c-.33 1.57-1.44 2.41-3.13 2.41s-2.44-.94-2.13-2.41c.31-1.47 1.42-2.41 3.15-2.41s2.44.84 2.11 2.41Z"
							className="cls-9"
						/>
					</g>
				</g>
			</g>
		</svg>
	);
};

export const ShowroomLogoLight: React.FC<SVGProps<SVGSVGElement>> = (props) => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 306.03 49.67" {...props}>
		<defs>
			<style>
				{
					".cls-1{fill:#f4b0b0;mix-blend-mode:multiply}.cls-1,.cls-11,.cls-4,.cls-6,.cls-8,.cls-9{stroke-width:0}.cls-4{fill:#f9cf71}.cls-6{fill:#f9c5a1}.cls-8{fill:#cd1543}.cls-9{fill:#f1f2f2}.cls-11{fill:#f2634c}"
				}
			</style>
		</defs>
		<g
			style={{
				isolation: "isolate",
			}}
		>
			<g id="Layer_2" data-name="Layer 2">
				<g id="Layer_1-2" data-name="Layer 1">
					<path
						d="M31.97 39.64h.05-.06Z"
						style={{
							fill: "none",
							strokeWidth: 0,
						}}
					/>
					<path
						d="M31.97 39.64c-5.17.01-9.83.58-13.22 1.48 3.38-.9 8.04-1.46 13.22-1.48ZM15.49 36.13l-2.16 8.06c-6.88-.42-12.06-2.04-12.06-3.99 0-2.15 6.26-3.91 14.22-4.07Z"
						className="cls-1"
					/>
					<path
						d="M50.86 43.94c.06.1.1.21.14.31 8.36-.27 14.83-2.16 14.83-4.45 0-2.47-7.58-4.48-16.92-4.48-.94 0-1.85.02-2.75.06l4.7 8.56ZM32.15 39.64c8.87 0 16.29 1.62 18.34 3.8-2.05-2.18-9.47-3.8-18.34-3.8h-.12.12Z"
						className="cls-4"
					/>
					<path
						d="M31.97 39.64h.06c.04-.26 9.72-12.23 9.72-12.23L27.73 2.13c-.73-1.33-2.71-1.1-3.1.37l-7.1 26.12s14.25 10.64 14.44 11.02ZM46.16 35.38c-5.08.22-9.4 1.04-11.89 2.18 2.49-1.14 6.8-1.96 11.89-2.18Z"
						className="cls-11"
					/>
					<path
						d="M31.97 39.64c-1.29.01-2.55.04-3.77.11-1.82.1-3.55.27-5.14.51-1.58.23-3.03.52-4.3.86h-.01a20.374 20.374 0 0 0-2.06.65c-.31.11-.6.23-.87.36-.27.12-.52.25-.75.38-.93.51-1.53 1.08-1.74 1.68l2.16-8.06 2.04-7.51s14.25 10.64 14.44 11.02Z"
						style={{
							fill: "#aa2332",
							strokeWidth: 0,
						}}
					/>
					<path
						d="M50.86 43.94c-.08-.15-.19-.3-.32-.45 0-.02-.04-.04-.05-.05-2.05-2.19-9.47-3.8-18.34-3.8h-.12c.04-.26 9.72-12.22 9.72-12.22l4.42 7.97 4.69 8.56Z"
						style={{
							fill: "#f0492c",
							strokeWidth: 0,
						}}
					/>
					<path
						d="M32.11 40.31c-.24 2.21-7.04 3.98-15.41 3.98-1.16 0-2.28-.04-3.37-.1 0 .03-.03.05-.04.08v.01c-.04.12-.07.24-.07.37 0 2.77 8.48 5.01 18.93 5.01s18.93-2.25 18.93-5.01c0-.13-.03-.27-.08-.4-.69.02-1.38.04-2.09.04-8.69 0-15.84-1.74-16.8-3.98Z"
						className="cls-6"
					/>
					<path
						d="M51 44.25c-.69.03-1.38.04-2.09.04-8.69 0-15.85-1.74-16.8-3.98-.24 2.2-7.04 3.98-15.41 3.98-1.16 0-2.28-.04-3.37-.11.41-1.2 2.41-2.27 5.43-3.07 3.38-.9 8.04-1.46 13.21-1.48h.18c8.87 0 16.29 1.62 18.34 3.8l.05.05c.13.14.24.29.32.45.05.11.1.21.14.31Z"
						style={{
							fill: "#f4d2bf",
							strokeWidth: 0,
						}}
					/>
					<path
						d="M18.75 41.12c-3.02.8-5.01 1.88-5.42 3.07.41-1.2 2.41-2.27 5.42-3.07ZM50.49 43.44s.04.03.06.05c-.02-.02-.04-.03-.06-.05Z"
						className="cls-6"
					/>
					<path
						d="m17.53 28.62-2.04 7.51c-7.96.16-14.22 1.92-14.22 4.07 0 0-1.27-21.27-1.27-21.32 0-.93.75-1.68 1.68-1.68.42 0 .8.15 1.09.42h.01l14.75 11Z"
						style={{
							fill: "#F26B8D",
						}}
					/>
					<path
						d="m66.22 1.34-.39 38.46c0-2.47-7.57-4.48-16.92-4.48-.94 0-1.85.02-2.75.06l-4.42-7.97S63.82.45 63.83.44c.25-.27.61-.44 1.01-.44.75 0 1.36.59 1.38 1.34Z"
						style={{
							fill: "#f89c5d",
							strokeWidth: 0,
						}}
					/>
					<path
						d="m75.77 39.83 4.26-2.46c1.12 1.83 2.58 3.17 5.15 3.17 2.17 0 3.55-1.08 3.55-2.58 0-1.79-1.42-2.43-3.81-3.47l-1.31-.56c-3.77-1.61-6.27-3.62-6.27-7.88 0-3.92 2.99-6.91 7.65-6.91 3.32 0 5.71 1.16 7.43 4.18l-4.07 2.61c-.9-1.61-1.87-2.24-3.36-2.24s-2.5.97-2.5 2.24c0 1.57.97 2.2 3.21 3.17l1.31.56c4.44 1.9 6.95 3.85 6.95 8.21 0 4.71-3.7 7.28-8.66 7.28s-7.99-2.31-9.52-5.34ZM99.89 19.44h5.23v12.99h10.87V19.44h5.23v25.39h-5.23V37.1h-10.87v7.73h-5.23V19.44ZM127.52 32.14c0-7.5 5.83-13.03 13.14-13.03s13.14 5.53 13.14 13.03-5.82 13.03-13.14 13.03-13.14-5.53-13.14-13.03Zm20.91 0c0-4.74-3.14-8.36-7.77-8.36s-7.77 3.62-7.77 8.36 3.14 8.36 7.77 8.36 7.77-3.62 7.77-8.36ZM155.61 19.44h5.53l3.7 10.19c.63 1.75 1.34 4.59 1.34 4.59s.75-2.88 1.57-4.59l4.89-10.42h.52l4.97 10.42c.82 1.72 1.57 4.59 1.57 4.59s.71-2.84 1.34-4.59l3.66-10.19h5.08l-9.22 25.61h-.52l-5.71-12.25c-.67-1.42-1.42-3.77-1.42-3.77s-.78 2.35-1.46 3.77l-5.94 12.25h-.52l-9.37-25.61ZM213.82 44.83h-6.05l-4.18-6.42c-.49.07-1.01.11-1.49.11h-1.79v6.31h-5.23V19.44h7.02c7.09 0 11.35 3.4 11.35 9.6 0 3.96-1.75 6.46-4.89 7.88l5.26 7.92Zm-11.87-10.87c4.26 0 6.12-1.34 6.12-4.93s-1.94-5-6.12-5h-1.64v9.93h1.64ZM218.01 32.14c0-7.5 5.83-13.03 13.14-13.03s13.14 5.53 13.14 13.03-5.82 13.03-13.14 13.03-13.14-5.53-13.14-13.03Zm20.91 0c0-4.74-3.14-8.36-7.77-8.36s-7.77 3.62-7.77 8.36 3.14 8.36 7.77 8.36 7.77-3.62 7.77-8.36ZM248.74 32.14c0-7.5 5.83-13.03 13.14-13.03s13.14 5.53 13.14 13.03-5.82 13.03-13.14 13.03-13.14-5.53-13.14-13.03Zm20.91 0c0-4.74-3.14-8.36-7.77-8.36s-7.77 3.62-7.77 8.36 3.14 8.36 7.77 8.36 7.77-3.62 7.77-8.36ZM280.34 19.18h.41l12.51 13.74 12.32-13.74h.45v25.65h-5.04v-9.52c0-1.72.19-4.44.19-4.44s-1.53 2.24-2.73 3.58l-5.04 5.64h-.49l-5.04-5.64c-1.2-1.34-2.73-3.58-2.73-3.58s.19 2.73.19 4.44v9.52h-5V19.18Z"
						style={{
							fill: "#f1f2f2",
						}}
					/>
					<path d="M129.24.75h-3.71l-.47 2.24h3.71l.47-2.24z" className="cls-8" />
					<path
						d="M134.65 6.13c1.41 0 2.07.48 2.16 1.32h3.84c.13-2.78-2.13-3.77-5.54-3.77s-6.2 1.27-7.32 3.84l.73-3.46h-3.71l-.68 3.21c-.16-2.23-1.85-3.46-4.59-3.46-1.5 0-2.93.54-3.92 1.5h-.03l.27-1.26h-3.6l-1.44 6.78h-8.23l.39-2.13h7.35l.55-2.59h-7.35l.4-2.08h8.34l.57-2.69h-12.5l-2.49 12.18h12.39l-.73 3.43h3.71l.91-4.28h.03c.48.7 1.62 1.24 3.27 1.24 2.81 0 4.98-1.45 6.02-3.45l-.65 3.07h3.71l.72-3.41c.02 2.58 2.08 3.79 5.7 3.79 4.08 0 6.24-1.48 7.39-4.1h-3.92c-.52 1.11-1.55 1.65-2.88 1.65-1.78 0-2.59-.96-2.23-2.67.36-1.71 1.62-2.67 3.35-2.67Zm-14.43 2.69c-.33 1.57-1.44 2.41-3.13 2.41s-2.44-.94-2.13-2.41c.31-1.47 1.42-2.41 3.15-2.41s2.44.84 2.11 2.41Z"
						style={{
							fill: "#f1f2f2",
						}}
					/>
				</g>
			</g>
		</g>
	</svg>
);
