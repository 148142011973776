/**
 * @copyright Copyright 2021-2023 Epic Systems Corporation
 * @file Codegen plumbing for route registration
 * @author Erv Walter
 * @module Epic.Template.App.Routes
 */

import codegen from "babel-plugin-codegen/macro";
import { ComponentType } from "react";
import { ITopLevelComponentProps } from "./types";

export interface IRouteInfo {
	path: string;
	title?: string;
	component: ComponentType<ITopLevelComponentProps>;
	showFullWidth?: boolean;
	children?: IChildRouteInfo[];
}

export interface IChildRouteInfo {
	index?: boolean;
	childPath?: string;
	ChildElement: React.ElementType;
	redirectUrl?: string;
	replace?: boolean;
}

const routes: IRouteInfo[] = codegen.require("./registered-routes.js");

export default routes;
