/**
 * @copyright Copyright 2021 Epic Systems Corporation
 * @file Agreement modal that shows an agreement PDF and accept decline options
 * @module Epic.AppOrchard.Core.AgreementModal
 */

import {
	Box,
	Button,
	Checkbox,
	Divider,
	Flex,
	FormControl,
	Link,
	Modal,
	ModalBody,
	ModalCloseButton,
	ModalContent,
	ModalFooter,
	ModalHeader,
	ModalOverlay,
	Spacer,
	Text,
} from "@chakra-ui/react";
import React, { ChangeEvent, FC, memo } from "react";

interface IProps {
	isOpen: boolean;
	/* modal header */
	header: string;
	termsUrl: string;
	/* URL where terms can be downloaded */
	downloadTermsUrl: string;
	/* describe the terms document for users who are using a screenreader */
	termsAriaLabel: string;
	/* caption of the accept button */
	acceptCaption: string;
	/* text to show beneath the accept button, e.g. to explain the effect of accepting terms */
	acceptEffectText?: string;
	additionalDetails?: string;
	cancelCaption?: string;
	/* callback for closing the modal */
	onClose: () => void;
	onAcceptCheckedChanged: (ev: ChangeEvent<HTMLInputElement>) => void;
	acceptChecked: boolean;
	onAgree: () => void;
}

export const AgreementModal: FC<IProps> = memo((props: IProps) => {
	const {
		isOpen,
		header,
		termsUrl,
		termsAriaLabel,
		downloadTermsUrl,
		acceptCaption,
		acceptEffectText,
		acceptChecked,
		additionalDetails,
		cancelCaption,
		onClose,
		onAcceptCheckedChanged,
		onAgree,
	} = props;

	return (
		<Modal isOpen={isOpen} onClose={onClose} size="6xl" preserveScrollBarGap isCentered>
			<ModalOverlay />
			{/* We'll want to clear out this margin top for consistency when React is no longer in an iframe.
			  It's here because modals in the iframe don't take up the full window and the agreement
			  modals can be somewhat long so the bottom buttons can get cut off otherwise. */}
			<ModalContent mt="1rem">
				<ModalHeader>{header}</ModalHeader>
				<ModalCloseButton />
				<ModalBody>
					<object
						type="application/pdf"
						data={termsUrl}
						aria-label={termsAriaLabel}
						style={{ width: "100%", height: "480px" }}
					></object>
					<Text mt="1em">
						Don't see the terms document above?{" "}
						<Link href={downloadTermsUrl}>Click to download it.</Link>
					</Text>
					{additionalDetails && <Text mt="1em">{additionalDetails}</Text>}
				</ModalBody>

				<Divider />
				<ModalFooter display="block" pb="1em" pt="1em">
					<Flex>
						<FormControl id="AgreeToTerms">
							<Checkbox isChecked={acceptChecked} onChange={onAcceptCheckedChanged}>
								I agree to the terms outlined above
							</Checkbox>
						</FormControl>
						<Spacer />
						<Box>
							<Flex>
								<Spacer />
								<Button
									colorScheme="blue"
									onClick={onAgree}
									disabled={!acceptChecked}
									title={
										!acceptChecked
											? "You need to agree to the terms before continuing"
											: ""
									}
									mr={2}
								>
									{acceptCaption}
								</Button>
								<Button onClick={onClose}>{cancelCaption || "Disagree"}</Button>
							</Flex>
							{acceptEffectText && <Text mt="1em">{acceptEffectText}</Text>}
						</Box>
					</Flex>
				</ModalFooter>
			</ModalContent>
		</Modal>
	);
});
